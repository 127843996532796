import React from "react";

import Layout from "./Layout";
import Input from "./Input";
import Button from "./Button";

export default function MustHaves() {
 return (
  <Layout>
   <div className="px-6 py-6 flex flex-row bg-red-500 md:flex-col md:px-0 md:py-0 md:bg-gray-200">
    <div className="flex items-center">
     <h1 className="text-2xl font-bold text-white md:text-gray-800">
      Must haves
     </h1>
    </div>
    <p className="mt-3 text-sm text-gray-500">
     Things you always want to pack, and never want to forget.
    </p>
   </div>

   <div className="px-4 mt-6 md:px-0 flex">
    <Input name="name" placeholder="Tape" />
    <Input type="number" name="amount" placeholder="1" />
    <Button text="Add" />
   </div>
  </Layout>
 );
}
