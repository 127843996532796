const initialState = {
 text: "",
 tab: "no-date", // 'no-date', 'coming-up', 'past' or 'template'
 newPlan: {
  name: "",
  dateDependant: false,
  riggingDateStart: "",
  riggingDateEnd: "",
  eventDateStart: "",
  eventDateEnd: "",
 },
 gearText: "",
 gearTab: "all", // 'all', 'private', 'public'
};

export default function filtersReducer(state = initialState, action) {
 switch (action.type) {
  case "TEXT_FILTER":
   return {
    ...state,
    text: action.text,
   };

  case "TAB_FILTER":
   return {
    ...state,
    tab: action.tab,
   };

  case "NEW_PLAN_UPDATE":
   return {
    ...state,
    newPlan: {
     ...state.newPlan,
     ...action.update,
    },
   };

  case "NEW_PLAN_CLEAR":
   return {
    ...state,
    newPlan: {
     ...initialState.newPlan,
    },
   };

  case "GEAR_TEXT_FILTER":
   return {
    ...state,
    gearText: action.text,
   };

  case "GEAR_TAB_FILTER":
   return {
    ...state,
    gearTab: action.tab,
   };

  default:
   return state;
 }
}
