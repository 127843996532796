import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Sidebar from "./Sidebar";

export default function Layout({ title = "", children }) {
 const [sidebarShow, setSidebarShown] = useState(false);
 return (
  <div className=" flex w-full text-gray-800 dark:text-gray-400">
   <Helmet>
    <meta charSet="utf-8" />
    <title>H2R Gear{title ? ` - ${title}` : ""}</title>
   </Helmet>
   <Sidebar sidebarShown={sidebarShow} />
   <button
    className="absolute right-0 top-2 p-4 z-20 inline md:hidden text-white"
    title="Menu"
    onClick={() => {
     setSidebarShown(!sidebarShow);
    }}
   >
    <svg
     xmlns="http://www.w3.org/2000/svg"
     fill="none"
     viewBox="0 0 24 24"
     strokeWidth={2}
     stroke="currentColor"
     className="w-8 h-8"
    >
     {sidebarShow ? (
      <path
       strokeLinecap="round"
       strokeLinejoin="round"
       d="M6 18L18 6M6 6l12 12"
      />
     ) : (
      <path
       strokeLinecap="round"
       strokeLinejoin="round"
       d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
      />
     )}
    </svg>
   </button>
   <div
    style={{ height: "100svh" }}
    className="bg-gray-200 dark:bg-gray-800 flex-1 overflow-auto md:px-6 lg:px-20 md:py-12"
   >
    {children}
   </div>
  </div>
 );
}
