import { store } from "../db/store";

export const changeCableDisplayName = (cable, name) => {
 store.dispatch({
  type: "CABLE_NAME",
  cable,
  name,
 });

 return store.getState().settings.cableTypes;
};

export const changeCableColour = (cable, colour) => {
 store.dispatch({
  type: "CABLE_COLOUR",
  cable,
  colour,
 });

 return store.getState().settings.cableTypes;
};

export const changeStyleColour = (cable, style) => {
 store.dispatch({
  type: "CABLE_STYLE",
  cable,
  style,
 });

 return store.getState().settings.cableTypes;
};

export const changeMarketingEmails = (boolean) => {
 store.dispatch({
  type: "UPDATE_MARKETING_EMAILS",
  marketingEmails: boolean,
 });

 return store.getState().settings.marketingEmails;
};

export const updateLimits = (update) => {
 store.dispatch({
  type: "UPDATE_LIMITS",
  update,
 });
};
export const updateSubscriptionName = (name, id, planDetails) => {
 store.dispatch({
  type: "UPDATE_SUBSCRIPTION_NAME",
  name,
  id,
  planDetails,
 });
};

export const showWhatsNewModal = (boolean) => {
 store.dispatch({
  type: "UPDATE_WHATS_NEW",
  whatsNew: boolean,
 });

 return store.getState().settings.whatsNew;
};
