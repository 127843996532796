import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useFirestore } from "react-redux-firebase";
import randomatic from "randomatic";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import {
 exportComponentAsPNG,
 exportComponentAsJPEG,
 exportComponentAsPDF,
} from "react-component-export-image";
// import domtoimage from "dom-to-image";
import { createPortal } from "react-dom";
import { store } from "../db/store";
import { actionTypes } from "redux-firestore";

import Input, { InputCheckbox } from "./Input";
import Select from "./Select";
import { BuilderInternals } from "./Builder";
import Button from "./Button";
import * as planActions from "../actions/planActions";
import * as builderActions from "../actions/builderActions";
import ColourPicker from "./ColourPicker";
import { analytics } from "../db/store";
import { CustomSearchBox } from "./search/AddGearSearch";
import * as teamActions from "../actions/teamActions";
import * as settingsActions from "../actions/settingsActions";

import GearCard from "./GearCard";

import * as filterActions from "../actions/filterActions";
import * as notificationsActions from "../actions/notificationsActions";
import { CustomHits } from "./search/Hits";
import useSubscriptionStatus from "./hooks/useSubscriptionStatus";
import useIsMemberOfTeam from "./hooks/useIsMemberOfTeam";

const searchClient = algoliasearch(
 "CH9XG87FEC",
 "01d5f472d0b52bf55d0946a47545f1f3"
);

function Portal({ children }) {
 return createPortal(children, document.querySelector("#modal-portal"));
}

export default function Modal({
 title,
 acceptButtonText,
 onCancel,
 onAccept,
 height = "auto",
 mb = "",
 children,
 width = "w-full sm:w-1/2",
}) {
 return (
  <Portal>
   <div
    onKeyDownCapture={(e) => {
     if (e.key === "Escape") {
      return onCancel();
     }
    }}
    className={`fixed flex justify-center items-center bottom-0 right-0 w-screen h-screen z-20`}
   >
    <div
     className={`flex flex-col bg-white dark:bg-gray-800 rounded-lg shadow z-40 max-h-screen overflow-y-auto ${width}`}
     style={{ height: height }}
    >
     <div className="py-10 border-b-2 border-solid border-gray-200 dark:border-gray-900 sticky top-0 z-40 bg-white dark:bg-gray-800">
      <h2 className="px-10 text-2xl font-bold text-gray-800 dark:text-gray-200">
       {title}
      </h2>
     </div>
     <div
      className={`flex-1 px-4 sm:px-10 py-4 sm:py-12 z-20 text-gray-900 dark:text-gray-400 ${mb}`}
     >
      {children}
     </div>
     <div className="px-10 pb-5 pt-4 flex justify-between sticky bottom-0 z-30 bg-white dark:bg-gray-800 border-t-2 border-solid border-gray-200  dark:border-gray-900">
      <Button text="Cancel" colour="white" onClick={onCancel} />
      <Button text={acceptButtonText} onClick={onAccept} />
     </div>
    </div>
    <div
     className="fixed w-screen h-screen bg-gray-900/75 backdrop-filter backdrop-blur-sm  z-30"
     onClick={onCancel}
    />
   </div>
  </Portal>
 );
}

export const AddPlanModal = ({
 edit = false,
 copy = false,
 onCancel,
 plan,
 team = false,
 author,
}) => {
 const [submissionError, setSubmissionError] = useState("");

 const [creatingPlan, setCreatingPlan] = useState(false);

 const firestore = useFirestore();
 const history = useHistory();
 const uid = useSelector((state) => state.firebase.auth.uid);
 const {
  name,
  internalJobId = "",
  dateDependant,
  riggingDateStart,
  riggingDateEnd,
  eventDateStart,
  eventDateEnd,
 } = useSelector((state) => state.filters.newPlan);

 const handleAddPlan = async () => {
  // Valiation of form
  if (!name) {
   return setSubmissionError("This plan needs a name.");
  }

  if (dateDependant) {
   if (!riggingDateStart || !riggingDateStart || !eventDateStart) {
    return setSubmissionError("Opps, looks like you missed something above.");
   }
  }
  setSubmissionError("");
  setCreatingPlan(true);

  // const placklistId = await firestore
  //  .collection("packlists")
  //  .add({
  //   author_uid: uid,
  //   items: [],
  //   cables: [],
  //  })
  //  .then((res) => {
  //   return res.id;
  //  })
  //  .catch((err) => console.error(err));

  const planToAdd = {
   author_uid: !team ? uid : author,
   cables: [],
   items: [],
   name,
   internalJobId,
   pack_progress: 0,
   //  packlist_id: placklistId,
   status: "ACTIVE",
   _sharing: !team ? "onlyme" : "team",
   created: new Date(),
   lastSaved: new Date(),
   event_start_date:
    eventDateStart === ""
     ? null
     : firestore.Timestamp.fromDate(new Date(eventDateStart)), // CHANGE TO FIRESTORE TIMESTAMP
   event_end_date:
    eventDateEnd === ""
     ? null
     : firestore.Timestamp.fromDate(new Date(eventDateEnd)), // CHANGE TO FIRESTORE TIMESTAMP
   rigging_start_date:
    riggingDateStart === ""
     ? null
     : firestore.Timestamp.fromDate(new Date(riggingDateStart)), // CHANGE TO FIRESTORE TIMESTAMP
   rigging_end_date:
    riggingDateEnd === ""
     ? null
     : firestore.Timestamp.fromDate(new Date(riggingDateEnd)), // CHANGE TO FIRESTORE TIMESTAMP
  };

  const docId = await firestore
   .collection("plans")
   .add({ ...planToAdd })
   .then(async (res) => {
    // TO DO, add the ID back to the same doc
    await firestore.collection("plans").doc(res.id).update({ id: res.id });
    // await firestore
    //  .collection("packlists")
    //  .doc(placklistId)
    //  .update({ plan_id: res.id });

    return res.id;
   })
   .catch((err) => {
    return console.error(err);
   });

  await firestore.get({ collection: "plans", doc: docId }, (doc) => {
   planActions.addPlan(doc.data());

   filterActions.clearFilterNewPlan();
   return doc;
  });

  setCreatingPlan(false);
  return history.push(`/plan/${docId}`);
 };

 const handleEditPlan = async () => {
  // Valiation of form
  if (!name) {
   return setSubmissionError("This plan needs a name.");
  }

  if (dateDependant) {
   if (!riggingDateStart || !riggingDateStart || !eventDateStart) {
    return setSubmissionError("Opps, looks like you missed something above.");
   }
  }
  setSubmissionError("");
  setCreatingPlan(true);

  const changes = {
   name,
   internalJobId,
   event_start_date:
    eventDateStart === ""
     ? null
     : firestore.Timestamp.fromDate(new Date(eventDateStart)), // CHANGE TO FIRESTORE TIMESTAMP
   event_end_date:
    eventDateEnd === ""
     ? null
     : firestore.Timestamp.fromDate(new Date(eventDateEnd)), // CHANGE TO FIRESTORE TIMESTAMP
   rigging_start_date:
    riggingDateStart === ""
     ? null
     : firestore.Timestamp.fromDate(new Date(riggingDateStart)), // CHANGE TO FIRESTORE TIMESTAMP
   rigging_end_date:
    riggingDateEnd === ""
     ? null
     : firestore.Timestamp.fromDate(new Date(riggingDateEnd)), // CHANGE TO FIRESTORE TIMESTAMP
  };

  planActions.updatePlan(plan.id, {
   ...changes,
  });

  await firestore
   .collection("plans")
   .doc(plan.id)
   .update({ ...changes })
   .then((res) => {
    setCreatingPlan(false);
    onCancel();

    return filterActions.clearFilterNewPlan();
   })
   .catch((err) => {
    console.error(err);
   });

  return console.log("Plan update complete.");
 };

 const handleCopyPlan = async () => {
  // Valiation of form
  if (!name) {
   return setSubmissionError("This plan needs a name.");
  }

  if (dateDependant) {
   if (!riggingDateStart || !riggingDateStart || !eventDateStart) {
    return setSubmissionError("Opps, looks like you missed something above.");
   }
  }
  setSubmissionError("");
  setCreatingPlan(true);


  const planToAdd = {
   cables: [],
   items: [],
   ...plan,
  //  author_uid: uid,
   name,
   pack_progress: 0,
   status: "ACTIVE",
   created: new Date(),
   lastSaved: null,
   event_start_date:
    eventDateStart === ""
     ? null
     : firestore.Timestamp.fromDate(new Date(eventDateStart)), // CHANGE TO FIRESTORE TIMESTAMP
   event_end_date:
    eventDateEnd === ""
     ? null
     : firestore.Timestamp.fromDate(new Date(eventDateEnd)), // CHANGE TO FIRESTORE TIMESTAMP
   rigging_start_date:
    riggingDateStart === ""
     ? null
     : firestore.Timestamp.fromDate(new Date(riggingDateStart)), // CHANGE TO FIRESTORE TIMESTAMP
   rigging_end_date:
    riggingDateEnd === ""
     ? null
     : firestore.Timestamp.fromDate(new Date(riggingDateEnd)), // CHANGE TO FIRESTORE TIMESTAMP
  };

  const docId = await firestore
   .collection("plans")
   .add({ ...planToAdd })
   .then(async (res) => {
    // TO DO, add the ID back to the same doc
    await firestore.collection("plans").doc(res.id).update({ id: res.id });
    // await firestore
    //  .collection("packlists")
    //  .doc(placklistId)
    //  .update({ plan_id: res.id });

    return res.id;
   })
   .catch((err) => {
    return console.error(err);
   });

  await firestore.get({ collection: "plans", doc: docId }).then((doc) => {
   planActions.addPlan(doc.data());

   filterActions.clearFilterNewPlan();
   return history.push(`/plan/${docId}`);
  });

  return setCreatingPlan(false);
 };

 return (
  <Modal
   title={!edit ? "Add plan" : "Edit plan details"}
   acceptButtonText={
    !creatingPlan
     ? !edit
       ? "Create plan"
       : "Done"
     : !edit
     ? "Creating plan..."
     : "Saving..."
   }
   onAccept={() => {
    if (edit) {
     return handleEditPlan();
    } else if (copy) {
     return handleCopyPlan();
    } else {
     return handleAddPlan();
    }
   }}
   onCancel={onCancel}
  >
   {/* NAME */}
   <Input
    name="name"
    label="Name"
    type="text"
    placeholder="Name of the plan"
    value={name}
    onChange={(e) => {
     filterActions.updateFilterNewPlan({ name: e.target.value });
    }}
    css="w-full"
    valid={submissionError && !name ? false : true}
    autoFocus
   />

   <Input
    name="name"
    label="Internal job ID"
    type="text"
    placeholder="abcd1234"
    value={internalJobId}
    onChange={(e) => {
     filterActions.updateFilterNewPlan({ internalJobId: e.target.value });
    }}
    css="mt-4 w-full"
   />

   {/* DATE DEPENDANT */}
   <div className="mt-4 flex w-full">
    <InputCheckbox
     name="dates"
     checkboxLabelText="Include dates"
     value={dateDependant}
     checked={dateDependant}
     onChange={(e) => {
      let clearDates = {};
      if (e.target.checked === false) {
       clearDates = {
        riggingDateStart: "",
        riggingDateEnd: "",
        eventDateStart: "",
        eventDateEnd: "",
       };
      }
      return filterActions.updateFilterNewPlan({
       dateDependant: e.target.checked,
       ...clearDates,
      });
     }}
    />
    {/* <Input
     name="dates"
     label="Include dates"
     type="checkbox"
     placeholder="Name of the plan"
     value={dateDependant}
     checked={dateDependant}
     onChange={(e) => {
      let clearDates = {};
      if (e.target.checked === false) {
       clearDates = {
        riggingDateStart: "",
        riggingDateEnd: "",
        eventDateStart: "",
        eventDateEnd: "",
       };
      }
      return filterActions.updateFilterNewPlan({
       dateDependant: e.target.checked,
       ...clearDates,
      });
     }}
     css="w-full"
     valid={submissionError && !name ? false : true}
    /> */}
   </div>

   {dateDependant && (
    <>
     {/* RIGGING */}
     <div className="mt-6 flex w-full">
      <Input
       name="riggingDateStart"
       label="Rigging start"
       type="date"
       placeholder="Date"
       value={riggingDateStart}
       onChange={(e) => {
        filterActions.updateFilterNewPlan({ riggingDateStart: e.target.value });
        filterActions.updateFilterNewPlan({ riggingDateEnd: e.target.value });
       }}
       valid={submissionError && !riggingDateStart ? false : true}
       css="pr-2 w-1/2"
      />
      <Input
       name="riggingDateEnd"
       label="Rigging end"
       type="date"
       placeholder="Date"
       value={riggingDateEnd}
       onChange={(e) => {
        filterActions.updateFilterNewPlan({ riggingDateEnd: e.target.value });
       }}
       valid={submissionError && !riggingDateEnd ? false : true}
       css="pl-2 w-1/2"
      />
     </div>

     {/* EVENT DATES */}
     <div className="mt-6 flex w-full">
      <Input
       name="eventDateStart"
       label="Event start"
       type="date"
       placeholder="Date"
       value={eventDateStart}
       onChange={(e) => {
        filterActions.updateFilterNewPlan({ eventDateStart: e.target.value });
        filterActions.updateFilterNewPlan({ eventDateEnd: e.target.value });
       }}
       valid={submissionError && !eventDateStart ? false : true}
       css="pr-2 w-1/2"
      />
      <Input
       name="eventDateEnd"
       label="Event end"
       type="date"
       placeholder="Date"
       value={eventDateEnd}
       onChange={(e) => {
        filterActions.updateFilterNewPlan({ eventDateEnd: e.target.value });
       }}
       valid={submissionError && !eventDateEnd ? false : true}
       css="pl-2 w-1/2"
      />
     </div>
    </>
   )}

   {submissionError && (
    <div className="mt-6 text-red-500">{submissionError}</div>
   )}
  </Modal>
 );
};

export const AddTeamMemberModal = ({ onCancel }) => {
 const [email, setEmail] = useState("");
 const [adding, setAdding] = useState(false);

 const uid = useSelector((state) => state.firebase.auth.uid);
 const firestore = useFirestore();

 const handleAddTeamMember = async () => {
  setAdding(true);

  await firestore
   .collection("pendingTeamMembers")
   .add({
    email,
    team: uid,
   })
   .then((res) => res);

  onCancel();

  return await notificationsActions.addNotification(
   `We'll take it from here`,
   `${email} will be added to your team.`,
   `success`,
   10000
  );
 };

 return (
  <Modal
   title={"Add team member"}
   acceptButtonText={"Done"}
   onAccept={onCancel}
   onCancel={onCancel}
  >
   {/* NAME */}

   <div className="text-left leading-snug mt-4 bg-red-100 dark:bg-red-900 border border-red-200 dark:border-red-800 border-solid px-4 py-4 rounded text-gray-700 dark:text-gray-300 text-sm">
    <span className="font-bold">Heads up</span>: Make sure your team member
    already has an account on H2R Gear, then add them below.
   </div>
   <div className="mt-4 flex space-x-2 items-end">
    <Input
     name="email"
     label="Email"
     type="email"
     placeholder="Email address of team member"
     value={email}
     disabled={adding}
     onChange={(e) => {
      setEmail(e.target.value);
     }}
     css="w-full"
     autoFocus
     autoComplete="off"
    />
    <Button
     text="Add"
     disabled={!email.includes("@") || adding}
     onClick={handleAddTeamMember}
    />
   </div>
  </Modal>
 );
};

export const EditTeamNameModal = ({ currentName, onCancel }) => {
 const [tempName, setTempName] = useState("");
 const [changing, setChanging] = useState(false);

 const uid = useSelector((state) => state.firebase.auth.uid);
 const firestore = useFirestore();

 const handleEditTeamName = async () => {
  setChanging(true);
  if (tempName === "") return onCancel();
  await firestore
   .collection("teams")
   .doc(uid)
   .update({
    name: tempName,
   })
   .then((res) => res);

  teamActions.editTeamName(tempName);
  onCancel();
 };

 return (
  <Modal
   title={"Edit team name"}
   acceptButtonText={changing ? "Updating..." : "Done"}
   onAccept={handleEditTeamName}
   onCancel={onCancel}
  >
   {/* NAME */}

   <div className="flex space-x-2 items-end">
    <Input
     name="name"
     label="Team name"
     type="text"
     placeholder={currentName}
     value={tempName}
     onChange={(e) => {
      setTempName(e.target.value);
     }}
     css="w-full"
     autoFocus
     autoComplete="off"
    />
   </div>
  </Modal>
 );
};

// const gearItems = [
//  {
//   name: "BiDirectional HDMI/SDI",
//   manufacturer: "Blackmagic Design",
//   id: "7vh3o28dje",
//   type: "gear",
//   colour: "119,120,122",
//   secondaryColour: "232,158,21",
//   location: { x: 800, y: 350 },
//   width: 100,
//   height: 120,
//   inputs: [
//    { name: "SDI", type: "sdi", category: "video" },
//    { name: "HDMI", type: "hdmi", category: "video" },
//   ],
//   outputs: [
//    { name: "SDI", type: "sdi", category: "video" },
//    { name: "HDMI", type: "hdmi", category: "video" },
//   ],
//  },
//  {
//   name: "ATEM Television Studio HD",
//   manufacturer: "Blackmagic Design",
//   id: "7gajsiv7sa",
//   type: "gear",
//   colour: "24, 25, 26",
//   secondaryColour: "254,85,67",
//   location: { x: 180, y: 280 },
//   width: 550,
//   height: 130,
//   inputs: [
//    { name: "HDMI 1", type: "hdmi", category: "video" },
//    { name: "HDMI 2", type: "hdmi", category: "video" },
//    { name: "HDMI 3", type: "hdmi", category: "video" },
//    { name: "HDMI 4", type: "hdmi", category: "video" },
//    { name: "SDI 5", type: "sdi", category: "video" },
//    { name: "SDI 6", type: "sdi", category: "video" },
//    { name: "SDI 7", type: "sdi", category: "video" },
//    { name: "SDI 8", type: "sdi", category: "video" },
//    { name: "XLR L", type: "xlr", category: "audio" },
//    { name: "SDI 7", type: "xlr", category: "audio" },
//    { name: "ETH", type: "ethernet", category: "network" },
//   ],
//   outputs: [
//    { name: "SDI PGM 1", type: "sdi", category: "video" },
//    { name: "SDI PGM 2", type: "sdi", category: "video" },
//    { name: "SDI PGM 3", type: "sdi", category: "video" },
//    { name: "SDI PGM 4", type: "sdi", category: "video" },
//    { name: "SDI PGM 5", type: "sdi", category: "video" },
//    { name: "SDI AUX", type: "sdi", category: "video" },
//    { name: "SDI MultiView", type: "sdi", category: "video" },
//    { name: "HDMI MultiView", type: "hdmi", category: "video" },
//   ],
//  },
// ];

export const AddGearModal = ({
 onCancel,
 replace = false,
 addToPlan = true,
 planId,
 placeItemLocation,
 replaceGearItem,
}) => {
 const [results, setResults] = useState();
 const [loadingResults, setLoadingResults] = useState(false);
 const [searchQueryString, setSearchQueryString] = useState("");
 const [searchQuery, setSearchQuery] = useState([]);
 const [addingItem, setAddingItem] = useState(true);
 const [showHits, setShowHits] = useState(false);
 const [maxLoaded, setMaxLoaded] = useState(10);
 //const [hideAfterAdd, setHideAfterAdd] = useState(true);
 //const [showOnlyMyGear, setShowOnlyMyGear] = useState(false);

 const firestore = useFirestore();
 const history = useHistory();

 const user = useSelector((state) => state.firebase.profile);
 const partOfTeam = useSelector((state) => state.firebase.profile.partOfTeam);
 const uid = useSelector((state) => state.firebase.auth.uid);
 const user_items = useSelector((state) => state.firestore.data.items);
 const hideAfterAdd = useSelector((state) => state.plans.hideAfterAdd);
 const showOnlyMyGear = useSelector((state) => state.plans.showOnlyMyGear);
 const searchTab = useSelector((state) => state.plans.searchTab);
 const isSubscribed = useSubscriptionStatus();
 const emptyGearItem = useSelector((state) => state.builder.emptyGearItem);

 const searchFilterMap = {
  'my-gear': `uid:${uid}`,
  'team-gear': `uid:${partOfTeam}`,
  'community': '_private:false',
 }
 const searchFilterMapFirestore = {
  'my-gear': [["uid", "==", uid]],
  'team-gear': [["uid", "==", partOfTeam], ['_team', '==', true]],
  'community': [["_private", "==", false]],
 }


// === "my-gear" ? `uid:${uid}` : `_private:false`

 const search = async (
  query = searchQuery,
  checked = showOnlyMyGear,
  e,
  searchById = null
 ) => {
  if (e) e.preventDefault();
  if (searchQuery.length === 0) return setLoadingResults(false);

  console.log('[search]', query)

  setResults([]);
  setLoadingResults(true);

  // EMIT SEARCH EVENT
  if (typeof query.toString().replaceAll === "function") {
   analytics.logEvent("gear_search", {
    search_term: query.toString().replaceAll(",", " "),
   });
  } else {
   analytics.logEvent("gear_search", {
    search_term: query.toString(),
   });
  }

  let docs = []

  if (searchById) {
    // const specificResult = await firestore.doc(`/items/${searchById}`).get()
    const specificResult = await firestore.get({
      collection: 'items',
      where: [
        'id', '==', searchById
      ]
    })
  if(specificResult.docs?.length) {
    docs.push(specificResult.docs[0])
  }
  }
  const fullSearch = await firestore.get({
   collection: "items",
   where: [
    ["searchTerms", "array-contains-any", query],
    [...searchFilterMapFirestore[searchTab]],
   ],
   limit: 50,
   storeAs: "searchedItems",
    // orderBy: ["name", "asc"],
  });

  if(fullSearch.docs.length) {
    fullSearch.docs.forEach((doc)=>{
      docs.push(doc)
    })
  }

  setLoadingResults(false);

  let array = [];
  if (docs.length > 0) {
   await docs.forEach(async (doc) => {
    let checkAllTerms = query.every((i) => doc.data().searchTerms.includes(i));

    // using checkAllTerms to get relevant results to the top
    return array.push({ ...doc.data(), top: checkAllTerms });
   });

   return await setResults(array);
  } else {
   return setResults([]);
  }
 };

 const intialSearch = async () => {
  setLoadingResults(true);
  let arr = [];

  if (!user_items) {
   const res = await firestore.get({
    collection: "items",
    where: ["uid", "==", uid],
    //  orderBy: ["name", "asc"],
   });

   res.forEach((doc) => {
    arr.push(doc.data());
   });
  } else {
   Object.values(user_items).map((item, index) => {
    if (index > maxLoaded) return null;
    if (item.uid !== uid) return null;
    arr.push(item);
   });
  }
  setLoadingResults(false);
  setResults(arr);
 };
 const intialSearchTeam = async () => {
  setLoadingResults(true);
  let arr = [];

   const res = await firestore.get({
    collection: "items",
    where: [["uid", "==", partOfTeam], ['_team', '==', true]],
    //  orderBy: ["name", "asc"],
   });

   res.forEach((doc) => {
    arr.push(doc.data());
   });
  setLoadingResults(false);
  setResults(arr);
 };

 const addGearItem = async (gearItem) => {
  setAddingItem(gearItem.id);

  let id = randomatic("Aa0", 20);

  if (addToPlan) {
    console.log('adding to plan', placeItemLocation)
   planActions.addItem(planId, {
    ...gearItem,
    id,
    location: { ...placeItemLocation },
   });

   notificationsActions.addNotification(
    `Added gear item!`,
    `${gearItem.name} has been added to your plan.`
   );
  } else {
   //  firestore.collection("users").doc(uid).collection("items").add(gearItem);

   //  return console.log("should add to gear items", gearItem);
   firestore
    .collection("items")
    .add({
     ...gearItem,
     added: new Date(),
     uid: uid,
     _checked: false,
     _approved: false,
     _private: true,
    })
    .then((res) => {
     notificationsActions.addNotification(
      `Added gear item!`,
      `${gearItem.name} has been added to your collection.`,
      "success",
      5000
     );

     firestore.collection("items").doc(res.id).update({
      id: res.id,
     });
    });
  }

  setAddingItem(null);

  if (hideAfterAdd) {
   return onCancel(id);
  } else {
   return null;
  }
 };

 useEffect(() => {
  intialSearch();

  return () => store.dispatch({ type: actionTypes.CLEAR_DATA });
 }, []);

 useEffect(() => {
  if(searchTab === 'team-gear') {
    console.log('search team gear')
    intialSearchTeam()
  } else if (searchTab === 'my-gear') {
    intialSearch()
  } else {
    search();
  }
 }, [searchTab]);

 return (
  <Modal
   title={!replace ? "Add gear" : "Replace gear"}
   acceptButtonText={searchTab === "builder" ? "Add to plan" : "Done"}
   onAccept={() => {
    if (searchTab !== "builder") return onCancel();

    let id = randomatic("Aa0", 20);

    planActions.addItem(planId, {
     ...emptyGearItem,
     id,
     location: { ...placeItemLocation },
    });

    return onCancel(id);
   }}
   onCancel={onCancel}
   height="90%"
   mb="mb-12"
   width="w-full sm:w-5/6"
  >
   <div className="relative">
    <div className="flex">
     <button
      className={`ml-2 px-4 py-3 border-b-2 border-solid flex justify-center items-center  focus:outline-none focus:border-red-200 md:focus:border-red-400 md:border-gray-200 ${
       searchTab === "my-gear"
        ? "px-4 py-3 text-gray-900 dark:text-white border-white font-semibold flex justify-center items-center  md:border-red-500"
        : "border-red-500 md:border-gray-500"
      }`}
      onClick={() => {
       planActions.searchTab("my-gear");
      }}
     >
      <div className="ml-2 whitespace-no-wrap">My gear</div>
     </button>
     {partOfTeam && <button
      className={`ml-2 px-4 py-3 border-b-2 border-solid flex justify-center items-center  focus:outline-none focus:border-red-200 md:focus:border-red-400 md:border-gray-200 ${
       searchTab === "team-gear"
        ? "px-4 py-3 text-gray-900 dark:text-white border-white font-semibold flex justify-center items-center  md:border-red-500"
        : "border-red-500 md:border-gray-500"
      }`}
      onClick={() => {
       planActions.searchTab("team-gear");
      }}
     >
      <div className="ml-2 whitespace-no-wrap">Team gear</div>
     </button>}
     <button
      className={`ml-2 px-4 py-3 border-b-2 border-solid flex justify-center items-center focus:outline-none focus:border-red-200 md:focus:border-red-400 md:border-gray-200 ${
       searchTab === "community"
        ? "px-4 py-3 text-gray-900 dark:text-white border-white font-semibold flex justify-center items-center  md:border-red-500"
        : "border-red-500 md:border-gray-500"
      }`}
      onClick={() => {
       planActions.searchTab("community");
      }}
     >
      <div className="ml-2 whitespace-no-wrap">Community library</div>
     </button>
     <button
      className={`ml-2 px-4 py-3 border-b-2 border-solid flex justify-center items-center  focus:outline-none focus:border-red-200 md:focus:border-red-400 md:border-gray-200 ${
       searchTab === "builder"
        ? "px-4 py-3 text-gray-900 dark:text-white border-white font-semibold flex justify-center items-center  md:border-red-500"
        : "border-red-500 md:border-gray-500"
      }`}
      onClick={() => {
       planActions.searchTab("builder");
      }}
     >
      <div className="ml-2 whitespace-no-wrap">Quick builder</div>
     </button>
    </div>

    {searchTab !== "builder" ? (
     <>
      <form
       className="mt-6 relative"
       onSubmit={(e) => {
        search(undefined, undefined, e);
        console.log(e);
        return setShowHits(false);
       }}
      >
       <input
        type="text"
        className="px-6 h-12 w-full rounded-lg border-2 border-solid border-gray-200 dark:border-gray-700 focus:outline-none focus:ring bg-white dark:bg-gray-900"
        placeholder={
         searchTab === "my-gear" || searchTab === "team-gear"
          ? `Search gear items...`
          : `Search the community library...`
        }
        //   onInput={(e) => filterActions.updateFilterText(e.target.value)}
        onInput={(e) => {
         if (!showHits) setShowHits(true);
         setSearchQueryString(e.target.value);
         setSearchQuery(e.target.value.toLowerCase().split(" "));
        }}
        value={searchQueryString}
        onFocus={() => setShowHits(true)}
        autoFocus
       />
       <div className="absolute bg-gray-200 dark:bg-gray-800 bottom-0 cursor-default flex h-10 items-center mx-1 my-1 right-0 rounded top-0">
        <span className="px-3 text-gray-600">Press enter to search</span>
       </div>
      </form>
      {searchQueryString.length > 2 && isSubscribed && (
       <InstantSearch
        indexName="gearItem"
        searchClient={searchClient}
        searchState={{
         query: searchQueryString,
        }}
       >
        <Configure
         filters={searchFilterMap[searchTab]}
         hitsPerPage={10}
         analytics={false}
         //  distinct
        />
        <CustomSearchBox defaultRefinement="" />
        {showHits && searchQueryString !== "" && (
         <CustomHits
          resultClicked={(result) => {
           const { name, objectID } = result;
           console.log("result", result);

           setSearchQueryString(name);
           setSearchQuery(name.toLowerCase().split(" "));

           search(name.toLowerCase().split(" "), null, null, objectID);

           return setShowHits(false);
          }}
         />
        )}
       </InstantSearch>
      )}
     </>
    ) : (
     ""
    )}
   </div>
   {searchTab === "builder" ? (
    <BuilderInternals onPlan={true} />
   ) : (
    <>
     <div className="flex space-x-2">
      {!replace && (
       <InputCheckbox
        name="hideAfterAdd"
        id="hideAfterAdd"
        checked={hideAfterAdd}
        onChange={(e) => planActions.hideAfterAdding(e.target.checked)}
        checkboxLabelText="Hide this modal after adding gear item"
       />
      )}
     </div>

     <div className="mt-6 ">
      {loadingResults && (
       <div className="flex px-6 py-6 items-center animate animate-pulse text-gray-700 dark:text-gray-300 text-sm bg-gray-100 dark:bg-gray-900 rounded-lg w-100 h-40 border-2 border-solid border-gray-100 dark:border-gray-900">
        <span>Checking for items...</span>
       </div>
      )}
      <span className="text-gray-700 text-sm">
       {!loadingResults && Array.isArray(results) && results.length === 0
        ? "No results found."
        : null}
      </span>
      {Array.isArray(results)
       ? results
          .sort((a, b) => {
           //  if (a.uid === uid && b.uid === uid) return 1;
           return b.top - a.top;
          })
          .map((gearItem, index) => {
           if (index > maxLoaded) return null;
           return (
            <GearCard
             key={index}
             actionText={!replace ? "Add" : "Replace"}
             waitText={!replace ? "Adding..." : "Replacing..."}
             gearItem={gearItem}
             gearCardAction={(item) => {
              if (!replace) {
               addGearItem(item);
              } else {
               replaceGearItem({ ...item, id: randomatic("Aa0", 20) });
              }
             }}
             gearCardWait={addingItem}
             buttonDropdown={true}
             buttonDropdownOptions={[
              {
               title: "Create copy and edit",
               to: "/that",
               icon: "copy",
               func: function () {
                builderActions.loadEditItem({
                 ...gearItem,
                 name: `Copy of ${gearItem.name}`,
                 copyOf: gearItem.id,
                 _private: true,
                 updatingItem: false,
                });

                return history.push("/builder");
               },
              },
             ]}
            />
           );
          })
       : null}

      {results && results !== [] && results.length > maxLoaded && (
       <Button
        text="Load more"
        colour="white"
        css="mt-4"
        onClick={() => {
         setMaxLoaded(maxLoaded + 10);
        }}
       />
      )}
      <div className="mt-4 bg-red-100 dark:bg-red-900 border border-red-200 dark:border-red-800 border-solid px-4 py-4 rounded text-gray-700 dark:text-gray-300 text-sm">
       Can't find what you're looking for?{" "}
       <span
        className="underline cursor-pointer"
        onClick={() => {
         onCancel();

         return history.push(`/builder`);
        }}
       >
        Build your own gear item
       </span>
       .
      </div>
     </div>
    </>
   )}
  </Modal>
 );
};

export const PreBuilderModal = ({ onAccept, onCancel }) => {
 const [itemName, setItemName] = useState("");

 useEffect(() => {
  if (itemName.length > 3) {
   console.log("start checking");
  }
 }, [itemName]);

 return (
  <Modal
   title={"Add new item"}
   acceptButtonText="Continue"
   onAccept={onAccept}
   onCancel={onCancel}
  >
   {/* NAME */}
   <Input
    name="name"
    label="Name"
    type="text"
    placeholder="Item name"
    value={itemName}
    onChange={(e) => {
     setItemName(e.target.value);
     // filterActions.updateFilterNewPlan({ name: e.target.value });
    }}
    css="w-full"
    autoFocus
   />
  </Modal>
 );
};

export const BuildGearModal = ({ item, onCancel, planId, cables }) => {
 const [cablesConnected, setCablesConnected] = useState(false);

 const emptyBuilderItem = useSelector((state) => state.builder.emptyGearItem);

 useEffect(() => {
  builderActions.loadEditItem(item);

  let cLength = cables.filter((c) => {
   if (c.fromItem === item.id || c.toItem === item.id) return true;
   return false;
  }).length;

  if (cLength > 0) {
   setCablesConnected(true);
  }
 }, []);
 return (
  <Modal
   title="Gear builder"
   acceptButtonText="Done"
   onAccept={() => {
    let height = 94;

    if (
     emptyBuilderItem.inputs.length === 0 ||
     emptyBuilderItem.outputs.length === 0
    ) {
     height = 70;
    } else {
     height = 94;
    }

    if (emptyBuilderItem.img === null || emptyBuilderItem.img === undefined) {
    } else {
     height = height + 100;
    }

    console.log("planId", planId, item.id, emptyBuilderItem);

    planActions.editItem(planId, item.id, {
     ...emptyBuilderItem,
     height,
    });

    return onCancel();
   }}
   onCancel={onCancel}
   height="90%"
   mb="mb-12"
   width="w-100"
  >
   <BuilderInternals
    item={item}
    onPlan={true}
    cablesConnected={cablesConnected}
    originalInputsLength={item.inputs.length}
    originalOutputsLength={item.outputs.length}
   />
  </Modal>
 );
};

export const EditGearModal = ({
 planId,
 item,
 onCancel,
 onRemove,
 onReplace,
}) => {
 const [label, setLabel] = useState(item.label);
 const [manufacturer, setManufacturer] = useState(item.manufacturer);
 const [colour, setColour] = useState(item.colour);
 const [secondaryColour, setSecondaryColour] = useState(item.secondaryColour);
 const [img, setImg] = useState(item.img);

 const plan = useSelector((state) => state.plans[planId]);

 const handleInputOutputSwitch = (index, current, destination) => {
  const addedTo = [...item[destination], item[current][index]];

  const removedFrom = item[current].filter((io, i) => {
   if (i !== index) {
    return {
     io,
    };
   } else return null;
  });

  // REMOVE ANY CONNECTED CABLES
  plan.cables.filter((cable) => {
   if (
    (cable.toItem === item.id && cable.toPosition === index + 1) ||
    (cable.fromItem === item.id && cable.fromPosition === index + 1)
   ) {
    return planActions.removeCable(planId, cable.id);
   }
  });

  planActions.editItem(planId, item.id, {
   [current]: removedFrom,
   [destination]: addedTo,
  });

  return onCancel();
 };

 return (
  <Modal
   title={item.name}
   acceptButtonText="Done"
   onAccept={() => {
    let change = {
     colour,
     secondaryColour,
    };

    if (label !== undefined) {
     change.label = label;
    }
    if (manufacturer !== undefined) {
     change.manufacturer = manufacturer;
    }
    if (img !== undefined) {
     let h;
     if (item.inputs.length === 0 || item.outputs.length === 0) {
      h = 70;
     } else {
      h = 94;
     }
     if (img === null) {
      change.height = h;
     } else {
      change.height = h + 100;
     }
     change.img = img;
    }

    planActions.editItem(planId, item.id, change);

    return onCancel();
   }}
   onCancel={onCancel}
  >
   <div>
    <div className="flex">
     <Input
      name="label"
      label="Label"
      value={label}
      placeholder="Your label"
      onChange={(e) => {
       setLabel(e.target.value);
      }}
      css="w-full"
     />
     <Input
      name="name"
      label="Manufacturer"
      value={manufacturer}
      onChange={(e) => {
       setManufacturer(e.target.value);
      }}
      css="ml-6 w-full"
     />
    </div>
    {/* COLOURS */}
    <div className="flex mt-8">
     <div className="w-full">
      <h3 className="mb-4 text-lg text-gray-700">Background</h3>
      <ColourPicker
       relativeParent={true}
       colour={colour}
       onChangeComplete={(colour) => {
        setColour(colour);
       }}
       onClosePicker={() => {
        // console.log("closing picker");
       }}
      />
     </div>
     <div className="ml-4 w-full">
      <h3 className="mb-4 text-lg text-gray-700">Highlight</h3>
      <ColourPicker
       relativeParent={true}
       colour={secondaryColour}
       onChangeComplete={(colour) => {
        setSecondaryColour(colour);
       }}
       onClosePicker={() => {
        // console.log("closing picker");
       }}
      />
     </div>
    </div>
    <div className="mt-8">
     <span className="text-lg text-gray-700">Image</span>
     <div className="px-2 py-2 mt-4 flex items-center border border-solid border-gray-400 rounded-md">
      {img && <img className="w-20" src={img} alt={item.name} />}
      <div
       className={`flex space-x-3
                      ${img && "ml-2"}
      `}
      >
       <Button
        text={img ? "Replace image" : "Add image"}
        icon={img ? "replace" : "add"}
        colour="white"
        size="small"
        onClick={() => {
         setImg("/img/h6-2.png");
        }}
       />
       {img && (
        <Button
         text="Remove image"
         icon="delete"
         colour="white"
         size="small"
         onClick={() => {
          setImg(null);
         }}
        />
       )}
      </div>
     </div>
    </div>
    {/* CONFIG */}
    <div className="mt-8">
     <h2 className="text-lg text-gray-700">Input/Output switching</h2>
     <div className="py-4 grid grid-cols-2 space-x-4">
      <div>
       <h3 className="text-sm text-gray-600">Inputs</h3>
       <div>
        {item.inputs.map((input, index) => {
         if (input.type === "ethernet" || input.type === "usb") {
          return (
           <button
            onClick={() => {
             handleInputOutputSwitch(index, "inputs", "outputs");
            }}
            key={index}
            className="block border border-gray-400 focus:outline-none focus:ring mt-2 px-2 py-2 rounded-md text-gray-800"
           >
            {input.name}{" "}
            <span className="text-xs text-gray-600">Switch to output</span>
           </button>
          );
         }
        })}
       </div>
      </div>
      <div>
       <h3 className="text-sm text-gray-600">Outputs</h3>
       <div>
        {item.outputs.map((output, index) => {
         if (output.type === "ethernet" || output.type === "usb") {
          return (
           <button
            onClick={() => {
             handleInputOutputSwitch(index, "outputs", "inputs");
            }}
            key={index}
            className="block border border-gray-400 focus:outline-none focus:ring mt-2 px-2 py-2 rounded-md text-gray-800"
           >
            {output.name}{" "}
            <span className="text-xs text-gray-600">Switch to input</span>
           </button>
          );
         }
        })}
       </div>
      </div>
     </div>
    </div>
    <div className="mt-6 flex space-x-4">
     <Button
      text="Replace item"
      colour="white"
      icon="replace"
      justify="justify-left"
      onClick={() => {
       onReplace(item);
      }}
     />
     <Button
      text="Remove item"
      icon="delete"
      justify="justify-right"
      onClick={() => {
       onRemove(item.id);
      }}
     />
    </div>
   </div>
  </Modal>
 );
};

export const RemoveGearModal = ({ onCancel, onRemove }) => {
 return (
  <Modal
   title="Remove gear"
   acceptButtonText="Remove"
   onAccept={() => {
    onRemove();
   }}
   onCancel={onCancel}
  >
   <div>Are you sure you want to remove this piece of gear?</div>
  </Modal>
 );
};

/**
 * @param shareStatus {string} - Options are 'onlyme', 'anyone' or 'team'.
 */
export const SharePlanModal = ({ onCancel, plan, subscriptionPlanName }) => {
 const { id, _sharing = "onlyme" } = plan;

 const { isTeamMember, isTeamAdmin } = useIsMemberOfTeam();

 const [copyText, setCopyText] = useState("Copy");

 const history = useHistory();

 const handleUpdateShareStatus = async (choice) => {
  planActions.updatePlan(id, { _sharing: choice });
 };

 const handleCopyText = () => {
  setCopyText("Copied!");

  navigator.clipboard.writeText(
   `https://h2rgear.com${history.location.pathname}`
  );

  return setTimeout(() => {
   return setCopyText("Copy");
  }, 3000);
 };
 return (
  <Modal
   title="Share plan"
   acceptButtonText="Done"
   onAccept={() => {
    onCancel();
   }}
   onCancel={onCancel}
  >
   <div className="flex flex-col">
    <Select
     css="w-100"
     label="Share status"
     name="sharingSelect"
     options={[
      { name: "onlyme", displayName: "Only me" },
      { name: "anyone", displayName: "Anyone with the link" },
      {
       name: "team",
       displayName: "My team (view and edit)",
       disabled: ![isTeamMember, isTeamAdmin].includes(true),
      },
      {
       name: "team-anyone",
       displayName:
        "My team (view and edit) and anyone with the link (view only)",
       disabled: ![isTeamMember, isTeamAdmin].includes(true),
      },
     ]}
     selected={!_sharing ? "onlyme" : _sharing}
     onSelect={(choice) => {
      handleUpdateShareStatus(choice);
     }}
    />
    <div className="flex mt-6 items-end">
     <Input
      css="w-full"
      name="shareLink"
      label="Link"
      value={`https://h2rgear.com${history.location.pathname}`}
      disabled={true}
     />
     <Button
      css="ml-4"
      text={copyText}
      icon="clipboard-copy"
      colour="white"
      onClick={handleCopyText}
     />
    </div>
   </div>
  </Modal>
 );
};

/**
 * @param onCancel {function} - Called when modal is closed.
 * @param plan {object} - Plan.
 * @param subscriptionPlanName {string} - Users current subscription plan.
 * @param panZoomRef {ref} - React REF for PNG save.
 */
export const ExportPlanModal = ({
 onCancel,
 plan,
 subscriptionPlanName,
 panZoomRef,
 panZoomState,
}) => {
 const { name } = plan;

 const [pngQuality, setPngQuality] = useState(1);
 const [pngTransparent, setPngTransparent] = useState(true);

 const [downloadingPNG, setDownloadingPNG] = useState(false);
 const [downloadingJPEG, setDownloadingJPEG] = useState(false);
 const [downloadingPDF, setDownloadingPDF] = useState(false);

 const handleNameSanitize = (name) => {
  const invalidCharsRegex = /[\\/:\*\?"<>\|\.]/g;

  // Replace invalid characters with a space
  const sanitizedFileName = name.replace(invalidCharsRegex, ' ');

  return sanitizedFileName;
 }

 return (
  <Modal
   title="Export plan"
   acceptButtonText="Done"
   onAccept={() => {
    onCancel();
   }}
   onCancel={onCancel}
  >
   <div className="flex flex-col space-y-3">
    <Select
     css="w-100"
     label="Quality (PNG & JPEG)"
     name="pngQuality"
     options={[
      { name: 1, displayName: "1x" },
      { name: 2, displayName: "2x" },
      { name: 5, displayName: "5x" },
      { name: 10, displayName: "10x" },
     ]}
     selected={pngQuality}
     onSelect={(choice) => {
      setPngQuality(choice);
     }}
    />
    <InputCheckbox
     name="pngBgColour"
     checked={pngTransparent}
     onChange={() => setPngTransparent(!pngTransparent)}
     checkboxLabelText="Transparent background (PNG)"
    />

    <div className="flex flex-col justify-between md:flex-row space-y-4 md:space-y-0 md:space-x-4">
     <Button
      text={downloadingPNG ? "Downloading..." : "Download PNG"}
      colour="white"
      icon="download"
      onClick={async () => {
       setDownloadingPNG(true);
       await exportComponentAsPNG(panZoomRef, {
        fileName: `H2R Gear - ${handleNameSanitize(name)} ${pngQuality}x`,
        html2CanvasOptions: {
         foreignObjectRendering: true,
         scale: pngQuality,
         backgroundColor: pngTransparent ? "transparent" : "#f7fafc",
         ignoreElements: function (el) {
          return el.classList.contains("removeFromPngExport");
         },
        },
       });

       //  domtoimage
       //   .toBlob(panZoomRef?.current, {
       //    width: 768,
       //    height: 432,
       //    imagePlaceholder: TRANSPARENT_PIXEL,
       //    //  filter: function (el) {
       //    //   if (el.classList.contains("removeFromPngExport")) {
       //    //    return false;
       //    //   }
       //    //   return true;
       //    //  },
       //   })
       //   .then(function (blob) {
       //    window.saveAs(blob, `export`);
       //   });

       setDownloadingPNG(false);
      }}
     />

     <Button
      text={downloadingJPEG ? "Downloading..." : "Download JPEG"}
      colour="white"
      icon="download"
      onClick={async () => {
       setDownloadingJPEG(true);
       await exportComponentAsJPEG(panZoomRef, {
        fileName: `H2R Gear - ${handleNameSanitize(name)} ${pngQuality}x`,
        html2CanvasOptions: {
         foreignObjectRendering: true,
         scale: pngQuality,
         ignoreElements: function (el) {
          return el.classList.contains("removeFromPngExport");
         },
        },
       });

       setDownloadingJPEG(false);
      }}
     />

     <Button
      text={downloadingPDF ? "Downloading..." : "Download PDF"}
      colour="white"
      icon="download"
      onClick={async () => {
       setDownloadingPDF(true);

       //  return console.log(panZoomRef?.current?.offsetWidth);

       await exportComponentAsPDF(panZoomRef, {
        fileName: `H2R Gear - ${handleNameSanitize(name)}`,
        html2CanvasOptions: {
         foreignObjectRendering: true,
         scale: 4,
         backgroundColor: "transparent",
         ignoreElements: function (el) {
          return el.classList.contains("removeFromPngExport");
         },
        },
        pdfOptions: {
         unit: "px",
         orientation: "l",
         pdfFormat: [
          panZoomRef?.current?.offsetWidth * 6,
          panZoomRef?.current?.offsetHeight * 6,
         ],
        },
       });

       setDownloadingPDF(false);
      }}
     />
    </div>

    <div className="pt-4 text-gray-600 text-sm">
     <span className="italic">
      <span className="font-bold">Note:</span> Only works in Chrome and FireFox
      right now. Image not included.
     </span>
    </div>
   </div>
  </Modal>
 );
};

const features = {
 zones: {
  name: "Zones",
  text: "Adding zones",
  level: "Pro/Team",
  img: "/img/feature-zones.jpg",
 },
 export: {
  name: "Export",
  text: "Exporting",
  level: "Personal/Pro/Team",
  img: "/img/feature-export.jpg",
 },
 share: {
  name: "Share",
  text: "Sharing",
  level: "Personal/Pro/Team",
  img: "/img/feature-share.jpg",
 },
 patchlist: {
  name: "Patchlist",
  text: "Patchlists",
  level: "Pro/Team",
  img: "/img/feature-patchlist.jpg",
 },
 leftToRight: {
  name: "Left to Right",
  text: "Left to Right mode",
  level: "Pro/Team",
  img: "/img/feature-left-to-right.jpg",
 },
 shapes: {
  name: "Shapes",
  text: "Adding custom shapes",
  level: "Personal/Pro/Team",
  img: "/img/feature-shapes.png",
 },
};

export const UpgradeModal = ({ onCancel, feature = "zones" }) => {
 return (
  <Modal
   title={features[feature]?.name}
   acceptButtonText="Done"
   onAccept={onCancel}
   onCancel={onCancel}
  >
   <img
    alt={features[feature]?.name}
    className="border border-gray-300 border-solid mb-4 rounded"
    src={features[feature]?.img}
   />
   <div className="mx-auto mt-6">
    <span className="mb-4 font-bold block text-xl">Heads up!</span>
    <span className="font-semibold">{features[feature]?.text}</span> is
    available on the {features[feature]?.level} subscription. It might be{" "}
    {
     <Link className="underline" to="/upgrade">
      time to upgrade
     </Link>
    }
    !
   </div>
  </Modal>
 );
};

const shortcuts = [
 {
  keys: ["f"],
  description: "Zoom to fit all items.",
 },
 {
  keys: ["i"],
  description: "Zoom in.",
 },
 {
  keys: ["shift + i"],
  description: "Zoom in (2x speed).",
 },
 {
  keys: ["o"],
  description: "Zoom out.",
 },
 {
  keys: ["shift + o"],
  description: "Zoom out (2x speed).",
 },
 {
  keys: ["w", "up arrow"],
  description: "Pan up.",
 },
 {
  keys: ["s", "down arrow"],
  description: "Pan down.",
 },
 {
  keys: ["a", "left arrow"],
  description: "Pan left.",
 },
 {
  keys: ["d", "right arrow"],
  description: "Pan right.",
 },
 {
  keys: ["ctrl+a", "cmd+a"],
  description: "Select all gear items.",
 },
 {
  keys: ["c"],
  description: "Clear gear item selection.",
 },
 {
  keys: ["ctrl+c", "cmd+c"],
  description: "Copy selected gear item(s).",
 },
 {
  keys: ["ctrl+v", "cmd+v"],
  description: "Paste selected gear item(s).",
 },
 {
  keys: ["delete", "backspace"],
  description: "Delete selected gear item(s).",
 },
 {
  keys: ["g"],
  description: "Open the 'Add Gear' popup.",
 },
 {
  keys: ["p"],
  description: "Open Packlist.",
 },
 {
  keys: ["t"],
  description: "Add new Text Label.",
 },
 {
  keys: ["z"],
  description: "Add new Zone.",
 },
];

export const ShortcutsModal = ({ onCancel }) => {
 return (
  <Modal
   title={"Shortcuts"}
   acceptButtonText="Done"
   onAccept={onCancel}
   onCancel={onCancel}
  >
   <div className="mx-auto flex flex-col space-y-2">
    {shortcuts.map((s, i) => {
     return (
      <div key={i} className="grid grid-cols-2">
       <span className="text-right flex flex-wrap  justify-end">
        {s.keys.map((k, i) => {
         return (
          <span key={k} className="mr-2">
           {i >= 1 && <span className="mr-2 text-xs font-semibold">or</span>}
           <code
            key={k}
            className="px-1 py-0.5 text-sm font-semibold uppercase font-mono bg-gray-100 dark:bg-gray-900 rounded-sm "
           >
            {k}
           </code>
          </span>
         );
        })}
       </span>
       <span className=" whitespace-normal">{s.description}</span>
      </div>
     );
    })}
   </div>
  </Modal>
 );
};

export const ArchivePlanModal = ({ onCancel, onArchive }) => {
 return (
  <Modal
   title="Archive plan"
   acceptButtonText="Archive"
   onAccept={() => {
    onArchive();
   }}
   onCancel={onCancel}
  >
   <div>Are you sure you want to archive this plan?</div>
  </Modal>
 );
};

export const DeleteGearItemModal = ({ onCancel, onDelete }) => {
 return (
  <Modal
   title="Delete gear item"
   acceptButtonText="Delete"
   onAccept={() => {
    onDelete();
   }}
   onCancel={onCancel}
  >
   <p>Are you sure you want to delete this gear item? This cannot be undone!</p>
  </Modal>
 );
};

export const currentVersion = "2.4";

export const WhatsNewModal = () => {
 const showWhatsNewModal = useSelector((state) => state.settings.whatsNew);
 const uid = useSelector((state) => state.firebase.auth.uid);
 const firestore = useFirestore();

 const onCancel = async () => {
  settingsActions.showWhatsNewModal(false);
  firestore
   .collection("users")
   .doc(uid)
   .update({ whatsNewVersion: currentVersion });
 };

 if (!showWhatsNewModal) return null;
 return (
  <Modal
   title="What's new in H2R Gear"
   acceptButtonText="Done"
   onAccept={onCancel}
   onCancel={onCancel}
  >
   <p className="leading-snug font-semibold">
    Here's what is new in v{currentVersion}!
   </p>
   <p className="mt-4 leading-6">
    🔎 Fit on load: When a plan loads, it will zoom to fit all your plan in one screen!
   </p>
   <p className="mt-4 leading-6">
    📦 Zoom to zones: Use the "Fit all" menu to zoom directly into any zone on your plan.
   </p>
   <p className="mt-4 leading-6">
    👀 Filter gear by type: Search for gear on your "my gear" page to find any item by type.
   </p>
   <p className="mt-4 leading-6">
    And loads more.{" "}
    <a className="underline" href="https://docs.h2rgear.com/updates/changelog">
     Read all about it here
    </a>
    !
   </p>
  </Modal>
 );
};
