import React, { Fragment } from "react";

export default function CableTypeDefs({ cableTypes = [], getCableColour }) {
 return (
  <>
   {cableTypes.map((t) => {
    return (
     <Fragment key={t.name}>
      <marker
       id={`arrow_${t.name}`}
       markerWidth="16"
       markerHeight="16"
       refX="3"
       refY="4"
       orient="auto"
       markerUnits="strokeWidth"
       viewBox="0 0 20 20"
       scale={"500%"}
      >
       {/* <path
        d="M11.6066 1.30331L6.3033 6.60661L1 1.30331"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
       /> */}

       <path
        d="M3.60663 6.39337L1.21326 4L3.60663 1.60663"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={getCableColour(t.name)}
       />
      </marker>
      <marker
       id={`startArrow_${t.name}`}
       markerWidth="3"
       markerHeight="40"
       refX="20"
       refY="10"
       orient="auto"
       markerUnits="strokeWidth"
       viewBox="0 0 20 20"
      >
       <circle cx="10" cy="10" r="14" fill={getCableColour(t.name)} />
      </marker>
     </Fragment>
    );
   })}{" "}
  </>
 );
}
