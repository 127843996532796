import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { Edge, Node, useReactFlow } from "reactflow";

import * as planActions from "../../actions/planActions";

const defaultOptions = {
 maxHistorySize: 100,
 enableShortcuts: true,
};

// https://redux.js.org/usage/implementing-undo-history
export const useUndoRedo = ({
 maxHistorySize = defaultOptions.maxHistorySize,
 enableShortcuts = defaultOptions.enableShortcuts,
} = defaultOptions) => {
 // the past and future arrays store the states that we can jump to
 const { id } = useParams();
 const [past, setPast] = useState([]);
 const [future, setFuture] = useState([]);

 const planData = useSelector((state) => state.plans[id]);

 //  const { setNodes, setEdges, getPlanData, getEdges } = useReactFlow();

 const getPlanData = useCallback(() => {
  return planData;
 }, [planData]);

 const setPlanData = useCallback(
  (incoming) => {
   planActions.updatePlan(id, incoming);
  },
  [id]
 );

 const takeSnapshot = useCallback(() => {
  // push the current graph to the past state
  setPast((past) => [
   ...past.slice(past.length - maxHistorySize + 1, past.length),
   { planData: getPlanData() },
  ]);

  // whenever we take a new snapshot, the redo operations need to be cleared to avoid state mismatches
  setFuture([]);
 }, [getPlanData, maxHistorySize]);

 const undo = useCallback(() => {
  // get the last state that we want to go back to
  const pastState = past[past.length - 1];

  if (pastState) {
   // first we remove the state from the history
   setPast((past) => past.slice(0, past.length - 1));
   // we store the current graph for the redo operation
   setFuture((future) => [...future, { planData: getPlanData() }]);
   // now we can set the graph to the past state
   setPlanData(pastState.planData);
  }
 }, [setPlanData, getPlanData, past]);

 const redo = useCallback(() => {
  const futureState = future[future.length - 1];

  if (futureState) {
   setFuture((future) => future.slice(0, future.length - 1));
   setPast((past) => [...past, { planData: getPlanData() }]);
   setPlanData(futureState.planData);
  }
 }, [setPlanData, getPlanData, future]);

 useEffect(() => {
  // this effect is used to attach the global event handlers
  if (!enableShortcuts) {
   return;
  }

  const keyDownHandler = (event) => {
   if (
    event.key === "z" &&
    (event.ctrlKey || event.metaKey) &&
    event.shiftKey
   ) {
    redo();
   } else if (event.key === "z" && (event.ctrlKey || event.metaKey)) {
    undo();
   }
  };

  document.addEventListener("keydown", keyDownHandler);

  return () => {
   document.removeEventListener("keydown", keyDownHandler);
  };
 }, [undo, redo, enableShortcuts]);

 return {
  undo,
  redo,
  takeSnapshot,
  canUndo: !past.length,
  canRedo: !future.length,
 };
};

export default useUndoRedo;
