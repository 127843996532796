const initialState = {
 gridSize: 1,
};

export default function currentPlanReducer(state = initialState, action) {
 switch (action.type) {
  case "GRID_SIZE":
   return {
    ...state,
    gridSize: action.size,
   };

  default:
   return state;
 }
}
