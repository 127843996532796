import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";

import Logo from "./branding/Logo";
// import { getAndUpdateLimits } from "../db/firestore";
import * as planActions from "../actions/planActions";
import useIsMemberOfTeam from "./hooks/useIsMemberOfTeam";
// import usePartOfTeam from "./hooks/usePartOfTeam";

export default function Sidebar({ sidebarShown = false }) {
 //  const [userLimits, setUserLimits] = useState(null);

 //  const auth = useSelector((state) => state.firebase.auth);
 const user = useSelector((state) => state.firebase.profile);
 //  const limits = useSelector((state) => state.settings.limits);
 //  const subscriptions = useSelector(
 //   (state) => state.firestore.data.subscriptions
 //  );

 //  const planName = useSelector((state) => state.settings.planName);

 const firebase = useFirebase();
 //  const firestore = useFirestore();

 //  const handleGetLimits = async () => {
 //   return await firestore
 //    .doc(`users/${auth.uid}/subscription/current`)
 //    .get()
 //    .then((doc) => {
 //     console.log(doc.data(), subscriptions);
 //     if (!doc.exists) return getAndUpdateLimits(auth.uid);

 //     getAndUpdateLimits(auth.uid, doc.data().limits.plansLimit);
 //    });
 //  };

 useEffect(() => {
  if (isLoaded(user) && !isEmpty(user)) {
   //  handleGetLimits();
   //  getAndUpdateLimits(auth.uid, user.plansLimit);
  }
 }, [user]);

 const handleLogout = () => {
  planActions.logout();
  return firebase.logout();
 };
 return (
  <div
   style={{
    height: "100svh",
   }}
   className={`flex flex-col justify-between bg-white dark:bg-gray-900 border-r-2 border-solid border-gray-300 dark:border-gray-800 overflow-auto
   transform transition-transform ${
    sidebarShown
     ? "translate-x-0 absolute z-10 md:relative"
     : "-translate-x-full hidden transform md:relative md:flex md:-translate-x-0"
   }
   `}
  >
   <div className="px-10 py-10 font-bold text-xl flex justify-center items-center border-b-2 border-solid border-gray-300 dark:border-gray-800">
    <Link to="/plans">
     <Logo />
    </Link>
   </div>
   <nav className="mt-8 text-gray-700 dark:text-gray-400">
    <ul>
     <NavLink
      to="/plans"
      exact
      className="px-2 py-4 flex items-center hover:bg-red-100 dark:hover:bg-gray-800"
      activeClassName="bg-red-100 dark:bg-gray-800 text-red-600 dark:text-red-100 border-l-4 border-solid border-red-600"
     >
      <div className="flex justify-center items-center">
       <svg
        className="ml-6 w-5 h-5"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
       >
        <path d="M4.167 2.5H7.5a1.667 1.667 0 011.667 1.667V7.5A1.667 1.667 0 017.5 9.167H4.167A1.667 1.667 0 012.5 7.5V4.167c0-.917.75-1.667 1.667-1.667zm0 1.667V7.5H7.5V4.167H4.167zM12.5 2.5h3.333A1.667 1.667 0 0117.5 4.167V7.5a1.667 1.667 0 01-1.667 1.667H12.5A1.667 1.667 0 0110.833 7.5V4.167c0-.917.75-1.667 1.667-1.667zm0 1.667V7.5h3.333V4.167H12.5zm-8.333 6.666H7.5A1.667 1.667 0 019.167 12.5v3.333A1.667 1.667 0 017.5 17.5H4.167A1.667 1.667 0 012.5 15.833V12.5c0-.917.75-1.667 1.667-1.667zm0 1.667v3.333H7.5V12.5H4.167zm8.333-1.667h3.333A1.667 1.667 0 0117.5 12.5v3.333a1.667 1.667 0 01-1.667 1.667H12.5a1.667 1.667 0 01-1.667-1.667V12.5c0-.917.75-1.667 1.667-1.667zm0 1.667v3.333h3.333V12.5H12.5z" />
       </svg>
      </div>
      <h3 className="ml-5">Plans</h3>
     </NavLink>
     <NavLink
      to="/gear"
      exact
      className="mt-1 px-2 py-4 flex items-center hover:bg-red-100 dark:hover:bg-gray-800"
      activeClassName="bg-red-100 dark:bg-gray-800 text-red-600 dark:text-red-100 border-l-4 border-solid border-red-600"
     >
      <div className="flex justify-center items-center">
       <svg
        className="ml-6 w-5 h-5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
       >
        <path
         d="M2.5 7.5a1.667 1.667 0 011.667-1.667h.775a1.667 1.667 0 001.386-.741l.677-1.017a1.667 1.667 0 011.387-.742h3.216a1.666 1.666 0 011.387.742l.677 1.017a1.666 1.666 0 001.386.741h.775A1.666 1.666 0 0117.5 7.5V15a1.667 1.667 0 01-1.667 1.667H4.167A1.666 1.666 0 012.5 15V7.5z"
         stroke="currentColor"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
        />
        <path
         d="M12.5 10.833a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0v0z"
         stroke="currentColor"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
        />
       </svg>
      </div>
      <h3 className="ml-5">Gear</h3>
     </NavLink>
     <NavLink
      to="/builder"
      exact
      className="mt-1 px-2 py-4 flex items-center hover:bg-red-100 dark:hover:bg-gray-800"
      activeClassName="bg-red-100 dark:bg-gray-800 text-red-600 dark:text-red-100 border-l-4 border-solid border-red-600"
     >
      <div className="flex justify-center items-center">
       <svg
        className="ml-6 w-5 h-5"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
       >
        <path
         d="M14.167 18.333a1.667 1.667 0 01-1.667-1.666v-.834a.833.833 0 00-1.667 0v.834a1.667 1.667 0 01-1.666 1.666h-2.5A1.667 1.667 0 015 16.667v-2.5h-.833a2.5 2.5 0 010-5H5v-2.5C5 5.742 5.75 5 6.667 5h2.5v-.833a2.5 2.5 0 115 0V5h2.5a1.667 1.667 0 011.666 1.667v2.5a1.667 1.667 0 01-1.666 1.666h-.834a.834.834 0 000 1.667h.834a1.667 1.667 0 011.666 1.667v2.5a1.667 1.667 0 01-1.666 1.666h-2.5zm2.5-1.666v-2.5h-.834a2.5 2.5 0 010-5h.834v-2.5h-2.5A1.667 1.667 0 0112.5 5v-.833a.833.833 0 10-1.667 0V5a1.667 1.667 0 01-1.666 1.667h-2.5v2.5A1.667 1.667 0 015 10.833h-.833a.834.834 0 000 1.667H5a1.667 1.667 0 011.667 1.667v2.5h2.5v-.834a2.5 2.5 0 115 0v.834h2.5z"
         fill="currentColor"
        />
       </svg>
      </div>
      <h3 className="ml-5">Builder</h3>
     </NavLink>

     <NavLink
      to="/settings"
      activeClassName="bg-red-100 dark:bg-gray-800 text-red-600 dark:text-red-100 border-l-4 border-solid border-red-600"
      className="mt-1 px-2 py-4 flex items-center hover:bg-red-100 dark:hover:bg-gray-800"
     >
      <div className="flex justify-center items-center">
       <svg
        className="ml-6 w-5 h-5"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
       >
        <path d="M7.5 3.817v-.484c0-.916.75-1.666 1.667-1.666h1.666A1.667 1.667 0 0112.5 3.333v.484a6.67 6.67 0 011.6.925l.417-.242a1.667 1.667 0 012.283.608l.833 1.45a1.667 1.667 0 01-.608 2.275l-.417.242a6.72 6.72 0 010 1.85l.417.25a1.666 1.666 0 01.608 2.267l-.833 1.45a1.667 1.667 0 01-2.275.608l-.417-.25c-.489.388-1.03.705-1.608.942v.475a1.667 1.667 0 01-1.667 1.666H9.167A1.667 1.667 0 017.5 16.667v-.484a6.669 6.669 0 01-1.6-.925l-.417.242a1.667 1.667 0 01-2.283-.608l-.833-1.45a1.667 1.667 0 01.608-2.275l.417-.242a6.717 6.717 0 010-1.85l-.417-.25a1.667 1.667 0 01-.608-2.267l.833-1.45A1.667 1.667 0 015.475 4.5l.417.25A6.667 6.667 0 017.5 3.808v.009zm-.933 2.55l-.45.425-1.475-.85-.834 1.45 1.467.841-.142.609a5.017 5.017 0 000 2.316l.142.609-1.467.841.834 1.45 1.475-.85.45.425a5 5 0 002 1.167l.6.167v1.7h1.666v-1.7l.592-.167a5 5 0 002.008-1.167l.45-.425 1.475.85.834-1.45-1.467-.841.142-.609c.18-.761.18-1.555 0-2.316l-.142-.609 1.467-.841-.834-1.45-1.475.85-.45-.425a5 5 0 00-2-1.167l-.6-.167v-1.7H9.167v1.7l-.592.167a5 5 0 00-2.008 1.167zM10 13.333a3.333 3.333 0 110-6.667 3.333 3.333 0 010 6.667zm0-1.666a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334z" />
       </svg>
      </div>
      <h3 className="ml-5">Settings</h3>
     </NavLink>

     {user.partOfTeam && (
      <>
       <li className="mt-8 border-b-2 border-solid border-gray-200 dark:border-gray-800"></li>

       <NavLink
        to="/team/plans"
        exact
        className="mt-8 px-2 py-4 flex items-center hover:bg-red-100 dark:hover:bg-gray-800"
        activeClassName="bg-red-100 dark:bg-gray-800 text-red-600 dark:text-red-100 border-l-4 border-solid border-red-600"
       >
        <div className="flex justify-center items-center">
         <svg
          className="ml-6 w-5 h-5"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
         >
          <path d="M4.167 2.5H7.5a1.667 1.667 0 011.667 1.667V7.5A1.667 1.667 0 017.5 9.167H4.167A1.667 1.667 0 012.5 7.5V4.167c0-.917.75-1.667 1.667-1.667zm0 1.667V7.5H7.5V4.167H4.167zM12.5 2.5h3.333A1.667 1.667 0 0117.5 4.167V7.5a1.667 1.667 0 01-1.667 1.667H12.5A1.667 1.667 0 0110.833 7.5V4.167c0-.917.75-1.667 1.667-1.667zm0 1.667V7.5h3.333V4.167H12.5zm-8.333 6.666H7.5A1.667 1.667 0 019.167 12.5v3.333A1.667 1.667 0 017.5 17.5H4.167A1.667 1.667 0 012.5 15.833V12.5c0-.917.75-1.667 1.667-1.667zm0 1.667v3.333H7.5V12.5H4.167zm8.333-1.667h3.333A1.667 1.667 0 0117.5 12.5v3.333a1.667 1.667 0 01-1.667 1.667H12.5a1.667 1.667 0 01-1.667-1.667V12.5c0-.917.75-1.667 1.667-1.667zm0 1.667v3.333h3.333V12.5H12.5z" />
         </svg>
        </div>
        <h3 className="ml-5">Team plans</h3>
       </NavLink>

       <NavLink
         to="/team/gear"
         exact
         className="mt-1 px-2 py-4 flex items-center hover:bg-red-100 dark:hover:bg-gray-800"
         activeClassName="bg-red-100 dark:bg-gray-800 text-red-600 dark:text-red-100 border-l-4 border-solid border-red-600"
        >
         <div className="flex justify-center items-center">
          <svg
            className="ml-6 w-5 h-5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
            d="M2.5 7.5a1.667 1.667 0 011.667-1.667h.775a1.667 1.667 0 001.386-.741l.677-1.017a1.667 1.667 0 011.387-.742h3.216a1.666 1.666 0 011.387.742l.677 1.017a1.666 1.666 0 001.386.741h.775A1.666 1.666 0 0117.5 7.5V15a1.667 1.667 0 01-1.667 1.667H4.167A1.666 1.666 0 012.5 15V7.5z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <path
            d="M12.5 10.833a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0v0z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
          </svg>
         </div>
         <h3 className="ml-5">Team gear</h3>
        </NavLink>

       <NavLink
        to="/team"
        exact
        className="mt-1 px-2 py-4 flex items-center hover:bg-red-100 dark:hover:bg-gray-800"
        activeClassName="bg-red-100 dark:bg-gray-800 text-red-600 dark:text-red-100 border-l-4 border-solid border-red-600"
       >
        <div className="flex justify-center items-center">
         <svg
          className="ml-6 w-5 h-5"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
         >
          <path d="M7.5 3.817v-.484c0-.916.75-1.666 1.667-1.666h1.666A1.667 1.667 0 0112.5 3.333v.484a6.67 6.67 0 011.6.925l.417-.242a1.667 1.667 0 012.283.608l.833 1.45a1.667 1.667 0 01-.608 2.275l-.417.242a6.72 6.72 0 010 1.85l.417.25a1.666 1.666 0 01.608 2.267l-.833 1.45a1.667 1.667 0 01-2.275.608l-.417-.25c-.489.388-1.03.705-1.608.942v.475a1.667 1.667 0 01-1.667 1.666H9.167A1.667 1.667 0 017.5 16.667v-.484a6.669 6.669 0 01-1.6-.925l-.417.242a1.667 1.667 0 01-2.283-.608l-.833-1.45a1.667 1.667 0 01.608-2.275l.417-.242a6.717 6.717 0 010-1.85l-.417-.25a1.667 1.667 0 01-.608-2.267l.833-1.45A1.667 1.667 0 015.475 4.5l.417.25A6.667 6.667 0 017.5 3.808v.009zm-.933 2.55l-.45.425-1.475-.85-.834 1.45 1.467.841-.142.609a5.017 5.017 0 000 2.316l.142.609-1.467.841.834 1.45 1.475-.85.45.425a5 5 0 002 1.167l.6.167v1.7h1.666v-1.7l.592-.167a5 5 0 002.008-1.167l.45-.425 1.475.85.834-1.45-1.467-.841.142-.609c.18-.761.18-1.555 0-2.316l-.142-.609 1.467-.841-.834-1.45-1.475.85-.45-.425a5 5 0 00-2-1.167l-.6-.167v-1.7H9.167v1.7l-.592.167a5 5 0 00-2.008 1.167zM10 13.333a3.333 3.333 0 110-6.667 3.333 3.333 0 010 6.667zm0-1.666a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334z" />
         </svg>
        </div>
        <h3 className="ml-5">Team settings</h3>
       </NavLink>
      </>
     )}

     {/* <NavLink
       to="/gear"
       activeClassName="bg-red-100 dark:bg-gray-800 text-red-600 dark:text-red-100 border-l-4 border-solid border-red-600"
       className="mt-1 px-2 py-4 flex items-center hover:bg-red-100 dark:hover:bg-gray-800"
      >
       <div className="flex justify-center items-center">
        <svg
         className="ml-6 w-5 h-5"
         fill="currentColor"
         xmlns="http://www.w3.org/2000/svg"
        >
         <path d="M14.167 18.333a1.667 1.667 0 01-1.667-1.666v-.834a.833.833 0 00-1.667 0v.834a1.667 1.667 0 01-1.666 1.666h-2.5A1.667 1.667 0 015 16.667v-2.5h-.833a2.5 2.5 0 010-5H5v-2.5C5 5.742 5.75 5 6.667 5h2.5v-.833a2.5 2.5 0 115 0V5h2.5a1.667 1.667 0 011.666 1.667v2.5a1.667 1.667 0 01-1.666 1.666h-.834a.834.834 0 000 1.667h.834a1.667 1.667 0 011.666 1.667v2.5a1.667 1.667 0 01-1.666 1.666h-2.5zm2.5-1.666v-2.5h-.834a2.5 2.5 0 010-5h.834v-2.5h-2.5A1.667 1.667 0 0112.5 5v-.833a.833.833 0 10-1.667 0V5a1.667 1.667 0 01-1.666 1.667h-2.5v2.5A1.667 1.667 0 015 10.833h-.833a.834.834 0 000 1.667H5a1.667 1.667 0 011.667 1.667v2.5h2.5v-.834a2.5 2.5 0 115 0v.834h2.5z" />
        </svg>
       </div>
       <h3 className="ml-5">Gear</h3>
      </NavLink> */}
     {/* <NavLink
       to="/must-haves"
       activeClassName="bg-red-100 dark:bg-gray-800 text-red-600 dark:text-red-100 border-l-4 border-solid border-red-600"
       className="mt-1 px-2 py-4 flex items-center hover:bg-red-100 dark:hover:bg-gray-800"
      >
       <div className="flex justify-center items-center">
        <svg
         className="ml-6 w-5 h-5"
         fill="currentColor"
         xmlns="http://www.w3.org/2000/svg"
        >
         <path d="M6.667 5.833V4.167c0-.917.75-1.667 1.666-1.667h3.334a1.667 1.667 0 011.666 1.667v1.666h3.334A1.667 1.667 0 0118.333 7.5v8.333a1.667 1.667 0 01-1.666 1.667H3.333a1.667 1.667 0 01-1.666-1.667V7.5c0-.917.75-1.667 1.666-1.667h3.334zM13.333 7.5H6.667v8.333h6.666V7.5zM15 7.5v8.333h1.667V7.5H15zm-10 0H3.333v8.333H5V7.5zm3.333-1.667h3.334V4.167H8.333v1.666z" />
        </svg>
       </div>
       <h3 className="ml-5">Must haves</h3>
      </NavLink> */}
     <li className="mt-8 border-b-2 border-solid border-gray-200 dark:border-gray-800"></li>

     <a
      href="https://heretorecord.gitbook.io/h2r-gear/"
      target="_blank"
      rel="noopener noreferrer"
      className="mt-8 px-2 py-4 cursor-pointer flex items-center hover:bg-red-100 dark:hover:bg-gray-800"
     >
      <div className="flex justify-center items-center">
       <svg
        className="ml-6 w-5 h-5"
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
       >
        <path
         d="M9.75 12H9V9h-.75l1.5 3zM9 6h.008H9zm6.75 3a6.75 6.75 0 11-13.5 0 6.75 6.75 0 0113.5 0z"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
        />
       </svg>
      </div>
      <h3 className="ml-5">Help</h3>
     </a>
     <button
      onClick={() => handleLogout()}
      className="mt-1 px-2 py-4 w-full flex items-center hover:bg-red-100 dark:hover:bg-gray-800"
     >
      <div className="flex justify-center items-center">
       <svg
        className="ml-6 w-5 h-5"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
       >
        <path d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z" />
       </svg>
      </div>
      <h3 className="ml-5">Logout</h3>
     </button>
    </ul>
   </nav>
   <span className="flex-grow"></span>
   <div className="mt-8 border-b-2 border-solid border-gray-200 dark:border-gray-800"></div>
   {user && <UserPlanUsage />}

   <div className="border-b-2 border-solid border-gray-200 dark:border-gray-800"></div>

   <div className="px-10 pt-5 pb-1 text-xs text-gray-600 leading-relaxed">
    v{process.env.REACT_APP_VERSION}.{" "}
    <a
     href="https://heretorecord.gitbook.io/h2r-gear/updates/changelog"
     target="_blank"
     rel="noopener noreferrer"
     className="border-b border-solid border-gray-300 dark:border-gray-700 hover:border-gray-500"
    >
     See what's new
    </a>
    .
   </div>
   <div className="px-10 pb-5 text-xs text-gray-600 leading-relaxed">
    Issue or feedback?{" "}
    <a
     href="https://h2r.li/issue"
     target="_blank"
     rel="noopener noreferrer"
     className="border-b border-solid border-gray-300 dark:border-gray-700 hover:border-gray-500"
    >
     Let us know
    </a>
    .
   </div>
  </div>
 );
}

const UserPlanUsage = () => {
 //  const partOfTeam = usePartOfTeam();

 //  const uid = useSelector((state) => state.firebase.auth.uid);
 const partOfTeam = useSelector((state) => state.team) || false;
 const limits = useSelector((state) => state.settings.limits);
 const { isTeamMember } = useIsMemberOfTeam();

 const planName = useSelector((state) => state.settings.planName);

 if (isTeamMember)
  return (
   <div className="px-10 py-5">
    <div>
     <span className="text-gray-700 dark:text-gray-300 capitalize">
      {partOfTeam?.name}
     </span>{" "}
    </div>
    <div className="mt-2 text-gray-600 text-xs">Team member</div>
   </div>
  );

 return (
  <>
    {/* {['Starter', 'Personal'].includes(planName) && <div className="p-2 px-6 mb-3 bg-red-500/10 rounded flex flex-col gap-2">
      <p className="text-black dark:text-white mb-1">☀️ Summer sale!</p>
      <p className="text-xs max-w-[15rem]">Get 50% off your first year of Personal or Pro with code <mark className="p-0.5 bg-red-500/20 text-black dark:text-white rounded">SUMMER24</mark> at checkout.</p>
      <p className="text-xs text-gray-500">Ends June 15th.</p>
      <Link to="/upgrade" className="text-sm underline">Upgrade now →</Link>
    </div>} */}
    <div className="px-10 py-5">
    <div>
      <span className="text-gray-700 dark:text-gray-300 capitalize">
      {planName}
      </span>{" "}
      {planName !== "" && (
      <Link className="ml-1 text-xs uppercase text-gray-500 " to="/upgrade">
        Change
      </Link>
      )}
    </div>
    <div className="my-3 h-1 bg-gray-300 dark:bg-gray-800 rounded-full">
      <div
      style={{
        width: `
        ${
        limits.limit === 0
          ? 0
          : (limits.total / limits.limit) * 100 <= 100
          ? (limits.total / limits.limit) * 100
          : 100
        }%`,
      }}
      className="my-3 h-1 bg-red-500 rounded-full transition-all duration-700 ease-in-out"
      ></div>
    </div>
    <div className="text-gray-600 text-xs">
      {limits.limit === 0 ? (
      <span className=" text-transparent animate-pulse">Checking...</span>
      ) : (
      `${limits.total} of ${limits.limit} active plans`
      )}
    </div>
    </div>
  </>
 );
};
