import React, { useState } from "react";
/**
 * @param  {Object[]} item - Item object
 * @param  {string} item[].id - ID of the item
 * @param  {string} item[].text - Text of the item
 * @param  {string} item[].packed - If the item is checked or not
 * @param  {function} toggleCheckbox - Function to handle when the checkbox is toggled
 * @param  {boolean} removable - Can the item be removed by the user
 * @param  {function} onRemove - Function to handle the removal of the item
 */
export default function PacklistItem({
 item,
 toggleCheckbox,
 removable = true,
 onRemove,
}) {
 const [showRemove, setShowRemove] = useState(false);
 return (
  <div
   className="mt-2 py-2 w-full flex justify-between"
   onMouseEnter={() => {
    setShowRemove(true);
   }}
   onMouseLeave={() => {
    setShowRemove(false);
   }}
  >
   <button
    className={`w-full flex justify-start items-center hover:text-gray-600 focus:outline-none focus:text-gray-600 ${
     item.packed
      ? "text-gray-400 dark:text-gray-700"
      : "text-gray-700 dark:text-gray-400"
    }`}
    onClick={() => toggleCheckbox(item.id)}
   >
    <div>
     <svg
      className="w-6 h-6"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
     >
      <path
       d={
        !item.packed
         ? "M12 22a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16.001A8 8 0 0012 20z"
         : "M12 22a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16.001A8 8 0 0012 20zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 011.4 1.42l-4 4a1 1 0 01-1.4 0l-2-2a1 1 0 011.4-1.42v.01z"
       }
      />
     </svg>
    </div>
    <div className={`ml-4 ${item.packed && "italic line-through"}`}>
     {item.text}
    </div>
   </button>
   {removable && showRemove && (
    <button
     onClick={() => {
      onRemove(item.id);
     }}
     className="text-xs uppercase text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600"
    >
     Remove
    </button>
   )}
  </div>
 );
}
