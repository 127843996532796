import { store } from "../db/store";
import randomatic from "randomatic";

/** addNotification
 * @param {string} title - Title of the notification
 * @param {string} body - Body of the notification
 * @param {string} type - success, warn or fail
 */
export const addNotification = (
 title = "Success!",
 body = "That worked.",
 notificationType = "success",
 duration = 3000
) => {
 let id = randomatic("Aa0", 5);

 store.dispatch({
  type: "ADD_NOTIFICATION",
  title,
  body,
  notificationType,
  id,
 });

 setTimeout(() => {
  hideNotification(id);
 }, [duration]);
};

/** hideNotification
 * @param {number} id - ID of the notification to hide
 */
export const hideNotification = (id) => {
 return store.dispatch({
  type: "HIDE_NOTIFICATION",
  id,
 });
};

/** clearNotifications
 * @param {number} id - ID of the notification to hide
 */
export const clearNotifications = (id) => {
 return store.dispatch({
  type: "CLEAR_NOTIFICATIONS",
  id,
 });
};
