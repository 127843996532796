import React, { useEffect, useMemo, useState } from "react";
import Xarrow from "react-xarrows";
import {
 Editor,
 EditorState,
 ContentState,
 RichUtils,
 convertFromRaw,
 convertToRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { TextLabelInput } from "./SketchItem";

export default function Cable({ cable, panZoomState }) {
 const [hovered, setHovered] = useState(false);
 const [fromItem, setFromItem] = useState(null);
 const [toItem, setToItem] = useState(null);

 const caclulateCablePoints = () => {
  const div1Rect = fromItem?.getBoundingClientRect();
  const div2Rect = toItem?.getBoundingClientRect();
  const x1 = div1Rect.x + div1Rect.width / 2;
  const y1 = div1Rect.y + div1Rect.height / 2;
  const x2 = div2Rect.x + div2Rect.width / 2;
  const y2 = div2Rect.y + div2Rect.height / 2;

  // // Calculate the angle of the line
  const a = x1 - x2;
  const b = y1 - y2;
  const angle = Math.atan2(b, a);

  const div1Point = {
   x: x1 + (div1Rect.width / 2) * Math.cos(angle + Math.PI / 2),
   y: y1 + (div1Rect.height / 2) * Math.sin(angle + Math.PI / 2),
  };
  const div2Point = {
   x: x2 + (div2Rect.width / 2) * Math.cos(angle - Math.PI / 2),
   y: y2 + (div2Rect.height / 2) * Math.sin(angle - Math.PI / 2),
  };
  //   console.log("item", cable.id, div1Point, div2Point);

  return `M${div1Rect.x} ${div1Rect.y}
         L${div2Rect.x} ${div2Rect.y}`;
 };

 useEffect(() => {
  const div1Ref = document.getElementById(cable.from.id);
  const div2Ref = document.getElementById(cable.to.id);

  setFromItem(div1Ref);
  setToItem(div2Ref);
 }, []);

 if (!toItem || !fromItem) return null;
 return (
  // <div
  //  onMouseEnter={() => setHovered(true)}
  //  onMouseLeave={() => setHovered(false)}
  //  //  className="ring"
  // >
  <svg
   style={{
    position: "absolute",
    overflow: "visible",
    top: 0,
    left: 0,
    zIndex: -1,
   }}
   viewBox="0 0 200 100"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d={caclulateCablePoints()}
    fill="none"
    stroke="black"
    strokeWidth={2}
   />
  </svg>

  // </div>
 );
}

const Label = ({ text }) => {
 return <TextLabelInput planId={`1233`} textLabel={{}} />;
};

const NewLabel = ({ show }) => {
 return (
  <button
   className={`bg-white border text-center border-solid px-2 py-2 rounded focus:outline-none focus:ring transition-all
                            ${show ? "opacity-100" : "opacity-0"}
 `}
  >
   +
  </button>
 );
};
