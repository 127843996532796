import React, { useState, Fragment } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import * as builderActions from "../actions/builderActions";
import GearCard from "./GearCard";
import { DeleteGearItemModal } from "./Modal";
import * as notificationsActions from '../actions/notificationsActions'

export default function GearCards({ team = false }) {
 const [gearItemToDelete, setGearItemToDelete] = useState(null);
 const [showDeleteModal, setShowDeleteModal] = useState(false);

 const firestore = useFirestore();
 const history = useHistory();

 const filters = useSelector((state) => state.filters);
 const gear = useSelector((state) => state.firestore.data.items || {});
 const user = useSelector((state) => state.firebase.profile);


 const gearFiltered = Object.entries(gear)
  .filter(([id, item]) => {
   if (team && item?._team !== true) {
    return false;
   } else {
    return true;
   }
  })
  .filter(([_, item]) => {
   if (!item) {
    return false;
   }
   if (filters.gearText.toLowerCase() === "") {
    // console.log(id, item.event_end_date.toMillis() - Date.now());
    if (filters.gearTab === "private") {
     if (item._private && item._private === true) {
      return true;
     } else {
      return false;
     }
    } else if (filters.gearTab === "public") {
     if (!item._private) {
      return true;
     } else {
      return false;
     }
    } else {
     // if (item.event_end_date === null) {
     //  return true;
     // } else {
     //  return false;
     // }
    }
   }

   if (
    item.name?.toLowerCase().includes(filters.gearText.toLowerCase()) ||
    item.manufacturer?.toLowerCase().includes(filters.gearText.toLowerCase()) ||
    item.internalGearId?.toLowerCase().includes(filters.gearText.toLowerCase()) || 
    item.type?.toLowerCase().includes(filters.gearText.toLowerCase()) ||
    item.searchTerms?.includes(filters.gearText.toLowerCase())
   ) {
    return true;
   } else {
    return false;
   }
  });

 const editGearItem = async (item) => {
  await builderActions.loadEditItem({ ...item, updatingItem: true });

  return history.push("/builder");
 };

 const handleGearItemDelete = async () => {
    try {
        await firestore.collection("items").doc(gearItemToDelete).delete();
        notificationsActions.addNotification('Item deleted', `That item has now been removed from your account.`, 'success')
    } catch (error) {
        notificationsActions.addNotification('Something went wrong', "You don't have permission to delete this item", 'fail')
    } finally {
        setShowDeleteModal(false);
        return setGearItemToDelete(null);
    }
 };

 return (
  <Fragment>
   {gearFiltered.map(([id, item]) => {
    return (
     <GearCard
      key={item.id}
      actionText="Edit"
      waitText="Working..."
      gearItem={item}
      gearCardAction={(item) => {
       editGearItem(item);
      }}
      buttonDropdown={true}
      buttonDropdownOptions={[
       {
        title: "Create copy and edit",
        to: "/that",
        icon: "copy",
        func: function () {
         builderActions.loadEditItem({
          ...item,
          name: `Copy of ${item.name}`,
          copyOf: item.id,
          _private: true,
          updatingItem: false,
         });

         return history.push("/builder");
        },
       },
       !team && user.partOfTeam && {
           title: "Share with team",
           disabled: !user.partOfTeam || item?._team,
           to: "/that",
           icon: "share",
           func: async function () {
            await firestore.doc(`items/${item.id}`).update({_team: true, uid: user.partOfTeam})
            return notificationsActions.addNotification(
             "Gear shared with team",
             "We've added this gear into your team section",
             "success",
             10000
            );
           },
          },
       {
        title: "Delete gear item",
        to: "/that",
        icon: "delete",
        func: function () {
         setGearItemToDelete(item.id);
         return setShowDeleteModal(true);
        },
       },
      ]}
     />
    );
   })}

   {/* DELETE GEAR ITEM MODAL */}
   {showDeleteModal && (
    <DeleteGearItemModal
     onCancel={() => {
      setGearItemToDelete(null);
      return setShowDeleteModal(false);
     }}
     onDelete={() => {
      return handleGearItemDelete();
     }}
    />
   )}
  </Fragment>
 );
}
