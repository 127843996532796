import React, { useState } from "react";
import {
 Editor,
 EditorState,
 ContentState,
 RichUtils,
 convertFromRaw,
 convertToRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";

import * as planActions from "../actions/planActions";

const colours = [
 "gray-300",
 "green-300",
 "yellow-300",
 "red-300",
 "blue-300",
 "orange-300",
];

export default function TextLabelInput({
 planId,
 textLabel,
 readOnly = false,
}) {
 const { colour = "gray-300" } = textLabel;

 const [hovered, setHovered] = useState(false);
 const [editorState, setEditorState] = useState(() => {
  if (textLabel.content) {
   return EditorState.createWithContent(
    convertFromRaw(JSON.parse(textLabel.content))
   );
  } else {
   return EditorState.createWithContent(
    ContentState.createFromText("New text label")
   );
  }
 });

 const handleKeyCommand = (command, editorState) => {
  const newState = RichUtils.handleKeyCommand(editorState, command);

  if (newState) {
   setEditorState(newState);
   return "handled";
  }

  return "not-handled";
 };

 const handleSave = () => {
  const rawDraftContentState = JSON.stringify(
   convertToRaw(editorState.getCurrentContent())
  );
  planActions.updateTextLabel(planId, textLabel.id, {
   content: rawDraftContentState,
  });
 };

 return (
  <div
   onMouseEnter={() => setHovered(true)}
   //    onMouseLeave={() => setHovered(false)}
   //    onMouseEnter={() => setHovered(true)}
   onMouseLeave={() => setHovered(false)}
   className={`rounded  relative
   bg-${colour}
   `}
  >
   <div
    className={`handle pt-3 rounded
   bg-${colour}
   `}
   ></div>
   <div
    className={`noPan bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-300 border border-solid px-2 py-2 cursor-text rounded focus:outline-none focus:ring
    border-${colour}
    `}
    style={{ minWidth: "max-content" }}
   >
    <Editor
     onBlur={handleSave}
     editorState={editorState}
     onChange={setEditorState}
     handleKeyCommand={handleKeyCommand}
     spellCheck={true}
     readOnly={readOnly}
     blockStyleFn={() => "noPan"}
    />
   </div>
   <div className="absolute top-0 min-w-[200px] mt-3 pb-10 bg-transparent w-full h-full"></div>
   {hovered && readOnly === false && (
    <div className="flex w-full cursor-default justify-between py-1 absolute text-sm text-gray-400 ">
     <div className="flex">
      {colours.map((c) => {
       return (
        <button
         key={c}
         onClick={() =>
          planActions.updateTextLabel(planId, textLabel.id, { colour: c })
         }
         className={`noPan mx-1 w-4 h-4 bg-${c} border border-gray-500 dark:border-gray-800 rounded-sm`}
        ></button>
       );
      })}
     </div>
     <button
      className="noPan px-1 py-0.5 bg-white border broder-solid border-gray-500 text-xs text-gray-900 rounded"
      onClick={() => {
       planActions.removeTextLabel(planId, textLabel.id);
      }}
     >
      <svg
       xmlns="http://www.w3.org/2000/svg"
       className="h-3 w-3"
       viewBox="0 0 20 20"
       fill="currentColor"
      >
       <path
        fillRule="evenodd"
        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
        clipRule="evenodd"
       />
      </svg>
     </button>
    </div>
   )}
  </div>
 );
}
