import React, { useEffect, useState } from "react";
import randomatic from "randomatic";
import { CSVLink } from "react-csv";

import PackProgress from "./PackProgress";
import Input from "./Input";
import PacklistItem from "./PacklistItem";
import * as planActions from "../actions/planActions";
import Button from "./Button";
// import useGetCableCustomName from "./hooks/useGetCableCustomName";

const csvHeaders = ["Packed,", "Count,", "Item,", "Internal ID/Label,", "\n"];

export default function Packlist({ plan, onClose, shown = false }) {
 const [extraText, setExtraText] = useState("");
 const [itemsCollected, setItemsCollected] = useState([]);
 const [cablesCollected, setCablesCollected] = useState([]);
 let [csv, setCsv] = useState([[csvHeaders.join("")]]);
 const [copiedCsv, setCopiedCsv] = useState(false)

 const addExtra = async () => {
  if (extraText === "") return null;
  const itemToAdd = {
   text: extraText,
   packed: false,
   id: randomatic("Aa0", 20),
  };
  // packlistActions.addPacklistExtra(plan.packlist_id, itemToAdd);
  planActions.addExtra(plan.id, itemToAdd);
  return setExtraText("");
 };

 const collectItems = () => {
  let newItemsObject = {};

  plan.items.map((item) => {
   let itemString;

   if (item.label) {
    itemString = item.label;
   } else if (item.internalGearId) {
    itemString = `${item.label || item.name} • ${item.internalGearId}`;
   } else if (item.name) {
    itemString = item.name;
   } else {
    itemString = item.type;
   }

   //  itemString = item.label || item.name;

   // if (!cable.fromType) {
   //  cableString = cable.type;
   // } else if (cable.fromType === cable.toType) {
   //  cableString = `${cable.fromType.toUpperCase()} ${
   //   cable.length ? `(${cable.length})` : ""
   //  }`;
   // } else {
   //  cableString = `${cable.fromType.toUpperCase()} to ${cable.toType.toUpperCase()} ${
   //   cable.length ? `(${cable.length})` : ""
   //  }`;
   // }

   newItemsObject = {
    ...newItemsObject,
    [itemString]: newItemsObject[itemString]
     ? [...newItemsObject[itemString], { ...item }]
     : [{ ...item }],
   };
  });

  return setItemsCollected(newItemsObject);
 };

 const collectCables = () => {
  let newCablesObject = {};

  plan.cables.map((cable) => {
   let cableString;

   if (!cable.fromType) {
    cableString = cable.type;
   } else if (cable.fromType === cable.toType) {
    cableString = `${cable.fromType.toUpperCase()}${
     cable.label ? ` • ${cable.label}` : ""
    } ${cable.length ? `(${cable.length})` : ""}`;
   } else {
    cableString = `${cable.fromType.toUpperCase()} to ${cable.toType.toUpperCase()}${
     cable.label ? ` • ${cable.label}` : ""
    } ${cable.length ? `(${cable.length})` : ""}`;
   }

   newCablesObject = {
    ...newCablesObject,
    [cableString]: newCablesObject[cableString]
     ? [...newCablesObject[cableString], { ...cable }]
     : [{ ...cable }],
   };
  });

  return setCablesCollected(newCablesObject);
 };

 const handleCopyAsCsv = () => {
  setCopiedCsv(true)

  navigator.clipboard.writeText(csv.join(""));

  setTimeout(() => {
    setCopiedCsv(false)
  }, 3000);
}

 useEffect(() => {
  collectItems();
  collectCables();

  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [plan]);

 useEffect(() => {
  let c = [];
  c = [...c, [",", ",", "•• GEAR ••,", ",", `\n`].join("")];
  Object.entries(itemsCollected).map(([name, count]) => {
   return (c = [
    ...c,
    [
     `${count[0]?.packed ? "✔" : ""},`,
     `${count.length},`,
     `${name},`,
     `${count[0]?.internalGearId || ""},`,
     `\n`,
    ].join(""),
   ]);
  });

  c = [...c, [",", ",", "•• CABLES ••,", `\n`].join("")];

  Object.entries(cablesCollected).map(([name, count]) => {
   return (c = [
    ...c,
    [
     `${count[0]?.packed ? "✔" : ""},`,
     `${count.length},`,
     `${name},`,
     `${count[0]?.label || ""},`,
     `\n`,
    ].join(""),
   ]);
  });

  c = [...c, [",", ",", "•• EXTRAS ••,", `\n`].join("")];

  plan?.extras?.map((extra) => {
   return (c = [
    ...c,
    [`${extra?.packed ? "✔" : ""},`, , `1,`, `${extra.text},`, `\n`].join(""),
   ]);
  });

  setCsv([csv[0], c.join("")]);
 }, [itemsCollected, cablesCollected, plan.extras]);

 if (!shown) return null;
 return (
  <div
   style={{
    height: "100svh",
   }}
   className="fixed flex justify-end items-center top-0 left-0 w-screen z-20 select-text"
  >
   <div
    style={{
     height: "100svh",
    }}
    className="flex flex-col justify-between w-11/12 md:w-1/2 bg-white dark:bg-gray-800 rounded-lg shadow z-40 overflow-auto"
   >
    <div>
     <div className="py-6 border-b-2 border-solid border-gray-200 dark:border-gray-900 sticky top-0 bg-white dark:bg-gray-800">
      <div className="px-10 flex justify-between items-center">
       <div>
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100">
         Packlist
        </h2>
        <h3 className="pt-2 font-light text-gray-500">{plan.name}</h3>
       </div>
       <div>
        <PackProgress progress={plan.pack_progress} />
        <div className="pt-2 text-gray-500 text-xs font-light uppercase text-right">
         % Packed
        </div>
       </div>
      </div>
     </div>

     <div className="px-10 py-10">
      <div className=" text-gray-500 flex justify-end text-sm gap-4">
       {/* <span>CSV</span> */}
       <CSVLink
        filename={`Packlist - ${plan.name}.csv`}
        className="underline hover:text-gray-400"
        data={csv.join("")}
        // separator={","}
       >
        Download as CSV
       </CSVLink>

       <button
        className="underline hover:text-gray-400"
        onClick={handleCopyAsCsv}
       >
        {copiedCsv ? 'Copied to clipboard!' : 'Copy as CSV'}
       </button>
      </div>
      <div className=" text-gray-500 flex justify-end text-sm">
       {/* <span>CSV</span> */}
       
      </div>
      {/* <textarea
       className="mt-2 border border-gra-200 px-2 py-1 rounded text-gray-600 text-sm w-full focus:outline-none focus:ring select-all"
       value={csv.join("")}
       readOnly={true}
      /> */}

      {/* LOADING SPINNER WHILE THE PACKLIST LOADS */}
      <div className="mt-6">
       {plan.items.length === 0 ? (
        <div className="text-sm italic text-gray-500">
         No gear added... yet.
        </div>
       ) : (
        <div className="uppercase text-gray-500">Gear</div>
       )}
       {/* {plan.items.map((item) => {
        return (
         <button
          key={item.id}
          className={`mt-2 py-2 w-full flex justify-start items-center hover:text-gray-600 focus:outline-none focus:text-gray-600 ${
           !item.packed ? "text-gray-700" : "text-gray-400"
          }`}
          onClick={() =>
           planActions.editItem(plan.id, item.id, {
            packed: item.packed ? !item.packed : true,
           })
          }
         >
          <div>
           <svg
            className="w-6 h-6"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d={
              !item.packed
               ? "M12 22a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16.001A8 8 0 0012 20z"
               : "M12 22a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16.001A8 8 0 0012 20zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 011.4 1.42l-4 4a1 1 0 01-1.4 0l-2-2a1 1 0 011.4-1.42v.01z"
             }
            />
           </svg>
          </div>
          <div className={`ml-4 ${item.packed && "italic line-through"}`}>
           {item.label ? item.label : item.name}
          </div>
         </button>
        );
       })} */}
       {plan && plan.length === 0
        ? null
        : Object.entries(itemsCollected).map(([type, items]) => {
           //  if (cable.amount > 0) {
           return (
            <button
             key={type}
             className={`mt-2 py-2 w-full flex justify-start items-center hover:text-gray-600 focus:outline-none focus:text-gray-600 ${
              items.filter((cable) => cable.packed).length ===
              itemsCollected[type].length
               ? "text-gray-400 dark:text-gray-700"
               : "text-gray-700 dark:text-gray-400"
             }`}
             onClick={() => {
              items.map((c) => {
               if (
                items.filter((cable) => cable.packed).length ===
                itemsCollected[type].length
               ) {
                // if all are unpacked/packed, toggle
                return planActions.packUnpackItem(plan.id, c.id);
               } else {
                // if some are packed but not all, force packing of all
                return planActions.packUnpackItem(plan.id, c.id, true);
               }
              });
             }}
            >
             <div>
              <svg
               className="w-6 h-6"
               fill="currentColor"
               xmlns="http://www.w3.org/2000/svg"
              >
               <path
                d={
                 items.filter((cable) => cable.packed).length ===
                 itemsCollected[type].length
                  ? "M12 22a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16.001A8 8 0 0012 20zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 011.4 1.42l-4 4a1 1 0 01-1.4 0l-2-2a1 1 0 011.4-1.42v.01z"
                  : "M12 22a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16.001A8 8 0 0012 20z"
                }
               />
              </svg>
             </div>
             <div
              className={`ml-4 ${
               items.filter((cable) => cable.packed).length ===
                itemsCollected[type].length && "italic line-through"
              }`}
             >
              <span className="font-semibold">{items.length}x</span>{" "}
              <span className="uppercase">{type}</span>
             </div>
            </button>
           );
           //  } else return null;
          })}
      </div>
      <div className="mt-6">
       {plan.cables && plan.cables.length > 0 && (
        <div className="mt-10 uppercase text-gray-500">Cables</div>
       )}

       {plan && plan.length === 0
        ? null
        : Object.entries(cablesCollected).map(([type, cables]) => {
           //  if (cable.amount > 0) {
           return (
            <button
             key={type}
             className={`mt-2 py-2 w-full flex justify-start items-center hover:text-gray-600 focus:outline-none focus:text-gray-600 ${
              cables.filter((cable) => cable.packed).length ===
              cablesCollected[type].length
               ? "text-gray-400 dark:text-gray-700"
               : "text-gray-700 dark:text-gray-400"
             }`}
             onClick={() => {
              cables.map((c) => {
               if (
                cables.filter((cable) => cable.packed).length ===
                cablesCollected[type].length
               ) {
                // if all are unpacked/packed, toggle
                return planActions.packUnpackCable(plan.id, c.id);
               } else {
                // if some are packed but not all, force packing of all
                return planActions.packUnpackCable(plan.id, c.id, true);
               }
              });
             }}
            >
             <div>
              <svg
               className="w-6 h-6"
               fill="currentColor"
               xmlns="http://www.w3.org/2000/svg"
              >
               <path
                d={
                 cables.filter((cable) => cable.packed).length ===
                 cablesCollected[type].length
                  ? "M12 22a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16.001A8 8 0 0012 20zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 011.4 1.42l-4 4a1 1 0 01-1.4 0l-2-2a1 1 0 011.4-1.42v.01z"
                  : "M12 22a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16.001A8 8 0 0012 20z"
                }
               />
              </svg>
             </div>
             <div
              className={`ml-4 ${
               cables.filter((cable) => cable.packed).length ===
                cablesCollected[type].length && "italic line-through"
              }`}
             >
              <span className="font-semibold">{cables.length}x</span>{" "}
              <span className="uppercase">{type}</span>
             </div>
            </button>
           );
           //  } else return null;
          })}
      </div>
      {/* EXTRAS */}
      <div className="mt-6">
       {plan.extras && plan.extras.length > 0 && (
        <div className="mt-10 uppercase text-gray-500">Extras</div>
       )}

       {plan.extras && plan.extras.length === 0
        ? null
        : plan.extras &&
          plan.extras.map((extra) => {
           return (
            <PacklistItem
             item={extra}
             key={extra.id}
             toggleCheckbox={(item) =>
              planActions.packUnpackExtra(plan.id, item)
             }
             onRemove={(id) => {
              planActions.removeExtra(plan.id, id);
             }}
            />
           );
          })}
      </div>
      <div className="mt-6 pt-6 flex space-x-4 border-t border-solid  border-gray-200 dark:border-gray-900 ">
       <Button
        text="Pack all"
        size="small"
        colour="white"
        disabled={plan.items.length === 0}
        onClick={() => {
         planActions.packUnpackAll(plan.id, true);
        }}
       />
       <Button
        text="Unpack all"
        size="small"
        colour="white"
        disabled={plan.items.length === 0}
        onClick={() => {
         planActions.packUnpackAll(plan.id, false);
        }}
       />
      </div>
     </div>
    </div>
    {/* EXTRAS INPUT */}
    <div className="px-10 pb-4 border-t-2 border-solid border-gray-200 dark:border-gray-900 sticky bottom-0 bg-white  dark:bg-gray-800">
     <Input
      autoFocus={true}
      name="addToPacklist"
      value={extraText}
      placeholder="Add extras... tape, spare cables, etc"
      onChange={(e) => {
       setExtraText(e.target.value);
      }}
      enterPressed={() => {
       addExtra();
      }}
     />
    </div>
   </div>
   <div
    className="fixed w-screen h-screen bg-gray-900 opacity-50 z-30"
    onClick={onClose}
   />
  </div>
 );
}
