import { store } from "../db/store";

export const updateFilterText = (text) => {
 return store.dispatch({
  type: "TEXT_FILTER",
  text: text,
 });
};

export const updateFilterTab = (tab) => {
 return store.dispatch({
  type: "TAB_FILTER",
  tab,
 });
};

export const updateFilterNewPlan = (update) => {
 return store.dispatch({
  type: "NEW_PLAN_UPDATE",
  update,
 });
};

export const clearFilterNewPlan = () => {
 return store.dispatch({
  type: "NEW_PLAN_CLEAR",
 });
};

export const updateGearFilterText = (text) => {
 return store.dispatch({
  type: "GEAR_TEXT_FILTER",
  text: text,
 });
};

export const updateGearFilterTab = (tab) => {
 return store.dispatch({
  type: "GEAR_TAB_FILTER",
  tab: tab,
 });
};
