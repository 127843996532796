import React, { useState } from "react";
import { useFirebase } from "react-redux-firebase";

import Logo from "./branding/Logo";
import Input from "./Input";
import Button from "./Button";

function ResetPage() {
 const [email, setEmail] = useState("");
 const [resetting, setResetting] = useState(false);
 const [error, setError] = useState("");

 const firebase = useFirebase();

 const handleReset = (e) => {
  e.preventDefault();

  setResetting(true);

  if (!email) return setResetting(false);
  return firebase
   .resetPassword(email)
   .then((res) => {
    setError("We've sent you a reset email.");

    setResetting(false);
   })
   .catch((err) => {
    console.log(err);
    if (err.code === "auth/user-not-found") {
     setError("User not found");
    } else {
     setError("Something went wrong. Try again.");
    }

    setResetting(false);
   });
 };

 return (
  <div className="flex flex-col justify-center bg-red-500 h-screen w-screen">
   <div className="px-12 py-10 flex flex-col w-11/12 md:w-1/2 max-w-md bg-white dark:bg-gray-900 mx-auto rounded-lg">
    <form className="flex flex-col mb-5" onSubmit={(e) => handleReset(e)}>
     <Logo />

     <span className="mt-8 mb-10 h-1 border bg-gray-200 dark:bg-gray-700"></span>

     <h2 className="text-xl mb-6 text-gray-900 dark:text-gray-100">
      Reset your password
     </h2>

     <div className="mb-8">
      <Input
       name="email"
       label="Email"
       type="email"
       value={email}
       onChange={(e) => setEmail(e.target.value)}
       placeholder="me@address.com"
       required={true}
       autoFocus={true}
      />
     </div>

     <Button
      text={!resetting ? "Reset" : "Resetting..."}
      onSubmit={(e) => e.preventDefault()}
      type="submit"
      disabled={resetting}
     />

     {error !== "" ? (
      <span className="italic mt-4 mx-auto text-gray-600">{error}</span>
     ) : null}
    </form>
   </div>
  </div>
 );
}

export default ResetPage;
