import React, { createRef, Fragment, useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import Draggable from "react-draggable";
import "../styles/draggable.css";
import { Menu, Item, Separator, useContextMenu } from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import useGetUserDisplayName from "./hooks/useGetUserDisplayName";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function Portal({ children }) {
 return createPortal(children, document.querySelector("#gear-item-menu"));
}

const getContrastYIQ = (hexcolor = "#FFF") => {
 // If a leading # is provided, remove it
 if (hexcolor.slice(0, 1) === "#") {
  hexcolor = hexcolor.slice(1);
 }

 var r = parseInt(hexcolor.substr(0, 2), 16);
 var g = parseInt(hexcolor.substr(2, 2), 16);
 var b = parseInt(hexcolor.substr(4, 2), 16);
 var yiq = (r * 299 + g * 587 + b * 114) / 1000;
 return yiq >= 128 ? "text-gray-900" : "text-white";
};

const returnString = (component) => {
 console.log("component", component.props);
 return component.props.cableName;
};

export default function GearItem({
 gearItem = {},
 x = 0,
 y = 0,
 onDrag,
 onStop,
 onStart,
 handleCableConnectionStart,
 handleCableConnectionEnd,
 removeGearItem,
 duplicateGearItem,
 replaceGearItem,
 deleteGearItem,
 addGearToLibrary,
 removeCableConnection,
 disabled = false,
 demoMode = true,
 opacity = "1",
 scale = 1,
 onMouseEnter,
 onMouseLeave,
 cables = [],
 selected = false,
 addRemoveSelected,
 drawing = false,
 ltr = false,
}) {
 const params = useParams();
 const gridSize = useSelector((state) => state.plans[params.id].gridSize || 1);

 return (
  <Draggable
   onDrag={onDrag}
   onStop={onStop}
   onStart={onStart}
   handle=".handle"
   position={{ x: x, y: y }}
   disabled={disabled}
   scale={scale}
   grid={[gridSize, gridSize]}
   //  onMouseDown={() => {
   //   addRemoveSelected(gearItem.id);
   //  }}
  >
   <div className="w-fit">
    <GearItemBase
     gearItem={gearItem}
     handleCableConnectionStart={handleCableConnectionStart}
     handleCableConnectionEnd={handleCableConnectionEnd}
     removeGearItem={removeGearItem}
     duplicateGearItem={duplicateGearItem}
     replaceGearItem={replaceGearItem}
     deleteGearItem={deleteGearItem}
     addGearToLibrary={addGearToLibrary}
     removeCableConnection={removeCableConnection}
     demoMode={demoMode}
     opacity={opacity}
     scale={scale}
     onMouseEnter={onMouseEnter}
     onMouseLeave={onMouseLeave}
     cables={cables}
     selected={selected}
     addRemoveSelected={addRemoveSelected}
     drawing={drawing}
     ltr={ltr}
    />
   </div>
  </Draggable>
 );
}

export const GearItemNoDrag = function ({ gearItem = {}, demoMode = true }) {
 return (
  <Fragment>
   <GearItemBase gearItem={gearItem} demoMode={demoMode} />
  </Fragment>
 );
};

const GearItemBase = ({
 gearItem,
 handleCableConnectionStart = null,
 handleCableConnectionEnd = null,
 removeGearItem = null,
 duplicateGearItem = null,
 addGearToLibrary = null,
 replaceGearItem = null,
 deleteGearItem = null,
 removeCableConnection = null,
 demoMode = true,
 opacity = "1",
 addRemoveSelected,
 drawing,
 onMouseEnter = () => {
  return null;
 },
 onMouseLeave = () => {
  return null;
 },
 cables = [],
 selected = false,
 ltr,
}) => {
 const { show } = useContextMenu({
  id: gearItem.id,
 });
 const imgRef = createRef();

 function handleItemClick({ event }) {
  switch (event.currentTarget.id) {
   case "edit":
    removeGearItem(gearItem.id);
    break;
   case "duplicate":
    duplicateGearItem(gearItem.id);
    break;
   case "replace":
    replaceGearItem(gearItem);
    break;
   case "add_gear":
    addGearToLibrary(gearItem);
    break;
   case "delete":
    deleteGearItem(gearItem.id);
    break;

   default:
    break;
  }
 }

 function displayMenu(e) {
  if (demoMode) return null;
  show(e, {
   //  position: {
   //   x: gearItem.width / 2,
   //   y: gearItem.height / 2,
   //  },
  });
 }

 useEffect(() => {
  if (imgRef.current) {
   var ctx = imgRef.current.getContext("2d");
   var img = new Image();
   img.onload = function () {
    ctx.drawImage(img, 0, 0, img.width / 10, img.height / 10);
   };
   img.src = gearItem.img;
  }
 }, [imgRef]);

 return (
  <div
   className={`gearItem w-fit ${ltr ? "text-left" : "text-center"} ${
    selected
     ? "ring-2 ring-offset-4 ring-blue-500 ring-offset-white dark:ring-offset-gray-900"
     : "shadow-md"
   }
   `}
   style={{
    height: ltr
     ? `${
        gearItem?.inputs?.length > gearItem?.outputs?.length
         ? gearItem?.inputs?.length * 24
         : gearItem?.outputs?.length * 24
       }px`
     : "auto",
    width: !ltr ? `${gearItem.width}px` : "auto",
    // width: `${gearItem.width}px`,
    gridTemplateColumns: ltr
     ? `${gearItem.inputs.length >= 1 ? 40 : 0}px ${
        gearItem.img ? "150" : "80"
       }px ${gearItem.outputs.length >= 1 ? 40 : 0}px`
     : "1fr",
    gridTemplateRows: ltr
     ? "1fr"
     : `${gearItem.inputs.length >= 1 ? 22 : 0}px ${
        gearItem.img ? "150" : "50"
       }px ${gearItem.outputs.length >= 1 ? 22 : 0}px`,
    opacity,
    transition: "500ms opacity ease-in-out",
   }}
  >
   <div
    className="inputs"
    style={{
     minHeight: ltr ? "20px" : "100%",
     gridTemplateRows: ltr ? "" : "",
     gridTemplateColumns: ltr
      ? "1fr"
      : `repeat(${gearItem.inputs.length}, minmax(0, 1fr))`,
    }}
   >
    <Portal>
     <Menu id={gearItem.id} style={{ zIndex: "1000" }}>
      <Item id="edit" onClick={handleItemClick}>
       Edit
      </Item>
      <Item id="duplicate" onClick={handleItemClick}>
       Duplicate
      </Item>
      <Item id="replace" onClick={handleItemClick}>
       Replace
      </Item>
      <Separator />
      <Item id="add_gear" onClick={handleItemClick}>
       Add to My Gear
      </Item>
      <Separator />
      <Item id="delete" onClick={handleItemClick}>
       Delete
      </Item>
     </Menu>
    </Portal>

    {gearItem.inputs.map((input, index) => {
     return (
      <div
       data-type="input"
       style={{
        backgroundColor: input.backgroundColour ? input.backgroundColour : '#f4f4f4'
       }}
       key={`${input.name}-${index}-`}
       onMouseDown={(e) => {
        e.preventDefault();
        if (demoMode) return null;
        if (drawing) return null;
        handleCableConnectionStart(
         gearItem.id,
         index + 1,
         input.type,
         e,
         true,
         "input"
        );
       }}
       onMouseUp={(e) => {
        e.preventDefault();
        if (demoMode) return null;
        handleCableConnectionEnd(
         gearItem.id,
         index + 1,
         input.type,
         e,
         false,
         "input"
        );
       }}
       onDoubleClick={(e) => {
        if (demoMode) return null;
        removeCableConnection(gearItem.id, index + 1);
       }}
       onMouseEnter={() => {
        onMouseEnter(gearItem.id, index + 1, "input");
       }}
       onMouseLeave={() => {
        onMouseLeave(gearItem.id, index + 1);
       }}
       className={`input2  input2${ltr ? "-ltr" : "-rtl"}
       
       ${
        cables.filter(
         (c) => c.toItem === gearItem.id && c.toPosition === index + 1
        ).length >= 1
         ? "opacity-95"
         : "opacity-100"
       }

       ${
        gearItem.inputs.length === 1
         ? ltr
           ? "radius-left-both"
           : "radius-top-both"
         : ""
       }`}
       data-tooltip={`${input.type}\n(${input.name})`}
      >
       {input.name}
      </div>
     );
    })}
   </div>

   <div
    id={gearItem.id}
    onDoubleClick={() => {
     if (demoMode) return null;
     removeGearItem(gearItem.id);
    }}
    onContextMenu={displayMenu}
    onMouseDown={() => {
     if (demoMode) return null;
     addRemoveSelected(gearItem.id);
    }}
    className={`gearItem_drag ${!demoMode && "handle noPan"} ${
     ltr && gearItem.cssClassName ? gearItem.cssClassName : null
    }
    ${ltr ? "p-0.5" : ""}
    ${getContrastYIQ(gearItem.colour)}
    `}
    style={{
     backgroundColor: `${gearItem.colour}`,
     borderLeft: `3px ${gearItem.secondaryColour} solid`,
     borderRight: `3px ${gearItem.secondaryColour} solid`,
    }}
   >
    {gearItem.img && (
     <>
      {/* <canvas
       ref={imgRef}
       className="pointer-events-none"
       //  width="80"
       //  height="100"
       style={
        {
         // height: "100px",
         //  backgroundImage: `url(${gearItem.img})`,
        }
       }
      ></canvas> */}
      <div
       style={{
        height: "100px",
        backgroundImage: `url(${gearItem.img})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
       }}
       className="pointer-events-none"
      ></div>
     </>
    )}
    <div
     id={gearItem.id}
     className={`gearItem_drag_name text-center
     `}
     style={{
      fontSize: `${gearItem.width < 151 ? "0.55rem" : "0.80rem"}`,
     }}
    >
     {gearItem.label ? gearItem.label : gearItem.name}
    </div>
    <div
     id={gearItem.id}
     className="gearItem_drag_manufacturer text-center opacity-75"
     style={{ fontSize: `7px` }}
    >
     {gearItem.manufacturer === "" || gearItem.manufacturer === "Generic"
      ? ""
      : gearItem.manufacturer}
    </div>
    <span className={`${!ltr && gearItem.cssClassName}-after`}></span>
   </div>

   <div
    className="outputs"
    style={{
     minHeight: ltr ? "20px" : "100%",
     gridTemplateRows: ltr
      ? `repeat(${gearItem.outputs.length}, minmax(0, 1fr))`
      : "1fr",
     gridTemplateColumns: ltr
      ? "1fr"
      : `repeat(${gearItem.outputs.length}, minmax(0, 1fr))`,
    }}
   >
    {gearItem.outputs.map((output, index) => {
     return (
      <div
       key={`${output.name}-${index}`}
       id={gearItem.id}
       style={{
        backgroundColor: output.backgroundColour ? output.backgroundColour : '#f4f4f4'
       }}
       onMouseDown={(e) => {
        e.preventDefault();
        if (demoMode) return null;
        handleCableConnectionStart(
         gearItem.id,
         index + 1,
         output.type,
         e,
         false,
         "output"
        );
       }}
       onMouseUp={(e) => {
        e.preventDefault();
        if (demoMode) return null;
        handleCableConnectionEnd(
         gearItem.id,
         index + 1,
         output.type,
         e,
         true,
         "output"
        );
       }}
       onMouseEnter={() => {
        onMouseEnter(gearItem.id, index + 1, "output");
       }}
       onMouseLeave={() => {
        onMouseLeave(gearItem.id, index + 1);
       }}
       className={`output output${ltr ? "-ltr" : "-rtl"}

       ${
        cables.filter(
         (c) => c.fromItem === gearItem.id && c.fromPosition === index + 1
        ).length >= 1 && "opacity-95"
       }
       
       ${
        gearItem.outputs.length === 1
         ? ltr
           ? "radius-right-both"
           : "radius-bottom-both"
         : null
       }`}
       data-tooltip={`${output.type}\n(${output.name})`}
      >
       {output.name}
      </div>
     );
    })}
   </div>
  </div>
 );
};

const ConnectionName = ({ cableName }) => {
 const name = useGetUserDisplayName(cableName) || cableName;

 console.log("running", cableName);

 console.log("name", name);
 return name;
};
