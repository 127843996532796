import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import PackProgress from "./PackProgress";
import Button from "./Button";
import Packlist from "./Packlist";
import { AddPlanModal, ArchivePlanModal } from "./Modal";
import * as planActions from "../actions/planActions";
import * as filterActions from "../actions/filterActions";
import * as notificationActions from "../actions/notificationsActions";
import { getAndUpdateLimits } from "../db/firestore";
import useIsMemberOfTeam from "./hooks/useIsMemberOfTeam";

export default function PlanCard({ plan, empty = false }) {
 const [showPacklist, setShowPacklist] = useState(false);
 const [showDeleteModal, setShowArchiveModal] = useState(false);
 const [showEditPlanModal, setShowEditPlanModal] = useState(false);
 const [showCopyPlanModal, setShowCopyPlanModal] = useState(false);

 const history = useHistory();

 const auth = useSelector((state) => state.firebase.auth);
 const user = useSelector((state) => state.firebase.profile);
 const limits = useSelector((state) => state.settings.limits);
 const { isTeamMember } = useIsMemberOfTeam();
 const partOfTeam = useSelector((state) => state.firebase.profile.partOfTeam);

 if (empty) {
  return (
   <div className="w-full h-24 p-10 py-3 flex justify-between items-center bg-white dark:bg-gray-900 rounded-lg shadow hover:shadow-md transition duration-200 ease-in-out animate-pulse">
    <div className="flex flex-col items-start">
     <h3 className="text-xl font-semibold text-transparent rounded-full">
      Loading plans, give us a sec...
     </h3>
     <div className="mt-2 text-transparent text-sm rounded-full">
      Loading plans...
     </div>
    </div>
   </div>
  );
 } else {
  return (
   <div className=" w-full pl-4 pr-10  py-3 flex justify-between items-center bg-white dark:bg-gray-900 rounded-lg shadow hover:shadow-md transition duration-200 ease-in-out">
    {/* PLAN DETAILED AMOUNT */}
    <span className="hidden sm:flex w-14 h-14 text-gray-400 text-xs bg-gray-100 dark:bg-gray-800 rounded-lg  flex-col space-y-1 justify-center items-center">
     <span className="flex space-x-1">
      <svg
       width="16"
       height="16"
       viewBox="0 0 16 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path
        d="M6 11.5V12.1713C6.00003 12.4341 5.9483 12.6942 5.84775 12.937C5.7472 13.1797 5.59981 13.4002 5.414 13.586L5 14H11L10.586 13.586C10.4002 13.4002 10.2528 13.1797 10.1523 12.937C10.0517 12.6942 9.99997 12.4341 10 12.1713V11.5M14 3.5V10C14 10.3978 13.842 10.7794 13.5607 11.0607C13.2794 11.342 12.8978 11.5 12.5 11.5H3.5C3.10218 11.5 2.72064 11.342 2.43934 11.0607C2.15804 10.7794 2 10.3978 2 10V3.5M14 3.5C14 3.10218 13.842 2.72064 13.5607 2.43934C13.2794 2.15804 12.8978 2 12.5 2H3.5C3.10218 2 2.72064 2.15804 2.43934 2.43934C2.15804 2.72064 2 3.10218 2 3.5M14 3.5V8C14 8.39782 13.842 8.77936 13.5607 9.06066C13.2794 9.34196 12.8978 9.5 12.5 9.5H3.5C3.10218 9.5 2.72064 9.34196 2.43934 9.06066C2.15804 8.77936 2 8.39782 2 8V3.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
       />
      </svg>

      <span>{plan?.items?.length || 0}</span>
     </span>
     <span className="flex space-x-1">
      <svg
       width="16"
       height="16"
       viewBox="0 0 16 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path
        d="M4.49548 2L4.49548 6.5L9.57656 6.5C9.57689 8 9.57956 9.57605 9.57689 11.3434L9.57656 13.3434M9.57656 13.3434L12.5765 10.3437M9.57656 13.3434L6.57679 10.3435"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
       />
      </svg>

      <span>{plan?.cables?.length || 0}</span>
     </span>
    </span>
    <div className="pl-0 sm:pl-6 flex flex-col items-start">
     <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
      {plan.name}
     </h3>
     <div className="mt-1 text-gray-400 text-sm">
      {plan.internalJobId && (
       <span className="px-1 py-0.5 mr-1 uppercase font-semibold text-xs text-gray-500 dark:text-gray-500 rounded bg-gray-200 dark:bg-gray-800/70">
        Job ID: {plan.internalJobId}
       </span>
      )}
      {plan?._sharing?.includes("team") && (
       <span className="px-1 py-0.5 mr-1 uppercase font-semibold text-xs text-red-500 dark:text-red-600 rounded bg-red-200 dark:bg-red-900/40">
        team plan
       </span>
      )}
      {plan.event_start_date
       ? plan.event_start_date?.toMillis() === plan.event_end_date?.toMillis()
         ? moment(plan.event_start_date?.toDate()).format("Do MMM, YYYY")
         : moment(plan.event_start_date?.toDate()).format("Do")
       : null}
      {!plan.event_end_date
       ? null
       : plan.event_end_date?.toMillis() === plan.event_start_date?.toMillis()
       ? null
       : ` - ${moment(plan.event_end_date?.toDate()).format("Do MMM, YYYY")}`}
     </div>
    </div>
    <span className="flex-grow"></span>
    {plan.status !== "ARCHIVED" ? (
     <div className="flex items-center">
      <div className="">
       <Button
        text="Edit plan"
        colour="white"
        onClick={() => {
         history.push(`/plan/${plan.id}`);
        }}
        dropdown={true}
        dropdownOptions={[
         {
          title: "Edit details",
          to: "/that",
          icon: "edit",
          func: function () {
           console.log("plan.event_start_date", typeof plan.event_start_date);
           filterActions.updateFilterNewPlan({
            name: plan.name,
            internalJobId: plan.internalJobId,
            dateDependant: plan.event_start_date !== null ? true : false,
            riggingDateStart: plan.rigging_start_date
             ? moment(plan.rigging_start_date.seconds, "X").format("YYYY-MM-DD")
             : "",
            riggingDateEnd: plan.rigging_end_date
             ? moment(plan.rigging_end_date.seconds, "X").format("YYYY-MM-DD")
             : "",
            eventDateStart: plan.event_start_date
             ? moment(plan.event_start_date, "X").format("YYYY-MM-DD")
             : "",
            eventDateEnd: plan.event_end_date
             ? moment(plan.event_end_date, "X").format("YYYY-MM-DD")
             : "",
           });
           return setShowEditPlanModal(true);
          },
         },
         {
          title: "Create copy",
          to: "/that",
          icon: "copy",
          disabled: 
            isTeamMember && partOfTeam
             ? false
             : limits.total >= limits.limit && true
           ,
          func: function () {
           filterActions.updateFilterNewPlan({
            ...plan,
            name: `Copy of ${plan.name}`,
            dateDependant: plan.event_start_date !== null ? true : false,
            riggingDateStart: plan.rigging_start_date
             ? moment(plan.rigging_start_date.seconds, "X").format("YYYY-MM-DD")
             : "",
            riggingDateEnd: plan.rigging_end_date
             ? moment(plan.rigging_end_date.seconds, "X").format("YYYY-MM-DD")
             : "",
            eventDateStart: plan.event_start_date
             ? moment(plan.event_start_date, "X").format("YYYY-MM-DD")
             : "",
            eventDateEnd: plan.event_end_date
             ? moment(plan.event_end_date, "X").format("YYYY-MM-DD")
             : "",
           });
           return setShowCopyPlanModal(true);
          },
         },
         {
          title: "Archive plan",
          to: "/that",
          icon: "archive",
          func: async function () {
           planActions.archivePlan(plan.id, plan._sharing, auth.uid, limits.limit);
          },
         },
         user.partOfTeam &&
          plan._sharing !== "team" && {
           title: "Share with team",
           disabled: !user.partOfTeam || plan?._sharing?.includes("team"),
           to: "/that",
           icon: "share",
           func: function () {
            planActions.updatePlan(plan.id, {
             _sharing: "team",
             original_author_uid: plan.author_uid,
             author_uid: user.partOfTeam,
            });
            return notificationActions.addNotification(
             "Plan shared with team",
             "We've moved this plan into your team section",
             "success",
             10000
            );
           },
          },
         user.partOfTeam &&
          plan?._sharing?.includes("team") &&
          plan.original_author_uid && {
           title: "Return to owner",
           disabled: !user.partOfTeam,
           to: "/that",
           icon: "share",
           func: function () {
            planActions.updatePlan(plan.id, {
             _sharing: "onlyme",
             author_uid: plan.original_author_uid || plan.author_uid,
            });
            return notificationActions.addNotification(
             "Plan returned to original owner",
             "We've moved this plan out of your team section",
             "success",
             10000
            );
           },
          },
        ]}
       />
      </div>
      <button
       onClick={() => setShowPacklist(true)}
       className="ml-10 flex justify-center  focus:outline-none focus:ring"
      >
       <PackProgress progress={plan.pack_progress} />
      </button>
     </div>
    ) : (
     <Button
      text="Unarchive"
      disabled={limits.limitHit}
      colour="white"
      onClick={async () => {
       planActions.updatePlan(plan.id, { status: "ACTIVE" });
       return setTimeout(()=>{
        return getAndUpdateLimits(auth.uid, limits.limit);
       }, [1000])
      }}
     />
    )}
    <Packlist
     shown={showPacklist}
     plan={plan}
     onClose={() => setShowPacklist(false)}
    />
    {showDeleteModal && (
     <ArchivePlanModal
      onCancel={() => setShowArchiveModal(false)}
      onArchive={() => {
       planActions.archivePlan(plan.id);

       getAndUpdateLimits(auth.uid, user.plansLimit);

       return setShowArchiveModal(false);
      }}
      planId={plan.id}
     />
    )}
    {showEditPlanModal && (
     <AddPlanModal
      edit={true}
      plan={plan}
      onCancel={() => {
       filterActions.clearFilterNewPlan();
       return setShowEditPlanModal(false);
      }}
     />
    )}
    {showCopyPlanModal && (
     <AddPlanModal
      copy={true}
      plan={plan}
      onCancel={() => {
       filterActions.clearFilterNewPlan();
       return setShowCopyPlanModal(false);
      }}
     />
    )}
   </div>
  );
 }
}
