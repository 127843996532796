import { getAndUpdateLimits } from "../db/firestore";
import { store } from "../db/store";

/**
 * addPlan - Add a plan to the Redux state
 * @param {object} plan - The doc.data() returned from Firestore
 */
export const addPlan = (plan) => {
 return store.dispatch({
  type: "ADD_PLAN",
  plan,
 });
};

/**
 * addPlans - Add a groupd of plans to the Redux state
 * @param {object} plans - The doc.data() returned from Firestore
 */
export const addPlans = (plans = {}) => {
 return store.dispatch({
  type: "ADD_PLANS",
  plans,
 });
};

/**
 * archivePlan - Archive a plan
 * @param {string} id - ID of the plan to archive
 */
export const archivePlan = async (id, currentShareState, uid, limit) => {
 store.dispatch({
  type: "ARCHIVE_PLAN",
  id,
  currentShareState,
 });

 await savePlan(id);

 return setTimeout(()=>{
  return getAndUpdateLimits(uid, limit)
 }, [1000])
};

/**
 * updatePlan - Update a plan
 * @param {string} id - ID of the plan to update
 * @param {object} update - Update object
 */
export const updatePlan = (id, update) => {
 store.dispatch({
  type: "UPDATE_PLAN",
  id,
  update,
 });

 savePlan(id);
};

/**
 * savePlan - Saves a plan to firestore
 * @param {object} planId - The plan to save
 * @param {boolean} notify - Should the user be notified that the save happened
 */
export const savePlan = async (planId, notify = false) => {
 return await store.dispatch({
  type: "SAVE_PLAN",
  planId,
  notify,
 });
};

/**
 * addItem - Add a gear item to a given plan
 * @param {string} id - ID of the plan to add the item to
 * @param {string} item - Item to add
 */
export const addItem = (id, item) => {
 store.dispatch({
  type: "ADD_ITEM",
  id,
  item,
 });

 calculatePackedPercent(id);
 savePlan(id);
};

/**
 * removeItem - Remove a gear item from a given plan
 * @param {string} id - ID of the plan to remove the item from
 * @param {string} item - ID of the item to remove
 */
export const removeItem = (id, item) => {
 store.dispatch({
  type: "REMOVE_ITEM",
  id,
  item,
 });

 calculatePackedPercent(id);
 savePlan(id);
};

/**
 * duplicateItem - Create a duplicate of the given item
 * @param {string} id - ID of the plan to duplicate the item into
 * @param {object} item - Item to duplicate
 */
export const duplicateItem = (id, item) => {
 store.dispatch({
  type: "DUPLICATE_ITEM",
  id,
  item,
 });

 calculatePackedPercent(id);
 savePlan(id);
};

/**
 * editItem - Edits a gear item
 * @param {string} id - ID of the plan in which to edit the item
 * @param {string} itemId - ID of the item to edit
 * @param {Object} change - Object containing the change
 */
export const editItem = (id, itemId, change) => {
 store.dispatch({
  type: "EDIT_ITEM",
  id,
  itemId,
  change,
 });

 calculatePackedPercent(id);
 savePlan(id);
};

/**
 * moveItem - Move a gear item around
 * @param {string} id - ID of the plan to move the item within
 * @param {string} item - ID of the item to move
 * @param {object} location - X and Y location of where the item is dropped
 */
export const moveItem = (id, item, location) => {
 return store.dispatch({
  type: "MOVE_ITEM",
  id,
  item,
  location,
 });
};

/**
 * moveItems - Move multiple gear items around
 * @param {string} id - ID of the plan to move the item within
 * @param {string} item - ID of the item to move
 * @param {object} delta - X and Y delta delta of where the item is dropped
 */
export const moveItems = (id, item, delta) => {
 return store.dispatch({
  type: "MOVE_ITEMS",
  id,
  item,
  delta,
 });
};

/**
 * addCable - Add a cable to connect two items
 * @param  {string} id - ID of the plan in which to add a cable
 * @param  {object} cable - Cable object to add
 */
export const addCable = (id, cable) => {
 console.log("added cable");
 store.dispatch({
  type: "ADD_CABLE",
  id,
  cable,
 });

 calculatePackedPercent(id);
 savePlan(id);
};

/**
 * updateCable - Update a cable
 * @param  {string} id - ID of the plan in which to add a cable
 * @param  {string} cableId - ID of the cable to update
 * @param  {object} update - Cable object to update
 * @param  {boolean} save - Should the plan be saved on every update (default: true)
 */
export const updateCable = (id, cableId, update, save = true) => {
 store.dispatch({
  type: "UPDATE_CABLE",
  id,
  cableId,
  update,
 });

 // calculatePackedPercent(id);
 if (save) savePlan(id);
};
/**
 * addCablePin - Adds a new pin to a cable
 * @param  {string} id - ID of the plan in which to add a cable
 * @param  {string} cableId - ID of the cable to update
 * @param  {object} update - Cable object to update
 * @param  {boolean} save - Should the plan be saved on every update (default: true)
 */
export const addCablePin = (id, cableId, pin, save = true) => {
 store.dispatch({
  type: "ADD_CABLE_PIN",
  id,
  cableId,
  pin,
 });

 // calculatePackedPercent(id);
 if (save) savePlan(id);
};

/**
 * packUnpackItem - Toggle an item to packed/unpacked
 * @param  {string} id - ID of the plan in which to add a cable
 * @param  {string} item - ID of the item object to add
 * @param  {boolean} forceTrue - Should the item be forced to true
 */
export const packUnpackItem = (id, item, forceTrue = false) => {
 store.dispatch({
  type: "PACK_UNPACK_ITEM",
  id,
  item,
  forceTrue,
 });

 calculatePackedPercent(id);
 savePlan(id);
};

/**
 * packUnpackCable - Toggle a cable to packed/unpacked
 * @param  {string} id - ID of the plan in which to add a cable
 * @param  {string} cable - ID of the cable object to add
 * @param  {boolean} forceTrue - Should the cables to forced to true
 */
export const packUnpackCable = (id, cable, forceTrue = false) => {
 store.dispatch({
  type: "PACK_UNPACK_CABLE",
  id,
  cable,
  forceTrue,
 });

 calculatePackedPercent(id);
 savePlan(id);
};

/**
 * packUnpackAll - Packs all items, cables and extras
 * @param  {string} id - ID of the plan in which to add a cable
 * @param  {boolean} pack - Should items be packed or unpacked
 */
export const packUnpackAll = (id, pack) => {
 store.dispatch({
  type: "PACK_UNPACK_ALL",
  id,
  pack,
 });

 calculatePackedPercent(id);
 savePlan(id);
};

/**
 * repatchCable - Takes a new cable from one gear item to another
 * @param  {string} id - ID of the plan in which to repatch the cable
 * @param  {object} cableId - Cable that needs to change
 * @param  {object} direction - 'fromItem' or 'toItem'
 * @param  {object} newItemId - ID of the item to repatch the cable to
 */
export const repatchCable = (id, cableId, direction, newItemId) => {
 console.log(`Repatching cable,`, id, cableId, direction, newItemId);
 store.dispatch({
  type: "REPATCH_CABLE",
  id,
  cableId,
  direction,
  newItemId,
 });

 savePlan(id);
};

/**
 * removeCable - Add a cable to connect two items
 * @param  {string} id - ID of the plan from which to remove a cable
 * @param  {string} cable - ID of the cable to remove
 */
export const removeCable = (id, cable) => {
 store.dispatch({
  type: "REMOVE_CABLE",
  id,
  cable,
 });

 calculatePackedPercent(id);
 savePlan(id);
};

/**
 * addExtra - Add an extra item to a given plan
 * @param {string} id - ID of the plan to add the item to
 * @param {string} item - Item to add
 */
export const addExtra = (id, item) => {
 store.dispatch({
  type: "ADD_EXTRA",
  id,
  item,
 });

 calculatePackedPercent(id);
 savePlan(id);
};

/**
 * packUnpackExtra - Toggle an extra to packed/unpacked
 * @param  {string} id - ID of the plan
 * @param  {object} extraId - ID of the extra object to add
 */
export const packUnpackExtra = (id, extraId) => {
 store.dispatch({
  type: "PACK_UNPACK_EXTRA",
  id,
  extraId,
 });

 calculatePackedPercent(id);
 savePlan(id);
};

/**
 * removeExtra - Remove an extra item from a given plan
 * @param {string} id - ID of the plan to remove the item from
 * @param {string} extraId - Item to remove
 */
export const removeExtra = (id, extraId) => {
 store.dispatch({
  type: "REMOVE_EXTRA",
  id,
  extraId,
 });

 calculatePackedPercent(id);
 savePlan(id);
};

/**
 * calculatePackedPercent - Calculates the percentage that a plan is packed
 * @param  {string} id - ID of the plan from calculate
 */
export const calculatePackedPercent = (id) => {
 store.dispatch({
  type: "CALCULATE_PACKED_PERCENT",
  id,
 });

 savePlan(id);
};

/**
 * addTextLabel - Adds a text label to a given plan
 * @param  {string} id - ID of the plan
 * @param  {object} textLabel - Text label to add
 */
export const addTextLabel = (id, textLabel) => {
 store.dispatch({
  type: "ADD_TEXT_LABEL",
  id,
  textLabel,
 });

 savePlan(id);
};

/**
 * updateTextLabel - Updates a text label in a given plan
 * @param  {string} id - ID of the plan
 * @param  {string} textLabelId - ID of the text label to update
 * @param  {object} update - Object containing the update(s)
 */
export const updateTextLabel = (id, textLabelId, update) => {
 store.dispatch({
  type: "UPDATE_TEXT_LABEL",
  id,
  textLabelId,
  update,
 });

 savePlan(id);
};

/**
 * removeTextLabel - Removes a text label in a given plan
 * @param  {string} id - ID of the plan
 * @param  {string} textLabelId - ID of the text label to update
 */
export const removeTextLabel = (id, textLabelId) => {
 store.dispatch({
  type: "REMOVE_TEXT_LABEL",
  id,
  textLabelId,
 });

 savePlan(id);
};

/**
 * addZone - Adds a zone to the plan
 * @param  {string} id - ID of the plan
 * @param  {object} textLabel - Text label to add
 */
export const addZone = (id, zone) => {
 store.dispatch({
  type: "ADD_ZONE",
  id,
  zone,
 });

 savePlan(id);
};

/**
 * updateZone - Updates a zone in a given plan
 * @param  {string} id - ID of the plan
 * @param  {string} zoneId - ID of the zone to update
 * @param  {object} update - Object containing the update(s)
 */
export const updateZone = (id, zoneId, update) => {
 store.dispatch({
  type: "UPDATE_ZONE",
  id,
  zoneId,
  update,
 });

 savePlan(id);
};

/**
 * removeZone - Removes a zone in a given plan
 * @param  {string} id - ID of the plan
 * @param  {string} zoneId - ID of the zone to update
 */
export const removeZone = (id, zoneId) => {
 store.dispatch({
  type: "REMOVE_ZONE",
  id,
  zoneId,
 });

 savePlan(id);
};

/**
 * leftToRightSwitch - Reorintates the plan
 * @param  {string} id - ID of the plan
 * @param  {boolean} ltr - LTR or not
 */
export const leftToRightSwitch = (id, ltr = true) => {
 store.dispatch({
  type: "LEFT_TO_RIGHT_SWITCH",
  id,
  ltr,
 });

 savePlan(id);
};

/**
 * showHideCableKey - Reorintates the plan
 * @param  {string} id - ID of the plan
 * @param  {boolean} show - Show or hide the key
 */
export const showHideCableKey = (id, show = true) => {
 store.dispatch({
  type: "SHOW_HIDE_CABLE_KEY",
  id,
  show,
 });

 savePlan(id);
};

/**
 * hideAfterAdding - Show the modal be hiden after adding a gear item
 * @param  {string} checked - Checked status of the UI
 */
export const hideAfterAdding = (checked) => {
 store.dispatch({
  type: "HIDE_AFTER_ADDING",
  checked,
 });
};

/**
 * showOnlyMyGear - Show only the users gear in the "add gear" modal
 * @param  {string} checked - Checked status of the UI
 */
export const showOnlyMyGear = (checked) => {
 store.dispatch({
  type: "SHOW_ONLY_MY_GEAR",
  checked,
 });
};

/**
 * searchTab - Tab switching for the addGear modal
 * @param  {string} tab - Which tab to select
 */
export const searchTab = (tab) => {
 store.dispatch({
  type: "SEARCH_TAB",
  tab,
 });
};

/**
 * logout - When a user logs out
 */
export const logout = () => {
 store.dispatch({
  type: "LOGOUT",
 });
};
