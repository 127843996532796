import React, { useCallback, useState } from "react";

// import randomatic from "randomatic";

// import Input from "./Input";
// import PacklistItem from "./PacklistItem";
// import * as planActions from "../actions/planActions";
// import { cableTypes, cableTypes as cableTypesDefault } from "../db/categories";
import useGetCableColour from "./hooks/useGetCableColour";
import { CSVLink } from "react-csv";

const csvHeaders = [
 "Cable,",
 "Output Item,",
 "Output,",
 "->,",
 "Input Item,",
 "Input",
 "\n",
];

// export default function Pactchlist({ plan }) {
//  const { cables, items } = plan;

//  let [csv, setCsv] = useState([[csvHeaders.join("")]]);

//  useEffect(() => {
//   let c = [];
//   cables.forEach((cable) => {
//    let cableFromItem = items.find((item) => item.id === cable.fromItem);
//    let cableToItem = items.find((item) => item.id === cable.toItem);

//    return (c = [
//     ...c,
//     [
//      `${cable.type},`,
//      `${cableFromItem.name},`,
//      `${cableFromItem.outputs[cable.fromPosition - 1].name},`,
//      "->,",
//      `${cableToItem.name},`,
//      `${cableToItem.inputs[cable.toPosition - 1].name},`,
//      "\n",
//     ].join(""),
//    ]);
//   });

//   setCsv([csv[0], c.join("")]);
//  }, [cables, items]);

//  return (
//   <>
//    <textarea className="w-screen" value={csv.join("")} />
//    <br />
//   </>
//  );
// }

export default function Patchlist({
 items = [],
 cables = [],
 planName = "",
 onClose,
 shown = false,
}) {
 const [sorting, setSorting] = useState("cable");
 const [copiedCsv, setCopiedCsv] = useState(false)

 console.log("reworking");
 //  let [csv, setCsv] = useState([[csvHeaders.join("")]]);

 const csv = useCallback(() => {
  let c = [[csvHeaders.join("")]];
  cables.forEach((cable) => {
   let cableFromItem = items.find((item) => item.id === cable.fromItem);
   let cableToItem = items.find((item) => item.id === cable.toItem);

   if (cableFromItem.type === "shape" || cableToItem.type === "shape")
    return null;
   return (c = [
    ...c,
    [
     `${cable.type}${cable?.label ? ` - ${cable?.label}` : ''}${
      cable?.length ? ` - (${cable?.length})` : ''
     },`,
     `${cableFromItem.label || cableFromItem.name},`,
     `${cableFromItem.outputs[cable.fromPosition - 1].name},`,
     "->,",
     `${cableToItem.label || cableToItem.name},`,
     `${cableToItem.inputs[cable.toPosition - 1].name},`,
     "\n",
    ].join(""),
   ]);
  });

  return [csv[0], c.join("")];
 }, [cables, items]);

 const handleCopyAsCsv = () => {
  setCopiedCsv(true)

  navigator.clipboard.writeText(csv().join(''));

  setTimeout(() => {
    setCopiedCsv(false)
  }, 3000);
}

 //  useEffect(() => {
 //   let c = [];
 //   plan.cables.forEach((cable) => {
 //    let cableFromItem = plan.items.find((item) => item.id === cable.fromItem);
 //    let cableToItem = plan.items.find((item) => item.id === cable.toItem);

 //    if (cableFromItem.type === "shape" || cableToItem.type === "shape")
 //     return null;
 //    return (c = [
 //     ...c,
 //     [
 //      `${cable.type}${cable.label && ` - ${cable.label}`}${
 //       cable.length && ` - (${cable.length})`
 //      },`,
 //      `${cableFromItem.label || cableFromItem.name},`,
 //      `${cableFromItem.outputs[cable.fromPosition - 1].name},`,
 //      "->,",
 //      `${cableToItem.label || cableToItem.name},`,
 //      `${cableToItem.inputs[cable.toPosition - 1].name},`,
 //      "\n",
 //     ].join(""),
 //    ]);
 //   });

 //   setCsv([csv[0], c.join("")]);
 //  }, [plan.cables, plan.items]);

 if (!shown) return null;

 return (
  <div
   style={{
    height: "100svh",
   }}
   className="fixed flex justify-end items-center top-0 right-0 max-w-3xl z-30 select-text"
  >
   <div
    style={{
     height: "100svh",
    }}
    className="flex flex-col justify-between w-11/12 md:max-w-xl bg-white dark:bg-gray-800 rounded-lg shadow z-40 overflow-auto"
   >
    <div>
     <div className="py-6 border-b-2 border-solid border-gray-200 dark:border-gray-900 sticky top-0 bg-white dark:bg-gray-800">
      <div className="px-10 flex justify-between items-center">
       <div>
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100">
         Patchlist
        </h2>
        <h3 className="pt-2 font-light text-gray-500">{planName}</h3>
       </div>
       <div></div>
      </div>
     </div>
     <div className="px-10 py-10 mb-20 ">
      <div className=" text-gray-500 flex justify-between">
        <span>CSV</span>
        <div className=" text-gray-500 flex justify-end text-sm gap-4">
        <CSVLink
        filename={`Packlist - ${planName}.csv`}
        className="underline hover:text-gray-400"
        data={csv().join("")}
        // separator={","}
       >
        Download
       </CSVLink>

       <button
        className="underline hover:text-gray-400"
        onClick={handleCopyAsCsv}
       >
        {copiedCsv ? 'Copied!' : 'Copy'}
       </button>
        </div>
      </div>
      <textarea
       className="mt-2 border border-gray-200 dark:border-gray-700 px-2 py-1 rounded bg-white dark:bg-gray-900 text-gray-600 dark:text-gray-100 text-sm w-full focus:outline-none focus:ring"
       value={csv().join("")}
       readOnly={true}
      />
      <div className="mt-6">
       <div className="uppercase text-gray-500">Patches</div>
       <div className="mt-2 flex flex-col space-y-4 cursor-default">
        <div
         style={{
          gridTemplateColumns: "1fr 1fr .4fr 1fr",
         }}
         className="px-2 py-1 grid uppercase text-sm font-semibold text-gray-400"
        >
         <button
          className="text-left uppercase text-sm focus:outline-none focus:text-gray-700"
          onClick={() => {
           setSorting("cable");
          }}
         >
          Cable {sorting === "cable" && "↓"}
         </button>
         <button
          className="text-left uppercase text-sm focus:outline-none focus:text-gray-700"
          onClick={() => {
           setSorting("output");
          }}
         >
          Output {sorting === "output" && "↓"}
         </button>
         <div> </div>
         <button
          className="text-left uppercase text-sm focus:outline-none focus:text-gray-700"
          onClick={() => {
           setSorting("input");
          }}
         >
          Input {sorting === "input" && "↓"}
         </button>
        </div>
        {cables
         .sort((a, b) => {
          switch (sorting) {
           case "cable":
            if (a.type < b.type) {
             return -1;
            }
            if (a.type > b.type) {
             return 1;
            }
            return 0;

           case "output":
            if (a.fromItem < b.fromItem) {
             return -1;
            }
            if (a.fromItem > b.fromItem) {
             return 1;
            }
            return 0;

           case "input":
            if (a.toItem < b.toItem) {
             return -1;
            }
            if (a.toItem > b.toItem) {
             return 1;
            }
            return 0;
           default:
            break;
          }
         })
         .map((c) => {
          let cableFromItem = items.find((item) => item.id === c.fromItem);
          let cableToItem = items.find((item) => item.id === c.toItem);

          if (cableFromItem?.type === "shape" || cableToItem?.type === "shape")
           return null;
          return (
           <div
            key={c.id}
            style={{
             gridTemplateColumns: "1fr 1fr .4fr 1fr",
            }}
            className="px-2 py-2 grid items-center hover:bg-gray-100 dark:hover:bg-gray-900 rounded"
           >
            <Cable type={c.type} label={c.label} length={c.length} />
            <div>
             <span className="font-semibold text-gray-800 dark:text-gray-500">
              {cableFromItem?.outputs[c.fromPosition - 1].name}
             </span>
             <br />
             <span className="text-sm uppercase text-gray-600">
              {cableFromItem?.label || cableFromItem?.name}
             </span>
            </div>
            <span className="flex items-center justify-center">→</span>
            <div>
             <span className="font-semibold text-gray-800 dark:text-gray-500">
              {cableToItem.inputs[c.toPosition - 1].name}
             </span>
             <br />
             <span className="text-sm uppercase text-gray-600">
              {" "}
              {cableToItem.label || cableToItem.name}
             </span>
            </div>
           </div>
          );
         })}
       </div>
      </div>
     </div>
    </div>
   </div>
   <div
    className="fixed w-screen h-screen bg-gray-900 opacity-50 z-30"
    onClick={onClose}
   />
  </div>
 );
}

const Cable = ({ type, label = "", length = "" }) => {
 const c = useGetCableColour(type);

 return (
  <div className="flex flex-col justify-center items-left">
   <span
    style={{
     borderColor: c.colour,
     borderStyle: c.style,
     color: c.colour,
    }}
    className="px-1 py-0.5 w-min flex items-center justify-center border-2 border-solid text-white text-sm uppercase rounded "
   >
    {type}
   </span>
   <span className="text-sm uppercase text-gray-500">
    {label} {length && `(${length})`}
   </span>
  </div>
 );
};
