import { CABLES } from "./definitions";

export const deviceCategories = [
 "audio",
 "camera",
 "comms",
 "computer",
 "controller",
 "converter",
 "distributor",
 "laptop",
 "lens",
 "lighting",
 "mic",
 "monitor",
 "network",
 "other",
 "patch panel",
 "pcie",
 "projector",
 "power",
 "recorder",
 "signal generator",
 "streaming",
 "switcher",
 "venue",
 "video router",
 "wireless",
];

export const cssClassnames = [
 "camera",
 "laptop",
 "monitor",
 "projector",
 "router",
 "venue",
 "mic",
 "pcie",
 "wireless",
];

export const cableCategories = ["video", "audio", "network", "data"];
export const allCustomTypes = [
 "custom-1",
 "custom-2",
 "custom-3",
 "custom-4",
 "custom-5",
];
export const allCableTypes = [
 "custom-1",
 "custom-2",
 "custom-3",
 "custom-4",
 "custom-5",
 "hdmi",
 "sdi",
 "ethernet",
 "ethercon",
 "fiber",
 "usb",
 "thunderbolt",
 "xlr",
 "rca",
 "jack",
 "xlr/jack",
 "minijack",
 "displayport",
 "dvi",
 "firewire",
 "vga",
 "wireless",
 "power",
 "dmx",
 "midi",
 "powercon",
 "speakon",
 "lightning",
];

export const cableTypes = [
 {
  name: "custom-1",
  displayName: "Custom 1",
  colour: "#B6B6B6",
  style: "solid",
  category: "custom",
  connectsTo: allCableTypes,
 },
 {
  name: "custom-2",
  displayName: "Custom 2",
  colour: "#B6B6B6",
  style: "solid",
  category: "custom",
  connectsTo: allCableTypes,
 },
 {
  name: "custom-3",
  displayName: "Custom 3",
  colour: "#B6B6B6",
  style: "solid",
  category: "custom",
  connectsTo: allCableTypes,
 },
 {
  name: "custom-4",
  displayName: "Custom 4",
  colour: "#B6B6B6",
  style: "solid",
  category: "custom",
  connectsTo: allCableTypes,
 },
 {
  name: "custom-5",
  displayName: "Custom 5",
  colour: "#B6B6B6",
  style: "solid",
  category: "custom",
  connectsTo: allCableTypes,
 },
 {
  name: "hdmi",
  displayName: "HDMI",
  colour: "#5C5C5C",
  style: "solid",
  category: "video",
  connectsTo: ["hdmi", "dvi", "displayport", ...allCustomTypes],
 },
 {
  name: "sdi",
  displayName: "SDI",
  colour: "#89B2E1",
  style: "solid",
  category: "video",
  connectsTo: ["sdi", ...allCustomTypes],
 },
 {
  name: "ethernet",
  displayName: "Ethernet",
  colour: "#0057BE",
  style: "solid",
  category: "network",
  connectsTo: ["ethernet", "ethercon", ...allCustomTypes],
 },
 {
  name: "ethercon",
  displayName: "EtherCon",
  colour: "#0057BE",
  style: "solid",
  category: "network",
  connectsTo: ["ethernet", "ethercon", ...allCustomTypes],
 },
 {
  name: "fiber",
  displayName: "Fiber",
  colour: "#D00000",
  style: "solid",
  category: "data",
  connectsTo: ["fiber", ...allCustomTypes],
 },
 {
  name: "usb",
  displayName: "USB",
  colour: "#E166F5",
  style: "solid",
  category: "data",
  connectsTo: ["usb", "thunderbolt", "power", "hdmi", ...allCustomTypes],
 },
 {
  name: "thunderbolt",
  displayName: "Thunderbolt",
  colour: "#9B00B5",
  style: "solid",
  category: "data",
  connectsTo: ["usb", "thunderbolt", "hdmi", ...allCustomTypes],
 },
 {
  name: "xlr",
  displayName: "XLR",
  colour: "#46E956",
  style: "solid",
  category: "audio",
  connectsTo: [
   "xlr",
   "xlr/jack",
   "jack",
   "minijack",
   "speakon",
   "rca",
   ...allCustomTypes,
  ],
 },
 {
  name: "rca",
  displayName: "RCA",
  colour: "#00B512",
  style: "solid",
  category: "audio",
  connectsTo: ["rca", "minijack", "xlr", "xlr/jack", "jack", ...allCustomTypes],
 },
 {
  name: "jack",
  displayName: "Jack",
  colour: "#48EACD",
  style: "solid",
  category: "audio",
  connectsTo: ["jack", "xlr/jack", "xlr", "minijack", "rca", ...allCustomTypes],
 },
 {
  name: "xlr/jack",
  displayName: "XLR/Jack",
  colour: "#48EACD",
  style: "solid",
  category: "audio",
  connectsTo: ["jack", "xlr/jack", "xlr", "minijack", "rca", ...allCustomTypes],
 },
 {
  name: "minijack",
  displayName: "Mini-Jack",
  colour: "#00BE9C",
  style: "solid",
  category: "audio",
  connectsTo: ["minijack", "rca", "jack", "xlr/jack", "xlr", ...allCustomTypes],
 },
 {
  name: "displayport",
  displayName: "DisplayPort",
  colour: "#EABC48",
  style: "solid",
  category: "data",
  connectsTo: ["displayport", "hdmi", ...allCustomTypes],
 },
 {
  name: "dvi",
  displayName: "DVI",
  colour: "#B58509",
  style: "solid",
  category: "video",
  connectsTo: ["dvi", "hdmi", ...allCustomTypes],
 },
 {
  name: "firewire",
  displayName: "FireWire",
  colour: "#B6B6B6",
  style: "solid",
  category: "data",
  connectsTo: ["firewire", ...allCustomTypes],
 },
 {
  name: "vga",
  displayName: "VGA",
  colour: "#5C5C5C",
  style: "solid",
  category: "video",
  connectsTo: ["vga", ...allCustomTypes],
 },
 {
  name: "wireless",
  displayName: "Wireless",
  allCustomTypes,
  colour: "#89B2E1",
  style: "dashed",
  category: "data",
  connectsTo: ["wireless", ...allCustomTypes],
 },
 {
  name: "power",
  displayName: "Power",
  colour: "#0057BE",
  style: "solid",
  category: "power",
  connectsTo: ["power", "usb", "powercon", ...allCustomTypes],
 },
 {
  name: "dmx",
  displayName: "DMX",
  colour: "#E07C7C",
  style: "solid",
  category: "lighting",
  connectsTo: ["dmx", ...allCustomTypes],
 },
 {
  name: "midi",
  displayName: "MIDI",
  colour: "#D00000",
  style: "solid",
  category: "audio",
  connectsTo: ["midi", ...allCustomTypes],
 },
 {
  name: "powercon",
  displayName: "PowerCon",
  colour: "#E166F5",
  style: "solid",
  category: "power",
  connectsTo: ["power", "powercon", ...allCustomTypes],
 },
 {
  name: "speakon",
  displayName: "Speakon",
  colour: "#9B00B5",
  style: "solid",
  category: "audio",
  connectsTo: ["speakon", "xlr", ...allCustomTypes],
 },
 {
  name: "lightning",
  displayName: "Lightning",
  colour: "#46E956",
  style: "solid",
  category: "data",
  connectsTo: ["lightning", "usb", ...allCustomTypes],
 },
];

export const connectorTypes = [
 { name: "custom-1", displayName: "Custom 1", category: "custom" },
 { name: "custom-2", displayName: "Custom 2", category: "custom" },
 { name: "custom-3", displayName: "Custom 3", category: "custom" },
 { name: "custom-4", displayName: "Custom 4", category: "custom" },
 { name: "custom-5", displayName: "Custom 5", category: "custom" },
 { name: "hdmi", displayName: "HDMI", category: "video" },
 { name: "sdi", displayName: "SDI", category: "video" },
 { name: "fiber", displayName: "Fiber", category: "data" },
 {
  name: "ethernet",
  displayName: "Ethernet",
  category: "network",
 },
 {
  name: "ethercon",
  displayName: "EtherCon",
  category: "network",
 },
 { name: "usb", displayName: "USB", category: "data" },
 { name: "thunderbolt", displayName: "Thunderbolt", category: "data" },
 { name: "xlr", displayName: "XLR", category: "audio" },
 { name: "jack", displayName: "Jack", category: "audio" },
 { name: "xlr/jack", displayName: "XLR/Jack combo", category: "audio" },
 { name: "rca", displayName: "RCA", category: "audio" },
 {
  name: "minijack",
  displayName: "Mini-Jack",
  category: "audio",
 },
 { name: "displayport", displayName: "DisplayPort", category: "video" },
 { name: "dvi", displayName: "DVI", category: "video" },
 { name: "firewire", displayName: "FireWire", category: "data" },
 { name: "vga", displayName: "VGA", category: "video" },
 { name: "wireless", displayName: "Wireless", category: "data" },
 { name: "power", displayName: "Power", category: "power" },
 { name: "powercon", displayName: "PowerCon", category: "power" },
 { name: "dmx", displayName: "DMX", category: "lighting" },
 { name: "midi", displayName: "MIDI", category: "audio" },
 { name: "speakon", displayName: "Speakon", category: "audio" },
 { name: "lightning", displayName: "Lightning", category: "data" },
];

export const biDirectionalCables = [CABLES.ETHERNET, CABLES.USB, CABLES.FIBRE];

export const availableCableColours = [
 "#B6B6B6",
 "#5C5C5C",
 "#89B2E1",
 "#0057BE",
 "#E07C7C",
 "#D00000",
 "#E166F5",
 "#9B00B5",
 "#46E956",
 "#00B512",
 "#48EACD",
 "#00BE9C",
 "#EABC48",
 "#B58509",
];

export const availableCableColoursInputsOutputs = [
 "#F4F4F4",
 "#a3e635", // lime-400
 "#2dd4bf", // teal-400
 "#38bdf8", // sky-400
 "#c084fc", // purple-400
 "#fb7185", // rose-400
 "#4ade80", // green-400
];
