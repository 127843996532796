import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Layout from "./Layout";

import { useFirestore } from "react-redux-firebase";
import Button from "./Button";
import { AddTeamMemberModal, EditTeamNameModal } from "./Modal";
import * as notificationsActions from "../actions/notificationsActions";

export default function Team() {
 const [showEditTeamNameModal, setShowEditTeamNameModal] = useState(false);
 const team = useSelector((state) => state.team);

 const uid = useSelector((state) => state.firebase.auth.uid);

 return (
  <Layout title="Team">
   <div className="px-6 py-6 flex flex-row bg-red-500 md:flex-col md:px-0 md:py-0 md:bg-transparent">
    <div className="flex items-center">
     <h1 className="text-2xl font-bold text-white md:text-gray-800 md:dark:text-gray-100">
      Team
     </h1>
    </div>
   </div>
   <div className="px-4 mt-6 md:px-0">
    {Object.keys(team).length === 0 ? (
     "Loading..."
    ) : (
     <>
      <div className="flex space-x-2">
       <h2 className="text-xl font-semi-bold text-gray-600 dark:text-gray-300">
        {team.name}
       </h2>
       {uid === team.admin && (
        <Button
         disabled={uid !== team.admin}
         text="Edit"
         colour="white"
         size="small"
         onClick={() => {
          setShowEditTeamNameModal(true);
         }}
        />
       )}
      </div>

      {uid === team.admin ? (
       <>
        <TeamTable />
        <h2 className="mt-6 text-xl font-semi-bold text-gray-600 dark:text-gray-400">
         Limits
        </h2>

        <p className="mt-2 text-sm text-gray-700 dark:text-gray-400">
         You have added {team.members.length} of your {team.limits.members}{" "}
         available team members. Need more?{" "}
         <a className="underline" href="mailto:support@heretorecord.com">
          Get in touch
         </a>
         .
        </p>
       </>
      ) : (
       <p className="mt-2 text-sm text-gray-700 dark:text-gray-400">
        No settings available.
       </p>
      )}
      <></>
     </>
    )}
   </div>

   {showEditTeamNameModal && (
    <EditTeamNameModal
     onCancel={() => {
      setShowEditTeamNameModal(false);
     }}
     currentName={team.name}
    />
   )}
  </Layout>
 );
}

const TeamTable = () => {
 const [people, setPeople] = useState([]);
 const [showAddTeamMemberModal, setShowAddTeamMemberModal] = useState(false);
 const [memberToRemove, setMemberToRemove] = useState(null);

 const firestore = useFirestore();
 const uid = useSelector((state) => state.firebase.auth.uid);
 const team = useSelector((state) => state.team);

 const handleGetTeamMembers = async () => {
  await firestore.onSnapshot(
   {
    collection: "users",
    where: ["partOfTeam", "==", uid],
   },
   (res) => {
    if (res.docs.length >= 1) {
     let tArray = res.docs.map((tm) => tm.data());

     setPeople(tArray);
    }
   }
  );
 };

 const handleRemoveTeamMember = async (member_uid, member_email) => {
  console.log(`Should remove team member ${member_uid}`);

  // Send off remove request

  await firestore
   .collection("pendingTeamMembersRemoval")
   .add({
    uid: member_uid,
    team: uid,
   })
   .then((res) => res);

  // Notification for refresh
  return await notificationsActions.addNotification(
   `We'll take it from here`,
   `${member_email} will be removed from your team.`,
   `success`,
   10000
  );
 };

 useEffect(() => {
  handleGetTeamMembers();
 }, []);

 return (
  <div className="mt-2">
   <div className="sm:flex sm:items-center">
    <div className="sm:flex-auto">
     <p className="mt-2 text-sm text-gray-700 dark:text-gray-400">
      A list of all the users in your account including their name, email and
      role.
     </p>
    </div>
    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
     <Button
      text="Add member"
      disabled={team.members.length >= team.limits.members}
      onClick={() => {
       setShowAddTeamMemberModal(true);
      }}
     />
    </div>
   </div>

   {showAddTeamMemberModal && (
    <AddTeamMemberModal
     onCancel={() => {
      setShowAddTeamMemberModal(false);
     }}
    />
   )}
   <div className="mt-8 flex flex-col">
    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
     <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
      <div className="overflow-hidden bg-gray-100 dark:bg-gray-900 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
       <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50 dark:bg-gray-900">
         <tr>
          <th
           scope="col"
           className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-100 sm:pl-6"
          >
           Name
          </th>
          <th
           scope="col"
           className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
          >
           Email
          </th>
          <th
           scope="col"
           className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
          >
           Role
          </th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
           <span className="sr-only">Edit</span>
          </th>
         </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white dark:bg-gray-900">
         {people.map((person) => (
          <tr key={person.email}>
           <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-100 sm:pl-6">
            {person.displayName || (
             <span className="italic text-gray-600 font-normal">
              Name not provided
             </span>
            )}
           </td>
           <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 dark:text-gray-500">
            {person.email}
           </td>
           <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 dark:text-gray-500">
            {person.uid === uid ? "Admin" : "Member"}
           </td>
           <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            {/* <a href="#" className="text-red-500 hover:text-red-600">
             Edit<span className="sr-only">, {person.email}</span>
            </a> */}
            <Button
             disabled={person.uid === uid || memberToRemove === person.uid}
             text="Remove"
             colour="white"
             size="small"
             onClick={() => {
              setMemberToRemove(person.uid);

              return handleRemoveTeamMember(person.uid, person.email);
             }}
            />
           </td>
          </tr>
         ))}
        </tbody>
       </table>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};
