import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { saveUserCableTypes } from "../db/firestore";
import * as settingsActions from "../actions/settingsActions";
import ColourPicker from "./ColourPicker";
import Select from "./Select";
import { allCustomTypes, availableCableColours } from "../db/categories";

export default function SettingsCableColours({ cable }) {
 const uid = useSelector((state) => state.firebase.auth.uid);
 const cableTypes = useSelector((state) => state.settings.cableTypes);
 const [userDisplayName, setUserDisplayName] = useState(
  cable.userDisplayName || cable.displayName
 );

 //  const handleCableDisplayNameChange = (name) => {
 //   if (cable.displayName === userDisplayName) return null;

 //   return settingsActions.changeCableDisplayName(cable, name);
 //  };

 const handleCableColourChange = async (cable, colour) => {
  const update = await settingsActions.changeCableColour(cable, colour);
  return handleCableSave(update);
 };

 const handleCableStyleChange = async (cable, style) => {
  const update = await settingsActions.changeStyleColour(cable, style);

  return handleCableSave(update);
 };

 const handleCableSave = (update) => {
  console.log("handleCableSave");
  return saveUserCableTypes(uid, update);
 };

 useEffect(() => {
  setUserDisplayName(cable.userDisplayName);
 }, [cable.userDisplayName]);

 return (
  <div className="flex mt-4 justify-center items-center relative">
   <div className="">
    {/* {allCustomTypes.includes(cable.name) ? (
     <input
      className="px-1 py-1 bg-white dark:bg-gray-800 rounded uppercase text-sm hover:border focus:outline-none focus:ring"
      value={userDisplayName || ""}
      onChange={(e) => {
       setUserDisplayName(e.target.value);
       handleCableDisplayNameChange(e.target.value);
      }}
      onBlur={handleCableSave}
      placeholder={cable.displayName}
     /> */}
    <span className="px-1 py-1 bg-white dark:bg-gray-800 rounded uppercase text-sm">
     {cable.displayName}
    </span>
   </div>
   <div
    className="w-100 h-1 mx-2 flex-grow my-auto"
    // style={{ backgroundColor: cable.colour }}
   >
    <svg
     xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 100 100"
     preserveAspectRatio="none"
    >
     <path
      stroke={cable.colour}
      strokeDasharray={
       !cable.style || cable.style === "solid"
        ? null
        : cable.style === "dashed"
        ? "1.5,1.5"
        : "0.4,1"
      }
      strokeLinejoin="round"
      strokeWidth="2"
      d="M 0 0 l 150 0"
     />
    </svg>
   </div>
   {/* <div className="mr-4 px-1 py-1 bg-white rounded-l uppercase text-sm">
    {cable.displayName}
   </div> */}
   <ColourPicker
    colour={cable.colour}
    onChangeComplete={(colour) => {
     handleCableColourChange(cable, colour);
    }}
    onClosePicker={() => {
     //  handleCableSave();
    }}
    swatchesOnly={true}
    swatchColours={availableCableColours}
   />
   <Select
    name="cableStyle"
    css="ml-2"
    selected={cable.style || "solid"}
    options={[
     { name: "solid", displayName: "Solid" },
     { name: "dashed", displayName: "Dashed" },
     { name: "dotted", displayName: "Dotted" },
    ]}
    onSelect={(choice) => {
     handleCableStyleChange(cable, choice);
    }}
    // onBlur={() => {
    //  handleCableSave();
    // }}
   />
  </div>
 );
}
