export const ETHERNET = "ethernet";
export const HDMI = "hdmi";
export const USB = "usb";
export const FIBRE = "fibre";

export const CABLES = {
 ETHERNET,
 HDMI,
 USB,
 FIBRE,
};
