import React, {
 useState,
 useEffect,
 createRef,
 Fragment,
 useCallback,
 useMemo,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import randomatic from "randomatic";
// import { PanZoom } from "react-easy-panzoom";
import { Helmet } from "react-helmet";
import Tour from "reactour";
import KeyboardEventHandler from "react-keyboard-event-handler";
// import debounce from "lodash/debounce";
import {
 TransformWrapper,
 TransformComponent,
 //  MiniMap,
} from "react-zoom-pan-pinch";

import GearItem from "./GearItem";
import Packlist from "./Packlist";
import Patchlist from "./Patchlist";
import "../styles/cables.css";
// import calculateCables, {
//  calculateCablesLTR,
// } from "../helpers/calculateCables";
import * as planActions from "../actions/planActions";
// import * as packlistActions from "../actions/packlistActions";
import Button from "./Button";
import {
 AddGearModal,
 BuildGearModal,
 //  EditGearModal,
 SharePlanModal,
 ExportPlanModal,
 UpgradeModal,
 ShortcutsModal,
} from "./Modal";
import SplashScreen from "./SplashScreen";
import NotFound from "./NotFound";
import {
 cableTypes as cableTypesDefault,
 //  biDirectionalCables,
} from "../db/categories";
import TextLabel from "./TextLabel";
// import CableTextLabel from "./CableTextLabel";
import CablePin from "./CablePin";
import * as notificationsActions from "../actions/notificationsActions";
// import * as currentPlanActions from "../actions/currentPlanActions";
import Zone from "./Zone";
// import SketchItem from "./sketch/SketchItem";
import FlyoutMenu from "./FlyoutMenu";
import Cable from "./Cable";
// import SketchItem from "./sketch/SketchItem";
import { isProPlanName } from "../helpers/proPlanNames";
import useUndoRedo from "./hooks/useUndoRedo";
import CableTypeDefs from "./CableTypeDefs";
import Select from "./Select";
import SketchItem, { shapeColourOptions } from "./sketch/SketchItem";
import { InputCheckbox } from "./Input";
import { EditorState, convertFromRaw } from "draft-js";

const shadowGearItem = {
 inputs: [],
 outputs: [],
 name: "Drop to add",
 width: 50,
 height: 94,
 color: "#b6b6b6",
 secondaryColour: "#555555",
};

export default function Plan() {
 const firestore = useFirestore();
 const user = useSelector((state) => state.firebase.profile);
 const auth = useSelector((state) => state.firebase.auth);
 //  const plans = useSelector((state) => state.plans);
 const params = useParams();
 const plan = useSelector((state) => state.plans[params.id]);
 const cableTypes = useSelector((state) => state.settings.cableTypes);

 const subscriptionPlanName =
  useSelector((state) => state.settings.planName) || "Starter";

 const panZoomRef = createRef();
 const printRef = createRef();
 const outlineRef = createRef();
 const history = useHistory();

 const [draggedItem, setDraggedItem] = useState();
 const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
 const [dragStartSelected, setDragStartSelected] = useState([]);
 const [showPacklist, setShowPacklist] = useState(false);
 const [showPatchlist, setShowPatchlist] = useState(false);
 const [showAddGearModal, setShowAddGearModal] = useState(false);
 const [showUpgradeModal, setShowUpgradeModal] = useState(false);
 const [isTourOpen, setIsTourOpen] = useState(false);
 const [showShortcutsModal, setShowShortcutsModal] = useState(false);

 const [itemToReplace, setItemToReplace] = useState(null);
 const [showReplaceGearModal, setShowReplaceGearModal] = useState(false);
 const [showBuilderModal, setShowBuilderModal] = useState(false);
 const [showShareModal, setShowShareModal] = useState(false);
 const [showExportModal, setShowExportModal] = useState(false);
 const [removingItem, setRemovingItem] = useState("");
 const [noAccess, setNoAccess] = useState(false);
 const [altModifier, setAltModifier] = useState(false);
 const [shiftModifier, setShiftModifier] = useState(false);

 const [connectedDevicesAndCables, setConnectedDevicesAndCables] = useState([]);
 const [mouseStart, setMouseStart] = useState({ x: 0, y: 0 });
 const [mouseEnd, setMouseEnd] = useState({ x: 0, y: 0 });
 const [drawing, setDrawing] = useState(false);
 const [connection, setConnection] = useState();
 const [connectionStart, setConnectionStart] = useState();
 const [panZoomState, setPanZoomState] = useState({
  angle: 0,
  scale: 1,
  positionX: 0,
  positionY: 0,
  w: 0,
  h: 0
 });
 const [placeItemLocation, setPlaceItemLocation] = useState({ x: 10, y: 10 });
 const gridSize = useSelector((state) => state.plans[params.id]?.gridSize || 1);
 const [outline, setOutline] = useState({ x: 0, y: 0, w: 100, h: 100 });
 const [loaded, setLoaded] = useState(false)

 const [selectedItems, setSelectedItems] = useState([]);
 const [copiedItems, setCopiedItems] = useState([]);

 const { undo, redo, canUndo, canRedo, takeSnapshot } = useUndoRedo();

 const packlistMemo = useMemo(() => {
  if (!showPacklist) return null;
  return (
   <Packlist
    shown={showPacklist}
    plan={plan}
    onClose={() => setShowPacklist(false)}
   />
  );
 }, [plan, showPacklist]);

 const patchlistMemo = useMemo(() => {
  if (!showPatchlist) return null;
  return (
   <Patchlist
    shown={showPatchlist}
    planName={plan?.name}
    items={plan?.items}
    cables={plan?.cables}
    onClose={() => setShowPatchlist(false)}
   />
  );
 }, [showPatchlist, plan?.name, plan?.items, plan?.cables]);

 const setTourToShown = () => {
  firestore.collection("users").doc(auth.uid).update({ gearTourShown: true });
 };

 const fetchPlan = async () => {
  console.log("getting plan");
  // return firestore.onSnapshot(
  //  {
  //   collection: "plans",
  //   doc: params.id,
  //  },
  //  (doc) => {
  //   if (doc.exists) {
  //    if (doc.data().status === "ARCHIVED") {
  //     return setNoAccess(true);
  //    } else {
  //     console.log("should redirect to new URL");
  //     history.replace(
  //      `${window.location.pathname}?${doc.data().name.replaceAll(" ", "-")}`
  //     );
  //     return planActions.addPlan(doc.data());
  //    }
  //   }
  //  },
  //  (error) => {
  //   const errorMessage = error.toString();

  //   if (errorMessage?.includes("Missing or insufficient permissions")) {
  //    setNoAccess(true);
  //   }
  //  }
  // );

  await firestore
   .collection("plans")
   .doc(params.id)
   .get()
   .then(async (doc) => {
    if (doc.exists) {
     if (doc.data().status === "ARCHIVED") {
      return setNoAccess(true);
     } else {
      console.log("should redirect to new URL");
      history.replace(
       `${window.location.pathname}?${doc.data().name.replaceAll(" ", "-")}`
      );
      handleUpdateOutline(
       doc.data()?.items,
       doc.data()?.textLabels,
       doc.data()?.zones
      );
      planActions.addPlan(doc.data());
      return setLoaded(true)
     }
    }
   })
   .catch((err) => {
    const errorMessage = err.toString();

    if (errorMessage?.includes("Missing or insufficient permissions")) {
     setNoAccess(true);
    }
   });
 };

 const removeCables = (gearId, position) => {
  const filtered = plan.cables.filter((line) => {
   if (position === null) {
    return line.toItem === gearId || line.fromItem === gearId;
   } else {
    return line.toItem === gearId && line.toPosition === position;
   }
  });

  if (filtered.length > 0) {
   filtered.forEach((f) => {
    // packlistActions.removeCable(plan.packlist_id, f);
    return planActions.removeCable(plan.id, f.id);
   });
  }
 };

 const removeCableConnection = (gearItem, position) => {
  removeCables(gearItem, position);
 };

 const removeGearItem = (gearId) => {
  takeSnapshot();
  //  Filter lines and remove any connected lines
  removeCables(gearId, null);

  // const packlistItem = packlist[plan.packlist_id].items.filter(
  //  (i) => i.id === gearId
  // );

  // if (packlistItem.length > 0) {
  //  firestore
  //   .collection("packlists")
  //   .doc(plan.packlist_id)
  //   .update({ items: firestore.FieldValue.arrayRemove(packlistItem[0]) });
  // }

  //  Remove gear item from redux
  planActions.removeItem(plan.id, gearId);

  return setRemovingItem("");
 };

 const duplicateGearItem = (item) => {
  takeSnapshot();

  let itemToAdd = {
   ...item,
   id: randomatic("Aa0", 20),
   location: {
    x: item.location.x + item.width + 10,
    y: item.location.y,
   },
   packed: false,
  };

  return planActions.addItem(plan.id, itemToAdd);
  // planActions.removeItem(plan.id, gearId);

  // return setRemovingItem("");
 };

 const zoomToElement = useCallback((element) => {
  panZoomRef?.current?.zoomToElement(element)
 }, [panZoomRef])

 const zoneDropdownOptions = useMemo(()=> {
  if(!plan?.zones) return []
  return plan.zones.map((zone, i) => {
    let zoneName = `New zone ${i + 1}`
    if (zone.content) {
      zoneName = EditorState.createWithContent(
        convertFromRaw(JSON.parse(zone?.content || ''))
       ).getCurrentContent().getPlainText()
    }
    return {
      title: `Fit zone: ${zoneName}`,
      to: "/that",
      icon: "fit",
      func: function () {
        const el = document.getElementById(`zone_${zone.id}`)
        return zoomToElement(el);
      },
     }
  })
 }, [panZoomRef, plan?.zones])

 const replaceGearItem = (newItem) => {
  // WHAT TO REPLACE IT WITH
  // console.log(`Item to replace ${itemToReplace.id}`);
  // console.log(`New item ${newItem.id}`);

  const newItemWithLocation = {
   ...newItem,
   location: {
    x: itemToReplace.location.x + itemToReplace.width + 20,
    y: itemToReplace.location.y,
   },
  };

  planActions.addItem(plan.id, newItemWithLocation);
  // packlistActions.addPacklistItem(plan.packlist_id, newItemWithLocation);

  let failedRepatches = [];

  // CHECK EACH INPUT TO SEE IF IT MATCHES
  plan.cables.filter((c) => {
   if (c.toItem === itemToReplace.id) {
    // CAN THE CABLE BE PATCHED?

    if (newItem.inputs.length < c.toPosition) return failedRepatches.push(c);

    if (
     c.fromType
      ? c.fromType === newItem.inputs[c.toPosition - 1].type
      : c.type === newItem.inputs[c.toPosition - 1].type
    ) {
     return planActions.repatchCable(plan.id, c.id, "toItem", newItem.id);
    }
   } else {
    return null;
   }
  });

  // CHECK EACH OUTPUT TO SEE IF IT MATCHES
  plan.cables.filter((c) => {
   if (c.fromItem === itemToReplace.id) {
    // CAN THE CABLE BE PATCHED?
    if (newItem.outputs.length < c.fromPosition) return failedRepatches.push(c);
    if (
     c.toType
      ? c.toType === newItem.outputs[c.fromPosition - 1].type
      : c.type === newItem.outputs[c.fromPosition - 1].type
    ) {
     return planActions.repatchCable(plan.id, c.id, "fromItem", newItem.id);
    }
   } else {
    return null;
   }
  });

  if (failedRepatches.length > 0) {
   setTimeout(() => {
    notificationsActions.addNotification(
     `Repatch issue!`,
     `Unable to repatch ${failedRepatches.length} cable(s).`,
     `fail`,
     5000
    );
    // alert(`Unable to repatch ${failedRepatches.length} cable(s)`);
   }, 1000);
  }
  // ADD TO PACKLIST AND REMOVE OLD ITEM
  // ADD NEW ITEM AT RIGHT LOCATION
  // SAVE
  // INFORM OF ANY REMOVED CABLED
  // //  Filter lines and remove any connected lines
  // removeCables(gearId, null);
  // const packlistItem = packlist[plan.packlist_id].items.filter(
  //  (i) => i.id === gearId
  // );
  // firestore
  //  .collection("packlists")
  //  .doc(plan.packlist_id)
  //  .update({ items: firestore.FieldValue.arrayRemove(packlistItem[0]) });
  // //  Remove gear item from redux
  // planActions.removeItem(plan.id, gearId);
  // return setRemovingItem("");
 };

 const getCableColour = useCallback(
  (cable) => {
   const cableColour = cableTypes.filter((c) => {
    if (c.name === cable) {
     return c;
    }
   });
   if (cableColour.length >= 1) {
    return cableColour[0].colour;
   } else {
    return "#b6b6b6";
   }
  },
  [cableTypes]
 );

 const cableTypeDefs = useMemo(
  () => (
   <CableTypeDefs cableTypes={cableTypes} getCableColour={getCableColour} />
  ),
  [cableTypes, getCableColour]
 );

 const getCableStyle = useCallback(
  (cable) => {
   const cableStyle = cableTypes.filter((c) => {
    if (c.name === cable?.type) {
     return c;
    } else return null;
   });
   if (cableStyle.length >= 1) {
    return cableStyle[0].style;
   } else {
    console.log("No cable style found for", cable);
    return "solid";
   }
  },
  [cableTypes]
 );

 const savePlan = async () => {
  notificationsActions.addNotification(
   "Saving....",
   "Saving your plan to the cloud.",
   "warn"
  );
  return planActions.savePlan(plan.id, true);
 };

 const handleAddGearToLibrary = async (gearItem) => {
  await firestore
   .collection("items")
   .add({
    ...gearItem,
    added: new Date(),
    uid: user.uid,
    _checked: false,
    _approved: false,
    _private: true,
   })
   .then((res) => {
    console.log(`New item added:`, res.id);
    firestore.collection("items").doc(res.id).update({
     id: res.id,
    });

    notificationsActions.addNotification(
     `Added to My Gear!`,
     `${gearItem.name} has been added to your gear library.`,
     "success",
     5000
    );
   })
   .catch((error) => {
    //  HANDLE ERROR
   });
 };

 const onGearItemMouseEnter = (gearItem, index, inOrOut) => {
  console.log("onGearItemMouseEnter");
  let connectedCables = [];

  if (inOrOut === "input") {
   connectedCables = plan.cables.filter(
    (c) => c.toItem === gearItem && c.toPosition === index
   );
  } else {
   connectedCables = plan.cables.filter(
    (c) => c.fromItem === gearItem && c.fromPosition === index
   );
  }

  let connected = [];
  connectedCables.map((cable) => {
   connected.push(cable.fromItem);
   connected.push(cable.toItem);
   return connected.push(cable.id);
  });

  setConnectedDevicesAndCables([...connected]);
 };

 //  const onCableMouseEnter = (cable) => {
 //   console.log("onCableMouseEnter");
 //   // setConnectedDevicesAndCables([cable.fromItem, cable.toItem, cable.id]);
 //  };

 const onCableMouseEnter = useCallback((cable) => {
  console.log("onCableMouseEnter");
  setConnectedDevicesAndCables([cable.fromItem, cable.toItem, cable.id]);
 }, []);

 const onStart = (e, data) => {
  e.preventDefault();
  takeSnapshot();

  setDraggedItem(e.target.id);
  setDragStart({
   x: data.x,
   y: data.y,
  });
  setDragStartSelected(plan.items.filter((i) => selectedItems?.includes(i.id)));

  const connectedCables = plan.cables.filter(
   (c) => c.fromItem === e.target.id || c.toItem === e.target.id
  );

  let connected = [];
  connectedCables.map((cable) => {
   connected.push(cable.fromItem);
   connected.push(cable.toItem);
   return connected.push(cable.id);
  });

  if (selectedItems.length > 1) return;
  setConnectedDevicesAndCables([e.target.id, ...connected]);
 };

 const onDrag = (e, data) => {
  e.preventDefault();
  // FOR BETTER GRAPHICS
  // THIS SHOULD BE OFF

  let deltaX = data.x - dragStart.x;
  let deltaY = data.y - dragStart.y;

  if (selectedItems.length > 1) {
   dragStartSelected.map((item) => {
    if (draggedItem === item.id) {
     planActions.moveItem(plan.id, draggedItem, {
      x: data.x,
      y: data.y,
     });
    } else {
     planActions.moveItem(plan.id, item.id, {
      x: deltaX + item.location.x,
      y: deltaY + item.location.y,
     });
    }
   });
  } else {
   planActions.moveItem(plan.id, draggedItem, {
    x: data.x,
    y: data.y,
   });
  }
 };

 const onStop = (e, data) => {
  e.preventDefault();
  // planActions.moveItem(plan.id, draggedItem, {
  //  x: (data.x / gridSize) * gridSize,
  //  y: (data.y / gridSize) * gridSize,
  // });
  setDraggedItem();
  setConnectedDevicesAndCables([]);

  handleUpdateOutline();
  planActions.savePlan(plan.id);
 };

 const handleCableConnectionStart = (
  id,
  position,
  type,
  e,
  flip = false,
  inOrOut
 ) => {
  takeSnapshot();
  // ALREADY CONNECTED
  const filtered = plan.cables.filter((cable) => {
   if (type === "shape") return null;
   if (flip) return cable.toItem === id && cable.toPosition === position;
   return cable.fromItem === id && cable.fromPosition === position;
  });
  if (filtered.length >= 1) {
   planActions.removeCable(plan.id, filtered[0].id);
   //  return alert("Looks like this is already connected.");
  }

  setConnection(type);
  setConnectionStart({ id: id, position: position, type: type, inOrOut });
  setDrawing(true);

  
  const x = (e.clientX - panZoomState.positionX) / panZoomState.scale
  
  const y = ((e.clientY + (20 * panZoomState.scale)) - panZoomState.positionY) / panZoomState.scale

  setMouseStart({x,y});
  setMouseEnd({x,y});
 };

 const handleAddCableLabel = useCallback(
  (cableId) => {
   planActions.updateCable(plan.id, cableId, {
    label: "label",
    length: "5m",
   });

   setTimeout(() => {
    document.getElementById(`cableTextLabel_${cableId}`).focus();
    document.getElementById(`cableTextLabel_${cableId}`).select();
   }, 500);
  },
  [plan?.id]
 );

 const handleAddShadowItem = (start, mouseEndPosition) => {
  // console.log("should create shadow", start);

  const newGearItemId = randomatic("Aa0", 20);
  // Create Gear Item
  planActions.addItem(params.id, {
   ...shadowGearItem,
   location: {
    x: mouseEndPosition.x - 25,
    y: mouseEndPosition.y - (start.inOrOut === "input" ? 90 : 0),
   },
   id: newGearItemId,
   packed: false,
   inputs:
    start.inOrOut === "output"
     ? [{ name: `${start.type} in`, type: start.type }]
     : [],
   outputs:
    start.inOrOut === "input"
     ? [{ name: `${start.type} in`, type: start.type }]
     : [],
   name: "New Item",
  });

  const fromItemId = start.inOrOut === "output" ? start.id : newGearItemId;
  const fromItemPosition = start.inOrOut === "output" ? start.position : 1;
  const toItemId = start.inOrOut === "output" ? newGearItemId : start.id;
  const toItemPosition = start.inOrOut === "output" ? 1 : start.position;

  // Create cable between the two
  return planActions.addCable(params.id, {
   fromItem: fromItemId,
   fromPosition: fromItemPosition,
   fromType: start.type,
   id: randomatic("Aa0", 20),
   packed: false,
   toItem: toItemId,
   toPosition: toItemPosition,
   toType: start.type,
   type: start.type,
  });
 };

 const handleCableConnectionEnd = (
  id,
  position,
  type,
  e,
  flip = false,
  inOrOut
 ) => {
  if (!drawing || !id || !position || !type) {
   if (altModifier) {
    handleAddShadowItem(connectionStart, mouseEnd);
   }
   return setDrawing(false);
  }

  console.log("handleCableConnectionEnd");
  let connectionEnd = { id, position, type, inOrOut };

  let start = flip ? connectionEnd : connectionStart;
  let end = flip ? connectionStart : connectionEnd;

  if (start.inOrOut === end.inOrOut) {
   setDrawing(false);

   if (start.id === end.id) return null;
   return notificationsActions.addNotification(
    `Connection failed!`,
    `You can't connect an ${inOrOut} to an ${inOrOut}.`,
    `warn`,
    5000
   );
  }

  // Already connected end point
  const filtered = plan.cables.filter((cable) => {
   if (cable.toType === "shape" || cable.fromType === "shape") return false;
   return (
    (cable.toItem === end.id && cable.toPosition === parseInt(end.position)) ||
    (cable.fromItem === start.id &&
     cable.fromPosition === parseInt(start.position))
   );
  });
  if (filtered.length >= 1) {
   setDrawing(false);
   setMouseStart({ x: 0, y: 0 });
   setMouseEnd({ x: 0, y: 0 });
   setAltModifier(false);
   return notificationsActions.addNotification(
    `Already connected!`,
    `Try removing the previous cable first.`,
    `warn`,
    5000
   );
   //  return alert("Looks like this is already connected.");
  }

  if (
   type === "shape" ||
   start.type === "shape" ||
   cableTypesDefault
    .filter((t) => t.name === end.type)[0]
    .connectsTo?.includes(start.type) === true
  ) {
   let cableId = randomatic("Aa0", 20);
   let cable = {
    fromItem: start.id,
    fromPosition: parseInt(start.position),
    toItem: end.id,
    toPosition: parseInt(end.position),
    id: cableId,
    fromType: start.type,
    toType: end.type,
    type: end.type,
   };

   planActions.addCable(plan.id, cable);
   //  console.log("After add", cable);

   if (altModifier !== false) {
    handleAddCableLabel(cableId);
   }
   //  packlistActions.addCable(plan.packlist_id, cable);
  } else if (type === undefined) {
   console.log("Drawing cancelled");
  } else {
   notificationsActions.addNotification(
    `Connection issue!`,
    `You can't connect ${connection} to ${type}.`,
    `fail`,
    5000
   );
   //  alert(`Oops... You can't connect ${connection} to ${type}.`);
  }

  setConnection();
  setConnectionStart();
  setDrawing(false);
  setMouseStart({ x: 0, y: 0 });
  setMouseEnd({ x: 0, y: 0 });
 };

 //  const preventPan = (e, x, y) => {
 //   if (e.target.id !== "panZoom") {
 //    return true;
 //   }
 //  };

 const handleTextLabelOnStop = (id, drag) => {
  planActions.updateTextLabel(plan.id, id, {
   location: {
    x: drag.x,
    y: drag.y,
   },
  });
  return handleUpdateOutline();
 };

 const handleUpdateOutline = async (
  items = plan?.items || [],
  textLabels = plan?.textLabels || [],
  zones = plan?.zones || []
 ) => {
  let top = 1;
  let bottom = -1;
  let left = 1;
  let right = -1;

  [...items, ...textLabels, ...zones].forEach((i) => {
   let itemTop = i.location.y;
   let itemLeft = i.location.x;
   let itemRight = i.location.x + (i.width || i.size?.width);
   let itemBottom = i.location.y + (i.height || i.size?.height);

   if (itemTop < top) top = itemTop;
   if (itemLeft < left) left = itemLeft;
   if (itemRight > right) right = itemRight;
   if (itemBottom > bottom) bottom = itemBottom;
  });

  setOutline({
   x: left,
   y: top,
   w: right - left,
   h: bottom - top,
  });

  // return panZoomRef?.current?.zoomToElement(outlineRef?.current);
 };

 const handleZoneOnStop = (id, drag) => {
  planActions.updateZone(plan.id, id, {
   location: {
    x: drag.x,
    y: drag.y,
   },
  });
 };

 const handleSetPanZoomStateAndPlaceItem = (ref) => {
    const w = document?.body?.clientWidth;
    const h = document?.body?.clientHeight;
    const centerX = w / 2;
    const centerY = h / 2;

    setPanZoomState({...ref?.state, w, h});

    setPlaceItemLocation({
      x: (-ref.state.positionX + centerX) / ref.state.scale,
      y: (-ref.state.positionY + centerY) / ref.state.scale
    });

 }

 const handleSelectAll = () => {
  let array = [];

  plan.items.map((i) => array.push(i.id));
  return setSelectedItems(array);
 };

 const handleAddRemoveSelected = (id) => {
  // HOLDING SHIFT?

  const index = selectedItems.indexOf(id);
  if (shiftModifier) {
   // SHOULD ADD/REMOVE SINGLE ITEM
   //  console.log(id, index);
   if (index > -1) {
    let newArray = Array.from(selectedItems);
    newArray.splice(index, 1);
    setSelectedItems(newArray);
   } else {
    let newArray = Array.from(selectedItems);
    newArray.push(id);
    setSelectedItems(newArray);
   }
  } else {
   // NOT HOLDING SHIFT?
   // SHOULD CLEAR LIST OR ADD/REMOVE ITEM
   if (index > -1) {
    // setSelectedItems([]);
   } else {
    setSelectedItems([id]);
   }
  }
  // console.log(selectedItems);
 };

 const cableKeyMemo = useMemo(() => {
  const cablesInUse = [];

  plan?.cables.forEach((cable) => {
   cablesInUse.push(cable.fromType);
   cablesInUse.push(cable.toType);
  });

  return cableTypes.map((cable) => {
   if (!cablesInUse.includes(cable.name)) return null;
   return (
    <div key={cable.name} className="flex items-center space-x-2">
     <span
      // style={{
      //  backgroundColor: cable?.colour,
      // }}
      className="w-5 h-0.5 block"
     >
      <svg
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 100 100"
       preserveAspectRatio="none"
      >
       <path
        stroke={cable.colour}
        strokeDasharray={
         !cable.style || cable.style === "solid"
          ? null
          : cable.style === "dashed"
          ? "20,20"
          : "4,1"
        }
        strokeLinejoin="round"
        strokeWidth="30"
        d="M 0 0 l 150 0"
       />
      </svg>
     </span>
     <span className="text-xs w-20">{cable.displayName}</span>
    </div>
   );
  });
 }, [cableTypes, plan?.cables]);

 useEffect(() => {
  // if (plans[params.id]) {
  //  console.log("Already have plan");
  // } else {
  //  console.log("getting plan");
  //  fetchPlan();
  // }
  fetchPlan();

  return () => {
   firestore.unsetListener({ collection: "plans" });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [auth, firestore]);

 useEffect(() => {
  if (user) {
   if (!user.gearTourShown) {
    setIsTourOpen(true);
   } else {
    setIsTourOpen(false);
   }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [user]);

 useEffect(() => {
  const style = document.createElement("style");

  style.className = "cookieHider";
  style.textContent = ".CookieConsent {display: none!important;}";
  document.head.append(style);
 }, []);

//  ON LOAD zoom to fit all gear items
 const [fitted, setFitted] = useState(false)
 useEffect(() => {
  if(outlineRef?.current && !fitted && loaded) {
    setFitted(true)
    return zoomToElement(outlineRef?.current)
  }
 }, [outlineRef, fitted, zoomToElement, loaded]);

 if (plan && loaded) {
  return (
   <div
    onMouseMove={(e) => {
     if (drawing) {
      console.log('[panZoomState]', panZoomState)
      setMouseEnd({
       x: (e.clientX - panZoomState.positionX) / panZoomState.scale,
       y: (e.clientY - panZoomState.positionY) / panZoomState.scale,
      });
     }
    }}
    onMouseUp={(e) => {
     // DESELECT ALL SELECTED ITEMS
     //  console.log(e.target);
     if (
      e.target.id === "panZoomId" ||
      e.target.classList.contains("react-transform-wrapper") ||
      e.target.classList.contains("react-transform-component")
     ) {
      document?.activeElement?.blur();
      setSelectedItems([]);
     }

     if (!drawing) return null;

     if (e.target.dataset.type !== "input") return handleCableConnectionEnd();
    }}
    className="bg-gray-100 dark:bg-gray-900 h-full overflow-hidden"
    style={{
     WebkitUserSelect: "none",
     touchAction: "none",
     //  backgroundImage: `url('/img/transparent.jpg')`,
     //  backgroundSize: "200px",
    }}
   >
    <Helmet>
     <title>H2R Gear - {plan.name}</title>
    </Helmet>
    <div
     className={`pb-12 px-6 fixed bottom-0 right-0 z-20 flex justify-between w-screen items-end pointer-events-none
    `}
    >
     <div className="mb-4 flex flex-col-reverse sm:flex-row space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 justify-end items-baseline sm:items-end">
      <Button
       icon="zoomout"
       colour="white"
       onClick={() => {
        panZoomRef.current.zoomOut(0.1);
       }}
      />
      <Button
       icon="zoomin"
       colour="white"
       onClick={() => {
        panZoomRef.current.zoomIn(0.1);
       }}
      />
      <Button
       icon="fit"
       text="Fit all"
       buttonTitle="Zoom to fit all"
       colour="white"
       onClick={(e) => {
        return panZoomRef?.current?.zoomToElement(outlineRef?.current);
       }}
       dropdown={plan.zones}
       dropdownDirection={'up'}
       dropdownOptions={[
        ...zoneDropdownOptions
       ]}
      />

      {auth.uid === plan.author_uid ||
      (user.partOfTeam === plan.author_uid &&
       plan._sharing?.includes("team")) ? (
       <>
        <Button
         text="Undo"
         icon="undo"
         size="small"
         colour="white"
         disabled={canUndo}
         onClick={undo}
        />
        <Button
         text="Redo"
         icon="redo"
         size="small"
         colour="white"
         disabled={canRedo}
         onClick={redo}
        />
        {selectedItems.length > 1 && (
         <>
          <Button
           text="Align to top"
           icon="align-top"
           size="small"
           colour="white"
           onClick={() => {
            let top = 10000;
            plan?.items.forEach((item) => {
             if (!selectedItems.includes(item.id)) return null;

             let y = item.location.y;
             if (y <= top) return (top = y);

             return;
            });
            selectedItems.map((id) => {
             return planActions.moveItem(plan.id, id, { y: top });
            });

            return planActions.savePlan(plan.id);
           }}
          />
          <Button
           text="Align to left"
           icon="align-left"
           size="small"
           colour="white"
           onClick={() => {
            let left = 10000;
            plan?.items.forEach((item) => {
             if (!selectedItems.includes(item.id)) return null;

             let x = item.location.x;
             if (x <= left) return (left = x);

             return;
            });
            selectedItems.map((id) => {
             return planActions.moveItem(plan.id, id, { x: left });
            });

            return planActions.savePlan(plan.id);
           }}
          />
         </>
        )}
        {/* <div className="flex space-x-2">
         {selectedItems.length > 0 && (
          <Button
           text="Clear selection"
           colour="white"
           size="small"
           css=" mb-2"
           onClick={() => setSelectedItems([])}
          />
         )}
         {selectedItems.length < 2 && (
          <Button
           text="Select all"
           colour="white"
           size="small"
           css=" mb-2"
           onClick={handleSelectAll}
          />
         )}
        </div> */}
       </>
      ) : null}
     </div>
     {auth.uid === plan.author_uid ||
     (user.partOfTeam === plan.author_uid &&
      plan._sharing?.includes("team")) ? (
      <div className="mb-4 flex flex-col items-end">
       <Button
        text="Zone"
        icon="add"
        colour="white"
        size="small"
        css=" mb-2"
        onClick={() => {
         if (user.partOfTeam || isProPlanName(subscriptionPlanName)) {
          takeSnapshot();
          return planActions.addZone(plan.id, {
           text: "New zone",
           id: randomatic("Aa0", 20),
           location: {
            x: placeItemLocation.x - 150,
            y: placeItemLocation.y - 150,
           },
           size: {
            width: 350,
            height: 300,
           },
          });
         }

         setShowUpgradeModal("zones");
        }}
       />

       <Button
        text="Shape"
        icon="add"
        colour="white"
        size="small"
        css="mb-2"
        onClick={() => {
         console.log(subscriptionPlanName);
         if (
          user.partOfTeam ||
          subscriptionPlanName === "Personal" ||
          isProPlanName(subscriptionPlanName)
         ) {
          takeSnapshot();
          return planActions.addItem(plan.id, {
           type: "shape",
           content: JSON.stringify({
            blocks: [
             {
              key: randomatic("Aa0", 5),
              text: "New shape",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
             },
            ],
            entityMap: {},
           }),
           id: randomatic("Aa0", 20),
           location: {
            x: placeItemLocation.x,
            y: placeItemLocation.y,
           },
           width: 1,
           height: 10,
           colour:
            shapeColourOptions[
             Math.floor(Math.random() * shapeColourOptions.length)
            ],
           inputs: [{}],
           outputs: [{}],
           size: "medium",
          });
         }

         setShowUpgradeModal("shapes");
        }}
       />

       <Button
        text="Text label"
        icon="add"
        colour="white"
        size="small"
        css=" mb-2"
        onClick={() =>
         planActions.addTextLabel(plan.id, {
          text: "New text label",
          id: randomatic("Aa0", 20),
          location: {
           x: placeItemLocation.x,
           y: placeItemLocation.y,
          },
         })
        }
       />

       <div data-tour="add-gear">
        <Button
         text="Add gear"
         icon="add"
         onClick={() => setShowAddGearModal(true)}
        />
       </div>
      </div>
     ) : null}
    </div>
    <div
     //  style={{
     //   backgroundColor: `rgba(255,255,255,0.8)`,
     //  }}
     className="text-gray-900 dark:text-gray-100 backdrop-filter backdrop-blur-sm px-6 py-2 w-screen bg-white/80 dark:bg-gray-900/80 border-b-2  border-solid border-gray-200 dark:border-gray-700 z-20 fixed top-0 right-0 flex justify-between items-center overflow-visible"
    >
     <div className="font-semibold truncate">
      {plan.name}{" "}
      {plan.internalJobId && (
       <span className="px-1 py-0.5 mr-1 uppercase font-semibold text-xs text-gray-500 dark:text-gray-500 rounded bg-gray-200 dark:bg-gray-800/70">
        Job ID: {plan.internalJobId}
       </span>
      )}
      {plan._sharing?.includes("team") && (
       <span className="px-1 py-0.5 mr-1 uppercase font-semibold text-xs text-red-500 dark:text-red-600 rounded bg-red-200 dark:bg-red-900/40">
        team plan
       </span>
      )}
     </div>
     <div className="flex space-x-2">
      <Button
       text="Shortcuts"
       size="small"
       colour="white"
       onClick={() => setShowShortcutsModal(true)}
      />
     </div>
     {showShortcutsModal && (
      <ShortcutsModal onCancel={() => setShowShortcutsModal(false)} />
     )}
     {/* <FlyoutMenu /> */}
    </div>
    {plan?.cables?.length > 0 && plan?.showKey && (
     <div className="text-gray-900 dark:text-gray-100 backdrop-filter backdrop-blur-sm px-3 py-2 bg-white/80 dark:bg-gray-900/80  dark:border-gray-700 z-10 fixed top-10 right-0 flex flex-col space-y-2 justify-between items-center overflow-visible rounded-bl-md">
      {cableKeyMemo}
     </div>
    )}
    <div
     //  style={{
     //   backgroundColor: `rgba(255,255,255,0.8)`,
     //  }}
     className="px-6 py-2 w-screen backdrop-filter backdrop-blur-sm overflow-visible bg-white/80 dark:bg-gray-900/80 border-t-2 border-solid border-gray-200 dark:border-gray-700 z-20 fixed bottom-0 right-0 flex justify-between items-center"
    >
     {auth.uid === plan.author_uid ||
     (user.partOfTeam === plan.author_uid &&
      plan._sharing?.includes("team")) ? (
      <Fragment>
       <div className="p-1 w-full flex space-x-2 overflow-x-auto overflow-y-hidden sm:overflow-y-auto max-h-16  items-center">
        <Link to={plan._sharing?.includes("team") ? "/team/plans" : "/plans"}>
         <Button
          colour="white"
          size="small"
          text="← Plans"
          css="whitespace-pre"
         />
        </Link>

        {/* <Button icon="cog" colour="white" size="small" text="Settings" /> */}
        <FlyoutMenu title={`Settings`}>
         <Select
          label="Grid size"
          selected={gridSize || 1}
          name="cableStyle"
          css="w-full"
          size="small"
          options={[
           { name: 1, displayName: "1x1" },
           { name: 10, displayName: "10x10" },
           { name: 25, displayName: "25x25" },
          ]}
          onSelect={(choice) => {
           //  currentPlanActions.gridSize(parseInt(choice));
           planActions.updatePlan(params.id, { gridSize: parseInt(choice) });
          }}
         />
         <span className="text-gray-500">Orientation</span>
         <Button
          text={plan?.ltr ? "Switch: Top to Bottom" : "Switch: Left to Right"}
          icon="left-to-right"
          size="small"
          colour="white"
          onClick={() => {
           if (user.partOfTeam || isProPlanName(subscriptionPlanName)) {
            return planActions.leftToRightSwitch(
             plan.id,
             !plan?.ltr ? true : !plan?.ltr
            );
           }

           setShowUpgradeModal("leftToRight");
          }}
         />
         <InputCheckbox
          name="showkey"
          label={"Cable key"}
          checked={plan?.showKey}
          onChange={(e) => {
           return planActions.showHideCableKey(plan.id, e.target.checked);
          }}
          checkboxLabelText={plan?.showKey ? "Key showing" : "Key hidden"}
         />
        </FlyoutMenu>

        <span className="flex-1"></span>
        <Button
         text="Save"
         icon="save"
         size="small"
         colour="white"
         onClick={() => savePlan()}
        />

        <Button
         text="Export"
         colour="white"
         icon="download"
         size="small"
         onClick={() => {
          if (
           user.partOfTeam ||
           subscriptionPlanName === "Personal" ||
           isProPlanName(subscriptionPlanName)
          ) {
           return setShowExportModal(true);
          }

          setShowUpgradeModal("export");
         }}
        />
        {showExportModal && (
         <ExportPlanModal
          subscriptionPlanName={subscriptionPlanName}
          panZoomRef={printRef}
          onCancel={() => setShowExportModal(false)}
          plan={plan}
          panZoomState={panZoomState}
         />
        )}

        {showUpgradeModal && (
         <UpgradeModal
          onCancel={() => setShowUpgradeModal(false)}
          feature={showUpgradeModal}
          plan={plan}
         />
        )}

        <Button
         text="Share"
         colour="white"
         size="small"
         icon="share"
         onClick={() => {
          if (
           user.partOfTeam ||
           subscriptionPlanName === "Personal" ||
           isProPlanName(subscriptionPlanName)
          ) {
           return setShowShareModal(true);
          }

          setShowUpgradeModal("share");
         }}
        />
        {showShareModal && (
         <SharePlanModal
          subscriptionPlanName={subscriptionPlanName}
          onCancel={() => setShowShareModal(false)}
          plan={plan}
         />
        )}

        <Button
         text="Patchlist"
         colour={!showPatchlist && "white"}
         icon="patchlist"
         size="small"
         onClick={() => {
          if (user.partOfTeam || isProPlanName(subscriptionPlanName)) {
           return setShowPatchlist(!showPatchlist);
          }

          setShowUpgradeModal("patchlist");
         }}
        />
        <Button
         text="Packlist"
         colour="white"
         size="small"
         icon="packlist"
         onClick={() => setShowPacklist(true)}
        />

        {showAddGearModal && (
         <AddGearModal
          onCancel={(selectedItem) => {
           setShowAddGearModal(false);
           if (selectedItem) {
            setSelectedItems([selectedItem]);
           }
          }}
          planId={plan.id}
          placeItemLocation={placeItemLocation}
         />
        )}
        {showReplaceGearModal && (
         <AddGearModal
          onCancel={() => {
           setItemToReplace(null);
           setShowReplaceGearModal(false);
          }}
          replace={true}
          itemToReplace={itemToReplace}
          replaceGearItem={(item) => {
           setShowReplaceGearModal(false);
           replaceGearItem(item);
          }}
          planId={plan.id}
          placeItemLocation={placeItemLocation}
         />
        )}
        {/* {showBuilderModal && (
         <BuildGearModal
          onCancel={() => setShowBuilderModal(false)}
          planId={plan.id}
         />
        )} */}
        {removingItem !== "" && (
         //  <EditGearModal
         //   planId={plan.id}
         //   item={removingItem}
         //   onCancel={() => setRemovingItem("")}
         //   onRemove={(id) => {
         //    removeGearItem(id);
         //   }}
         //   onReplace={(id) => {
         //    setItemToReplace(id);
         //    setRemovingItem("");
         //    setShowReplaceGearModal(true);
         //   }}
         //  />
         <BuildGearModal
          item={removingItem}
          onCancel={() => {
           setRemovingItem("");
           setShowBuilderModal(false);
          }}
          planId={plan.id}
          cables={plan.cables}
         />
        )}
       </div>
       {!user.planTourShown && (
        <Tour
         steps={steps}
         isOpen={isTourOpen}
         onRequestClose={() => {
          setTourToShown();
          setIsTourOpen(false);
         }}
         rounded={5}
         maskSpace={30}
         accentColor="#f56565"
         lastStepNextButton={<Button text="DONE" />}
        />
       )}
      </Fragment>
     ) : (
      <div className="flex justify-between items-center py-1 text-gray-500 text-sm w-full">
       <div className="flex items-center">
        <p
         className="bg-gray-500 px-2 py-1 rounded text-white uppercase whitespace-nowrap
        "
        >
         Read only
        </p>
        {/* <p className="ml-4 text-gray-800 text-lg">{plan.name} </p> */}
       </div>

       <div className="flex items-center justify-end">
        <span className="text-center">Made with H2R Gear</span>
        <a
         className="ml-2 bg-red-400 px-2 py-1 rounded text-white hover:bg-red-500 whitespace-nowrap"
         href="https://h2rgear.com/signup"
        >
         Create account →
        </a>
       </div>
      </div>
     )}
    </div>
    {packlistMemo}
    {patchlistMemo}
    <div
     id="print-ref"
     ref={printRef}
     className="cursor-pointer pointer-events-auto"
    >
     {/* PRINTABLE KEY MEMO */}
     {showExportModal && (
      <div className="text-gray-900 dark:text-gray-100 backdrop-filter backdrop-blur-sm px-3 py-2 bg-white/80 dark:bg-gray-900/80  dark:border-gray-700 z-10 fixed top-0 right-0 flex flex-col space-y-2 justify-between items-center overflow-visible rounded-bl-md">
       {cableKeyMemo}
      </div>
     )}
     {/* <PanZoom
      ref={panZoomRef}
      id="panZoom"
      className="h-screen focus:outline-none transition-all"
      style={
       {
        // backgroundImage: `url('/img/dots.png')`,
        // backgroundSize: (panZoomState?.scale * gridSize) / 1,
        // backgroundPosition: `${panZoomState.positionX}% ${panZoomState.positionY}%`,
       }
      }
      preventPan={preventPan}
      autoCenter={true}
      autoCenterZoomLevel={1}
      //  disableScrollZoom={true}
      disableDoubleClickZoom={true}
      zoomSpeed={0.8}
      minZoom={0.1}
      maxZoom={2}
      disableKeyInteraction={true}
      //  keyMapping={{
      //   83: { x: 0, y: -1, z: 0 },
      //   87: { x: 0, y: 1, z: 0 },
      //   68: { x: -1, y: 0, z: 0 },
      //   65: { x: 1, y: 0, z: 0 },
      //   73: { x: 0, y: 0, z: -20 },
      //   79: { x: 0, y: 0, z: 20 },
      //  }}

      onStateChange={(change) => {
       if (
        panZoomRef.current &&
        panZoomRef.current.getContainerBoundingRect()
       ) {
        const refRect = panZoomRef.current.getContainerBoundingRect();
        setPlaceItemLocation({
         x: refRect.width / 2 - change.x,
         y: refRect.height / 2 - change.y,
        });
        setPanZoomState(change);
        console.log(change);
       }
      }}
     > */}

     <KeyboardEventHandler
      handleKeys={["esc"]}
      onKeyEvent={(key, e) => {
       if (key === "esc" && drawing) {
        setDrawing(false);
       }
      }}
     />
     <KeyboardEventHandler
      handleKeys={["alt"]}
      onKeyEvent={(key, e) => {
       if (key === "alt") {
        setAltModifier(true);
       }
      }}
     />
     <KeyboardEventHandler
      handleKeys={["alt"]}
      handleEventType="keyup"
      onKeyEvent={(key, e) => {
       if (key === "alt") {
        setAltModifier(false);
       }
      }}
     />
     <KeyboardEventHandler
      handleKeys={["shift"]}
      onKeyEvent={(key, e) => {
       if (key === "shift") {
        setShiftModifier(true);
       }
      }}
     />
     <KeyboardEventHandler
      handleKeys={["shift"]}
      handleEventType="keyup"
      onKeyEvent={(key, e) => {
       if (key === "shift") {
        setShiftModifier(false);
       }
      }}
     />
     <KeyboardEventHandler
      handleKeys={["i", "shift+i"]}
      handleEventType="keyup"
      onKeyEvent={(key, e) => {
       panZoomRef.current.zoomIn(key === "shift+i" ? 0.5 : 0.1);
      }}
     />
     <KeyboardEventHandler
      handleKeys={["o", "shift+o"]}
      handleEventType="keyup"
      onKeyEvent={(key, e) => {
       panZoomRef.current.zoomOut(key === "shift+o" ? 0.5 : 0.1);
      }}
     />

     <KeyboardEventHandler
      handleKeys={["w", "shift+w", "up", "shift+up"]}
      handleEventType="keyup"
      onKeyEvent={(key, e) => {
       //  panZoomRef.current.moveByRatio(0, key?.includes("shift") ? 10 : 1);
       panZoomRef.current.setTransform(
        panZoomState.positionX,
        panZoomState.positionY + (key?.includes("shift") ? 100 : 20),
        panZoomState.scale,
        100,
        "easeOut"
       );
      }}
     />
     <KeyboardEventHandler
      handleKeys={["s", "shift+s", "down", "shift+down"]}
      handleEventType="keyup"
      onKeyEvent={(key, e) => {
       panZoomRef.current.setTransform(
        panZoomState.positionX,
        panZoomState.positionY - (key?.includes("shift") ? 100 : 20),
        panZoomState.scale,
        100,
        "easeOut"
       );
      }}
     />
     <KeyboardEventHandler
      handleKeys={["a", "shift+a", "left", "shift+left"]}
      handleEventType="keyup"
      onKeyEvent={(key, e) => {
       panZoomRef.current.setTransform(
        panZoomState.positionX + (key?.includes("shift") ? 100 : 20),
        panZoomState.positionY,
        panZoomState.scale,
        100,
        "easeOut"
       );
      }}
     />
     <KeyboardEventHandler
      handleKeys={["d", "shift+d", "right", "shift+right"]}
      handleEventType="keyup"
      onKeyEvent={(key, e) => {
       panZoomRef.current.setTransform(
        panZoomState.positionX - (key?.includes("shift") ? 100 : 20),
        panZoomState.positionY,
        panZoomState.scale,
        100,
        "easeOut"
       );
      }}
     />
     <KeyboardEventHandler
      handleKeys={["meta + a", "ctrl + a"]}
      onKeyEvent={(key, e) => {
       handleSelectAll();
      }}
     />
     <KeyboardEventHandler
      handleKeys={["c"]}
      onKeyEvent={(key, e) => {
       setSelectedItems([]);
      }}
     />
     <KeyboardEventHandler
      handleKeys={["meta + c", "ctrl + c"]}
      onKeyEvent={(key, e) => {
       if (selectedItems.length > 0) {
        setCopiedItems(selectedItems);
       }
      }}
     />
     <KeyboardEventHandler
      handleKeys={["meta + v", "ctrl + v"]}
      onKeyEvent={(key, e) => {
       if (copiedItems.length > 0) {
        return copiedItems.map((item) => {
         let itm = plan.items.filter((i) => i.id === item);
         if (itm.length < 1)
          return notificationsActions.addNotification(
           "Duplication failed",
           "Item could not be duplicated as it cannot be found.",
           "fail"
          );
         duplicateGearItem(itm[0]);
        });
       }
      }}
     />
     <KeyboardEventHandler
      handleKeys={["delete", "backspace"]}
      onKeyEvent={(key, e) => {
       if (selectedItems.length > 0) {
        selectedItems.map((item) => {
         return removeGearItem(item);
        });
       }
      }}
     />
     {/* MODALS */}
     <KeyboardEventHandler
      handleKeys={["g"]}
      onKeyEvent={(key, e) => {
       e.preventDefault();
       setShowAddGearModal(true);
      }}
     />
     <KeyboardEventHandler
      handleKeys={["p"]}
      onKeyEvent={(key, e) => {
       e.preventDefault();
       setShowPacklist(true);
      }}
     />
     <KeyboardEventHandler
      handleKeys={["f"]}
      onKeyEvent={(key, e) => {
       e.preventDefault();
       return panZoomRef?.current?.zoomToElement(outlineRef?.current);
      }}
     />

     {/* ACTIONS */}
     <KeyboardEventHandler
      handleKeys={["t"]}
      onKeyEvent={(key, e) => {
       planActions.addTextLabel(plan.id, {
        text: "New text label",
        id: randomatic("Aa0", 20),
        location: {
         x: placeItemLocation.x,
         y: placeItemLocation.y,
        },
       });
      }}
     />
     <KeyboardEventHandler
      handleKeys={["z"]}
      onKeyEvent={(key, e) => {
       if (user.partOfTeam || isProPlanName(subscriptionPlanName)) {
        return planActions.addZone(plan.id, {
         text: "New zone",
         id: randomatic("Aa0", 20),
         location: {
          x: placeItemLocation.x - 150,
          y: placeItemLocation.y - 150,
         },
         size: {
          width: 300,
          height: 300,
         },
        });
       }
       
       setShowUpgradeModal("zones");
      }}
      />

     <TransformWrapper
      ref={panZoomRef}
      initialScale={1}
      wheel={{ step: 0.03 }}
      limitToBounds={false}
      minScale={0.1}
      maxScale={2}
      disablePadding={true}
      doubleClick={{ disabled: true }}
      panning={{
       excluded: [
        "noPan",
        "handle",
        "gearItem",
        "gearItem_drag",
        "gearItem_drag_name",
        "gearItem_drag_manufacturer",
        "input",
        "input2",
        "output",
        "DraftEditor-root",
        "DraftEditor-editorContainer",
        "public-DraftStyleDefault-block",
        "public-DraftStyleDefault-ltr",
        "span",
        // "button",
       ],
       
      }}
      onPanningStop={(ref) => {
       handleSetPanZoomStateAndPlaceItem(ref)
      }}
      onZoomStop={(ref) => {
       handleSetPanZoomStateAndPlaceItem(ref)
      }}

      // onTransformed={(ref, { scale, positionX, positionY }) => {
      //  console.log(scale, positionX, positionY);
      // }}
     >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
       <React.Fragment>
        <TransformComponent>
         <div
          id="panZoomId"
          className="h-screen w-screen pointer-events-auto"
          // style={{ transform: "translateX(50%) translateY(50%)" }}
         >
          {/* CENTER MARKER HIDDEN */}
          {/* <div className="pointer-events-none absolute w-5 h-5 bg-blue-500" style={{ left: placeItemLocation.x, top: placeItemLocation.y}}></div> */}

        <div 
            id="drawing_line"
            className="z-[9] pointer-events-none absolute top-0 left-0 right-0 bottom-0 w-scree h-screen"
            // style={{ transform: "translateX(-50%) translateY(-50%)"} }
            >
            <svg
             className="text-gray-500 bg-transparent"
             stroke="currentColor"
             
             style={{
              overflow: "visible",
              zIndex: 0,
              strokeDasharray: 10,
              animation: "dash 1s linear infinite",
             }}
            >
             {drawing && (
              <polyline
               points={`${mouseStart?.x},${mouseStart?.y - 20} ${mouseEnd?.x},${
                mouseEnd?.y - 5
               }`}
               style={{ strokeWidth: "2" }}
              />
             )}
            </svg>
            {altModifier && drawing && (
             <GearItem
              gearItem={{
               ...shadowGearItem,
               inputs:
                connectionStart.inOrOut === "output"
                 ? [{ name: connectionStart.type }]
                 : [],
               outputs:
                connectionStart.inOrOut === "input"
                 ? [{ name: connectionStart.type }]
                 : [],
              }}
              opacity={0.3}
              x={mouseEnd.x - 25}
              y={mouseEnd.y - (connectionStart.inOrOut === "input" ? 90 : 0)}
             />
            )}
        </div>


          <div className="relative z-[8] h-auto w-auto">
           {plan.zones &&
            plan.zones.map((zone) => {
             return (
              <Zone
               key={zone.id}
               planId={plan.id}
               zone={zone}
               x={zone.location.x}
               y={zone.location.y}
               scale={panZoomState.scale}
               onStop={(id, drag) => handleZoneOnStop(id, drag)}
               opacity={connectedDevicesAndCables.length === 0 ? "1" : ".1"}
               takeSnapshot={takeSnapshot}
               readOnly={
                auth.uid === plan.author_uid ||
                (user.partOfTeam === plan.author_uid &&
                 plan._sharing?.includes("team"))
                 ? false
                 : true
               }
               addRemoveSelected={handleAddRemoveSelected}
               selected={selectedItems?.includes(zone.id)}
               zoomToElement={zoomToElement}
              />
             );
            })}

           {/* <SketchItem
        item={{
         id: "ABDCDD",
         colour: "red",
         location: { x: 0, y: 0 },
         size: "large",
        }}
        onDrag={null}
        onStop={null}
        onStart={null}
       /> */}

           {/* RECTANGLE OUTLINE */}

           <div
            ref={outlineRef}
            style={{
             width: `${outline?.w + 200}px`,
             height: `${outline?.h + 200}px`,
             top: `${outline?.y - 100}px`,
             left: `${outline?.x - 100}px`,
            }}
            className="absolute inset-0 pointer-events-none"
           ></div>

           

           <svg
            style={{
             position: "absolute",
             overflow: "visible",
             top: 0,
             left: 0,
             zIndex: -1,
            }}
           >
            <defs>{cableTypeDefs}</defs>
           </svg>

           {plan.items.map((item) => {
            if (item.type === "shape")
             return (
              <SketchItem
               key={item.id}
               planId={plan?.id}
               item={item}
               onDrag={onDrag}
               onStop={onStop}
               onStart={onStart}
               scale={panZoomState.scale}
               handleCableConnectionStart={handleCableConnectionStart}
               handleCableConnectionEnd={handleCableConnectionEnd}
               updateItem={(id, update) => {
                console.log(id, update);
                return planActions.editItem(plan.id, id, update);
               }}
               onDrawing={() => {}}
               disabled={
                !(
                 auth.uid === plan.author_uid ||
                 (user.partOfTeam === plan.author_uid &&
                  plan._sharing?.includes("team"))
                )
               }
               drawing={drawing}
               selected={selectedItems?.includes(item.id)}
               addRemoveSelected={handleAddRemoveSelected}
               opacity={
                connectedDevicesAndCables.length === 0
                 ? "1"
                 : connectedDevicesAndCables?.includes(item.id)
                 ? "1"
                 : ".1"
               }
               showExportModal={showExportModal}
               deleteGearItem={() => removeGearItem(item.id)}
              />
             );
            if (
             auth.uid === plan.author_uid ||
             (user.partOfTeam === plan.author_uid &&
              plan._sharing?.includes("team"))
            ) {
             return (
              <GearItem
               id={plan.id}
               key={item.id}
               gearItem={item}
               x={item.location.x}
               y={item.location.y}
               onDrag={onDrag}
               onStop={onStop}
               onStart={onStart}
               handleCableConnectionStart={handleCableConnectionStart}
               handleCableConnectionEnd={handleCableConnectionEnd}
               removeGearItem={() => setRemovingItem(item)}
               duplicateGearItem={() => duplicateGearItem(item)}
               replaceGearItem={(id) => {
                console.log("should replace", id);
                setItemToReplace(id);
                setRemovingItem("");
                setShowReplaceGearModal(true);
               }}
               deleteGearItem={() => removeGearItem(item.id)}
               addGearToLibrary={handleAddGearToLibrary}
               removeCableConnection={removeCableConnection}
               demoMode={false}
               opacity={
                connectedDevicesAndCables.length === 0
                 ? "1"
                 : connectedDevicesAndCables?.includes(item.id)
                 ? "1"
                 : ".1"
               }
               onMouseEnter={onGearItemMouseEnter}
               onMouseLeave={() => {
                setConnectedDevicesAndCables([]);
               }}
               scale={panZoomState.scale}
               cables={plan.cables}
               selected={selectedItems?.includes(item.id)}
               addRemoveSelected={handleAddRemoveSelected}
               drawing={drawing}
               ltr={plan?.ltr}
              />
             );
            } else {
             return (
              <GearItem
               id={plan.id}
               key={item.id}
               gearItem={item}
               x={item.location.x}
               y={item.location.y}
               onDrag={onDrag}
               onStop={onStop}
               onStart={onStart}
               handleCableConnectionStart={handleCableConnectionStart}
               handleCableConnectionEnd={handleCableConnectionEnd}
               removeGearItem={() => setRemovingItem(item)}
               removeCableConnection={removeCableConnection}
               demoMode={true}
               disabled={true}
               opacity={
                connectedDevicesAndCables.length === 0
                 ? "1"
                 : connectedDevicesAndCables?.includes(item.id)
                 ? "1"
                 : ".1"
               }
               onMouseEnter={onGearItemMouseEnter}
               onMouseLeave={() => {
                setConnectedDevicesAndCables([]);
               }}
               scale={panZoomState.scale}
               cables={plan.cables}
               addRemoveSelected={() => {
                return null;
               }}
               ltr={plan?.ltr}
              />
             );
            }
           })}

           {plan?.cables?.map((cable, i) => {
            // const start = cable.fromItem;
            // const end = cable.toItem;

            // const filteredFrom = plan.items.filter((item) => item.id === start);
            // const filteredTo = plan.items.filter((item) => item.id === end);

            // if (
            //  cable.fromPosition > filteredFrom[0].outputs.length ||
            //  cable.toPosition > filteredTo[0].inputs.length
            // ) {
            //  planActions.removeCable(plan.id, cable.id);
            //  return null;
            // }

            // const cableColour = getCableColour(
            //  filteredFrom[0].type === "shape"
            //   ? "shape"
            //   : filteredFrom[0].outputs[cable.fromPosition - 1].type
            // );

            // const points = plan?.ltr
            //  ? calculateCablesLTR(filteredTo, filteredFrom, cable)
            //  : calculateCables(filteredTo, filteredFrom, cable);

            // const connectedCable =
            //  connectedDevicesAndCables.length === 0
            //   ? "1"
            //   : connectedDevicesAndCables?.includes(cable.id)
            //   ? "1"
            //   : "0.15";

            return (
             <Cable
              key={cable.id}
              plan={plan}
              cable={cable}
              panZoomState={panZoomState}
              onCableMouseEnter={onCableMouseEnter}
              connectedDevicesAndCables={connectedDevicesAndCables}
              setConnectedDevicesAndCables={setConnectedDevicesAndCables}
              getCableStyle={getCableStyle}
              getCableColour={getCableColour}
              handleAddCableLabel={handleAddCableLabel}
              takeSnapshot={takeSnapshot}
              ltr={plan.ltr}
              readOnly={
               auth.uid === plan.author_uid ||
               (user.partOfTeam === plan.author_uid &&
                plan._sharing?.includes("team"))
                ? false
                : true
              }
             />
            );
           })}
           <div className="relative">
            {plan.cables.map((cable, i) => {
              // Hide pins when cables are set to flyOff
              if(cable.flyOff && plan.ltr) return null
             return (
              <div
               className="absolute top-0 left-0"
               style={{ transform: "translate(-50%, -50%)" }}
               key={`cable_pin_${cable.id}`}
              >
               {cable.pin && cable.pin.x !== null && (
                <CablePin
                 planId={plan.id}
                 cableId={cable.id}
                 x={cable.pin.x}
                 y={cable.pin.y}
                 scale={panZoomState.scale}
                 demoMode={false}
                 takeSnapshot={takeSnapshot}
                 getCableColour={getCableColour(cable.fromType)}
                 ltr={plan.ltr}
                 readOnly={
                  auth.uid === plan.author_uid ||
                  (user.partOfTeam === plan.author_uid &&
                   plan._sharing?.includes("team"))
                   ? false
                   : true
                 }
                />
               )}
              </div>
             );
            })}
            {plan.cables.map((cable, i) => {
             return (
              <div
               className="absolute top-0 left-0"
               style={{ transform: "translate(-50%, -50%)" }}
               key={`cable_pin_2_${cable.id}`}
              >
               {cable.pin2 && cable.pin2.x !== null && (
                <CablePin
                 planId={plan.id}
                 pinNumber={2}
                 cableId={cable.id}
                 x={cable.pin2.x}
                 y={cable.pin2.y}
                 scale={panZoomState.scale}
                 demoMode={false}
                 ltr={plan.ltr}
                 takeSnapshot={takeSnapshot}
                 getCableColour={getCableColour(cable.fromType)}
                 readOnly={
                  auth.uid === plan.author_uid ||
                  (user.partOfTeam === plan.author_uid &&
                   plan._sharing?.includes("team"))
                   ? false
                   : true
                 }
                />
               )}
              </div>
             );
            })}
           </div>
           {plan.textLabels &&
            plan.textLabels.map((textLabel) => {
             return (
              <TextLabel
               key={textLabel.id}
               planId={plan.id}
               textLabel={textLabel}
               x={textLabel.location.x}
               y={textLabel.location.y}
               scale={panZoomState.scale}
               onStop={(id, drag) => handleTextLabelOnStop(id, drag)}
               opacity={connectedDevicesAndCables.length === 0 ? "1" : ".1"}
               readOnly={
                auth.uid === plan.author_uid ||
                (user.partOfTeam === plan.author_uid &&
                 plan._sharing?.includes("team"))
                 ? false
                 : true
               }
              />
             );
            })}
           {/* {plan.shapes &&
        plan.shapes.map((shape) => {
         return (
          <SketchItem
           key={shape.id}
           item={shape}
           scale={panZoomState}
           onStart={onStart}
           onDrag={(e, data) => {
            planActions.editShape(
             plan.id,
             shape.id,
             { x: data.x, y: data.y },
             false
            );
           }}
           onStop={onStop}
           updateItem={(id, update) => {
            planActions.editShape(plan.id, id, update);
           }}
           onConnectionStart={handleCableConnectionStart}
           onConnectionEnd={handleCableConnectionEnd}
          />
         );
        })} */}
          </div>
         </div>
        </TransformComponent>
       </React.Fragment>
      )}
     </TransformWrapper>
     {/* </PanZoom> */}
    </div>
   </div>
  );
 } else if (noAccess) {
  return <NotFound />;
 } else {
  return <SplashScreen />;
 }
}

const steps = [
 {
  selector: ".gearItem",
  content: (
   <div>
    <h2 className="font-semibold">This is a gear item.</h2>
    <p className="mt-3">Inputs are on the top.</p>
    <p className="mt-3">Outputs are on the bottom.</p>
    <p className="mt-3 italic text-gray-600 text-sm">
     Tip: Double-click the gear item for more options.
    </p>
   </div>
  ),
 },
 {
  selector: "[data-tour='cable']",
  content: (
   <div>
    <h2 className="font-semibold">This is a cable.</h2>
    <p className="mt-3">These are drawn from output to input.</p>
    <p className="mt-3 italic text-gray-600 text-sm">
     Tip: Double-click the input side to remove a cable.
    </p>
   </div>
  ),
 },
 {
  selector: "[data-tour='add-gear']",
  content: (
   <div>
    <h2 className="font-semibold">Add more gear.</h2>
    <p className="mt-3">You can add more gear items here.</p>
    <p className="mt-3 italic text-gray-600 text-sm">
     Tip: Build your own items and they will show up in here.
    </p>
   </div>
  ),
 },
 {
  selector: "[data-tour='more-help']",
  content: (
   <div>
    <h2 className="font-semibold">New more help?</h2>
    <p className="mt-3">
     Restart this tutorial or{" "}
     <a
      className="underline text-red-600"
      href="https://heretorecord.gitbook.io/h2r-gear/"
      target="_blank"
      rel="noopener noreferrer"
     >
      visit our full docs here
     </a>
     .
    </p>
   </div>
  ),
 },
];
