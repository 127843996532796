import React, { useState, useEffect } from "react";

import Logo from "./branding/Logo";

const phrases = [
 "Pulling cables...",
 "Finding tape...",
 "Crimping BNC connectors...",
 "Locating multi-tool...",
 "Opening rack cases...",
 "Assembling gimbal...",
 "Untangling SDI cables...",
 "Locating USB-C dongles...",
 "Checking user manual...",
 "Soldering XLR connectors...",
 "Unlocking OB van...",
];

const getRndInteger = (min, max) => {
 return Math.floor(Math.random() * (max - min)) + min;
};

function SplashScreen() {
 const [currentPhrase, setCurrentPhase] = useState(
  phrases[getRndInteger(0, phrases.length - 1)]
 );

 useEffect(() => {
  const interval = setInterval(() => {
   setCurrentPhase(phrases[getRndInteger(0, phrases.length - 1)]);
  }, 5000);

  return () => {
   clearInterval(interval);
  };
 }, []);

 return (
  <div
   style={{
    height: "100svh",
   }}
   className="flex flex-col justify-center bg-red-500 w-screen"
  >
   <div className="px-12 py-10 flex flex-col max-w-sm bg-white dark:bg-gray-900 mx-auto rounded-lg">
    <div className="flex flex-col mb-5">
     <Logo />

     <span className="mt-8 mb-10 h-1 border bg-gray-200 dark:bg-gray-700"></span>

     <span className="text-center text-gray-700 dark:text-gray-300 animate-bounce">
      {currentPhrase}
     </span>
    </div>
   </div>
  </div>
 );
}

export default SplashScreen;
