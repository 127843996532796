import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

function classNames(...classes) {
 return classes.filter(Boolean).join(" ");
}

export default function FAQs({ title = "FAQs", faqs = [] }) {
 return (
  <div className="">
   <div className="py-8 px-6 md:px-0">
    <div className="divide-y-2 divide-gray-200">
     <h2 className="text-lg font-bold mb-2">{title}</h2>
     <dl className="mt-4 space-y-6 divide-y divide-gray-200">
      {faqs.map((faq) => (
       <Disclosure as="div" key={faq.question} className=" ">
        {({ open }) => (
         <>
          <dt className="text-lg">
           <Disclosure.Button className="py-2 px-2 text-left w-full flex justify-between items-start rounded text-gray-400 focus:outline-none focus:ring">
            <span className="font-medium text-gray-900 dark:text-gray-100">
             {faq.question}
            </span>
            <span className="ml-6 h-7 flex items-center">
             <ChevronDownIcon
              className={classNames(
               open ? "-rotate-180" : "rotate-0",
               "h-6 w-6 transform"
              )}
              aria-hidden="true"
             />
            </span>
           </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2 pr-12">
           <p className="text-base text-gray-700  dark:text-gray-300">
            {faq.answer}
           </p>
          </Disclosure.Panel>
         </>
        )}
       </Disclosure>
      ))}
     </dl>
    </div>
   </div>
  </div>
 );
}
