import { useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";

import Input from "./Input";
import Button from "./Button";

import { availableCableColoursInputsOutputs, connectorTypes } from "../db/categories";
import * as builderActions from "../actions/builderActions";
import ConnectionCombobox from "./builder/ConnectionCombobox";
import { useSelector } from "react-redux";
import ColourPicker from "./ColourPicker";

export default function BuilderIO({
 io,
 i,
 type,
 disabled = false,
 originalLength,
}) {
 const moduleViewerMemo = useMemo(
  () => (
   <ModuleViewer
    i={i}
    type={type}
    disabled={disabled}
    originalLength={originalLength}
   />
  ),
  [i, type, disabled, originalLength]
 );

 return <>{moduleViewerMemo}</>;
}

const ModuleViewer = ({ i, type, disabled, originalLength }) => {
 const gearItem = useSelector(
  (state) => state.builder.emptyGearItem?.[`${type}s`]?.[i]
 );
 console.log(gearItem);
 return (
  <Draggable draggableId={`${type}-${i}`} index={i} isDragDisabled={disabled}>
   {(provided) => {
    return (
     <div
      {...provided.dragHandleProps}
      {...provided.draggableProps}
      ref={provided.innerRef}
      tabIndex="-1"
      className="flex w-full items-center"
     >
      <div className="w-3/6">
       <Input
        name={gearItem.name}
        value={gearItem.name}
        placeholder={`${type} name`}
        onChange={(e) => {
         console.log("input");
         builderActions.updateInputOutput(`${type}s`, i, {
          name: e.target.value,
         });
        }}
       />
      </div>
      <div className="ml-2 w-2/6">
       {/* <Select
        selected={io.type}
        options={connectorTypes}
        css="mt-4"
        onSelect={(change) => {
         builderActions.updateInputOutput(`${type}s`, i, {
          type: change,
          category: connectorTypes.filter((t) => t.name === change)[0].category,
         });
        }}
       /> */}
       <ConnectionCombobox
        selected={gearItem.type}
        onSelect={(change) => {
         console.log("Combo selecting");
         builderActions.updateInputOutput(`${type}s`, i, {
          type: change,
          category: connectorTypes.filter((t) => t.name === change)[0].category,
         });
        }}
       />
      </div>
      <div className="ml-2 mt-4">
        <ColourPicker
            colour={gearItem.backgroundColour || '#f4f4f4'}
            onChangeComplete={(colour) => {
            builderActions.updateInputOutput(`${type}s`, i, {
                backgroundColour: colour,
            });
            // handleCableColourChange(cable, colour);
            }}
            onClosePicker={() => {
            //  handleCableSave();
            }}
            swatchesOnly={true}
            swatchColours={availableCableColoursInputsOutputs}
        />
        </div>
      <div className="ml-2 mt-4">
       <Button
        icon="delete"
        colour="white"
        onClick={() => {
         builderActions.removeInputOutput(`${type}s`, i);
        }}
        disabled={disabled && i < originalLength}
       />
      </div>
      <div
       className={`px-2 py-2 flex justify-center items-center align-middle mt-3 ${
        disabled && i < originalLength && "opacity-10 cursor-not-allowed"
       }`}
      >
       <svg
        className={`w-5 h-5 text-gray-400 hover:text-gray-600 `}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
       >
        <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 010 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 010 2H4a1 1 0 01-1-1z"
         fill="currentColor"
        />
       </svg>
      </div>
     </div>
    );
   }}
  </Draggable>
 );
};
