import React from "react";

import Layout from "./Layout";

export default function Help() {
 return (
  <Layout title="Help">
   <div className="px-6 py-6 flex flex-row bg-red-500 md:flex-col md:px-0 md:py-0 md:bg-gray-200">
    <div className="flex items-center">
     <h1 className="text-2xl font-bold text-white md:text-gray-800">Help</h1>
    </div>
   </div>

   <div className="px-4 mt-6 md:px-0">
    <div className="mt-6">
     <div>
      <h2 className="text-lg font-bold mb-2">Getting started</h2>
      <p className="mt-2">
       Now that you are logged in, you can get started creating plans!
      </p>
      <p className="mt-2">
       Over on the homepage, you can add plans, edit their details and jump into
       the plan editor in order to add gear. You can also see at a glance how
       much you have packed for that plan.
      </p>
     </div>

     <div className="mt-6">
      <h2 className="text-lg font-bold mb-2">Plan editor</h2>
      <p className="mt-2">
       The plan editor is where you will be able to, well... make all your
       plans. Everything from adding gear, connecting cables and labeling gear
       is possible.
      </p>
      <p className="mt-2">
       Open up the demo plan in your account to get the hand of how the plan
       editor works.
      </p>
      <p className="mt-2">Here are a few important tips to help you out...</p>
      <p className="mt-2 font-bold">Gear items</p>
      <p className="mt-2">
       A gear item is a piece of gear in your plan. It represents a piece of
       equipment that you have (or plan to buy some day).
      </p>
      <p className="mt-2">
       The INPUTS of the gear item are on top and the OUTPUTS are at the bottom.
      </p>
      <p className="mt-2 font-bold">Adding gear items</p>
      <p className="mt-2">
       In a gear plan, you can add a gear item. This is done by clicking the
       "Add" button to the bottom right. This will launch a modal to add any
       item.
      </p>
      <p className="mt-2">
       Search for the item by name to see what options are currently already
       added to H2R Gear.
      </p>
      <p className="mt-2 font-bold">Double-click gear item</p>
      <p className="mt-2">
       To edit or remove a gear item, just double-click it. Then a modal will
       open with options for editing and removing that item.
      </p>
      <p className="mt-2 font-bold">Connecting cables</p>
      <p className="mt-2">
       Cables are connected from OUTPUT to INPUT. You should start the
       connection at the output of the previous gear item and connect it to an
       input.
      </p>
      <p className="mt-2 font-bold">Disconnect cables</p>
      <p className="mt-2">
       To remove a cable, double-click where it connects to an INPUT. Then it
       will vanish!
      </p>
     </div>

     <div className="mt-6">
      <h2 className="text-lg font-bold mb-2">Builder</h2>
      <p className="mt-2">
       If you cannot add the gear that you already own, feel free to create it
       with the builder.
      </p>
      <p className="mt-2">
       This will allow you to make any gear item and specify it's inputs and
       outputs.
      </p>
      <p className="mt-2">
       Keep in mind that these gear items will be available to other users, but
       will be marked as 'user submitted' until they are approved.
      </p>
     </div>

     <div className="mt-6">
      <h2 className="text-lg font-bold mb-2">Settings</h2>
      <p className="mt-2">
       The settings tab is where you can change your cable colours. Once
       updated, these will appear in all your past, present and future plans.
      </p>
     </div>
    </div>
   </div>
  </Layout>
 );
}
