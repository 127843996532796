import { cableTypes } from "../db/categories";
import { saveFirestoreDoc } from "../db/firestore";

const initialState = {
 hideAfterAdd: true,
 showOnlyMyGear: false,
 searchTab: "my-gear",
};

const packedProgress = (state) => {
 let itemsCheckedTotal = 0;
 let itemsRunningTotal = 0;

 state.items.map((item) => {
  if (item.packed) {
   itemsCheckedTotal = itemsCheckedTotal + 1;
  }
  return (itemsRunningTotal = itemsRunningTotal + 1);
 });

 let cablesCheckedTotal = 0;
 let cablesRunningTotal = 0;

 state.cables.map((cable) => {
  if (cable.packed) {
   cablesCheckedTotal = cablesCheckedTotal + 1;
  }
  return (cablesRunningTotal = cablesRunningTotal + 1);
 });

 let extrasCheckedTotal = 0;
 let extrasRunningTotal = 0;

 state.extras &&
  state.extras.map((extra) => {
   if (extra.packed) {
    extrasCheckedTotal = extrasCheckedTotal + 1;
   }
   return (extrasRunningTotal = extrasRunningTotal + 1);
  });

 const percentPacked =
  (100 * (cablesCheckedTotal + itemsCheckedTotal + extrasCheckedTotal)) /
  (itemsRunningTotal + cablesRunningTotal + extrasRunningTotal);

 return isNaN(percentPacked) ? 0 : percentPacked;
};

export default function plansReducer(state = initialState, action) {
 let plan = action.plan;
 //  let id = action.plan.id;

 switch (action.type) {
  case "ADD_PLAN":
   return {
    ...state,
    [action.plan.id]: {
     ...plan,
    },
   };

  case "ADD_PLANS":
   return {
    ...state,
    ...action.plans,
   };

  case "ARCHIVE_PLAN":
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     status: "ARCHIVED",
     _sharing: action.currentShareState === "team" ? "team" : "onlyme",
    },
   };

  case "UPDATE_PLAN":
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     ...action.update,
    },
   };

  case "SAVE_PLAN":
   return saveFirestoreDoc(action.planId, state, action.notify);

  case "ADD_ITEM": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     items: [...state[action.id].items, { ...action.item }],
    },
   };
  }

  case "REMOVE_ITEM": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     items: [
      ...state[action.id].items.filter((item) => item.id !== action.item),
     ],
    },
   };
  }

  case "EDIT_ITEM": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     items: [
      ...state[action.id].items.map((item) => {
       if (item.id !== action.itemId) {
        return item;
       } else {
        return { ...item, ...action.change };
       }
      }),
     ],
    },
   };
  }

  case "MOVE_ITEM": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     items: [
      ...state[action.id].items.map((item) => {
       if (item.id !== action.item) {
        return item;
       } else {
        return { ...item, location: { ...item.location, ...action.location } };
       }
      }),
     ],
    },
   };
  }

  case "MOVE_ITEMS": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     items: [
      ...state[action.id].items.map((item) => {
       if (item.id !== action.item) {
        return item;
       } else {
        return {
         ...item,
         location: {
          x: item.location.x - action.delta.x,
          y: item.location.y - action.delta.y,
         },
        };
       }
      }),
     ],
    },
   };
  }

  case "ADD_CABLE": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     cables: [...state[action.id].cables, { ...action.cable }],
    },
   };
  }

  case "UPDATE_CABLE": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     cables: [
      ...state[action.id].cables.map((c) => {
       if (c.id === action.cableId) {
        return { ...c, ...action.update };
       } else {
        return c;
       }
      }),
     ],
    },
   };
  }

  case "ADD_CABLE_PIN": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     cables: [
      ...state[action.id].cables.map((c) => {
       if (c.id === action.cableId) {
        return {
         ...c,
         pins: [
          ...(c.pins || []),
          {
           x: action.pin.x,
           y: action.pin.y,
          },
         ],
        };
       } else {
        return c;
       }
      }),
     ],
    },
   };
  }

  case "PACK_UNPACK_ITEM": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     items: [
      ...state[action.id].items.map((c) => {
       if (c.id === action.item) {
        return {
         ...c,
         packed: action.forceTrue ? true : c.packed ? !c.packed : true,
        };
       } else {
        return c;
       }
      }),
     ],
    },
   };
  }

  case "PACK_UNPACK_CABLE": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     cables: [
      ...state[action.id].cables.map((c) => {
       if (c.id === action.cable) {
        return {
         ...c,
         packed: action.forceTrue ? true : c.packed ? !c.packed : true,
        };
       } else {
        return c;
       }
      }),
     ],
    },
   };
  }

  case "PACK_UNPACK_ALL": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     cables: [
      ...state[action.id].cables.map((c) => {
       return {
        ...c,
        packed: action.pack,
       };
      }),
     ],
     items: [
      ...state[action.id].items.map((c) => {
       return {
        ...c,
        packed: action.pack,
       };
      }),
     ],
     extras: state[action.id].extras
      ? [
         ...state[action.id].extras.map((c) => {
          return {
           ...c,
           packed: action.pack,
          };
         }),
        ]
      : [],
    },
   };
  }

  case "REPATCH_CABLE": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     cables: [
      ...state[action.id].cables.map((c) => {
       if (c.id === action.cableId) {
        return {
         ...c,
         [action.direction]: action.newItemId,
        };
       } else {
        return c;
       }
      }),
     ],
    },
   };
  }

  case "REMOVE_CABLE": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     cables: [
      ...state[action.id].cables.filter((cable) => cable.id !== action.cable),
     ],
    },
   };
  }

  case "ADD_EXTRA": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     extras: state[action.id].extras
      ? [...state[action.id].extras, { ...action.item }]
      : [{ ...action.item }],
    },
   };
  }

  case "PACK_UNPACK_EXTRA": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     extras: [
      ...state[action.id].extras.map((c) => {
       if (c.id === action.extraId) {
        return {
         ...c,
         packed: c.packed ? !c.packed : true,
        };
       } else {
        return c;
       }
      }),
     ],
    },
   };
  }

  case "REMOVE_EXTRA": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     extras: [
      ...state[action.id].extras.filter((extra) => {
       if (extra.id !== action.extraId) {
        return extra;
       } else {
        return null;
       }
      }),
     ],
    },
   };
  }

  case "CALCULATE_PACKED_PERCENT": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     pack_progress: packedProgress(state[action.id]),
    },
   };
  }

  case "ADD_TEXT_LABEL": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     textLabels: state[action.id].textLabels
      ? [...state[action.id].textLabels, { ...action.textLabel }]
      : [{ ...action.textLabel }],
    },
   };
  }

  case "UPDATE_TEXT_LABEL": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     textLabels: [
      ...state[action.id].textLabels.map((label) => {
       if (label.id !== action.textLabelId) {
        return label;
       } else {
        return { ...label, ...action.update };
       }
      }),
     ],
    },
   };
  }

  case "REMOVE_TEXT_LABEL": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     textLabels: [
      ...state[action.id].textLabels.filter((label) => {
       if (label.id !== action.textLabelId) {
        return label;
       } else {
        return null;
       }
      }),
     ],
    },
   };
  }

  case "ADD_ZONE": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     zones: state[action.id].zones
      ? [...state[action.id].zones, { ...action.zone }]
      : [{ ...action.zone }],
    },
   };
  }

  case "UPDATE_ZONE": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     zones: [
      ...state[action.id].zones.map((zone) => {
       if (zone.id !== action.zoneId) {
        return zone;
       } else {
        return { ...zone, ...action.update };
       }
      }),
     ],
    },
   };
  }

  case "REMOVE_ZONE": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     zones: [
      ...state[action.id].zones.filter((zone) => {
       if (zone.id !== action.zoneId) {
        return zone;
       } else {
        return null;
       }
      }),
     ],
    },
   };
  }

  case "LEFT_TO_RIGHT_SWITCH": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     ltr: action.ltr,
    },
   };
  }

  case "SHOW_HIDE_CABLE_KEY": {
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     showKey: action.show,
    },
   };
  }

  case "HIDE_AFTER_ADDING": {
   return {
    ...state,
    hideAfterAdd: action.checked,
   };
  }

  case "SHOW_ONLY_MY_GEAR": {
   return {
    ...state,
    showOnlyMyGear: action.checked,
   };
  }

  case "SEARCH_TAB": {
   return {
    ...state,
    searchTab: action.tab,
   };
  }

  case "LOGOUT": {
   return {
    ...initialState,
   };
  }

  default:
   return state;
 }
}
