import React from "react";
import { Link } from "react-router-dom";

import { HomeFooter } from "./landing/Home";

export default function Terms() {
 return (
  <>
   <header className="bg-red-500 text-white flex flex-col space-y-4 justify-center py-5 md:py-10 items-center">
    <h1 className="text-4xl md:text-5xl font-bold">H2R Gear</h1>
    <Link to="/" className="underline">
     Go back home
    </Link>
   </header>

   <HomeSection bgColour="bg-white" title="Cookies">
    <div className=" mt-20">
     <CookiePolicy />
    </div>
   </HomeSection>

   <HomeSection bgColour="bg-white" title="Terms">
    <div className=" mt-20">
     <TermsOfUsage />
    </div>
   </HomeSection>

   <HomeFooter />
  </>
 );
}

const HomeSection = ({
 bgColour = "bg-gray-100",
 title = "Example title",
 subtitle = "",
 image = null,
 video = null,
 backgroundSize = "cover",
 children,
}) => {
 return (
  <section
   className={`${bgColour} max-w-5xl mx-auto flex flex-col justify-center items-center py-16 relative overflow-hidden px-10 text-gray-800`}
  >
   <h2 className="text-3xl text-gray-800 font-semibold">{title}</h2>
   <p className="text-lg text-gray-700 mt-3">{subtitle}</p>
   {video && (
    <div className="hidden sm:flex mt-12 max-w-5xl rounded">
     <video
      className="rounded h-60 sm:h-96"
      src={video}
      loop
      autoPlay
      muted
     ></video>
    </div>
   )}
   {image && (
    <div
     style={{
      backgroundImage: `url(${image})`,
      backgroundPosition: "center",
      backgroundSize,
      backgroundRepeat: "no-repeat",
     }}
     className={`mt-20 h-80 w-full ${video ? "visible sm:hidden" : "visible"}`}
    ></div>
   )}
   {children}
  </section>
 );
};

const CookiePolicy = () => {
 return (
  <>
   <p className="leading-8">
    <span className="font-bold">What Are Cookies</span>
   </p>

   <p className="leading-8">
    As is common practice with almost all professional websites this site uses
    cookies, which are tiny files that are downloaded to your computer, to
    improve your experience. This page describes what information they gather,
    how we use it and why we sometimes need to store these cookies. We will also
    share how you can prevent these cookies from being stored however this may
    downgrade or 'break' certain elements of the sites functionality.
   </p>

   <p className="leading-8">
    <span className="font-bold">How We Use Cookies</span>
   </p>

   <p className="leading-8">
    We use cookies for a variety of reasons detailed below. Unfortunately in
    most cases there are no industry standard options for disabling cookies
    without completely disabling the functionality and features they add to this
    site. It is recommended that you leave on all cookies if you are not sure
    whether you need them or not in case they are used to provide a service that
    you use.
   </p>

   <p className="leading-8">
    <span className="font-bold">Disabling Cookies</span>
   </p>

   <p className="leading-8">
    You can prevent the setting of cookies by adjusting the settings on your
    browser (see your browser Help for how to do this). Be aware that disabling
    cookies will affect the functionality of this and many other websites that
    you visit. Disabling cookies will usually result in also disabling certain
    functionality and features of the this site. Therefore it is recommended
    that you do not disable cookies. This Cookies Policy was created with the
    help of the{" "}
    <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">
     Cookies Policy Generator from CookiePolicyGenerator.com
    </a>
    .
   </p>
   <p className="leading-8">
    <span className="font-bold">The Cookies We Set</span>
   </p>

   <ul>
    <li>
     <p className="leading-8 font-semibold">Account related cookies</p>
     <p className="leading-8">
      If you create an account with us then we will use cookies for the
      management of the signup process and general administration. These cookies
      will usually be deleted when you log out however in some cases they may
      remain afterwards to remember your site preferences when logged out.
     </p>
    </li>

    <li>
     <p className="leading-8 font-semibold">Login related cookies</p>
     <p className="leading-8">
      We use cookies when you are logged in so that we can remember this fact.
      This prevents you from having to log in every single time you visit a new
      page. These cookies are typically removed or cleared when you log out to
      ensure that you can only access restricted features and areas when logged
      in.
     </p>
    </li>

    <li>
     <p className="leading-8 font-semibold">Subscription related cookies</p>
     <p className="leading-8">
      If you begin the process of signing up to a paid subscription on the site,
      this will trigger a cookie from our payment processor Paddle.
     </p>
    </li>
   </ul>

   <p className="leading-8">
    <span className="font-bold">Third Party Cookies</span>
   </p>

   <p className="leading-8">
    In some special cases we also use cookies provided by trusted third parties.
    The following section details which third party cookies you might encounter
    through this site.
   </p>

   <ul>
    <li>
     <p className="leading-8">
      This site uses Google Analytics which is one of the most widespread and
      trusted analytics solution on the web for helping us to understand how you
      use the site and ways that we can improve your experience. These cookies
      may track things such as how long you spend on the site and the pages that
      you visit so we can continue to produce engaging content.
     </p>
     <p className="leading-8">
      For more information on Google Analytics cookies, see the official Google
      Analytics page.
     </p>
    </li>
   </ul>

   <p className="leading-8">
    <span className="font-bold">More Information</span>
   </p>

   <p className="leading-8">
    Hopefully that has clarified things for you and as was previously mentioned
    if there is something that you aren't sure whether you need or not it's
    usually safer to leave cookies enabled in case it does interact with one of
    the features you use on our site.
   </p>

   <p className="leading-8">
    For more general information on cookies, please read{" "}
    <a href="https://www.generateprivacypolicy.com/#cookies">
     "Cookies" article from the Privacy Policy Generator
    </a>
    .
   </p>

   <p className="leading-8">
    However if you are still looking for more information then you can contact
    us through one of our preferred contact methods:
   </p>

   <ul>
    <li>Email: support@heretorecord.com</li>
   </ul>
  </>
 );
};

const TermsOfUsage = () => {
 return (
  <>
   <ul>
    <li>
     <p className="leading-8 font-semibold">Payment processing</p>
     <p className="leading-8">
      Our order process is conducted by our online reseller Paddle.com.
      Paddle.com is the Merchant of Record for all our orders. Paddle provides
      all customer service inquiries and handles returns.
     </p>
    </li>

    <li>
     <p className="leading-8 font-semibold">Refunds</p>
     <p className="leading-8">
      We have a 30 day money back guarantee as part of our refund policy. Get in
      touch with us to process this.
     </p>
    </li>
   </ul>
  </>
 );
};
