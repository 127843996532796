import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";

import Logo from "./branding/Logo";
import Input from "./Input";
import Button from "./Button";
// import GoogleButton from 'react-google-button' // optional

function LoginPage() {
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const [loggingIn, setLoggingIn] = useState(false);
 const [error, setError] = useState("");

 const firebase = useFirebase();
 const auth = useSelector((state) => state.firebase.auth);

 const loginWithGoogle = async () => {
  return await firebase.login({ provider: "google", type: "popup" });
 };

 const handleLogin = (e) => {
  e.preventDefault();

  setLoggingIn(true);

  if (!email || !password) return setLoggingIn(false);
  return firebase.login({ email, password }).catch((err) => {
   if (err.code === "auth/user-not-found") {
    setError("User not found");
   } else if (err.code === "auth/wrong-password") {
    setError("Incorrect Email or Password");
   } else {
    setError("Something went wrong. Try again.");
   }

   setLoggingIn(false);
  });
 };

 return (
  <div className="flex flex-col justify-center bg-red-500 h-screen w-screen">
   {!isLoaded(auth) ? (
    <span>Loading...</span>
   ) : isLoaded(auth) && isEmpty(auth) ? (
    // <GoogleButton/> button can be used instead

    <div className="px-12 py-10 flex flex-col w-11/12 md:w-1/2 max-w-md bg-white dark:bg-gray-900 mx-auto rounded-lg">
     <form className="flex flex-col mb-5" onSubmit={(e) => handleLogin(e)}>
      <Logo />

      <span className="mt-8 mb-10 h-1 border-b-2 border-solid border-gray-200 dark:border-gray-700"></span>

      <div className="mb-8">
       <Input
        name="email"
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="me@address.com"
        required={true}
        autoFocus={true}
       />
       <Input
        name="password"
        label="Password"
        type="password"
        css="mt-6"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="••••••••••"
        required={true}
       />
      </div>

      <div className="flex items-center justify-between">
       <Button
        text={!loggingIn ? "Login" : "Logging in..."}
        onSubmit={(e) => e.preventDefault()}
        type="submit"
        disabled={loggingIn}
       />
       <Link
        className="text-gray-700 focus:outline-none focus:ring rounded"
        to="/reset"
       >
        Forgot password?
       </Link>
      </div>

      {error !== "" ? (
       <span className="italic mt-4 mx-auto text-red-600">{error}</span>
      ) : null}
     </form>
     <Button
      text="Login with Google"
      colour="white"
      onClick={loginWithGoogle}
     />
     <span className="mt-6 mb-4 h-1 border-b-2 border-solid border-gray-200 dark:border-gray-700"></span>

     <Link
      className="mt-2 text-gray-700 dark:text-gray-500 focus:outline-none focus:ring rounded text-center"
      to="/signup"
     >
      Create account →
     </Link>
    </div>
   ) : (
    <Redirect to="/plans"></Redirect>
   )}
  </div>
 );
}

export default LoginPage;
