import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import Layout from "./Layout";
import { GearItemNoDrag } from "./GearItem";
import Button from "./Button";
import BuilderIO from "./BuilderIO";
import Input, { InputCheckbox } from "./Input";
import Select from "./Select";
import ColourPicker from "./ColourPicker";
import { deviceCategories } from "../db/categories";
import * as builderActions from "../actions/builderActions";
import * as notificationsActions from "../actions/notificationsActions";

const valid = {
 name: true,
 type: true,
 manufacturer: true,
};

export default function Builder() {
 return (
  <Layout title="Builder">
   <BuilderInternals />
  </Layout>
 );
}

export const BuilderInternals = ({
 onPlan = false,
 cablesConnected = false,
 originalInputsLength = 0,
 originalOutputsLength = 0,
}) => {
 const [savingGearItem, setSavingGearItem] = useState(false);
 const [updatingGearItem, setUpdatingGearItem] = useState(false);
 const [gearItemValidation, setGearItemValidation] = useState({
  ...valid,
 });
 const [dragging, setDragging] = useState(false);
 const [uploading, setUploading] = useState(false);

 const uid = useSelector((state) => state.firebase.auth.uid);
 const gearItem = useSelector((state) => state.builder.emptyGearItem);
 const partOfTeam = useSelector((state) => state.firebase.profile.partOfTeam);
 const firestore = useFirestore();
 const firebase = useFirebase();

 console.log('gearItem', gearItem)

 const checkNameIncrement = (inOrOut) => {
  let lastInputName = gearItem[inOrOut][gearItem[inOrOut].length - 1].name;
  let lastInputNameSplit = lastInputName.split(" ");

  let lastInputNameFirstWord = lastInputNameSplit[0];
  let lastInputNameLastWord = lastInputNameSplit[lastInputNameSplit.length - 1];
  // console.log(parseInt(lastInputNameLastWord));

  if (isNaN(parseInt(lastInputNameLastWord))) console.log("NNAANN");

  if (!isNaN(parseInt(lastInputNameLastWord))) {
   builderActions.addInputOutput(inOrOut, {
    ...gearItem[inOrOut][gearItem[inOrOut].length - 1],
    name: `${
     lastInputNameSplit.length > 1 ? `${lastInputNameFirstWord} ` : ""
    }${parseInt(lastInputNameLastWord) + 1}`,
   });
  } else {
   builderActions.addInputOutput(inOrOut, null);
  }
 };

 //  const toDataURL = (url) =>
 //   fetch(url)
 //    .then((response) => response.blob())
 //    .then(
 //     (blob) =>
 //      new Promise((resolve, reject) => {
 //       const reader = new FileReader();
 //       reader.onloadend = () => resolve(reader.result);
 //       reader.onerror = reject;
 //       reader.readAsDataURL(blob);
 //      })
 //    );

 const handleFileUpload = async (file) => {
  // 1MB max file size limit
  if (file.size >= 1097152)
   return notificationsActions.addNotification(
    "File too large",
    "This file is larger than the 1MB limit.",
    "fail"
   );

  setUploading(true);

  let d = new Date().getTime();
  await firebase
   .uploadFile(`items/${uid}`, file, "", {
    name: d,
   })
   .then((res) => {
    console.log(res);

    res.uploadTaskSnapshot.ref.getDownloadURL().then((downloadURL) => {
     builderActions.updateEmptyItem({ img: downloadURL });
    });
    setUploading(false);
   })
   .catch((err) => {
    console.error(err);
    setUploading(false);
   });
 };

 const onDragStart = () => {
  setDragging(true);
 };

 const onDragEnd = (result) => {
  const { destination, source } = result;

  setDragging(false);

  if (!destination) {
   return null;
  }

  if (
   destination.droppableId === source.droppableId &&
   destination.index === source.index
  ) {
   return null;
  }

  if (destination.droppableId !== source.droppableId) {
   return builderActions.moveInputOutput(source, destination);
  }

  builderActions.reorderInputOutput(
   destination.droppableId,
   source,
   destination
  );
 };

 const handleSaveGearItem = async () => {
  //  VALIDATE GEAR ITEM
  if (
   gearItem.name === "" ||
   gearItem.manufacturer === "" ||
   gearItem.type === ""
  ) {
   return setGearItemValidation({
    status: "error",
    text: "Looks like you are missing some details.",
    name: gearItem.name === "" ? false : true,
    manufacturer: gearItem.manufacturer === "" ? false : true,
    type: gearItem.type === "" ? false : true,
   });
  } else {
   setGearItemValidation({ ...valid });
  }

  setSavingGearItem(true);

  if (gearItem.updatingItem !== true) {
   //   SAVE TO FIRESTORE
   await firestore
    .collection("items")
    .add({
     ...gearItem,
     added: new Date(),
     uid:  gearItem._team ? partOfTeam : uid,
     _checked: false,
     _approved: false,
    })
    .then((res) => {
     firestore.collection("items").doc(res.id).update({
      id: res.id,
     });

     setSavingGearItem(false);

     builderActions.clearEmptyObject();

     setGearItemValidation({
      status: "success",
      text: "Gear item added!",
     });

     notificationsActions.addNotification(
      `Added gear item${gearItem._team ? ' to team section' : ''}!`,
      `${gearItem.name} has been added.`,
      "success",
      5000
     );

     return setTimeout(() => {
      setGearItemValidation({ ...valid });
     }, 5000);
    })
    .catch((error) => {
     setGearItemValidation({
      status: "error",
      text: "Something went wrong, please try again.",
     });
     return setSavingGearItem(false);
    });
  } else {
   //   UPDATE TO FIRESTORE
   await firestore
    .collection("items")
    .doc(gearItem.id)
    .update({
     ...gearItem,
    })
    .then((res) => {
     setSavingGearItem(false);

     builderActions.clearEmptyObject();

     setGearItemValidation({
      status: "success",
      text: "Gear item updated!",
     });

     return setTimeout(() => {
      setGearItemValidation({ ...valid });
     }, 5000);
    })
    .catch((error) => {
     setGearItemValidation({
      status: "error",
      text: "Something went wrong, please try again.",
     });
     return setSavingGearItem(false);
    });
  }
 };

 useEffect(() => {
  if (gearItem.inputs.length >= gearItem.outputs.length) {
   builderActions.updateWidth(gearItem.inputs.length * 50);
  } else {
   builderActions.updateWidth(gearItem.outputs.length * 50);
  }

  if (gearItem.inputs.length === 0 || gearItem.outputs.length === 0) {
   builderActions.updateHeight(70);
  } else {
   builderActions.updateHeight(94);
  }
 }, [gearItem.inputs.length, gearItem.outputs.length]);

 useEffect(() => {
  builderActions.updateEmptyItem({
   searchTerms: [
    ...gearItem.name.split(" ").map((word) => word.toLowerCase()),
    ...gearItem.manufacturer.split(" ").map((word) => word.toLowerCase()),
   ],
  });
 }, [gearItem.name, gearItem.manufacturer]);

 useEffect(() => {
  // console.log("Builder loaded");
  return () => {
   builderActions.clearEmptyObject();
  };
 }, []);
 return (
  <>
   {onPlan ? null : (
    <div className="px-6 py-6 flex flex-row bg-red-500 md:flex-col md:px-0 md:py-0 md:bg-gray-200 md:dark:bg-transparent">
     <div className="flex items-center">
      <h1 className="text-2xl font-bold text-white md:text-gray-800 md:dark:text-gray-100">
       Builder
      </h1>
     </div>
    </div>
   )}

   <div className="px-4 mt-6 md:px-0">
    <div className="mt-6">
     <div
      className={`mt-4 overflow-hidden w-100 bg-gray-300 dark:bg-gray-900 rounded relative flex justify-center items-center
                    ${gearItem.img ? "h-64" : "h-40"}
     `}
     >
      <GearItemNoDrag gearItem={gearItem} demoMode={true} disabled={true} />
     </div>

     {/* NAME AND MANU */}
     <div className="mt-6 flex">
      <Input
       name="name"
       label="Name"
       placeholder="My gear item"
       css="w-full"
       value={gearItem.name}
       onChange={(e) => {
        builderActions.updateEmptyItem({ name: e.target.value });
       }}
       valid={gearItemValidation.name}
      />
      <Input
       name="manufacturer"
       label="Manufacturer"
       placeholder="Generic"
       css="ml-6 w-full"
       value={gearItem.manufacturer}
       onChange={(e) => {
        builderActions.updateEmptyItem({ manufacturer: e.target.value });
       }}
       valid={gearItemValidation.manufacturer}
      />
     </div>

     <div className="mt-6 flex">
      <Select
       name="category"
       label="Category"
       css="w-1/2"
       value={gearItem.type}
       options={deviceCategories}
       onSelect={(choice) => {
        builderActions.updateEmptyItem({ type: choice, cssClassName: choice });
       }}
       selected={gearItem.type}
       valid={gearItemValidation.type}
      />

      <div className="ml-6 flex w-1/2">
       {/* COLOURS */}
       <div className="w-full">
        <h3 className="mb-4 text-lg text-gray-700 dark:text-gray-500">
         Background
        </h3>
        <ColourPicker
         colour={gearItem.colour}
         onChangeComplete={(colour) => {
          builderActions.updateEmptyItem({ colour });
         }}
         onClosePicker={() => {
          // console.log("closing picker");
         }}
        />
       </div>
       <div className="ml-4 w-full">
        <h3 className="mb-4 text-lg text-gray-700 dark:text-gray-500">
         Highlight
        </h3>
        <ColourPicker
         colour={gearItem.secondaryColour}
         onChangeComplete={(colour) => {
          builderActions.updateEmptyItem({ secondaryColour: colour });
         }}
         onClosePicker={() => {
          // console.log("closing picker");
         }}
        />
       </div>
      </div>
     </div>

     {onPlan && cablesConnected && (
      <div className="text-left leading-snug mt-4 bg-red-100 dark:bg-red-900 border border-red-200 dark:border-red-800 border-solid px-4 py-4 rounded text-gray-700 dark:text-gray-300 text-sm">
       <span className="font-bold">Heads up</span>: Some editing features are
       removed because cables are connected to this item.
      </div>
     )}

     <div className="">
      {/* INPUTS */}
      <div className="w-full mt-6 grid grid-cols-2">
       <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <div>
         <h3 className="text-lg text-gray-700 dark:text-gray-500">Inputs</h3>
         <Droppable droppableId="inputs">
          {(provided) => (
           <div
            style={{ minHeight: "4rem" }}
            className={`${dragging ? "bg-gray-300 rounded" : "bg-transparent"}`}
            ref={provided.innerRef}
            {...provided.droppableProps}
           >
            {gearItem.inputs.map((input, i) => {
             return (
              <BuilderIO
               key={i}
               i={i}
               io={input}
               type="input"
               disabled={cablesConnected}
               originalLength={originalInputsLength}
              />
             );
            })}
            {provided.placeholder}
           </div>
          )}
         </Droppable>
         <div className="mt-4">
          <Button
           text="Add input"
           colour="white"
           justify="justify-start"
           onClick={() => {
            if (gearItem.inputs.length > 0) {
             checkNameIncrement("inputs");
            } else {
             builderActions.addInputOutput("inputs");
            }
           }}
          />
         </div>
        </div>
        <div className="ml-4">
         <h3 className="text-lg text-gray-700 dark:text-gray-500">Outputs</h3>
         <Droppable droppableId="outputs">
          {(provided) => (
           <div
            style={{ minHeight: "4rem" }}
            className={`${dragging ? "bg-gray-300 rounded" : "bg-transparent"}`}
            ref={provided.innerRef}
            {...provided.droppableProps}
           >
            {gearItem.outputs.map((output, i) => {
             return (
              <BuilderIO
               key={i}
               i={i}
               io={output}
               type="output"
               disabled={cablesConnected}
               originalLength={originalOutputsLength}
              />
             );
            })}
            {provided.placeholder}
           </div>
          )}
         </Droppable>
         <div className="mt-4">
          <Button
           text="Add output"
           colour="white"
           justify="justify-start"
           onClick={() => {
            if (gearItem.outputs.length > 0) {
             checkNameIncrement("outputs");
            } else {
             builderActions.addInputOutput("outputs");
            }
           }}
          />
         </div>
        </div>
       </DragDropContext>
      </div>

      {/* OUTPUTS */}
      <div className="ml-4 w-full">
       {/* <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="outputs">
       {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
         {gearItem.outputs.map((output, i) => {
          return <BuilderIO key={i} i={i} io={output} type="output" disabled={cablesConnected} />;
         })}
         {provided.placeholder}
        </div>
       )}
      </Droppable>
     </DragDropContext> */}
      </div>
     </div>

     {/* IMAGE */}

     {/* <div className="mt-8">
      <span className="text-lg text-gray-700">Image</span>
      <div className="px-2 py-2 mt-4 flex items-center border border-solid border-gray-400 rounded-md">
       {gearItem.img && (
        <img className="w-20" src={gearItem.img} alt={gearItem.name} />
       )}
       <div
        className={`flex space-x-3
                      ${gearItem.img && "ml-2"}
      `}
       >
        {!uploading ? (
         <label
          className="px-1 py-1 cursor-pointer bg-white text-gray-800 border border-solid border-gray-400 hover:shadow-sm hover:bg-gray-100 rounded transition duration-300"
          htmlFor="addImage"
         >
          {gearItem.img ? "Replace image" : "Add image"}
         </label>
        ) : (
         "Uploading..."
        )}
        <input
         className="hidden"
         id="addImage"
         type="file"
         accept="image/*"
         disabled={uploading}
         onChange={(e) => {
          if (e.target.files.length > 0) {
           handleFileUpload(e.target.files[0]);
          }
         }}
        />
       
        {gearItem.img && (
         <Button
          text="Remove image"
          icon="delete"
          colour="white"
          size="small"
          onClick={() => {
           builderActions.updateEmptyItem({ img: null });
          }}
         />
        )}
       </div>
      </div>
     </div> */}

     {/* PRIVATE GEAR ITEM */}
     {onPlan ? null : (
      <div className="mt-6 ">
       <InputCheckbox
        name="private"
        label="Community library"
        css=""
        checked={gearItem._private}
        checkboxLabelText={
         gearItem._private ? (
          <span>
           <span className="font-semibold">Hidden</span>
           <span className="ml-2 text-sm text-gray-600">
            This gear item is for your use only.
           </span>
          </span>
         ) : (
          <span>
           <span className="font-semibold">Available</span>
           <span className="ml-2 text-sm text-gray-600">
            This gear item will be made available to all other H2R Gear users.
           </span>
          </span>
         )
        }
        onChange={(e) => {
         builderActions.updateEmptyItem({ _private: e.target.checked });
         if (!e.target.checked)
          builderActions.updateEmptyItem({ internalGearId: null });
        }}
       />
       {partOfTeam &&
       <>
        <div className="mt-4"></div>
        <InputCheckbox
          name="team"
          label="Share with team"
          css=""
          checked={gearItem._team}
          checkboxLabelText={
          gearItem._team ? (
            <span>
            <span className="font-semibold">On</span>
            <span className="ml-2 text-sm text-gray-600">
              This gear item will be added to the team gear area.
            </span>
            </span>
          ) : (
            <span>
            <span className="font-semibold">Off</span>
            <span className="ml-2 text-sm text-gray-600">
              This gear item will be added to your personal collection.
            </span>
            </span>
          )
          }
          onChange={(e) => {
            builderActions.updateEmptyItem({ 
              _team: e.target.checked,
            });
          if (!e.target.checked)
            builderActions.updateEmptyItem({ internalGearId: null });
          }}
        />
       </>}
      </div>
     )}

     {gearItem._private && (
      <Input
       name="internal-gear-id"
       label="Internal Gear ID"
       placeholder="abcd1234"
       css="mt-6 w-1/2"
       value={gearItem.internalGearId}
       onChange={(e) => {
        builderActions.updateEmptyItem({ internalGearId: e.target.value });
       }}
       valid={gearItemValidation.internalGearId}
      />
     )}

     {onPlan ? null : (
      <div className="flex justify-between mt-6">
       {/* SEARCH TERMS */}
       <div className="">
        <h3 className="mb-4 text-lg text-gray-700 dark:text-gray-500">
         Search terms
        </h3>
        {gearItem.searchTerms.map((term, i) => {
         if (term !== "") {
          return (
           <span
            key={term + i}
            className="bg-gray-300 dark:bg-gray-700 px-1 py-1 mr-1 rounded text-gray-700 dark:text-gray-300"
           >
            {term}
           </span>
          );
         }
        })}
       </div>
      </div>
     )}
     {/* BUTTON AND VALIDATION */}
     {onPlan ? null : (
      <div>
       {gearItem.updatingItem ? (
        <Button
         text={!updatingGearItem ? "Update gear item" : "Updating gear item..."}
         justify="justify-end"
         onClick={() => {
          handleSaveGearItem();
         }}
         disabled={updatingGearItem}
        />
       ) : (
        <Button
         text={!savingGearItem ? "Add gear item" : "Adding gear item..."}
         justify="justify-end"
         onClick={() => {
          handleSaveGearItem();
         }}
         disabled={savingGearItem}
        />
       )}

       {gearItemValidation && (
        <div
         className={`mt-4 ${
          gearItemValidation.status === "error"
           ? "text-red-500"
           : "text-gray-700"
         }`}
        >
         {gearItemValidation.text}
        </div>
       )}
      </div>
     )}

     {!onPlan && !gearItem._private && (
      <div className="text-left leading-snug mt-4 bg-red-100 dark:bg-red-900 border border-red-200 dark:border-red-800 border-solid px-4 py-4 rounded text-gray-700 dark:text-gray-300 text-sm">
       <span className="font-bold">Heads up</span>: Items are marked with 'User
       submitted' and will be checked for accuracy. Changes may be made to your
       item.
      </div>
     )}
    </div>
   </div>
  </>
 );
};
