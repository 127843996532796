import React, { useEffect, useState } from "react";
import {
 BrowserRouter as Router,
 Switch,
 Route,
 Redirect,
 Link,
} from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import {
 ReactReduxFirebaseProvider,
 isLoaded,
 isEmpty,
 useFirestore,
} from "react-redux-firebase";
import CookieConsent from "react-cookie-consent";

import { store, rrfProps } from "./db/store";
import { getAndUpdateLimits } from "./db/firestore";
import "./styles/gear.css";

import Admin from "./components/Admin";
import Home from "./components/Home";
import Login from "./components/Login";
import Reset from "./components/Reset";
import Signup from "./components/Signup";
import Plan from "./components/Plan";
import Welcome from "./components/Welcome";
import MyGear from "./components/MyGear";
import Gear from "./components/Gear";
import Builder from "./components/Builder";
import Settings from "./components/Settings";
import Help from "./components/Help";
import Upgrade from "./components/Upgrade";
import MustHaves from "./components/MustHaves";
import * as settingsActions from "./actions/settingsActions";
import * as teamActions from "./actions/teamActions";
import SplashScreen from "./components/SplashScreen";
import Terms from "./components/Terms";
import Notifications from "./components/Notifications";
import Team from "./components/Team";
import Sketch from "./components/Sketch";
import { isActive } from "./components/hooks/useSubscriptionStatus";
import { currentVersion, WhatsNewModal } from "./components/Modal";
import ThemeSwitcher from "./components/ThemeSwitcher";
import ZoomTest from "./components/ZoomTest";

function AuthIsLoaded({ children }) {
 const [cableSettingsLoaded, setCableSettingsLoaded] = useState(false);
 const [subscriptionsFetched, setSubscriptionsFetched] = useState(false);

 const auth = useSelector((state) => state.firebase.auth);
 const user = useSelector((state) => state.firebase.profile);

 const firestore = useFirestore();

 const handleGetAvailableSubscriptions = async () => {
  if (subscriptionsFetched) return;
  setSubscriptionsFetched(true);

  const usersCurrentSubscrption = await firestore.get({
   collection: "users",
   doc: `${auth.uid}/subscription/current`,
  });

  if (!usersCurrentSubscrption.exists) {
   settingsActions.updateSubscriptionName("Starter", "659880");
   return getAndUpdateLimits(auth.uid);
  } else if (!isActive(usersCurrentSubscrption.data())) {
   settingsActions.updateSubscriptionName("Starter", "659880");
   return getAndUpdateLimits(auth.uid);
  }

  const usersCurrentSubscrptionDoc = await firestore.get({
   collection: "subscriptions",
   doc:
    usersCurrentSubscrption.data().subscription_plan_id ||
    usersCurrentSubscrption.data().product_id,
  });

  getAndUpdateLimits(
   auth.uid,
   usersCurrentSubscrption.exists
    ? usersCurrentSubscrptionDoc?.data()?.limits?.plans
    : 2
  );

  settingsActions.updateSubscriptionName(
   usersCurrentSubscrptionDoc.data().name,
   usersCurrentSubscrptionDoc.id,
   {
    ...usersCurrentSubscrptionDoc.data(),
    ...usersCurrentSubscrption.data(),
   }
  );
 };

 const handlePartOfTeam = async () => {
  const team = await firestore.get({
   collection: "teams",
   doc: `${user.partOfTeam}`,
  });

  if (!team.exists) {
   console.log("no team info found");
  } else {
   teamActions.addTeamData(team.data());
  }
 };

 const handleShouldShowWhatsNew = async () => {
  if (
   user.isLoaded &&
   (!user.whatsNewVersion || user.whatsNewVersion < currentVersion)
  ) {
   settingsActions.showWhatsNewModal(true);
  } else {
   settingsActions.showWhatsNewModal(false);
  }
 };

 useEffect(() => {
  if (user && auth.uid && !subscriptionsFetched) {
   handleGetAvailableSubscriptions();
  }
 }, [user]);

 useEffect(() => {
  if (user.cableTypes && !cableSettingsLoaded) {
   setCableSettingsLoaded(true);
   user.cableTypes.forEach((c) => {
    return settingsActions.changeCableColour(c, c.colour);
   });
  }
  if (user.marketingEmails) {
   settingsActions.changeMarketingEmails(user.marketingEmails);
  }

  if (user.partOfTeam) {
   handlePartOfTeam();
  }
 }, [user]);

 useEffect(() => {
  if (user && auth.uid) {
   handleShouldShowWhatsNew();
  }
 }, [auth.uid, user]);

 if (!isLoaded(auth)) return <SplashScreen />;
 return children;
}

function PrivateRoute({ children, ...rest }) {
 const auth = useSelector((state) => state.firebase.auth);

 return (
  <Route
   {...rest}
   render={({ location }) =>
    isLoaded(auth) && !isEmpty(auth) ? (
     children
    ) : (
     <Redirect
      to={{
       pathname: `/login`,
       state: { from: location },
      }}
     />
    )
   }
  />
 );
}

const App = () => {
 return (
  <Provider store={store}>
   <ReactReduxFirebaseProvider {...rrfProps}>
    <Router>
     <AuthIsLoaded>
      <Notifications />
      <WhatsNewModal />
      <ThemeSwitcher />
      <CookieConsent
       location="bottom"
       buttonText="Okay"
       cookieName="h2r-gear-essential"
       style={{ background: "#f56565" }}
       buttonStyle={{
        backgroundColor: "#ffffff",
        fontSize: "16px",
        borderRadius: "10%",
       }}
       expires={150}
      >
       This website uses some cookies to enhance the user experience.{" "}
       <Link to="/terms">Read about our cookies and terms</Link>.
      </CookieConsent>
      <Switch>
       <Route path="/sketch/:id">
        <Sketch />
       </Route>
       {/* <Route path="/plan/:id/packlist">
        <PacklistOnly />
       </Route> */}

       <Route path="/zoom">
        <ZoomTest />
       </Route>

       <Route path="/plan/:id">
        <Plan />
       </Route>
       <Route path="/login">
        <Login />
       </Route>
       <Route path="/reset">
        <Reset />
       </Route>
       <Route path="/signup">
        <Signup />
       </Route>
       <PrivateRoute path="/admin">
        <Admin />
       </PrivateRoute>
       <PrivateRoute path="/must-haves">
        <MustHaves />
       </PrivateRoute>
       <PrivateRoute path="/team/gear">
        <Gear team={true} />
       </PrivateRoute>
       <PrivateRoute path="/team/plans">
        <Home team={true} />
       </PrivateRoute>
       <PrivateRoute path="/team">
        <Team />
       </PrivateRoute>
       <PrivateRoute path="/my-gear">
        <MyGear />
       </PrivateRoute>
       <PrivateRoute path="/gear">
        <Gear />
       </PrivateRoute>
       <PrivateRoute path="/builder">
        <Builder />
       </PrivateRoute>
       <PrivateRoute path="/settings">
        <Settings />
       </PrivateRoute>
       <PrivateRoute path="/help">
        <Help />
       </PrivateRoute>
       <PrivateRoute path="/upgrade">
        <Upgrade />
       </PrivateRoute>
       <PrivateRoute path="/welcome">
        <Welcome />
       </PrivateRoute>
       <PrivateRoute path="/plans">
        <Home />
       </PrivateRoute>
       <Route path="/terms">
        <Terms />
       </Route>
       <PrivateRoute path="/">
        <Home />
       </PrivateRoute>
      </Switch>
     </AuthIsLoaded>
    </Router>
   </ReactReduxFirebaseProvider>
  </Provider>
 );
};

export default App;
