import React from "react";
import Draggable from "react-draggable";
import "../styles/draggable.css";

import ZoneLabelInput from "./ZoneLabelInput";

export default function Zone({
 planId,
 zone = {},
 x = 0,
 y = 0,
 onStop,
 removeTextLabel,
 demoMode = true,
 opacity = "1",
 scale = 1,
 takeSnapshot,
 readOnly,
 addRemoveSelected,
 selected = false,
}) {
  
 return (
  <Draggable
   onDrag={(e) => {
    // e.preventDefault();
   }}
   onStop={(e, data) => {
    // e.preventDefault();
    onStop(zone.id, data);
   }}
   onStart={(e) => {
    // e.preventDefault();
    takeSnapshot();
   }}
   handle=".handle"
   position={{ x: x, y: y }}
   scale={scale}
   disabled={readOnly}
   
  >
   <div className="absolute" style={{ zIndex: "-1" }}>

    <ZoneBase
     planId={planId}
     zone={zone}
     removeTextLabel={removeTextLabel}
     demoMode={demoMode}
     opacity={opacity}
     scale={scale}
     takeSnapshot={takeSnapshot}
     readOnly={readOnly}
     addRemoveSelected={addRemoveSelected}
     selected={selected}
    />
   </div>
  </Draggable>
 );
}

const ZoneBase = ({
 planId,
 zone,
 opacity = "1",
 readOnly,
 scale = 1,
 takeSnapshot,
 addRemoveSelected,
}) => {
 return (
  <div
    id={`zone_${zone.id}`}
   className={`absolute select-none shadow-sm pointer-events-none rounded
   `}
   style={{
    minWidth: "max-content",
    opacity,
    transition: "500ms opacity ease-in-out",
   }}
   onMouseDown={() => {
    if (readOnly) return null;
    addRemoveSelected(zone.id);
   }}
  >
   <ZoneLabelInput
    planId={planId}
    zone={zone}
    readOnly={readOnly}
    scale={scale}
    takeSnapshot={takeSnapshot}
   />
  </div>
 );
};
