import React from "react";
import { useSelector } from "react-redux";

import { GearItemNoDrag } from "./GearItem";
import Button from "./Button";

export default function GearCard({
 empty = false,
 gearItem,
 actionText,
 waitText,
 gearCardAction,
 gearCardWait,
 buttonDropdown = false,
 buttonDropdownOptions = [],
}) {
 const uid = useSelector((state) => state.firebase.auth.uid);
 const partOfTeam = useSelector((state) => state.firebase.profile.partOfTeam);

 if (empty === true) {
  return (
   <div className="animate-pulse">
    <div className="mt-6 h-40 relative overflow-hidden w-100 bg-gray-100 dark:bg-gray-900 rounded-t-lg border-2 border-solid border-gray-300 dark:border-gray-700 flex justify-center items-center">
     {/* <GearItem gearItem={gearItem} disabled={true} /> */}
    </div>
    <div
     className="px-8 py-6 border-2 border-solid border-gray-300 dark:border-gray-700 rounded-b-lg flex justify-between bg-white dark:bg-gray-900"
     style={{ marginTop: "-2px" }}
    >
     <div>
      <div className="text-lg font-bold text-transparent bg-gray-200 dark:bg-gray-800 rounded">
       NAME NAME NAME NAME
      </div>
      <div className="mt-1 pt-3 text-sm font-light text-transparent bg-gray-200 dark:bg-gray-800 rounded uppercase">
       manufacturer
      </div>
     </div>
     {/* <Button
      text={gearCardWait !== gearItem.id ? actionText : waitText}
      colour="white"
      onClick={() => gearCardAction(gearItem)}
     /> */}
    </div>
   </div>
  );
 } else {
  return (
   <div>
    <div className="mt-6 h-40 relative overflow-hidden w-100 bg-gray-100 dark:bg-gray-900 rounded-t-lg border-2 border-solid border-gray-300 dark:border-gray-700 flex justify-center items-center">
     {/* USER SUBMITTED */}
     {gearItem._approved === false && gearItem.uid !== uid && gearItem.uid !== partOfTeam ? (
      <div className="absolute bg-red-400 cursor-default left-0 px-2 py-2 rounded-br text-white dark:text-gray-900 text-xs top-0 uppercase font-semibold">
       User submitted
      </div>
     ) : null}



     {/* MY GEAR */}
     {gearItem.uid === uid && gearItem._team !== true ? (
      <div
       className={`
      absolute cursor-default right-0 px-2 py-2 rounded-bl text-gray-700 text-xs top-0 uppercase font-semibold
      ${
       gearItem._private && gearItem._private
        ? "bg-orange-400"
        : "bg-gray-200 text-gray-600"
      }
      `}
      >
       <div className="">
        MY GEAR {gearItem._private && gearItem._private === true && "• Private"}
       </div>
      </div>
     ) : null}

     {/* TEAM GEAR */}
     {gearItem?._team ? (
      <div
       className={`
      absolute cursor-default right-0 px-2 py-2 rounded-bl text-white text-xs top-0 uppercase font-semibold
    
        bg-red-500
      `}
      >
       <div className="">TEAM GEAR</div>
      </div>
     ) : null}

     <GearItemNoDrag gearItem={gearItem} disabled={true} />
    </div>
    <div
     className="px-8 py-6 border-2 border-solid border-gray-300 dark:border-gray-700 rounded-b-lg flex justify-between bg-white dark:bg-gray-900"
     style={{ marginTop: "-2px" }}
    >
     <div>
      <div className="text-lg font-bold text-gray-900 dark:text-gray-100">
       {gearItem.name}
      </div>
      <div className="pt-3 text-sm font-light text-gray-500 uppercase">
       {gearItem.manufacturer}{" "}• {gearItem.type}
      </div>
      {gearItem.internalGearId && (
       <div className="mt-3 p-1 rounded text-xs font-light text-gray-900 bg-gray-300 dark:text-gray-500 dark:bg-gray-800 uppercase">
        Internal ID:{" "}
        <span className="font-semibold">{gearItem.internalGearId}</span>
       </div>
      )}
     </div>
     <Button
      text={gearCardWait !== gearItem.id ? actionText : waitText}
      css="h-full"
      colour="white"
      onClick={() => gearCardAction(gearItem)}
      dropdown={buttonDropdown}
      dropdownOptions={buttonDropdownOptions}
     />
    </div>
   </div>
  );
 }
}
