import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import moment from "moment";

import Layout from "./Layout";
import Button from "./Button";
import Select from "./Select";
import { getAndUpdateLimits } from "../db/firestore";
import * as settingsActions from "../actions/settingsActions";
import { analytics } from "../db/store";
import FAQs from "./FAQs";
import * as notificationActions from "../actions/notificationsActions";
import useSubscriptionStatus, { addDays } from "./hooks/useSubscriptionStatus";

const faqs = [
 {
  question: `What's an “active plan”?`,
  answer: `Plans in H2R Gear can be active or archived. Active plans can be edited and show up right within your dashboard. Archived plans cannot be edited without reverting them back to active.

  You can have as many archived plans as you want, however the limits of active plans are listed in the pricing breakdown.`,
 },
 {
  question: `I have a paid subscription, how do I convert to a Team?`,
  answer: `For now, you can cancel your subscription via the billing history section and then re-subscribe to a team plan. We’re working on a faster method for this! Alternatively, send an email to support@heretorecord.com.`,
 },
 {
  question: "How do Team subscriptions work?",
  answer: `A Team has an Admin and Members. When you start a Team subscription, you will be the admin of that team.

   From there you can add Team Members who can all see and edit plans shared to the team.
   
   An Admin or Team Member and share their personal plans with their Team.`,
 },
 {
  question: `Do Team Members need to pay for a subscription?`,
  answer: `Nope. Team Members will still hold their own personal account (it can be free or paid). They will have the same plans limit they had before, but they have the added benefit of getting access to all other Pro features. 

   For example, they can add zones and export their own personal plans!`,
 },
 {
  question: `How do plan limits work for Teams?`,
  answer: `The Team Admin can keep track of these limits in their own account as it is tied directly to the Team’s limits.`,
 },
 {
  question: `What if I need more team members?`,
  answer: `Just get in touch and we can help with this - Pricing listed above. And be sure to reach out with any specific requests or needs.`,
 },
];

const teamSubscriptionProductIds = [760974, 760975, 760976, 760977];

export default function Upgrade() {
 const firestore = useFirestore();

 const user = useSelector((state) => state.firebase.auth);
 const isSubscribed = useSubscriptionStatus();

 console.log("isSubscribed", isSubscribed);

 const currentPlanDetails =
  useSelector((state) => state.settings.planDetails) || {};
 const subscriptions =
  useSelector((state) => state.firestore.data.subscriptions) || null;
 const allUserSubscriptions =
  useSelector((state) => state.firestore.data.allSubscriptionDocs) || null;

 console.log(currentPlanDetails);

 const Paddle = window.Paddle;
 const openCheckout = (productId) => {
  Paddle.Checkout.open({
   product: productId,
   email: user.email,
   passthrough: JSON.stringify({ uid: user.uid }),
   successCallback: function (data) {
    var checkoutId = data.checkout.id;

    Paddle.Order.details(checkoutId, async function () {
     // Order data, downloads, receipts etc... available within 'data' variable.
     const d = data.product.id;
     console.log(d);
     console.log(data);

     const usersCurrentSubscrption = await firestore.get({
      collection: "users",
      doc: `${user.uid}/subscription/current`,
     });

     getAndUpdateLimits(user.uid, subscriptions[d].limits.plans);

     settingsActions.updateSubscriptionName(subscriptions[d]?.name, d, {
      ...subscriptions[d],
      ...usersCurrentSubscrption.data(),
     });

     analytics.logEvent("subscription_purchase", {
      subscription_id: d,
     });

     // IF TEAM, CREATE TEAM
     if (teamSubscriptionProductIds.includes(d)) {
      console.log(`Should create team with id ${user.uid}`);

      await firestore
       .collection("teams")
       .doc(user.uid)
       .set({
        admin: user.uid,
        limits: {
         ...subscriptions[d].limits,
        },
        members: [user.uid],
        name: "My team name",
       })
       .then(async (res) => {
        return res;
       })
       .catch((err) => {
        return console.error(err);
       });

      await firestore
       .collection("users")
       .doc(user.uid)
       .update({
        partOfTeam: user.uid,
       })
       .then(async (res) => {
        return res;
       })
       .catch((err) => {
        return console.error(err);
       });
     }

     return notificationActions.addNotification(
      `Purchase successful`,
      `Refresh the app to see all your new features!`,
      "success",
      20000
     );
    });
   },
   closeCallback: function (data) {
    var checkoutId = data.checkout.id;

    Paddle.Order.details(checkoutId, function (data) {
     // Order data, downloads, receipts etc... available within 'data' variable.
     return notificationActions.addNotification(
      `Checkout cancelled`,
      `Thanks for taking a look!`,
      "success"
     );
    });
   },
  });
 };

 const handleGetAllUserSubscription = async () => {
  const allUsersSubscriptionDocs = await firestore.get({
   collection: `users/${user.uid}/subscription`,
   orderBy: ["event_time", "desc"],
   storeAs: "allSubscriptionDocs",
  });

  if (allUsersSubscriptionDocs.docs.length > 0) {
   //  allUsersSubscriptionDocs.docs.map((doc) => console.info(doc.data()));
  }
 };

 const alertNameRewrite = (name) => {
  let returnName;

  switch (name) {
   case "subscription_payment_succeeded":
    returnName = "Payment success";
    break;

   case "payment_succeeded":
    returnName = "One-time Payment success";
    break;

   case "subscription_created":
    returnName = "Subscription created";
    break;

   case "subscription_payment_failed":
    returnName = "Subscription payment failed";
    break;

   default:
    returnName = name;
    break;
  }

  return returnName;
 };

 console.log(subscriptions);
 useEffect(() => {
  handleGetAllUserSubscription();
 }, []);

 return (
  <Layout title="Upgrade">
   <div className="px-6 py-6 flex flex-row bg-red-500 md:flex-col md:px-0 md:py-0 md:bg-gray-200 md:dark:bg-transparent">
    <div className="flex items-center">
     <h1 className="text-2xl font-bold text-white md:text-gray-800 md:dark:text-gray-100">
      Plan
     </h1>
    </div>
   </div>

   {/* <div className="px-4 mt-6 md:px-0">
    <div className="mt-6">
     <div>
      <h2 className="text-lg font-bold mb-2">Simple pricing</h2>
      <p className="mt-2 leading-normal">
       We've went out of our way to keep pricing as easy as possible. Don't see
       what you are looking for? Send us an email to chat about it.
      </p>
     </div>
    </div>
   </div> */}

   {isSubscribed && (
    <div className="mt-4 px-4 py-4 bg-gray-100 dark:bg-gray-900 rounded border border-solid border-gray-300 dark:border-gray-700">
     <h2 className="text-lg font-bold mb-2">
      You're currently on the {currentPlanDetails?.name} plan 🎉
     </h2>

     {currentPlanDetails.cancellation_effective_date && (
      <span className="underline">
       Note: Your plan will expire{" "}
       {currentPlanDetails.cancellation_effective_date}
      </span>
     )}

     {currentPlanDetails.product_id && (
      <p className="underline">
       Your 10 day access expires:{" "}
       {new Date(addDays(currentPlanDetails)).toLocaleString()}
      </p>
     )}

     <p className="mt-4 ">
      Use the links in <strong>Billing History</strong> for cancellation and
      receipts.
     </p>
     <p className="mt-4 leading-normal">
      Or reach out to{" "}
      <a className="underline" href="mailto:support@heretorecord.com">
       support@heretorecord.com
      </a>{" "}
      if you need any help changing your subscription plan.
     </p>
    </div>
   )}

   {allUserSubscriptions && (
    <div className="px-4 md:px-0 mt-4 flex flex-col">
     <h2 className="text-lg font-bold mb-2">Billing history</h2>
     <ul className="flex flex-col space-y-1">
      {Object.entries(allUserSubscriptions).map(([id, d]) => {
       if (id === "current") return null;
       else if (d.alert_name === "subscription_cancelled") return null;
       return (
        <li
         key={id}
         className="px-2 py-0.5 text-sm grid grid-cols-3 items-center rounded hover:bg-gray-100 dark:hover:bg-gray-900 transition-colors"
        >
         <span className="text-gray-600 italic">
          {moment(d.event_time).format("YYYY-MM-DD [at] HH:MM")}
         </span>{" "}
         <span>{alertNameRewrite(d.alert_name)}</span>
         {d.receipt_url && (
          <a
           href={d.receipt_url}
           target="_blank"
           rel="noopener noreferrer"
           className="px-2 py-1 bg-gray-300 hover:bg-gray-200 dark:bg-gray-600 rounded font-semibold flex space-x-2"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
           >
            <path d="M13.75 7h-3v5.296l1.943-2.048a.75.75 0 011.114 1.004l-3.25 3.5a.75.75 0 01-1.114 0l-3.25-3.5a.75.75 0 111.114-1.004l1.943 2.048V7h1.5V1.75a.75.75 0 00-1.5 0V7h-3A2.25 2.25 0 004 9.25v7.5A2.25 2.25 0 006.25 19h7.5A2.25 2.25 0 0016 16.75v-7.5A2.25 2.25 0 0013.75 7z" />
           </svg>
           <span>Download receipt</span>
          </a>
         )}
         {d.alert_name === "subscription_created" && d.cancel_url && (
          <a
           href={d.cancel_url}
           rel="noopener noreferrer"
           className="px-2 py-1 bg-red-400 hover:bg-red-500 rounded font-semibold flex space-x-2 text-white"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
           >
            <path
             fillRule="evenodd"
             d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
             clipRule="evenodd"
            />
           </svg>
           <span>Cancel subscription</span>
          </a>
         )}
        </li>
       );
      })}
     </ul>
    </div>
   )}

   <PricingTable openCheckout={openCheckout} />
   {/* <Button text="on time" onClick={() => openCheckout("778070")} /> */}
  </Layout>
 );
}

export const PricingTable = ({ demo = false, openCheckout }) => {
 const [billingType, setBillingType] = useState("monthly");
 const firestore = useFirestore();

 const currentPlan = useSelector((state) => state.settings.planId);
 const currentPlanDetails =
  useSelector((state) => state.settings.planDetails) || {};
 const subscriptions =
  useSelector((state) => state.firestore.data.subscriptions) || null;

 const handleGetAllSubscriptions = async () => {
  return await firestore.get({
   collection: "subscriptions",
   //  where: ["status", "==", "ACTIVE"],
  });
 };

 useEffect(() => {
  handleGetAllSubscriptions();
 }, []);
 return (
  <>
   <div className="px-4 mt-6 md:px-0">
    <div className="mt-6">
     <div>
      <h2 className="text-lg font-bold mb-2">Billing period</h2>
      <p className="mt-2">
       <Select
        css="w-56"
        options={["monthly", "yearly"]}
        selected={billingType}
        onSelect={(change) => {
         setBillingType(change);
        }}
       />
      </p>
     </div>
    </div>
   </div>
   <div className="px-4 mt-6 md:px-0 mx-auto flex space-x-0 space-y-2 lg:space-x-2 lg:space-y-0">
    {subscriptions === null ? (
     <div className="h-60 py-2 px-4 w-1/3 bg-gray-300 rounded flex flex-col animate-pulse text-gray-500">
      Loading plans...
     </div>
    ) : (
     <div>
      <div className="flex flex-col lg:flex-row space-x-0 space-y-2 lg:space-x-2 lg:space-y-0">
       {Object.entries(subscriptions)
        .sort(
         ([idA, planA], [idB, planB]) => planA.limits.plans - planB.limits.plans
        )
        .map(([id, plan]) => {
         if (plan.billing_type !== billingType) return null;
         return (
          <div
           key={id}
           className="py-2 px-4 w-full lg:w-1/3 bg-white dark:bg-gray-900 rounded border border-solid border-gray-300 dark:border-gray-700 flex flex-col"
          >
           <h2 className="font-bold text-2xl pb-3 pt-2">{plan?.name}</h2>
           <h3 className="font-bold text-xl pb-2">
            ${plan.recurring_price}{" "}
            <span className="text-sm text-gray-600 font-medium">
             /{billingType === "monthly" ? "month" : "year"}
            </span>
           </h3>
           {/* <span className="pt-2 py-2 text-xs font-bold uppercase text-gray-700">
           Includes...
          </span> */}
           {plan.features.map((feature) => {
            return (
             <span key={feature} className="py-1 text-sm">
              ✔ {feature}
             </span>
            );
           })}
           {!demo && (
            <div className="flex flex-col mt-auto">
             <div className="mt-3 mb-3 w-full h-1 bg-gray-300 dark:bg-gray-700"></div>
             <Button
              text={currentPlan === id ? "Current plan" : `Get ${plan?.name}`}
              css="w-full"
              onClick={() => openCheckout(id)}
              disabled={
               currentPlanDetails.status === "active" || plan?.name === "Starter"
              }
             />
            </div>
           )}
          </div>
         );
        })}
      </div>

      {/* ONE TIME */}
      <div className="mt-10 py-4 px-6 pb-8 w-full bg-white dark:bg-gray-900 rounded border border-solid border-gray-300 dark:border-gray-700 flex justify-between items-center">
       <div>
        <h2 className="font-bold text-2xl pb-3 pt-2">
         {subscriptions[778070]?.name}
        </h2>
        <h3 className="font-bold text-2xl pb-2">
         ${subscriptions[778070]?.price}{" "}
         <span className="text-sm text-gray-600 font-medium">
          {" "}
          One time purchase
         </span>
        </h3>
        <span className="text-sm text-gray-500 font-medium">
         Pay once • 10 day access to features • No subscription.
        </span>
        <br />
        <p className="mt-4 text-base text-gray-600 font-medium">
         ✔ All features in a "Pro" subscription
        </p>
        <br />
        <p className="-mt-2 text-base text-gray-600 font-medium">
         ✔ No monthly subscription bill
        </p>
        <br />
        <p className="-mt-2 text-base text-gray-600 font-medium">
         ✔ 10 day access to features
        </p>
       </div>
       <Button
        text={`Get 10-day Event access`}
        css="w-full"
        onClick={() => openCheckout(778070)}
        disabled={currentPlanDetails.status === "active"}
       />
      </div>
     </div>
    )}
   </div>
   <FAQs title="Billing FAQs" faqs={faqs} />
  </>
 );
};
