import React from "react";
import Draggable from "react-draggable";
import "../styles/draggable.css";

import TextLabelInput from "./TextLabelInput";

export default function TextLabel({
 planId,
 textLabel = {},
 x = 0,
 y = 0,
 onStop,
 removeTextLabel,
 disabled = false,
 demoMode = true,
 opacity = "1",
 scale = 1,
 readOnly,
}) {
 return (
  <Draggable
   onDrag={(e) => {
    // e.preventDefault();
    // console.log(drag.x, drag.y);
   }}
   onStop={(e, data) => {
    // e.preventDefault();
    onStop(textLabel.id, data);
   }}
   onStart={(e) => {
    // e.preventDefault();
    // console.log(drag);
   }}
   handle=".handle"
   defaultPosition={{ x: x, y: y }}
   //    disabled={disabled}
   scale={scale}
   disabled={readOnly}
  >
   <div className="noPan">
    <TextLabelBase
     planId={planId}
     textLabel={textLabel}
     removeTextLabel={removeTextLabel}
     demoMode={demoMode}
     opacity={opacity}
     scale={scale}
     readOnly={readOnly}
    />
   </div>
  </Draggable>
 );
}

const TextLabelBase = ({ planId, textLabel, opacity = "1", readOnly }) => {
 return (
  <div
   className="absolute select-none noPan"
   style={{
    minWidth: "max-content",
    opacity,
    transition: "500ms opacity ease-in-out",
   }}
  >
   <TextLabelInput planId={planId} textLabel={textLabel} readOnly={readOnly} />
  </div>
 );
};
