import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
 useFirebase,
 isLoaded,
 isEmpty,
 useFirestore,
} from "react-redux-firebase";

import Logo from "./branding/Logo";
import Input from "./Input";
import Button from "./Button";
// import GoogleButton from 'react-google-button' // optional

const valid = {
 name: true,
 email: true,
 password: true,
};

function SignupPage() {
 const [name, setName] = useState("");
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const [validation, setValidation] = useState({ ...valid });
 const [errorMessage, setErrorMessage] = useState("");
 const [signingUp, setSigningUp] = useState(false);

 const firebase = useFirebase();
 const firestore = useFirestore();
 const auth = useSelector((state) => state.firebase.auth);

 const handleSignup = async (e) => {
  e.preventDefault();

  if (!name || !email || !password)
   return setValidation({
    name: name === "" ? false : true,
    email: email === "" ? false : true,
    password: password === "" ? false : true,
   });

  setSigningUp(true);

  //   try {
  const user = await firebase
   .createUser({ email, password }, { displayName: name })
   .then((res) => {
    console.log(res);
   })
   .catch((err) => {
    setErrorMessage(err.message);
   });

  //   const userDoc = await firestore
  //    .doc(`users/${auth.uid}`)
  //    .onSnapshot((snapshot) => {
  //     console.log(snapshot);
  //    });

  //   await firestore.collection("users").doc(user.uid).update({ uid: user });

  // CREATE USER DOC

  // REDIRECT TO /
  //   } catch (error) {
  //    setErrorMessage(error.message);
  //   }
 };

 return (
  <div className="flex flex-col justify-center bg-red-500 h-screen w-screen">
   {/* // <GoogleButton/> button can be used instead */}
   {!isLoaded(auth) ? (
    <span>Loading...</span>
   ) : isLoaded(auth) && isEmpty(auth) ? (
    <div className="px-12 py-10 flex flex-col w-11/12 md:w-1/2 max-w-md bg-white dark:bg-gray-900 mx-auto rounded-lg">
     <form
      className="flex flex-col mb-5"
      onSubmit={(e) => {
       e.preventDefault();
       handleSignup(e);
      }}
     >
      <Logo />

      <span className="mt-8 mb-10 h-1 border bg-gray-200 dark:bg-gray-700"></span>

      <div className="mb-8">
       <Input
        name="name"
        label="Name"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="My Name"
        required={true}
        autoComplete="name"
        valid={validation.name}
       />
       <Input
        name="email"
        label="Email"
        type="email"
        css="mt-6"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="me@address.com"
        required={true}
        autoComplete="email"
        valid={validation.email}
       />
       <Input
        name="password"
        label="Password"
        type="password"
        css="mt-6"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="••••••••••"
        required={true}
        autoComplete="new-password"
        valid={validation.password}
       />
      </div>

      <Button
       text={!signingUp ? "Sign up" : "Signing up..."}
       onSubmit={(e) => handleSignup(e)}
       type="submit"
       disabled={signingUp}
      />
     </form>

     {errorMessage !== "" ? (
      <span className="italic mt-4 mx-auto text-red-600">{errorMessage}</span>
     ) : null}
     {/* <span className="mt-2 mb-6 h-1 border bg-gray-100"></span>
     <Button
      text="Login with Google"
      colour="white"
      onClick={loginWithGoogle}
     /> */}
     <span className="mt-6 mb-4 h-1 border-b-2 border-solid border-gray-200 dark:border-gray-700"></span>

     <Link
      className="mt-2 text-gray-700 dark:text-gray-500 focus:outline-none focus:ring rounded text-center"
      to="/login"
     >
      Have an account? Log in →
     </Link>
    </div>
   ) : (
    <Redirect to="/welcome"></Redirect>
   )}
  </div>
 );
}

export default SignupPage;
