import { useEffect, useState } from "react";

export default function useThemeSwitcher() {
 const [currentTheme, setCurrentTheme] = useState(
  localStorage.theme || "default"
 );

 useEffect(() => {
  // On component mount or when changing themes
  if (
   localStorage.theme === "dark" ||
   (!("theme" in localStorage) &&
    window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
   document.documentElement.classList.add("dark");
   setCurrentTheme("dark");
  } else {
   document.documentElement.classList.remove("dark");
   setCurrentTheme("light");
  }
 }, []);

 const setLightTheme = () => {
  localStorage.theme = "light";
  document.documentElement.classList.remove("dark");
  setCurrentTheme("light");
 };

 const setDarkTheme = () => {
  localStorage.theme = "dark";
  document.documentElement.classList.add("dark");
  setCurrentTheme("dark");
 };

 const respectOSPreference = () => {
  localStorage.removeItem("theme");
  if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
   document.documentElement.classList.add("dark");
  } else {
   document.documentElement.classList.remove("dark");
  }
  setCurrentTheme("");
 };

 return { currentTheme, setLightTheme, setDarkTheme, respectOSPreference };
}
