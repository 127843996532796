import React, { useEffect, useRef, useState } from "react";
import {
 Editor,
 EditorState,
 ContentState,
 RichUtils,
 convertFromRaw,
 convertToRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";

import * as planActions from "../actions/planActions";

const colours = [
 "gray",
 "green",
 "yellow",
 "red",
 "blue",
 "orange",
 "indigo",
 "pink",
 "purple",
 "teal",
];

export default function ZoneLabelInput({
 planId,
 zone,
 scale,
 takeSnapshot,
 readOnly = false,
}) {
 const { colour = "gray" } = zone;
 const [start, setStart] = useState({
  x: 0,
  y: 0,
 });
 const [widthAndHeight, setWidthAndHeight] = useState({
  x: zone?.size?.width,
  y: zone?.size?.height,
 });
 const [resizing, setResizing] = useState(false);

 const resizeRef = useRef();

 const [hovered, setHovered] = useState(false);
 const [editorState, setEditorState] = useState(() => {
  if (zone.content) {
   return EditorState.createWithContent(
    convertFromRaw(JSON.parse(zone.content))
   );
  } else {
   return EditorState.createWithContent(
    ContentState.createFromText("New zone")
   );
  }
 });

 const handleKeyCommand = (command, editorState) => {
  const newState = RichUtils.handleKeyCommand(editorState, command);

  if (newState) {
   setEditorState(newState);
   return "handled";
  }

  return "not-handled";
 };

 const handleSave = () => {
  const rawDraftContentState = JSON.stringify(
   convertToRaw(editorState.getCurrentContent())
  );
  planActions.updateZone(planId, zone.id, {
   content: rawDraftContentState,
  });
 };

 const handleResize = (e) => {
  e.preventDefault();
  if (readOnly) return null;
  takeSnapshot();
  setResizing(true);
  setStart({
   x: e.clientX / scale,
   y: e.clientY / scale,
  });
 };

 const onResizeMove = (e) => {
  e.preventDefault();
  if (!resizing) return;

  const newPos = {
   x: e.clientX / scale - start.x,
   y: e.clientY / scale - start.y,
  };
  setWidthAndHeight({
   x: widthAndHeight.x + newPos.x,
   y: widthAndHeight.y + newPos.y,
  });
 };

 const onResizeUp = (e) => {
  e.preventDefault();
  setResizing(false);

  const newPos = {
   x: e.clientX / scale - start.x,
   y: e.clientY / scale - start.y,
  };

  planActions.updateZone(planId, zone.id, {
   size: {
    width: widthAndHeight.x + newPos.x,
    height: widthAndHeight.y + newPos.y,
   },
  });

  document.removeEventListener("mousemove", onResizeMove);
  document.removeEventListener("mouseup", onResizeUp);
 };

 useEffect(() => {
  if (resizing) {
   document.addEventListener("mousemove", onResizeMove);
   document.addEventListener("mouseup", onResizeUp);
  }
 }, [resizing]);

 return (
  <>
   <div
    className={`text-sm cursor-text pointer-events-auto
   text-${colour}-400 
   `}
   >
    <Editor
     onBlur={handleSave}
     editorState={editorState}
     onChange={setEditorState}
     handleKeyCommand={handleKeyCommand}
     spellCheck={true}
     readOnly={readOnly}
    />
   </div>

   <div
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
    className={`rounded relative
   bg-${colour}-400 dark:bg-${colour}-700
   `}
   >
    <div
     className={`handle pt-3 rounded pointer-events-auto
   bg-${colour}-400 dark:bg-${colour}-700
   `}
    ></div>
    <div
     className={`relative border border-solid rounded focus:outline-none focus:ring cursor-default
    bg-${colour}-300 dark:bg-${colour}-800
    border-${colour}-400 dark:border-${colour}-800
    `}
     //  onMouseUp={(e) => {
     //   let w = parseInt(e.target.style.width);
     //   let h = parseInt(e.target.style.height);

     //   if (w === zone.size.width || h === zone.size.height) {
     //    return null;
     //   }

     //   planActions.updateZone(planId, zone.id, {
     //    size: {
     //     width: w,
     //     height: h,
     //    },
     //   });
     //  }}
     style={{
      minWidth: "50px",
      minHeight: "50px",
      width: widthAndHeight?.x,
      height: widthAndHeight?.y,
      // resize: readOnly ? "none" : "both",
      overflow: "hidden",
     }}
    >
     {!readOnly && (
      <span
       ref={resizeRef}
       onMouseDown={handleResize}
       style={{
        cursor: "nwse-resize",
       }}
       className={`absolute noPan rounded-tl-full w-5 h-5 bottom-0 right-0 pointer-events-auto bg-${colour}-700`}
      ></span>
     )}
    </div>
    {hovered && readOnly === false && (
     <div className="flex w-full cursor-default justify-between pt-1 pb-2 pr-2 top-3 right-0 absolute text-sm text-gray-900 pointer-events-auto">
      <div className="flex ml-2">
       {colours.map((c) => {
        return (
         <button
          key={c}
          onClick={() => planActions.updateZone(planId, zone.id, { colour: c })}
          className={`mx-1 w-4 h-4 bg-${c}-300 dark:bg-${c}-800 border border-gray-500 dark:border-gray-700 rounded-sm`}
         ></button>
        );
       })}
      </div>
      <button
       className="px-1 py-0.5 bg-white border broder-solid border-gray-500 text-xs text-gray-900 rounded"
       onClick={() => {
        planActions.removeZone(planId, zone.id);
       }}
      >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-3 w-3"
        viewBox="0 0 20 20"
        fill="currentColor"
       >
        <path
         fillRule="evenodd"
         d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
         clipRule="evenodd"
        />
       </svg>
      </button>
     </div>
    )}
   </div>
  </>
 );
}
