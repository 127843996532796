import React, { useState, useEffect } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import Layout from "./Layout";
import PlanCards from "./PlanCards";
import * as planActions from "../actions/planActions";
import * as filterActions from "../actions/filterActions";
import { AddPlanModal } from "./Modal";
import Button from "./Button";
import EmailSignup from "./EmailSignup";
import useIsMemberOfTeam from "./hooks/useIsMemberOfTeam";

export default function Home({ team = false }) {
 const [addPlanModal, setAddPlanModal] = useState(false);
 const [isLoading, setIsLoading] = useState(false);
 const [gettingPlans, setGettingPlans] = useState(false);
 const { pathname } = useLocation();

 const firestore = useFirestore();

 const uid = useSelector((state) => state.firebase.auth.uid);
 const partOfTeam = useSelector((state) => state.firebase.profile.partOfTeam);
 const filters = useSelector((state) => state.filters);
 const limits = useSelector((state) => state.settings.limits);
 const { isTeamMember } = useIsMemberOfTeam();

 const getPlans = async () => {
  if (gettingPlans) return;
  setGettingPlans(true);
  console.log("getting plans");

  firestore
   .get({
    collection: "plans",
    where: [
     ["author_uid", "==", uid],
     //  ["status", "==", "ACTIVE"],
    ],
    orderBy: ["lastSaved", "desc"],
    // limit: 1,
   })
   .then((res) => {
    let plans = {};
    if (res.docs.length === 0) return setIsLoading(false);
    res.docs.map((doc) => {
     const planDataOmitted = { ...doc.data(), items: [], cables: [] };
     return (plans = {
      ...plans,
      [doc.id]: doc.data(),
     });
     //  return planActions.addPlan(doc.data());
    });
    planActions.addPlans(plans);
    setGettingPlans(false);
    return setIsLoading(false);
   });
 };

 useEffect(() => {
  // if (plans) return null;
  setIsLoading(true);

  if (team === false) {
   getPlans();
  } else {
   if (partOfTeam) {
    // firestore.onSnapshot(
    //  {
    //   collection: "plans",
    //   where: ["author_uid", "==", partOfTeam],
    //   orderBy: ["lastSaved", "desc"],
    //  },
    //  (res) => {
    //   if (res.docs.length === 0) return setIsLoading(false);
    //   res.docs.map((doc) => {
    //    return planActions.addPlan(doc.data());
    //   });
    //   setIsLoading(false);
    //  }
    // );
    firestore
     .get({
      collection: "plans",
      where: ["author_uid", "==", partOfTeam],
      // where: ["_sharing", "==", "team"],
      orderBy: ["lastSaved", "desc"],
     })
     .then((res) => {
      if (res.docs.length === 0) return setIsLoading(false);
      res.docs.map((doc) => {
       return planActions.addPlan(doc.data());
      });
      setGettingPlans(false);
      return setIsLoading(false);
     })
     .catch((err) => {
      console.error("errrr", err);
      setIsLoading(false);
     });
   }
  }

  // eslint-disable-next-line
 }, [partOfTeam, pathname]);
 return (
  <Layout title="Plans">
   <div className="px-6 py-6 flex flex-row bg-red-500 md:flex-col md:px-0 md:py-0 md:bg-gray-200 md:dark:bg-transparent">
    <div className="flex items-center">
     <h1 className="text-2xl font-bold text-white md:text-gray-800 md:dark:text-gray-100">
      {!team ? "Plans" : "Team plans"}
     </h1>
     <div className="ml-8 hidden md:flex items-center">
      <Button
       disabled={
        (team || isTeamMember) && partOfTeam
         ? false
         : limits.total >= limits.limit && true
       }
       text="Add plan"
       icon="add"
       onClick={() => setAddPlanModal(true)}
      />

      {!team &&
       !isTeamMember &&
       limits.total >= limits.limit &&
       limits.limit > 0 && (
        <span className="italic ml-4 text-gray-700 dark:text-gray-400 text-sm">
         You've hit your max for plans. Archive old ones or{" "}
         {
          <Link className="underline" to="/upgrade">
           upgrade for more
          </Link>
         }
         !
        </span>
       )}

      {addPlanModal && (
       <AddPlanModal
        onCancel={() => {
         filterActions.clearFilterNewPlan();
         return setAddPlanModal(false);
        }}
        team={team}
        author={partOfTeam}
       ></AddPlanModal>
      )}
     </div>
    </div>

    <div className="hidden md:visible">
     <EmailSignup />
    </div>

    <div className="relative">
     <input
      type="text"
      className="mt-6 px-6 h-12 w-full rounded-lg focus:outline-none focus:shadow-lg hidden md:block md:bg-gray-100 dark:md:bg-gray-900 md:focus:bg-white dark:md:focus:bg-gray-700 text-gray-900 dark:text-gray-100"
      placeholder="Search plans..."
      onChange={(e) => filterActions.updateFilterText(e.target.value)}
      value={filters.text}
     />
    </div>
   </div>
   <div className="mt-4 md:mt-0 px-4 pb-4 flex justify-start overflow-auto  md:text-gray-600 md:pt-6 md:pb-0 md:bg-gray-200 dark:md:bg-transparent md:justify-start">
    <button
     className={`ml-2 px-4 py-3 border-b-2 border-solid flex justify-center items-center md:ml-0 md:hover:text-gray-500 focus:outline-none focus:border-red-200 md:focus:border-red-400 md:border-gray-200 ${
      filters.tab === "no-date"
       ? "px-4 py-3 text-gray-900 dark:text-white  border-white font-semibold flex justify-center items-center  md:border-red-500"
       : "border-gray-500"
     }`}
     onClick={() => {
      filterActions.updateFilterTab("no-date");
     }}
    >
     <svg className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
       d="M4.268 14.837A11.615 11.615 0 0110 13.333c2.083 0 4.04.546 5.732 1.504M12.5 8.333a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm5 1.667a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
       stroke="currentColor"
       strokeWidth="2"
       strokeLinecap="round"
       strokeLinejoin="round"
      />
     </svg>
     <div className="ml-2 whitespace-no-wrap ">Plans</div>
    </button>
    <button
     className={`ml-2 px-4 py-3 border-b-2 border-solid flex justify-center items-center md:hover:text-gray-500 focus:outline-none focus:border-red-200 md:focus:border-red-400 md:border-gray-200 ${
      filters.tab === "coming-up"
       ? "px-4 py-3 text-gray-900 dark:text-white border-white font-semibold flex justify-center items-center md:border-red-500"
       : "border-gray-500"
     }`}
     onClick={() => {
      filterActions.updateFilterTab("coming-up");
     }}
    >
     <svg className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
       fill="none"
       stroke="currentColor"
       strokeWidth="2"
       fillRule="evenodd"
       clipRule="evenodd"
       d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
      />
     </svg>
     <div className="ml-2 whitespace-no-wrap">Coming up</div>
    </button>
    <button
     className={`ml-2 px-4 py-3 border-b-2 border-solid flex justify-center items-center md:hover:text-gray-500 focus:outline-none focus:border-red-200 md:focus:border-red-400 md:border-gray-200 ${
      filters.tab === "past"
       ? "px-4 py-3 text-gray-900 dark:text-white border-white font-semibold flex justify-center items-center md:border-red-500"
       : "border-gray-500"
     }`}
     onClick={() => {
      filterActions.updateFilterTab("past");
     }}
    >
     <svg className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
       fill="currentColor"
       fillRule="evenodd"
       clipRule="evenodd"
       d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 01-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
      />
     </svg>
     <div className="ml-2 whitespace-no-wrap">Past</div>
    </button>
    <button
     className={`ml-2 px-4 py-3 border-b-2 border-solid flex justify-center items-center md:hover:text-gray-500 focus:outline-none focus:border-red-200 md:focus:border-red-400 md:border-gray-200 ${
      filters.tab === "archived"
       ? "px-4 py-3 text-gray-900 dark:text-white border-white font-semibold flex justify-center items-center md:border-red-500"
       : "border-gray-500"
     }`}
     onClick={() => {
      filterActions.updateFilterTab("archived");
     }}
    >
     <svg className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
       d="M8.333 10h3.334m-7.5-3.333h11.666H4.167zm0 0a1.667 1.667 0 010-3.334h11.666a1.666 1.666 0 110 3.334H4.167zm0 0V15a1.667 1.667 0 001.666 1.667h8.334A1.667 1.667 0 0015.833 15V6.667H4.167z"
       stroke="currentColor"
       strokeWidth="2"
       strokeLinecap="round"
       strokeLinejoin="round"
      />
     </svg>
     <div className="ml-2 whitespace-no-wrap">Archived</div>
    </button>

    {/*   <button className="ml-2 px-4 py-3 flex justify-center items-center md:hover:text-gray-800">
     <svg className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
       fill="currentColor"
       d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm0 6a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zm11-1a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
      />
     </svg>
     <div className="ml-2 whitespace-no-wrap">Templates</div>
    </button> */}
   </div>
   <div className="px-4 mt-6 md:px-0">
    <div
     className={`mr-10 text-xs font-light uppercase text-right
                  ${
                   filters.tab === "archived"
                    ? "text-gray-200"
                    : "text-gray-500 "
                  }
        `}
    >
     % Packed
    </div>

    <PlanCards isLoading={isLoading} team={team} />
   </div>
  </Layout>
 );
}
