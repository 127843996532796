import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export default function useGetCableColour(cable) {
 const [c, setC] = useState({ colour: "#111111", style: "solid" });

 const userCableTypes = useSelector((state) => state.settings.cableTypes) || [];

 useEffect(() => {
  const cableColour = userCableTypes.filter((c) => {
   if (c?.name === cable) {
    return c;
   }
  });
  if (cableColour.length >= 1) {
   setC({ colour: cableColour?.[0]?.colour, style: cableColour?.[0]?.style });
  } else {
   console.log("USEGET No cable colour found for", cable);
   setC({ colour: "#111111", style: "solid" });
  }
 }, [userCableTypes, cable]);

 return c;
}
