import { saveFirestorePacklistDoc } from "../db/firestore";

const addCable = (cables, cable) => {
 let newCablesArray = [];

 let cableString;

 if (!cable.fromType) {
  cableString = cable.type;
 } else if (cable.fromType === cable.toType) {
  cableString = cable.fromType;
 } else {
  cableString = `${cable.fromType} to ${cable.toType}`;
 }

 if (cables.length === 0) {
  newCablesArray.push({ type: cableString, amount: 1, packed: false });
 } else {
  let filtered = cables.filter((c) => c.type === cableString);

  if (filtered.length === 0) {
   newCablesArray = [...cables];

   newCablesArray.push({ type: cableString, amount: 1, packed: false });
  } else {
   newCablesArray = cables.map((c) => {
    if (c.type === cableString) {
     return {
      type: c.type,
      packed: false,
      amount: c.amount + 1,
     };
    } else {
     return c;
    }
   });
  }
 }

 return newCablesArray;
};

const removeCable = (cables, cable) => {
 let newCablesArray = [];

 console.log(cable);

 let cableString;

 if (!cable.fromType) {
  cableString = cable.type;
 } else if (cable.fromType === cable.toType) {
  cableString = cable.fromType;
 } else {
  cableString = `${cable.fromType} to ${cable.toType}`;
 }

 if (cables.length === 0) {
  return [...cables];
 } else {
  let filtered = cables.filter((c) => c.type === cableString);

  if (filtered.length === 0) {
   return [...cables];
  } else {
   newCablesArray = cables.map((c) => {
    if (c.type === cableString) {
     return {
      type: c.type,
      packed: false,
      amount: c.amount - 1,
     };
    } else {
     return c;
    }
   });
  }
 }
 console.log(cables, "REMOVE A CABLE FROM THE PACKLIST");

 return newCablesArray;
};

export default function packlistsReducer(state = {}, action) {
 let packlist = action.packlist;

 switch (action.type) {
  case "ADD_PACKLIST":
   return {
    ...state,
    [action.id]: {
     ...packlist,
    },
   };

  case "ADD_PACKLIST_ITEM":
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     items: [...state[action.id].items, { ...action.item }],
    },
   };

  case "ADD_PACKLIST_CABLE":
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     cables: [...addCable(state[action.id].cables, action.cable)],
    },
   };

  case "REMOVE_PACKLIST_CABLE":
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     cables: [...removeCable(state[action.id].cables, action.cable)],
    },
   };

  case "ADD_PACKLIST_EXTRA":
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     extras: [...(state[action.id].extras || []), { ...action.item }],
    },
   };

  case "REMOVE_PACKLIST_EXTRA":
   return {
    ...state,
    [action.id]: {
     ...state[action.id],
     extras: [
      ...state[action.id].extras.filter((item, i) => {
       if (i !== action.index) {
        return item;
       }
      }),
     ],
    },
   };

  case "SAVE_PACKLIST":
   return saveFirestorePacklistDoc(action.packlistId, state);

  default:
   return state;
 }
}
