import React from "react";
import { Link } from "react-router-dom";

import Logo from "./branding/Logo";
import Button from "./Button";

export default function Welcome() {
 return (
  <div className="flex flex-col justify-center bg-red-500 h-screen w-screen">
   <div className="px-12 py-10 flex flex-col max-w-sm bg-white mx-auto rounded-lg">
    <div className="flex flex-col mb-5">
     <Logo />

     <span className="mt-8 mb-10 h-1 border bg-gray-200"></span>

     <p className="leading-snug font-semibold">Account setup complete.</p>
     <p className="mt-4 leading-snug text-gray-700">
      If you need any help along the way, head over to{" "}
      {<Link to="/help">/help</Link>}, or send an email to
      support@heretorecord.com.
     </p>

     <Link to="/plans" className="mt-6">
      <Button text="Let's get started..." />
     </Link>
    </div>
   </div>
  </div>
 );
}
