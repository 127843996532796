const initialState = {
 emptyGearItem: {
  _private: true,
  _team: false,
  name: "Gear Item",
  manufacturer: "",
  id: "",
  type: "",
  colour: "#b6b6b6",
  secondaryColour: "#555555",
  cssClassName: "",
  location: { x: 0, y: 0 },
  width: 100,
  height: 94,
  inputs: [{ name: "In 1", type: "hdmi", category: "video" }],
  outputs: [{ name: "Out 1", type: "hdmi", category: "video" }],
  searchTerms: ["gear, item"],
 },
};

const templates = {
 inputs: [{ name: "", type: "", category: "" }],
 outputs: [{ name: "", type: "", category: "" }],
};

const reorderList = (state, source, destination) => {
 const newState = Array.from(state);

 newState.splice(source.index, 1);
 newState.splice(destination.index, 0, state[source.index]);

 return [...newState];
};

const addItem = (stateSource, stateDestination, source, destination) => {
 const newState = Array.from(stateDestination);

 newState.splice(destination.index, 0, stateSource[source.index]);

 return [...newState];
};

const removeItem = (stateSource, source, destination) => {
 const newState = Array.from(stateSource);

 newState.splice(source.index, 1);

 return [...newState];
};

const updateItem = (oldArray, position, update) => {
 const newArray = Array.from(oldArray);

 newArray.splice(position, 1, { ...oldArray[position], ...update });

 return [...newArray];
};

export default function filtersReducer(state = initialState, action) {
 switch (action.type) {
  case "UPDATE_EMPTY_ITEM":
   return {
    ...state,
    emptyGearItem: {
     ...state.emptyGearItem,
     ...action.update,
    },
   };

  // case "UPDATE_INPUT_OUTPUT":
  //   console.log()
  //  return {
  //   ...state,
  //   emptyGearItem: {
  //    ...state.emptyGearItem,
  //    [action.inOrOut]: [
  //     ...state.emptyGearItem[action.inOrOut].map((item, i) => {
  //      if (i === action.index) {
  //       return {
  //        ...item,
  //        ...action.update,
  //       };
  //      } else {
  //       return item;
  //      }
  //     }),
  //    ],
  //   },
  //  };
  case "UPDATE_INPUT_OUTPUT":
   //  console.log(action, "action");
   return {
    ...state,
    emptyGearItem: {
     ...state.emptyGearItem,
     [action.inOrOut]: [
      ...updateItem(
       state.emptyGearItem[action.inOrOut],
       action.index,
       action.update
      ),
     ],
    },
   };

  case "ADD_INPUT_OUTPUT":
   return {
    ...state,
    emptyGearItem: {
     ...state.emptyGearItem,
     [action.inOrOut]: [
      ...state.emptyGearItem[action.inOrOut],
      action.object ? action.object : templates[action.inOrOut][0],
     ],
    },
   };

  case "REMOVE_INPUT_OUTPUT":
   return {
    ...state,
    emptyGearItem: {
     ...state.emptyGearItem,
     [action.inOrOut]: [
      ...state.emptyGearItem[action.inOrOut].filter((item, i) => {
       if (i !== action.index) {
        return {
         ...item,
        };
       }
      }),
     ],
    },
   };

  case "REORDER_INPUT_OUTPUT":
   return {
    ...state,
    emptyGearItem: {
     ...state.emptyGearItem,
     [action.inOrOut]: [
      ...reorderList(
       state.emptyGearItem[action.inOrOut],
       action.source,
       action.destination
      ),
     ],
    },
   };

  case "MOVE_INPUT_OUTPUT":
   return {
    ...state,
    emptyGearItem: {
     ...state.emptyGearItem,
     [action.destination.droppableId]: [
      ...addItem(
       state.emptyGearItem[action.source.droppableId],
       state.emptyGearItem[action.destination.droppableId],
       action.source,
       action.destination
      ),
     ],

     [action.source.droppableId]: [
      ...removeItem(
       state.emptyGearItem[action.source.droppableId],
       action.source,
       action.destination
      ),
     ],
    },
   };

  case "UPDATE_WIDTH":
   return {
    ...state,
    emptyGearItem: {
     ...state.emptyGearItem,
     width: action.width,
    },
   };

  case "UPDATE_HEIGHT":
   return {
    ...state,
    emptyGearItem: {
     ...state.emptyGearItem,
     height: action.height,
    },
   };

  case "CLEAR_EMPTY":
   return {
    ...state,
    emptyGearItem: {
     ...initialState.emptyGearItem,
    },
   };

  case "LOAD_EDIT":
   return {
    ...state,
    emptyGearItem: {
     ...initialState.emptyGearItem,
     ...action.item,
    },
   };

  default:
   return state;
 }
}
