import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

function classNames(...classes) {
 return classes.filter(Boolean).join(" ");
}

export default function FlyoutMenu({ title = "", children }) {
 return (
  <Popover className="relative">
   {({ open }) => (
    <>
     <Popover.Button
      className={classNames(
       open
        ? "text-gray-900 dark:text-gray-100"
        : "text-gray-700 dark:text-gray-300",
       "group bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 rounded transition inline-flex items-center text-base focus:outline-none focus:ring"
      )}
     >
      <span className="px-1">{title}</span>
      <ChevronUpIcon
       className={classNames(
        open ? "text-gray-600" : "text-gray-400",
        "ml-2 h-5 w-5 group-hover:text-gray-500"
       )}
       aria-hidden="true"
      />
     </Popover.Button>

     <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-0"
      enterTo="opacity-100 -translate-y-1"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 -translate-y-1"
      leaveTo="opacity-0 translate-y-0"
     >
      <Popover.Panel className="p-2 fixed z-10 bottom-0 transform -translate-y-10 mt-3 w-screen max-w-xs bg-white dark:bg-gray-800 border border-solid border-gray-400 dark:border-gray-700 shadow rounded flex flex-col items-start space-y-4">
       {children}
      </Popover.Panel>
     </Transition>
    </>
   )}
  </Popover>
 );
}
