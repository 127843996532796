import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import * as notificationActions from '../actions/notificationsActions'
import Input from '../components/Input'

import Layout from "./Layout";
import NotFound from "./NotFound";
import Button from "./Button";

export default function Admin() {
  const [uidToConvert, setUidToConvert] = useState('')
  const [moveItemsFrom, setMoveItemsFrom] = useState('')
  const [moveItemsto, setMoveItemsto] = useState('')

 const auth = useSelector((state) => state.firebase.auth);
 const firestore = useFirestore();

 const isAdmin = auth.uid === "Iai0VqgAkCOHAbdTxe5O6wOkgLu2";

 const convertToTeam = async () => {
  if(!uidToConvert) return null
    await firestore
     .collection("teams")
     .doc(uidToConvert)
     .set({
      admin: uidToConvert,
      limits: {
       members: 3,
       plans: 150,
       sharing: true
      },
      members: [uidToConvert],
      name: "My team name",
     })
     .then(async (res) => {
      return res;
     })
     .catch((err) => {
      return console.error(err);
     });

    await firestore
     .collection("users")
     .doc(uidToConvert)
     .update({
      partOfTeam: uidToConvert,
     })
     .then(async (res) => {
      return res;
     })
     .catch((err) => {
      return console.error(err);
     });

   return notificationActions.addNotification(
    `Team created`,
    `${uidToConvert}`,
    "success",
    20000
   );
 }

const handleMoveItems = async () => {
  if(!moveItemsFrom) return null
  if(!moveItemsto) return null

  return await firestore.collection('items').where('uid', '==', moveItemsFrom).onSnapshot(async (snapshot)=>{
    if(!snapshot.docs?.length) return notificationActions.addNotification(
      `No items found`,
      `${moveItemsFrom}`,
      "fail",
      20000
     );

     const updatePromises = snapshot.docs.map((doc) =>
      firestore.collection('items').doc(doc.id).update({ uid: moveItemsto })
    );
 
   await Promise.all(updatePromises);
 
   return notificationActions.addNotification(
     `${snapshot.docs.length} items moved`,
     `From ${moveItemsFrom} to ${moveItemsto}`,
     "success",
     20000
   );

  })

}

 if (isAdmin) {
  return (
   <Layout title="Admin">
    <div className="px-6 py-6 flex flex-row md:flex-col md:px-0 md:py-0 ">
     <div className="flex items-center">
      <h1 className="text-2xl font-bold text-white">Admin</h1>
     </div>
    </div>

    <div className="px-4 mt-6 md:px-0">
     <h2 className="text-xl font-semibold">Convert to team</h2>
      <div className="flex items-end w-100 gap-6">
        <Input placeholder="uid" value={uidToConvert} onChange={(e)=>{setUidToConvert(e.target.value)}} />
        <Button text="Convert" onClick={convertToTeam} /> 
      </div>
    </div>

    <div className="px-4 mt-6 md:px-0">
     <h2 className="text-xl font-semibold">Move items</h2>
      <div className="flex items-end w-100 gap-6">
        <Input placeholder="uid" value={moveItemsFrom} onChange={(e)=>{setMoveItemsFrom(e.target.value)}} />
        <span>to</span>
        <Input placeholder="uid" value={moveItemsto} onChange={(e)=>{setMoveItemsto(e.target.value)}} />
        <Button text="Move" onClick={handleMoveItems} /> 
      </div>
    </div>

   
   </Layout>
  );
 } else {
  return <NotFound />;
 }
}
