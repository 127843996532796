import { store } from "../db/store";

/** addTeamData
 * @param {object} teamData - Team data to add
 */
export const addTeamData = (teamData) => {
 return store.dispatch({
  type: "ADD_TEAM",
  teamData,
 });
};

/** editTeamName
 * @param {object} newName - New name for the team
 */
export const editTeamName = (newName) => {
 return store.dispatch({
  type: "EDIT_TEAM_NAME",
  newName,
 });
};
