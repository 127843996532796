import { RichUtils } from "draft-js";
import { EditorState } from "draft-js";
import { Editor } from "draft-js";
import { ContentState } from "draft-js";
import { convertFromRaw } from "draft-js";
import { convertToRaw } from "draft-js";
import React, { useState } from "react";
import Draggable from "react-draggable";
import * as planActions from "../../actions/planActions";
import * as notificationsActions from "../../actions/notificationsActions";
import { useFirebase } from "react-redux-firebase";
import ImgToCanvas from "../ImageToCanvas";
import Button from '../Button'

const boxStyle = {
 border: "none",
 borderRadius: "10px",
 padding: "5px",
};

export const shapeColourOptions = [
 "gray",
 "green",
 "purple",
 "blue",
 "orange",
 "teal",
 "pink",
];

const sizeOptions = ["extrasmall", "small", "medium", "large"];
const sizeMap = {
 extrasmall: {
  height: 5,
  width: 5,
 },
 small: {
  height: 10,
  width: 10,
 },
 medium: {
  height: 20,
  width: 20,
 },
 large: {
  height: 30,
  width: 30,
 },
};

export default function SketchItem({
 planId,
 item,
 onDrag,
 onStop,
 onStart,
 scale,
 handleCableConnectionStart,
 handleCableConnectionEnd,
 updateItem,
 onDrawing = false,
 disabled,
 drawing,
 addRemoveSelected,
 selected,
 opacity = 1,
 showExportModal,
 deleteGearItem,
}) {
 const [uploading, setUploading] = useState(false);
 const firebase = useFirebase();

 const handleFileUpload = async (file) => {
  // 1MB max file size limit
  if (file.size >= 1097152)
   return notificationsActions.addNotification(
    "File too large",
    "This file is larger than the 1MB limit.",
    "fail"
   );

  setUploading(true);

  notificationsActions.addNotification(
   "Uploading file...",
   "This will happen in the background. Don't leave this page.",
   "success"
  );

  let d = new Date().getTime();
  await firebase
   .uploadFile(`plans/${planId}/items`, file, "", {
    name: d,
   })
   .then((res) => {
    console.log(res);

    res.uploadTaskSnapshot.ref.getDownloadURL().then((downloadURL) => {
     //  builderActions.updateEmptyItem({ img: downloadURL });
     planActions.editItem(planId, item.id, { img: downloadURL });
     console.log("fileuploaded", downloadURL);
    });
    setUploading(false);
   })
   .catch((err) => {
    console.error(err);
    setUploading(false);
   });
 };

 return (
  <Draggable
   onDrag={onDrag}
   onStop={onStop}
   onStart={onStart}
   handle=".handle"
   cancel=".nohandle"
   position={{ x: item.location.x, y: item.location.y }}
   disabled={disabled}
   positionOffset={{ x: "-50%", y: "-50%" }}
   scale={scale}
   onMouseDown={() => {
    addRemoveSelected(item.id);
   }}
  >
   <div
    id={`item_${item.id}`}
    style={boxStyle}
    className={`w-max flex items-center justify-center group h-0 absolute
                ${item.img && " "}
                `}
   >
    <div className="w-full flex justify-around opacity-0 group-hover:opacity-100 transition transform translate-x-8 group-hover:translate-x-0">
     {/* LEFT */}
     {/* {Array(1)
      .fill()
      .map((_, index) => {
       if (disabled) return null;
       return (
        <span
         key={index}
         data-type="shape"
         onMouseDown={(e) => {
          handleCableConnectionStart(item.id, 1, "shape", e, true, "input");
         }}
         onMouseUp={(e) => {
          handleCableConnectionEnd(item.id, 1, "shape", e, false, "input");
         }}
         className="m-1 text-xs text-transparent w-3 h-3 border-2 border-solid border-gray-500 bg-gray-100 hover:bg-gray-500 rounded-full"
        >
         •
        </span>
       );
      })} */}
    </div>
    <div className=" flex flex-col justify-center items-center ">
     <div className="w-full flex justify-around opacity-0 group-hover:opacity-100 transition transform translate-y-8 group-hover:translate-y-0">
      {/* TOP */}
      {/* {Array(1)
       .fill()
       .map((_, index) => {
        if (disabled) return null;
        return (
         <span
          key={index}
          data-type="shape"
          onMouseDown={(e) => {
           handleCableConnectionStart(item.id, 1, "shape", e, true, "input");
          }}
          onMouseUp={(e) => {
           handleCableConnectionEnd(item.id, 1, "shape", e, false, "input");
          }}
          className="m-1 text-xs text-transparent w-3 h-3 border-2 border-solid border-gray-500 bg-gray-100 hover:bg-gray-500 rounded-full"
         >
          •
         </span>
        );
       })} */}
     </div>

     <span
      id={item.id}
      style={{
       opacity,
       transition: "500ms opacity ease-in-out",
      }}
      onMouseUp={(e) => {
       if (!drawing) return null;
       handleCableConnectionEnd(item.id, 1, "shape", e, false, "input");
      }}
      className={`handle text-gray-900 dark:text-white relative flex flex-col justify-center items-center w-max transition-all
                ${disabled ? "!cursor-default" : "!cursor-drag"}
                ${
                 false
                  ? ``
                  : `bg-${item.colour}-300 dark:bg-${item.colour}-800 shadow-sm`
                }
                ${item.size === "extrasmall" && "px-2 py-1 text-xxs rounded-sm"}
                ${item.size === "small" && "px-3 py-2 text-sm rounded"}
                ${item.size === "medium" && "px-5 py-3 text-base rounded"}
                ${item.size === "large" && "px-10 py-6 text-xl rounded-lg"}

                ${drawing && "ring-2 ring-transparent hover:ring-blue-400"}

                ${
                 selected && !disabled
                  ? "ring-2 ring-offset-4 ring-blue-500 ring-offset-white dark:ring-offset-gray-900"
                  : "shadow-md"
                }
                
   `}
     >
      {item.img && (
       <ImgToCanvas
        src={item.img || ""}
        size={item.size}
        useCanvas={showExportModal}
       />
      )}

      <TextLabelInput
       planId={planId}
       itemId={item?.id}
       textLabel={{ content: item?.content }}
       cableLabel={false}
       readOnly={disabled}
      />

      {/* MENU BAR */}
      {!disabled && selected && (
       <div
        className={[
         "absolute -top-12 py-1 z-[1000] px-2 w-max bg-gray-200 dark:bg-black border border-solid border-white/20 shadow-sm flex items-center justify-center space-x-2 rounded transition transform translate-y-8 group-hover:translate-y-0 pointer-events-auto",
         drawing ? "opacity-0" : "opacity-0 group-hover:opacity-100",
        ].join(" ")}
       >
        <div className="flex space-x-2">
         {sizeOptions.map((size) => {
          return (
           <SizeOption
            key={size}
            item={item}
            size={size}
            updateItem={updateItem}
           />
          );
         })}
        </div>
        {/* SPACER */}
        <span className="h-6 w-0.5 rounded block bg-white/20"></span>
        <div className="flex space-x-1">
         {shapeColourOptions.map((colour) => {
          return (
           <ColourOption
            key={colour}
            item={item}
            colour={colour}
            updateItem={updateItem}
           />
          );
         })}
        </div>
        {/* SPACER */}
        <span className="h-6 w-0.5 rounded block bg-white/20"></span>
        {item.img ? (
         <button
          onClick={() => planActions.editItem(planId, item.id, { img: null })}
          className={`px-1 cursor-pointer flex items-center justify-center rounded border-2 border-solid 
         bg-gray-300 dark:bg-gray-800 border-gray-700 hover:border-white`}
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 24 24"
           strokeWidth="1.5"
           stroke="currentColor"
           className="w-6 h-6"
          >
           <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="m2.25 15.75 5.159-5.159m8.98 1.762a2.249 2.249 0 0 1 2.452.488l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
           />
           <path strokeLinecap="round" strokeWidth="2" d="m5 3 15.2 18.1" />
          </svg>
         </button>
        ) : (
         <label
          className={`px-1 cursor-pointer flex items-center justify-center rounded border-2 border-solid 
              bg-gray-300 dark:bg-gray-800 border-gray-700 hover:border-white`}
          htmlFor="addImage"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 24 24"
           strokeWidth="1.5"
           stroke="currentColor"
           className="w-6 h-6"
          >
           <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
           />
          </svg>
         </label>
        )}
        <input
         className="hidden"
         id="addImage"
         type="file"
         accept="image/*"
         disabled={uploading}
         onChange={(e) => {
          if (e.target.files.length > 0) {
           handleFileUpload(e.target.files[0]);
          }
         }}
        />
        {/* SPACER */}
        <span className="h-6 w-0.5 rounded block bg-white/20"></span>
        <Button icon='delete' size="small" colour='white' onClick={()=>deleteGearItem(item.id) } />
       </div>
      )}
     </span>

     <div className="w-full flex justify-around  opacity-0 group-hover:opacity-100 transition transform -translate-y-8 group-hover:translate-y-0">
      {/* BOTTOM */}
      {Array(1)
       .fill()
       .map((_, index) => {
        if (disabled) return null;
        return (
         <span
          key={index}
          data-type="shape"
          onMouseDown={(e) => {
           handleCableConnectionStart(item.id, 1, "shape", e, true, "output");
          }}
          // onMouseUp={(e) => {
          //  handleCableConnectionEnd(item.id, 1, "shape", e, false, "output");
          // }}
          className="m-1 text-xs text-transparent w-3 h-3 border-2 border-solid border-gray-500 bg-gray-100 hover:bg-gray-500 rounded-full"
         >
          •
         </span>
        );
       })}
     </div>
    </div>
    <div className="w-full flex justify-around  opacity-0 group-hover:opacity-100 transition transform -translate-x-8 group-hover:translate-x-0">
     {/* RIGHT */}
     {/* {Array(1)
      .fill()
      .map((_, index) => {
       if (disabled) return null;
       return (
        <span
         key={index}
         data-type="shape"
         onMouseDown={(e) => {
          handleCableConnectionStart(item.id, 1, "shape", e, true, "input");
         }}
         onMouseUp={(e) => {
          handleCableConnectionEnd(item.id, 1, "shape", e, false, "input");
         }}
         className="m-1 text-xs text-transparent w-3 h-3 border-2 border-solid border-gray-500 bg-gray-100 hover:bg-gray-500 rounded-full"
        >
         •
        </span>
       );
      })} */}
    </div>
   </div>
  </Draggable>
 );
}

const SizeOption = ({ item, size, updateItem }) => {
 return (
  <button
   className={`text-sm text-gray-800 dark:text-gray-100 bg-gray-100 dark:bg-gray-800 rounded ring-2 ring-solid
              ${
               item.size === size
                ? "ring-gray-100"
                : "ring-gray-700 hover:ring-gray-100"
              }
   
   `}
   onClick={() =>
    updateItem(item.id, {
     size,
     height: sizeMap[size].height,
     width: sizeMap[size].width,
    })
   }
  >
   <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`h-6 w-6 transform
                ${size === "extrasmall" && "scale-[.3]"}
                ${size === "small" && "scale-50"}
                ${size === "medium" && "scale-75"}
         `}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
   >
    <path
     strokeLinecap="round"
     strokeLinejoin="round"
     d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
    />
   </svg>
  </button>
 );
};

const ColourOption = ({ item, colour, updateItem }) => {
 return (
  <button
   className={`w-5 h-5 rounded-full border-2 border-solid 
              bg-${colour}-300 dark:bg-${colour}-800 
              ${
               item.colour === colour
                ? "border-gray-100"
                : "border-gray-700 hover:border-white"
              }
   `}
   onClick={() => updateItem(item.id, { colour })}
  ></button>
 );
};

export const TextLabelInput = ({
 planId,
 itemId,
 textLabel = {},
 cableLabel = true,
 readOnly = false,
}) => {
 const { colour = "gray-300", content = "" } = textLabel;

 const [editorState, setEditorState] = useState(() => {
  if (content) {
   return EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
  } else {
   return EditorState.createWithContent(ContentState.createFromText(content));
  }
 });

 const handleKeyCommand = (command, editorState) => {
  const newState = RichUtils.handleKeyCommand(editorState, command);

  if (newState) {
   setEditorState(newState);
   return "handled";
  }

  return "not-handled";
 };

 const handleSave = () => {
  const rawDraftContentState = JSON.stringify(
   convertToRaw(editorState.getCurrentContent())
  );
  planActions.editItem(planId, itemId, {
   content: rawDraftContentState,
  });
 };

 return (
  <div
   className={`rounded relative nohandle
    ${cableLabel ? "bg-gray-300" : "bg-transparent"}
    
    `}
  >
   <div
    className={` text-center border-solid cursor-text rounded focus:outline-none focus:ring 
     
     ${
      cableLabel
       ? `bg-white border-${colour} border px-2 py-2 `
       : "bg-transparent"
     }
     `}
    style={{ minWidth: "10px" }}
   >
    <Editor
     onBlur={handleSave}
     editorState={editorState}
     onChange={setEditorState}
     handleKeyCommand={handleKeyCommand}
     spellCheck={true}
     readOnly={readOnly}
     textAlignment={`center`}
    />
   </div>
  </div>
 );
};
