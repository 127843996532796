import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import PlanCard from "./PlanCard";

export default function PlanCards({ isLoading, team = false }) {
 const uid = useSelector((state) => state.firebase.auth.uid);
 const plans = useSelector((state) => state.plans);
 const filters = useSelector((state) => state.filters);

 const plansFiltered = Object.entries(plans)
  .filter(([id, plan]) => {
   if (team && !plan?._sharing?.includes("team")) {
    return false;
   } else if (!team && plan.author_uid !== uid) {
    return false;
   } else if (!team && plan?._sharing?.includes("team")) {
    return false;
   } else {
    return true;
   }
  })
  .filter(([id, plan]) => {
   // if (plan.status && plan.status !== "ACTIVE") {
   //  return false;
   // }
   if (filters.text.toLowerCase() === "") {
    // console.log(id, plan.event_end_date.toMillis() - Date.now());
    if (filters.tab === "coming-up") {
     if (plan.status === "ARCHIVED") return false;
     if (plan.event_end_date && plan.event_end_date.toMillis() >= Date.now()) {
      return true;
     } else {
      return false;
     }
    } else if (filters.tab === "archived") {
     if (plan.status === "ARCHIVED") {
      return true;
     } else {
      return false;
     }
    } else if (filters.tab === "past") {
     if (plan.status === "ARCHIVED") return false;
     if (plan.event_end_date && plan.event_end_date.toMillis() <= Date.now()) {
      return true;
     } else {
      return false;
     }
    } else {
     if (plan.status === "ARCHIVED") return false;
     // if (plan.event_end_date === null) {
     //  return true;
     // } else {
     //return false;
     // }
    }
   }

   if (
    plan.name &&
    (plan.name.toLowerCase().includes(filters.text.toLowerCase()) ||
     plan.internalJobId?.toLowerCase().includes(filters.text.toLowerCase()))
   ) {
    return true;
   } else {
    return false;
   }
  });

 if (isLoading) {
  return (
   <div className={"mt-6"}>
    <PlanCard empty={true} />
   </div>
  );
 } else if (plansFiltered.length === 0) {
  return (
   <p className="italic mt-10 text-gray-600">{isLoading} No plans to show.</p>
  );
 } else {
  return (
   <Fragment>
    {plansFiltered
     .sort(([f, a], [i, b]) => {
      return b.created.seconds - a.created.seconds;
     })
     .map(([id, plan]) => {
      return (
       <div key={id} className={"mt-6"}>
        <PlanCard plan={plan} />
       </div>
      );
     })}
   </Fragment>
  );
 }
}
