import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// import "react-dates/initialize";
// import "react-dates/lib/css/_datepicker.css";

Sentry.init({
 dsn: "https://172406ecf0b646e9a2d523bdb8631370@o1307447.ingest.sentry.io/6551905",
 integrations: [new BrowserTracing()],

 // Set tracesSampleRate to 1.0 to capture 100%
 // of transactions for performance monitoring.
 // We recommend adjusting this value in production
 tracesSampleRate: 0.5,
});

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
// ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
