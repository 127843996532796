import { biDirectionalCables } from "../db/categories";

const calculateCables = (to, from, cable) => {
 //  console.log(ltr, cable);
 let points;
 let { pin = { x: null, y: null }, pin2 = { x: null, y: null } } = cable;
 // LINE IS DRAWN FROM THE END TO THE START
 const distanceFromElement = biDirectionalCables.includes(cable?.type) ? 5 : 8;

 const halfDistanceBetweenElements =
  (to[0]?.location?.y - from[0]?.location?.y - from[0]?.height) / 2 +
  4 * cable?.fromPosition +
  cable?.toPosition;

 // IF END ELEMENT IS PLACED ABOVE THE START ELEMENT
 if (
  to[0]?.location?.y >=
  from[0]?.location?.y + from[0]?.height + distanceFromElement * 4
 ) {
  const toX =
   to[0]?.location?.x +
   (to[0]?.width / to[0]?.inputs.length) * (cable?.toPosition - 0.5);
  const toY = to[0]?.location?.y - distanceFromElement;

  const turn1Y =
   pin.x !== null
    ? to[0]?.location?.y - distanceFromElement * 2
    : from[0]?.location?.y + from[0]?.height + halfDistanceBetweenElements;

  const turn2X =
   from[0]?.location?.x +
   (from[0]?.width / from[0]?.outputs.length) * (cable?.fromPosition - 0.5);

  const fromY = from[0]?.location?.y + from[0]?.height;

  //   console.log(toX, turn2X, turn2X - toX);

  const isLeft = toX - turn2X >= 0 ? true : false;
  const offsetX =
   toX - turn2X > 7
    ? 3
    : toX - turn2X >= 0
    ? (toX - turn2X) / 2
    : toX - turn2X >= -7
    ? (toX - turn2X) / 2
    : -3;

  //   points = `${toX},${toY} ${toX},${turn1Y} ${turn2X},${turn1Y} ${turn2X},${fromY}`;
  points = `
  M ${toX} ${toY} 
  L ${toX} ${isLeft ? turn1Y + offsetX : turn1Y - offsetX} 
  L ${toX} ${isLeft ? turn1Y - offsetX : turn1Y + offsetX}, ${
   pin.x !== null ? toX : toX - offsetX
  } ${isLeft ? turn1Y - offsetX : turn1Y + offsetX} 
  ${
   pin.x !== null
    ? `L ${pin.x} ${isLeft ? turn1Y - 3 : turn1Y - 3}
    L ${pin.x} ${pin.y}`
    : ""
  }

  ${
   pin2.x !== null
    ? `L ${pin2.x} ${pin.y}
  L ${pin2.x} ${pin2.y}`
    : ""
  }

  H ${pin.x !== null ? turn2X : turn2X + offsetX} 
  
  ${
   pin2.x
    ? ""
    : `L ${turn2X} ${
       pin.y !== null ? pin.y : isLeft ? turn1Y - offsetX : turn1Y + offsetX
      }, ${turn2X} ${
       pin.y !== null
        ? pin.y
        : isLeft
        ? turn1Y - offsetX * 2
        : turn1Y + offsetX * 2
      } `
  }

  V ${fromY} `;
 } else {
  // IF START ELEMENT IS PLACED BELOW THE END
  const toX =
   to[0]?.location?.x +
   (to[0]?.width / to[0]?.inputs.length) * (cable?.toPosition - 0.5);
  const toY = to[0]?.location?.y - distanceFromElement;

  const turn1Y = to[0]?.location?.y - 10 * cable?.toPosition - 10;

  let fromTopRight;
  let toTopLeft;
  let halfBetween;
  // IF END ELEMENT IS LEFT OF THE START
  if (
   from[0]?.location?.x + parseInt(from[0]?.width) <=
   to[0]?.location?.x + to[0]?.width / 2
  ) {
   fromTopRight = from[0]?.location?.x + parseInt(from[0]?.width);
   toTopLeft = to[0]?.location?.x;
   halfBetween = (toTopLeft - fromTopRight) / 2;
  } else {
   // IF END ELEMENT IS RIGHT OF THE START

   fromTopRight = from[0]?.location?.x;
   toTopLeft = to[0]?.location?.x + parseInt(to[0]?.width);
   halfBetween = (toTopLeft - fromTopRight) / 2;
  }

  const point3X = fromTopRight + halfBetween;

  const point4Y = from[0]?.location?.y + from[0]?.height + distanceFromElement;

  const point5X =
   from[0]?.location?.x +
   (from[0]?.width / from[0]?.outputs.length) * (cable?.fromPosition - 0.5);

  const point6Y = from[0]?.location?.y + from[0]?.height;

  const topCurveIsRight = toX >= point3X ? false : true;
  const bottomCurveIsRight = point5X <= point3X ? false : true;

  points = `
  M ${toX} ${toY} 
  L ${toX},${topCurveIsRight ? turn1Y : turn1Y} 
  
  H ${
   pin.x !== null
    ? toX
    : topCurveIsRight
    ? point3X + (cable?.toPosition + cable?.fromPosition * 10)
    : point3X - (cable?.toPosition + cable?.fromPosition * 10)
  }

  ${
   pin2.x !== null
    ? `L ${pin2.x} ${topCurveIsRight ? turn1Y : turn1Y}
      L ${pin2.x} ${pin2.y}
      L ${pin.x} ${pin2.y}
      L ${pin.x} ${pin.y}`
    : ""
  }

  ${
   pin.x !== null
    ? `
     ${pin2.x !== null ? "" : `L ${pin.x} ${topCurveIsRight ? turn1Y : turn1Y}`}
     L ${pin.x} ${pin.y}
     L ${point5X} ${pin.y}`
    : ""
  }

  
  
  L ${
   pin.x !== null
    ? point5X
    : topCurveIsRight
    ? point3X + (cable?.toPosition + cable?.fromPosition * 10)
    : point3X - (cable?.toPosition + cable?.fromPosition * 10)
  },${
   pin.y !== null
    ? pin.y
    : point4Y + (cable?.toPosition + cable?.fromPosition * 10)
  } 
  
  L ${bottomCurveIsRight ? point5X : point5X},${
   pin.y !== null
    ? pin.y
    : point4Y + (cable?.toPosition + cable?.fromPosition * 10)
  } 
  
  L ${point5X},${point6Y}`;
 }

 return {
  points,
  startX: 0,
  startY: 0,
  endX: 0,
  endY: 0,
};
};

export const calculateCablesLTR = (to, from, cable) => {
 let points;

 const distanceFromObject = 6;
 const heightOfInputOutput = 24;

 const fromHeightCalc = () => {
  let heightIn =
   from[0]?.height > from[0]?.inputs.length * heightOfInputOutput
    ? from[0]?.height
    : from[0]?.inputs.length * heightOfInputOutput;

  let heightOut =
   from[0]?.height > from[0]?.outputs.length * heightOfInputOutput
    ? from[0]?.height
    : from[0]?.outputs.length * heightOfInputOutput;

  return heightIn > heightOut ? heightIn : heightOut;
 };

 //  const toHeightCalc = () => {
 //   let heightIn =
 //    to[0]?.height > to[0]?.inputs.length * heightOfInputOutput
 //     ? to[0]?.height
 //     : to[0]?.inputs.length * heightOfInputOutput;

 //   let heightOut =
 //    to[0]?.height > to[0]?.outputs.length * heightOfInputOutput
 //     ? to[0]?.height
 //     : to[0]?.outputs.length * heightOfInputOutput;

 //   return heightIn > heightOut ? heightIn : heightOut;
 //  };

 const fromHeight = fromHeightCalc();
 //  const toHeight = toHeightCalc();

 //  const fromHeight =
 //   from[0]?.height > from[0]?.outputs.length * heightOfInputOutput
 //    ? from[0]?.height
 //    : from[0]?.outputs.length * heightOfInputOutput;

 const halfDistanceBetweenElements =
  (to[0]?.location?.y + from[0]?.location?.y) / 2 +
  4 * cable?.toPosition +
  cable?.fromPosition;

 const startX = to[0]?.location?.x - distanceFromObject;
 const startY = to[0]?.location?.y + cable?.toPosition * 24 - 12;

 let endX;
 let endY;
 if (from[0].type === "shape") {
  endX = from[0]?.location?.x + from[0]?.width;
  endY = from[0]?.location?.y - from[0]?.height;
 } else {
  endX =
   from[0]?.location?.x +
   (from[0]?.inputs.length === 0 || from[0]?.outputs.length === 0 ? 120 : 160) +
   distanceFromObject;

  endY = from[0]?.location?.y + cable?.fromPosition * 24 - 12;
 }

 const flyOffDistance = cable.label ? 80 : 30
 // Handle fly off cables
 if (
  cable?.flyOff
 ) {
  return ({
    points: `
    M${startX} ${startY}
    L${startX - flyOffDistance} ${startY}
    M${endX} ${endY}
    L${endX +flyOffDistance} ${endY}
    `,
    startX,
    startY,
    endX,
    endY,
  }
    );
 }

 // Handle straight lines when items align horizontally
 if (
  startY - endY <= heightOfInputOutput / 2 &&
  endY - startY <= heightOfInputOutput / 2 &&
  !cable?.pin?.x
 ) {
  return {
    points: `
      M${startX} ${startY}
      L${endX - 5} ${startY}
    `,
    startX,
    startY,
    endX,
    endY,
  };
 }

 

 return {points: `
  M${startX} ${startY}
  L${startX - 10 - cable?.toPosition * 4} ${startY}
  L ${
   cable?.pin?.x
    ? `${startX - 10 - cable?.toPosition * 4} ${cable?.pin?.y}`
    : `${startX - 10 - cable?.toPosition * 4} ${halfDistanceBetweenElements}`
  }
  L ${
   cable?.pin?.x
    ? `${cable?.pin?.x} ${cable?.pin?.y}`
    : `${endX + 10 + cable?.fromPosition * 4} ${halfDistanceBetweenElements}`
  }

  ${
   cable?.pin2?.x === null || cable?.pin2?.x === undefined
    ? cable?.pin?.x
      ? `L ${cable?.pin?.x} ${endY}`
      : `L ${endX + 10 + cable?.fromPosition * 4} ${endY}`
    : ""
  }

  ${cable?.pin2?.x ? `L${cable?.pin?.x} ${cable?.pin2?.y}` : ""}
  ${cable?.pin2?.x ? `L${cable?.pin2?.x} ${cable?.pin2?.y}` : ""}
  ${cable?.pin2?.x ? `L${cable?.pin2?.x} ${endY}` : ""}
  
  L${endX - 5} ${endY}
  `,
  startX,
  startY,
  endX,
  endY,
};

};
export default calculateCables;
