import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
 <form className="opacity-0 h-0" noValidate action="" role="search">
  <input
   className="opacity-0 h-0 border-2 px-6 py-2 rounded-md text-lg w-full focus:outline-none focus:ring"
   type="search"
   value={currentRefinement}
   onChange={(event) => refine(event.currentTarget.value)}
  />
  {/* <button onClick={() => refine("")}>Reset query</button> */}
  {/* {isSearchStalled ? "My search is stalled" : ""} */}
 </form>
);

export const CustomSearchBox = connectSearchBox(SearchBox);
