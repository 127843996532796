import { store } from "../db/store";

/** updateEmptyItem
 * @param {object} update - Object including the updates
 */
export const updateEmptyItem = (update) => {
 return store.dispatch({
  type: "UPDATE_EMPTY_ITEM",
  update,
 });
};

/** updateInputOutput
 * @param {string} inOrOut - 'Inputs' or 'Outputs'
 * @param {integar} index - Index of the item to update
 * @param {object} update - Object including the updates
 */
export const updateInputOutput = (inOrOut, index, update) => {
 return store.dispatch({
  type: "UPDATE_INPUT_OUTPUT",
  inOrOut,
  index,
  update,
 });
};

/** addInputOutput
 * @param {string} inOrOut - 'Inputs' or 'Outputs'
 * @param {object} object - Object to add in place of the default
 */
export const addInputOutput = (inOrOut, object = null) => {
 return store.dispatch({
  type: "ADD_INPUT_OUTPUT",
  inOrOut,
  object,
 });
};

/** removeInputOutput
 * @param {object} inOrOut - 'Inputs' or 'Outputs'
 * @param {object} index - Index of the item to update
 */
export const removeInputOutput = (inOrOut, index) => {
 return store.dispatch({
  type: "REMOVE_INPUT_OUTPUT",
  inOrOut,
  index,
 });
};

/** reorderInputOutput
 * @param {string} inOrOut - 'Inputs' or 'Outputs'
 * @param {object} source - Where it
 * @param {object} destination - Index of the item to update
 */
export const reorderInputOutput = (inOrOut, source, destination) => {
 return store.dispatch({
  type: "REORDER_INPUT_OUTPUT",
  inOrOut,
  source,
  destination,
 });
};

/** moveInputOutput
 * @param {object} source - Where it
 * @param {object} destination - Where it is going
 */
export const moveInputOutput = (source, destination) => {
 return store.dispatch({
  type: "MOVE_INPUT_OUTPUT",
  source,
  destination,
 });
};

/** updateWidth
 * @param {integar} width - Width to set the gear item to
 */
export const updateWidth = (width) => {
 return store.dispatch({
  type: "UPDATE_WIDTH",
  width,
 });
};

/** updateHeight
 * @param {integar} height - height to set the gear item to
 */
export const updateHeight = (height) => {
 return store.dispatch({
  type: "UPDATE_HEIGHT",
  height,
 });
};

/** clearEmptyObject
 * Clears the empty object on success, or to restart.
 */
export const clearEmptyObject = () => {
 return store.dispatch({
  type: "CLEAR_EMPTY",
 });
};

/** loadEditItem
 * Clears the empty object on success, or to restart.
 */
export const loadEditItem = (item) => {
 return store.dispatch({
  type: "LOAD_EDIT",
  item,
 });
};
