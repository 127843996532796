const initialState = [
 {
  order: 1,
  id: "ABCDE",
  notificationType: "success",
  title: "Successfully saved!",
  body: "Anyone with a link can now view this file.",
  show: false,
 },
 {
  order: 2,
  id: "EFGHI",
  notificationType: "warn",
  title: "That didn't work!",
  body: "Please try again.",
  show: false,
 },
 {
  order: 3,
  id: "JKLMN",
  notificationType: "fail",
  title: "Terrible news!",
  body: "That really did not work.",
  show: false,
 },
];

export default function notificationsReducer(state = initialState, action) {
 switch (action.type) {
  case "ADD_NOTIFICATION":
   return [
    ...state,
    {
     order: state.length + 1,
     id: action.id,
     notificationType: action.notificationType,
     title: action.title,
     body: action.body,
     show: true,
    },
   ];

  case "HIDE_NOTIFICATION":
   return [
    ...state.map((n) => {
     if (n.id === action.id) {
      return {
       ...n,
       show: false,
      };
     } else {
      return n;
     }
    }),
   ];

  case "CLEAR_NOTIFICATIONS":
   return [];

  default:
   return state;
 }
}
