import React from "react";
import Draggable from "react-draggable";
import "../styles/draggable.css";

import * as planActions from "../actions/planActions";
import { useSelector } from "react-redux";

export default function CablePin({
 planId,
 cableId,
 pinNumber = "",
 x = 0,
 y = 0,
 scale = 1,
 takeSnapshot,
 readOnly,
 getCableColour,
 ltr = false,
}) {
 const gridSize = useSelector((state) => state.plans[planId].gridSize || 1);

 // Hide pins when it's not editable
 if (readOnly) return null;
 return (
  <Draggable
   onDrag={(drag, data) => {
    planActions.updateCable(
     planId,
     cableId,
     {
      [`pin${pinNumber}`]: {
       x: (data.x / gridSize) * gridSize,
       y: (data.y / gridSize) * gridSize,
      },
     },
     false
    );
   }}
   onStop={(e, data) => planActions.savePlan(planId)}
   onStart={takeSnapshot}
   handle=".handle"
   position={{ x: x, y: y }}
   scale={scale}
   disabled={readOnly}
   grid={[gridSize, gridSize]}
  >
   <div
    onDoubleClick={() => {
     if (readOnly) return false;
     planActions.updateCable(planId, cableId, {
      [`pin${pinNumber}`]: {
       x: null,
       y: null,
      },
     });
    }}
    className="handle noPan removeFromPngExport w-5 h-5 rounded-full group hover:opacity-100 hover:bg-red-500 transition duration-300 ease-in-out flex items-center justify-center"
   >
    <span
     style={{
      backgroundColor: getCableColour,
     }}
     className="w-1 h-1 noPan inline-block group-hover:opacity-0"
    ></span>
   </div>
  </Draggable>
 );
}
