import { cableTypes } from "../db/categories";

// MODALS SHOULD BE TRUE BY DEFAULT
const initialState = {
 cableTypes: [...cableTypes],
 marketingEmails: false,
 limits: {
  total: 0,
  limit: 0,
  limitHit: true,
 },
 planName: "",
 planId: "",
 planDetails: {},
};

const changeCable = (state, action, type) => {
 const array = state.map((c) => {
  if (c.name === action.cable.name) {
   if (type === "name") {
    return {
     ...cableTypes.filter((t) => t.name === c.name)[0],
     ...action.cable,
     userDisplayName: action.name,
    };
   } else if (type === "colour") {
    return {
     ...cableTypes.filter((t) => t.name === c.name)[0],
     ...action.cable,
     colour: action.colour,
    };
   } else if (type === "style") {
    return {
     ...cableTypes.filter((t) => t.name === c.name)[0],
     ...action.cable,
     style: action.style,
    };
   }
  } else {
   return c;
  }
 });

 return array;
};

export default function filtersReducer(state = initialState, action) {
 switch (action.type) {
  case "CABLE_NAME":
   return {
    ...state,
    cableTypes: changeCable(state.cableTypes, action, "name"),
   };

  case "CABLE_COLOUR":
   return {
    ...state,
    cableTypes: changeCable(state.cableTypes, action, "colour"),
   };

  case "CABLE_STYLE":
   return {
    ...state,
    cableTypes: changeCable(state.cableTypes, action, "style"),
   };

  case "UPDATE_MARKETING_EMAILS":
   return {
    ...state,
    marketingEmails: action.marketingEmails,
   };

  case "UPDATE_WHATS_NEW":
   return {
    ...state,
    whatsNew: action.whatsNew,
   };

  case "UPDATE_LIMITS":
   return {
    ...state,
    limits: action.update,
   };

  case "UPDATE_SUBSCRIPTION_NAME":
   return {
    ...state,
    planName: action.name,
    planId: action.id,
    planDetails: action.planDetails,
   };

  default:
   return state;
 }
}
