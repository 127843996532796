import { createStore, combineReducers, compose } from "redux";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/performance";
import "firebase/storage";
import "firebase/analytics";
import { firebaseReducer } from "react-redux-firebase";
import {
 reduxFirestore,
 createFirestoreInstance,
 firestoreReducer,
} from "redux-firestore";

import builderReducer from "../reducers/builderReducer";
import plansReducer from "../reducers/plansReducer";
import filtersReducer from "../reducers/filtersReducer";
import packlistsReducer from "../reducers/packlistsReducer";
import gearReducer from "../reducers/gearReducers";
import settingsReducer from "../reducers/settingsReducer";
import notificationsReducer from "../reducers/notificationsReducer";
import teamReducer from "../reducers/teamReducer";
import currentPlanReducer from "../reducers/currentPlanReducer";

const firebaseConfig = {
 apiKey: process.env.REACT_APP_API_KEY,
 authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//  databaseURL: process.env.REACT_APP_DATABASE_URL,
 projectId: process.env.REACT_APP_PROJECT_ID,
 storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
 messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
 appId: process.env.REACT_APP_APP_ID,
 measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const rrfConfig = {
 userProfile: "users",
 useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);
firebase.performance();
export const analytics = firebase.analytics();
// Initialize Cloud Firestore through Firebase
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();

const createStoreWithFirebase = compose(
 reduxFirestore(firebase, rrfConfig) // firebase instance as first argument, rfConfig as optional second
)(createStore);

// Add firebase to reducers
const rootReducer = combineReducers({
 firebase: firebaseReducer,
 firestore: firestoreReducer, // <- needed if using firestore
 plans: plansReducer,
 filters: filtersReducer,
 packlists: packlistsReducer,
 settings: settingsReducer,
 builder: builderReducer,
 gear: gearReducer,
 notifications: notificationsReducer,
 team: teamReducer,
 currentPlan: currentPlanReducer,
});

// Create store with reducers and initial state
const initialState = {};
export const store = createStoreWithFirebase(
 rootReducer,
 initialState,
 window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const rrfProps = {
 firebase,
 config: rrfConfig,
 dispatch: store.dispatch,
 createFirestoreInstance, // <- needed if using firestore
};
