import React, { useState } from "react";

import * as planActions from "../actions/planActions";

export default function CableTextLabel({
 planId,
 from,
 cable,
 readOnly,
 ltr = false,
}) {
 const [editing, setEditing] = useState(false);

 const { length = "", label = "" } = cable;

 return (
  <>
   <span
    onClick={() => {
     if (readOnly) return false;
     setEditing(true);
    }}
    className={`px-0 text-xs text-gray-600 absolute bg-gray-200 dark:bg-gray-700 dark:text-gray-400 text-center  pb-0 cursor-text flex
    ${ltr ? "flex-row pt-0.5 pb-0.5 rounded-r" : "flex-col pt-4 rounded-b"}
    `}
    style={{
     transform: ltr
      ? `translate(${
         from[0]?.location?.x + from[0]?.height + from[0]?.height / 1.5
        }px, ${from[0]?.location?.y + cable.fromPosition * 24 - 22}px)`
      : `translate(${
         from[0]?.location?.x +
         (from[0]?.width / from[0]?.outputs.length) *
          (cable.fromPosition - 0.5) -
         24
        }px, ${from[0]?.location?.y + from[0]?.height - 10}px)`,

     width: `auto`,
     zIndex: "-1",
    }}
   >
    {/* LABEL */}
    {label !== "" || editing === true ? (
     <input
      readOnly={readOnly}
      maxLength={7}
      placeholder="Label"
      id={`cableTextLabel_${cable.id}`}
      type="text"
      onFocus={() => {
       if (readOnly) return false;
       setEditing(true);
      }}
      value={label}
      onInput={(e) => {
       planActions.updateCable(planId, cable.id, { label: e.target.value });
      }}
      className={[
         label.length > 5 ? "text-xxs" : "text-xs",
         ltr ? 'w-11' : 'w-10',
         'font-semibold block  text-center bg-gray-200  dark:bg-gray-700 dark:text-gray-400  focus:bg-white focus:outline-none rounded-sm'
      ].join(' ')}
     />
    ) : null}

    {/* LENGTH */}
    {length !== "" || editing === true ? (
     <input
      readOnly={readOnly}
      maxLength={6}
      placeholder="Length"
      type="text"
      onFocus={() => {
       if (readOnly) return false;
       setEditing(true);
      }}
      value={length}
      onInput={(e) => {
       planActions.updateCable(planId, cable.id, { length: e.target.value });
      }}
      className={[
         "font-light block text-center bg-gray-200 dark:bg-gray-700 dark:text-gray-400  focus:bg-white focus:outline-none rounded-sm",
         ltr ? 'w-6' : 'w-10'
      ].join(' ')}
     />
    ) : null}
    {editing && (
     <div className="flex z-50">
      <button
       onClick={() => {
        planActions.updateCable(planId, cable.id, { label: "", length: "" });
        setTimeout(() => {
         setEditing(false);
        }, 100);
       }}
       className="block mx-auto rounded-sm focus:outline-none focus:ring hover:bg-red-500 hover:text-white"
      >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-3 h-3"
       >
        <path
         fillRule="evenodd"
         d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
         clipRule="evenodd"
        />
       </svg>
      </button>
      <button
       onClick={() => {
        setTimeout(() => {
         setEditing(false);
        }, 100);
       }}
       className="block mx-auto rounded-sm focus:outline-none focus:ring hover:bg-green-500 hover:text-white"
      >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-4 h-4"
       >
        <path
         fillRule="evenodd"
         d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
         clipRule="evenodd"
        />
       </svg>
      </button>
     </div>
    )}
   </span>
  </>
 );
}
