import React, { useState, useEffect } from "react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";

import { connectorTypes } from "../../db/categories";
import { useSelector } from "react-redux";

function classNames(...classes) {
 return classes.filter(Boolean).join(" ");
}

export default function ConnectionCombobox({ selected, onSelect }) {
 const [query, setQuery] = useState("");
 const [selectedConnector, setSelectedConnector] = useState("hdmi");

 //  const connectors = [...connectorTypes];
 const connectors = useSelector((state) => state.settings.cableTypes);

 const handleOnSelect = (choice = connectors[0]) => {
  setSelectedConnector(choice);
  onSelect(choice.name);
 };

 const filteredConnectors =
  query === ""
   ? connectors
   : connectors.filter((connector) => {
      return connector.name.toLowerCase().includes(query.toLowerCase());
     });

 useEffect(() => {
  handleOnSelect(connectors.filter((p) => p.name === selected)[0]);
 }, [selected]);

 return (
  <Combobox as="div" value={selectedConnector} onChange={handleOnSelect}>
   {/* <Combobox.Label className="block text-sm font-medium text-gray-700">
    Type
   </Combobox.Label> */}
   <div className="relative mt-1">
    <Combobox.Input
     className="w-full mt-3 py-3 pl-3 pr-10 border border-gray-400 dark:border-gray-700 rounded-md focus:outline-none focus:ring bg-white dark:bg-gray-900"
     onChange={(event) => setQuery(event.target.value)}
     displayValue={(connector) => {
      if (connector.userDisplayName) {
       return connector.userDisplayName;
      } else {
       return connector.displayName;
      }
     }}
    />
    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
     <SelectorIcon className="mt-3 h-5 w-5 text-gray-400" aria-hidden="true" />
    </Combobox.Button>

    {filteredConnectors.length > 0 && (
     <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-max overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
      {filteredConnectors.map((connector) => (
       <Combobox.Option
        key={connector.name}
        value={connector}
        className={({ active }) =>
         classNames(
          "relative select-none py-2 pl-3 pr-9 cursor-pointer",
          active ? "bg-red-500 text-white" : "text-gray-900"
         )
        }
       >
        {({ active, selected }) => (
         <>
          <span
           className={classNames("block truncate", selected && "font-semibold")}
          >
           {connector.userDisplayName ? (
            <>
             {connector.userDisplayName} ({connector.displayName})
            </>
           ) : (
            connector.displayName
           )}
          </span>

          {selected && (
           <span
            className={classNames(
             "absolute inset-y-0 right-0 flex items-center pr-4",
             active ? "text-white" : "text-indigo-600"
            )}
           >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
           </span>
          )}
         </>
        )}
       </Combobox.Option>
      ))}
     </Combobox.Options>
    )}
   </div>
  </Combobox>
 );
}
