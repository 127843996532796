import React from "react";
import { useSelector } from "react-redux";
import { connectHits } from "react-instantsearch-dom";

const Hits = ({ hits, resultClicked }) => {
 const uid = useSelector((state) => state.firebase.auth.uid);

 if (hits.length === 0) return null;
 else
  return (
   <ol
    style={{ marginTop: "7rem" }}
    className="absolute bg-white dark:bg-gray-900 border-2 border-gray-200 dark:border-gray-700 border-solid shadow-md py-2 rounded-md top-0 w-full z-50"
   >
    {hits.map((hit) => (
     <li className="" key={hit.objectID}>
      <button
       className="w-full flex justify-between px-6 py-3 my-1 hover:bg-gray-200 hover:dark:bg-gray-800 cursor-pointer text-left focus:outline-none focus:ring dark:text-gray-200"
       onClick={() => resultClicked(hit)}
      >
       <span> {hit.name}</span>
       {hit.uid === uid && (
        <span
         className="
       px-2 py-1 rounded text-white text-xs uppercase
      bg-orange-400
      "
        >
         My gear
        </span>
       )}
      </button>
     </li>
    ))}
   </ol>
  );
};

export const CustomHits = connectHits(Hits);
