const initialState = {};

export default function teamReducer(state = initialState, action) {
 switch (action.type) {
  case "ADD_TEAM":
   return {
    ...state,
    ...action.teamData,
   };

  case "EDIT_TEAM_NAME":
   return {
    ...state,
    name: action.newName,
   };

  default:
   return state;
 }
}
