import { firestore } from "../db/store";
import {
 addNotification,
 clearNotifications,
} from "../actions/notificationsActions";

import * as settingsActions from "../actions/settingsActions";

export const saveFirestoreDoc = (id, data, notify = false) => {
 firestore
  .collection("plans")
  .doc(id)
  .update({ ...data[id], lastSaved: new Date() })
  .then((res) => {
   console.log(`Plan saved.`);
   if (notify) {
    clearNotifications();
    addNotification("🙌", "Plan saved successfully.");
   }
  })
  .catch((err) => {
   console.error(err);
   return addNotification("Opps", "Please try saving again.", "error");
  });

 return data;
};

/**
 * saveFirestorePacklistDoc
 * @param  {string} id - Firestore doc id to save
 * @param  {object} data - Data to save
 */
export const saveFirestorePacklistDoc = (id, data) => {
 firestore
  .collection("packlists")
  .doc(id)
  .update({ ...data[id], lastSaved: new Date() })
  .then((res) => {
   console.log(res);
  });

 return data;
};

/**
 * saveUserCableTypes
 * @param  {string} uid - Firestore doc id to save
 * @param  {object} data - Data to save
 */
export const saveUserCableTypes = (uid, data) => {
 const update = { cableTypes: [...data] };
 firestore
  .collection("users")
  .doc(uid)
  .update(update)
  .then((res) => {
   console.log(res);
  });

 return data;
};

/**
 * saveEmailMarketing
 * @param  {string} uid - Firestore doc id to save
 * @param  {boolean} boolean - Data to save
 */
export const saveEmailMarketing = (uid, boolean) => {
 firestore
  .collection("users")
  .doc(uid)
  .update({ marketingEmails: boolean })
  .then((res) => {
   console.log(res);
  });

 return boolean;
};

/**
 * saveUserUpdateModal
 * @param  {string} uid - Firestore doc id to save
 * @param  {object} modal - Modal that was seen
 */
export const saveUserUpdateModal = (uid, modal) => {
 firestore
  .collection("users")
  .doc(uid)
  .update({ welcomeModalShown: true })
  .then((res) => {
   console.log(res);
  });

 return modal;
};

/**
 * getAndUpdateLimits
 * @param  {string} uid - User to check
 * @param  {string} planLimit - Users plan limit, defaults to 2
 */
export const getAndUpdateLimits = async (uid, planLimit = 2, payload = {}) => {
 const currentTotal = await firestore
  .collection("plans")
  .where("author_uid", "==", uid)
  .where("status", "==", "ACTIVE")
  .get()
  .then((res) => {
   return res.docs.length;
  });
  console.log('getAndUpdateLimits', currentTotal)

 let l = currentTotal >= planLimit ? true : false;
 settingsActions.updateLimits({
  total: currentTotal,
  limit: planLimit,
  limitHit: l,
  payload,
 });
};
