import React from "react";
import useThemeSwitcher from "./hooks/useThemeSwitcher";

export default function ThemeSwitcher({ controls = false }) {
 const { currentTheme, setLightTheme, setDarkTheme, respectOSPreference } =
  useThemeSwitcher();

 if (!controls) return null;
 return (
  <div className="flex space-x-2">
   <button
    className={`px-2 py-1 bg-gray-300 text-gray-900 rounded ${
     currentTheme === "light" ? "border border-gray-600" : "opacity-100"
    }`}
    onClick={setLightTheme}
   >
    Light mode
   </button>
   <button
    className={`px-2 py-1 bg-gray-900 text-gray-400 rounded ${
     currentTheme === "dark" ? "border border-gray-500" : "opacity-100"
    }`}
    onClick={setDarkTheme}
   >
    Dark mode
   </button>
   <button
    className={`px-2 py-1 bg-gray-900 text-gray-400 rounded ${
     currentTheme === "" ? "border border-gray-500" : "opacity-100"
    }`}
    onClick={respectOSPreference}
   >
    OS preference
   </button>
  </div>
 );
}
