export default function gearReducer(state = {}, action) {
 let item = action.item;

 switch (action.type) {
  case "ADD_GEAR_ITEM":
   return {
    ...state,
    [action.item.id]: {
     ...item,
    },
   };
  case "EDIT_GEAR_ITEM":
   return {
    ...state,
    [action.itemId]: {
      ...state[action.itemId],
     ...action.update,
    },
   };

  default:
   return state;
 }
}
