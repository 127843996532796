import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import Button from "./Button";

const url =
 "https://heretorecord.us18.list-manage.com/subscribe/post?u=3d96906fbb564fa73cd7e1cef&amp;id=6db782ebc4";

export default function EmailSignup() {
 const [shown, setShown] = useState(false);

 const handleOnClose = () => {
  localStorage.setItem("dismissedEmailSignup", true);
 };

 useEffect(() => {
  if (localStorage.getItem("dismissedEmailSignup")) {
   console.log("should not show");
  } else {
   setShown(true);
  }
 }, []);

 if (!shown) return null;

 return (
  <div className="mt-6 py-2 px-4 bg-gray-300 rounded">
   <div className="flex justify-between items-center space-x-1">
    <span className="pr-1 font-semibold">
     Get updates on H2R Gear releases and improvements
    </span>
    <div className="flex space-x-1">
     <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
       <div>
        {/* <SimpleForm onSubmitted={(formData) => subscribe(formData)} /> */}
        {status === "sending" && (
         <div className="text-gray-900">Sending...</div>
        )}
        {status === "error" && (
         <div
          className="text-gray-900"
          dangerouslySetInnerHTML={{ __html: message }}
         />
        )}
        {status === "success" && (
         <div className="text-gray-900">Confirm via email!</div>
        )}
        {!status && (
         <MyForm
          onSubmitted={(formData) => subscribe(formData)}
          status={status}
         />
        )}
       </div>
      )}
     />
    </div>
    <Button
     text="Close"
     colour={`white`}
     size={`small`}
     onClick={() => {
      handleOnClose();
      setShown(false);
     }}
    />
   </div>
  </div>
 );
}

const MyForm = ({ onSubmitted, status }) => {
 const em = useSelector((state) => state.firebase.auth.email);
 const [email, setEmail] = useState(em);

 const handleSubscribe = (e) => {
  e.preventDefault();

  return onSubmitted({
   EMAIL: email,
  });
 };

 useEffect(() => {
  if (status === "success") {
   setEmail("");
  }
 }, [status]);

 return (
  <form className="flex space-x-2" onSubmit={(e) => handleSubscribe(e)}>
   <input
    type="email"
    name="email"
    id="email"
    required
    className="py-2 px-1 bg-gray-100 rounded text-gray-800 focus:outline-none focus:ring"
    value={email}
    placeholder="Your email"
    onChange={(e) => setEmail(e.target.value)}
   />

   <Button type="submit" text="Sign me up" css={`whitespace-nowrap`} />
  </form>
 );
};
