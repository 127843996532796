import React, { useEffect, useRef } from "react";

const widthMap = {
 extrasmall: 40,
 small: 80,
 medium: 160,
 large: 400,
};
export default function ImgToCanvas({ src, size, useCanvas }) {
 const imgRef = useRef(null);
 const canvasRef = useRef(null);

 useEffect(() => {
  const imgElement = imgRef.current;
  const canvasElement = canvasRef.current;
  const ctx = canvasElement.getContext("2d");
  const img = new Image();

  img.onload = function () {
   ctx.imageSmoothingEnabled = false;
   // Calculate scaling factors to maintain aspect ratio
   const scaleWidth = canvasElement.width / img.width;
   const scaleHeight = canvasElement.height / img.height;
   const scale = Math.min(scaleWidth, scaleHeight);

   // Calculate dimensions for the image on the canvas
   const scaledWidth = img.width * scale;
   const scaledHeight = img.height * scale;

   // Calculate the center position on the canvas
   const x = (canvasElement.width - scaledWidth) / 2;
   const y = (canvasElement.height - scaledHeight) / 2;

   // Draw the image with original aspect ratio
   ctx.drawImage(img, x, y, scaledWidth, scaledHeight);

   // You can add any additional logic here.
  };

  img.src = imgElement.src;
 }, []);

 return (
  <div className="pointer-events-none">
   <img
    ref={imgRef}
    src={src}
    alt="Item"
    style={{
     display: useCanvas ? "none" : "block",
     width: `${widthMap[size]}px`,
    }}
   />
   <canvas
    ref={canvasRef}
    width={widthMap[size]}
    height={"100%"}
    style={{ display: useCanvas ? "block" : "none" }}
   ></canvas>
  </div>
 );
}
