import React from "react";

const capitalize = (s) => {
 if (typeof s !== "string") return "";
 return s.charAt(0).toUpperCase() + s.slice(1);
};

export default function Select({
 name,
 label = "",
 options = [],
 selected = "",
 onSelect,
 css = "",
 valid = true,
 onBlur,
 size = "medium",
}) {
 return (
  <label htmlFor={name} className={`flex flex-col ${css}`}>
   <span className="text-lg text-gray-700 dark:text-gray-500">{label}</span>
   {/* <input
    className={`mt-4 px-5 py-3 border border-gray-400 rounded-md focus:outline-none focus:ring
    ${!valid && "border-red-500"}
    `}
    name={name}
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    required={required}
    checked={checked}
    autoFocus={autoFocus}
   /> */}
   <select
    name={name}
    id={name}
    className={`${
     label !== "" && "mt-4"
    }  bg-gray-100 dark:bg-gray-900 border border-gray-400 dark:border-gray-700 text-gray-900 dark:text-white rounded-md focus:outline-none focus:ring appearance-none
    ${!valid && "border-red-500"}
    ${size === "medium" ? "px-5 py-3" : "px-2 py-1 mt-0"}
    `}
    onChange={(e) => {
     onSelect(e.target.value);
    }}
    value={selected}
    onBlur={onBlur}
   >
    <option value="">Choose...</option>
    {options.map((option) => {
     if (typeof option === "object") {
      return (
       <option key={option.name} value={option.name} disabled={option.disabled}>
        {capitalize(option.displayName)}
       </option>
      );
     } else {
      return (
       <option key={option} value={option}>
        {capitalize(option)}
       </option>
      );
     }
    })}
   </select>
  </label>
 );
}
