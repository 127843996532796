import React from "react";

export default function Logo() {
 return (
  <div className="flex justify-center align-middle items-center">
   {/* <div className="h-6 w-6 rounded bg-red-500"></div> */}
   <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="30" height="30" rx="5" fill="#F56565" />
    <path fill="#C53030" d="M15 21h2v9h-2z" />
    <path fill="#E53E3E" d="M14 21h2v9h-2z" />
    <path fill="#1A202C" d="M12.907 14H15.5v7.259h-2.593z" />
    <path fill="#2D3748" d="M15.5 14h2.593v7.259H15.5z" />
    <path
     fill="#4A5568"
     d="M13.426 13.222h4.148V14h-4.148zM13.426 8.037h4.148v1.037h-4.148z"
    />
    <path fill="#EDF2F7" d="M12.389 7h6.222v1.037h-6.222z" />
    <rect x="12" y="9.074" width="7" height="4.148" rx=".5" fill="#EDF2F7" />
   </svg>
   <div className="ml-4 text-gray-800 dark:text-gray-100 font-bold text-xl">
    H2R Gear
   </div>
  </div>
 );
}
