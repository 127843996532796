import React from "react";

export default function Input({
 name,
 label,
 type,
 value = "",
 onChange,
 placeholder,
 css = "",
 valid = true,
 required = false,
 checked,
 autoFocus = false,
 autoComplete = "off",
 disabled = false,
 enterPressed = () => {},
}) {
 return (
  <label htmlFor={name} className={`flex flex-col ${css}`}>
   <span className="text-lg text-gray-700 dark:text-gray-500">{label}</span>
   <input
    className={`mt-4 px-5 py-3 bg-gray-100 dark:bg-gray-900 border border-gray-400  dark:border-gray-700 rounded-md focus:outline-none focus:ring placeholder:text-gray-500 dark:placeholder:text-gray-700
    ${!valid && "border-red-500"}
    ${disabled ? "text-gray-600 dark:text-gray-500" : "text-gray-900 dark:text-white"}
    `}
    name={name}
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    required={required}
    checked={checked}
    autoFocus={autoFocus}
    autoComplete={autoComplete}
    minLength={type === "password" ? "8" : "0"}
    disabled={disabled}
    onKeyDown={(e) => {
     if (e.key === "Enter") {
      return enterPressed();
     }
    }}
   />
  </label>
 );
}

export function InputCheckbox({
 name,
 label,
 value = "",
 onChange,
 css = "",
 valid = true,
 required = false,
 checked,
 autoFocus = false,
 autoComplete = "off",
 checkboxLabelText = "",
}) {
 return (
  <div className={`flex flex-col ${css}`}>
   <span className="text-lg text-gray-700 dark:text-gray-500">{label}</span>
   <label className="mt-4 px-5 py-3 bg-gray-100 dark:bg-gray-900  border border-solid border-gray-400  dark:border-gray-700 text-gray-900 dark:text-white rounded-md focus:outline-none focus:ring cursor-pointer">
    <input
     htmlFor={name}
     className={`cursor-pointer px-5 border border-gray-400 rounded-md focus:outline-none focus:ring
       ${!valid && "border-red-500"}
       `}
     name={name}
     id={name}
     type="checkbox"
     value={value}
     onChange={onChange}
     required={required}
     checked={checked}
     autoFocus={autoFocus}
     autoComplete={autoComplete}
    />
    <span className="ml-4 text-gray-700 dark:text-gray-100 text-base">
     {checkboxLabelText}
    </span>
   </label>
  </div>
 );
}
