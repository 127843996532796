import React, { useRef, useState } from "react";
import { PanZoom } from "react-easy-panzoom";
import { Helmet } from "react-helmet";
import SketchItem from "./sketch/SketchItem";
import Xarrow, { useXarrow, Xwrapper } from "react-xarrows";
import KeyboardEventHandler from "react-keyboard-event-handler";
import Cable from "./sketch/SketchCable";

// const demoItems = [];

const demoItems = [
 {
  id: 1,
  text: "ATEM Mini Pro ISO",
  colour: "purple-300",
  size: "large",
  location: {
   x: 450,
   y: 450,
  },
  connectors: [1, 1, 1, 1],
  //   img: "/img/atem-mini-pro-iso.png",
 },
 {
  id: 2,
  text: `GH5`,
  colour: "green-300",
  size: "medium",
  location: {
   x: 280,
   y: 190,
  },
  connectors: [1, 1, 1, 1],
  //   img: "/img/gh5.png",
 },
 {
  id: 3,
  text: `G7`,
  colour: "green-300",
  size: "medium",
  location: {
   x: 630,
   y: 190,
  },
  connectors: [1, 1, 1, 1],
 },
 {
  id: 4,
  text: "HDMI\nMonitor",
  colour: "gray-300",
  size: "small",
  location: {
   x: 450,
   y: 700,
  },
  connectors: [1, 1, 1, 1],
 },
 {
  id: 5,
  text: "Netgear\nSwitch",
  colour: "purple-300",
  size: "small",
  location: {
   x: 830,
   y: 420,
  },
  connectors: [1, 1, 1, 1],
 },
 {
  id: 6,
  text: "MacBook Pro",
  colour: "green-300",
  size: "medium",
  location: {
   x: 820,
   y: 200,
  },
  connectors: [1, 1, 1, 1],
  //   img: "/img/macbook.png",
 },
];

// const demoCables = [];

const demoCables = [
 {
  id: 1,
  from: { id: "item_1", anchor: "left" },
  to: { id: "item_2", anchor: "right" },
 },
 {
  id: 2,
  from: { id: "item_3", anchor: "left" },
  to: { id: "item_1", anchor: "right" },
 },
 {
  id: 3,
  from: { id: "item_1", anchor: "left" },
  to: { id: "item_4", anchor: "right" },
 },
 {
  id: 4,
  from: { id: "item_1", anchor: "left" },
  to: { id: "item_5", anchor: "right" },
 },
 {
  id: 5,
  from: { id: "item_6", anchor: "left" },
  to: { id: "item_1", anchor: "right" },
  label: "HDMI",
 },
 {
  id: 6,
  from: { id: "item_6", anchor: "left" },
  to: { id: "item_5", anchor: "right" },
  label: "Ethernet",
 },
];

export default function Sketch() {
 const [panZoomState, setPanZoomState] = useState({
  angle: 0,
  scale: 1,
  x: 0,
  y: 0,
 });
 const [drawing, setDrawing] = useState(false);
 const [mouseStart, setMouseStart] = useState({ x: 0, y: 0 });
 const [mouseEnd, setMouseEnd] = useState({ x: 0, y: 0 });
 const [fromItem, setFromItem] = useState(null);
 const [cables, setCables] = useState([...demoCables]);
 const [items, setItems] = useState([...demoItems]);

 const panZoomRef = useRef();
 const updateXarrow = useXarrow();

 const preventPan = (e, x, y) => {
  if (e.target.id !== "panZoom") {
   return true;
  }
 };

 const onStart = (e, data) => {
  e.preventDefault();
 };

 const onDrag = (e, data, id) => {
  e.preventDefault();
  handleUpdateItem(id, { location: { x: data.x, y: data.y } });
  //   updateXarrow(e, data);
 };

 const onStop = (e, data) => {
  e.preventDefault();
  //   updateXarrow(e, data);
 };

 const handleCableConnectionStart = (e, id) => {
  console.log("handleCableConnectionStart", "Drawing started", panZoomState);
  setDrawing(true);
  setMouseStart({
   x: (e.clientX - panZoomState.x) / panZoomState.scale,
   y: (e.clientY - panZoomState.y) / panZoomState.scale,
  });
  setMouseEnd({
   x: (e.clientX - panZoomState.x) / panZoomState.scale,
   y: (e.clientY - panZoomState.y) / panZoomState.scale,
  });

  setFromItem(id);
  console.log(id);
 };

 const handleCableConnectionEnd = (e, id) => {
  if (id === fromItem) return setDrawing(false);

  setCables([
   ...cables,
   {
    id: cables.length + 1,
    from: {
     id: fromItem,
    },
    to: {
     id: id,
    },
   },
  ]);
  setDrawing(false);
  console.log(cables);
 };

 const handleUpdateItem = (id, update) => {
  console.log("handleUpdateItems", id, update);
  setItems([
   ...items.map((item) => {
    if (item.id !== id) return item;

    return {
     ...item,
     ...update,
    };
   }),
  ]);
 };

 return (
  <div
   onMouseMove={(e) => {
    if (drawing) {
     setMouseEnd({
      x: (e.clientX - panZoomState.x) / panZoomState.scale,
      y: (e.clientY - panZoomState.y) / panZoomState.scale,
     });
    }
   }}
   //    onMouseUp={(e, id) => {
   //     if (!drawing) return null;

   //     return handleCableConnectionEnd(e, id);
   //    }}
   className="bg-gray-100 h-full overflow-hidden"
   style={{ WebkitUserSelect: "none" }}
  >
   <Helmet>
    <title>H2R Gear - Sketch</title>
   </Helmet>

   <button
    onClick={() => {
     setItems([
      ...items,
      {
       id: items.length + 1,
       text: "New item",
       colour: "purple-300",
       size: "large",
       location: {
        x: 450,
        y: 450,
       },
       connectors: [1, 1, 1, 1],
      },
     ]);
    }}
   >
    NEW2
   </button>
   <KeyboardEventHandler
    handleKeys={["esc"]}
    onKeyEvent={(key, e) => {
     if (drawing) return setDrawing(false);
    }}
   />

   <PanZoom
    ref={panZoomRef}
    id="panZoom"
    className="h-screen focus:outline-none"
    preventPan={preventPan}
    // autoCenter={true}
    // disableScrollZoom={true}
    disableDoubleClickZoom={true}
    zoomSpeed={0.4}
    minZoom={0.1}
    maxZoom={1.5}
    disableKeyInteraction={true}
    onStateChange={(change) => {
     updateXarrow();
     if (panZoomRef.current && panZoomRef.current.getContainerBoundingRect()) {
      setPanZoomState(change);
     }
    }}
   >
    <div className="relative z-10 h-auto w-auto">
     {/* {drawing && (
      <svg
       className="text-gray-500 absolute top-0 left-0"
       stroke="currentColor"
       style={{
        position: "absolute",
        overflow: "visible",
        top: 0,
        left: 0,
        zIndex: 0,
       }}
      >
       <polyline
        points={`${mouseStart.x},${mouseStart.y - 20} ${mouseEnd.x},${
         mouseEnd.y
        }`}
        style={{ strokeWidth: "2" }}
       />
      </svg>
     )} */}
     {/* <Xwrapper> */}
     {cables.map((cable) => {
      return (
       <Cable
        key={`cable_${cable.id}`}
        cable={cable}
        panZoomState={panZoomState}
       />
      );
     })}
     {items.map((item) => {
      return (
       <SketchItem
        key={`item_${item.id}`}
        item={item}
        onDrag={(e, data) => onDrag(e, data, item.id)}
        onStart={onStart}
        onStop={onStop}
        scale={panZoomState.scale}
        handleCableConnectionStart={handleCableConnectionStart}
        handleCableConnectionEnd={handleCableConnectionEnd}
        updateItem={handleUpdateItem}
        onDrawing={drawing}
       />
      );
     })}

     {/* </Xwrapper> */}
    </div>
   </PanZoom>
  </div>
 );
}
