import React, { useState } from "react";
import { SketchPicker, GithubPicker } from "react-color";
/**
 * ColourPicker retuns a colour picker input and dropdown for colour selection
 * @param  {string} colour - Colour to use for input/preview
 * @param  {function} onChangeComplete - Returns the selected hex colour
 * @param  {function} onClosePicker - When the dropdown picker closes, this function is returned
 */
export default function ColourPicker({
 colour,
 onChangeComplete,
 onClosePicker,
 relativeParent = false,
 swatchesOnly = false,
 swatchColours = [],
}) {
 const [showPicker, setShowPicker] = useState(false);

 return (
  <div
   className={`flex justify-left items-center bg-white dark:bg-gray-900 rounded-md uppercase text-sm border border-solid border-gray-400 dark:border-gray-700 ${
    !relativeParent && "relative"
   }`}
  >
   <button
    className={`w-8 h-6 rounded inline-block focus:outline-none focus:ring
    ${!swatchesOnly && "ml-2"}
    `}
    style={{ backgroundColor: colour }}
    onClick={() => {
     setShowPicker(!showPicker);
    }}
   ></button>
   {showPicker && (
    <div className="mt-10 absolute right-0 left-0 top-0 flex flex-col z-10">
     <div className="z-30 w-10">
      {swatchesOnly ? (
       <GithubPicker
        color={colour}
        onChangeComplete={(colour) => {
         onChangeComplete(colour.hex);
         setShowPicker(false);
        }}
        colors={swatchColours}
       />
      ) : (
       <SketchPicker
        color={colour}
        onChangeComplete={(colour) => {
         onChangeComplete(colour.hex);
        }}
       />
      )}
     </div>
     <div
      onClick={() => {
       onClosePicker();

       return setShowPicker(false);
      }}
      className="fixed top-0 right-0 bottom-0 w-screen h-screen z-20"
     ></div>
    </div>
   )}
   {!swatchesOnly && (
    <input
     className="ml-2 w-full py-3 px-1 bg-white dark:bg-gray-900 rounded-md focus:outline-none focus:ring"
     value={colour}
     placeholder="#111111"
     onChange={(e) => {
      onChangeComplete(e.target.value);
     }}
     onBlur={onClosePicker}
    />
   )}
  </div>
 );
}
