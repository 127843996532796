import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
/**
 * @param  {number} {progress} - % progress of packing (e.g. 50)
 */
export default function PackProgress({ progress }) {
 return (
  <CircularProgressbarWithChildren
   value={progress}
   className={`w-16 inline-block bg-white dark:bg-gray-900 rounded-full ${
    progress >= 100 ? "text-red-400 " : "text-red-300 dark:text-red-500"
   }`}
   styles={{
    // Customize the root svg element
    root: {},
    // Customize the path, i.e. the "completed progress"
    path: {
     // Path color
     stroke: `currentColor`,
     // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
     strokeLinecap: "round",
     // Customize transition animation
     transition: "stroke-dashoffset 0.5s ease 0s",
    },
    // Customize the circle behind the path, i.e. the "total progress"
    trail: {
     // Trail color
     stroke: "#EDF2F7",
     // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
     strokeLinecap: "butt",
     // Rotate the trail
     transform: "rotate(0.25turn)",
     transformOrigin: "center center",
     background: "red",
    },
    // Customize the text
    text: {
     // Text color
     fill: "#718096",
     // Text size
     fontSize: "16px",
    },
    // Customize background - only used when the `background` prop is true
    background: {
     fill: "black",
    },
   }}
  >
   <div className="text-xl text-gray-600 dark:text-gray-500">
    {Math.floor(progress)}
    {/* <span className="text-xs text-gray-500">%</span> */}
   </div>
   {progress === 100 && (
    <span className="absolute bg-gray-100 dark:bg-gray-900 font-semibold px-1 py-5 rounded-full text-red-400 text-xs transform uppercase">
     Packed
    </span>
   )}
  </CircularProgressbarWithChildren>
 );
}
