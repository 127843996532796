import React, { useState } from "react";
import { useSelector } from "react-redux";

import Layout from "./Layout";
import Input from './Input'
import Button from './Button'

import SettingsCableColours from "./SettingsCableColours";
import { Link } from "react-router-dom";
import ThemeSwitcher from "./ThemeSwitcher";
import { useFirestore } from "react-redux-firebase";

export default function Settings() {
 const cableTypes = useSelector((state) => state.settings.cableTypes);
 const uid = useSelector((state) => state.firebase.auth.uid);
 const user = useSelector((state) => state.firebase.profile);

 const [name, setName] = useState(user?.displayName)
 const [nameChanged, setNameChanged] = useState(false)
 const [saving, setSaving] = useState(false)

 const firestore = useFirestore()

 const handleChangeName = async () => {
    setSaving(true)
    await firestore.collection('users').doc(uid).update({displayName: name})
    setNameChanged(false)
    return setSaving(false)
 }

 return (
  <Layout title="Settings">
   <div className="px-6 py-6 flex flex-row bg-red-500 md:flex-col md:px-0 md:py-0 md:bg-gray-200 md:dark:bg-transparent">
    <div className="flex items-center">
     <h1 className="text-2xl font-bold text-white md:text-gray-800 md:dark:text-gray-100">
      Settings
     </h1>
    </div>
   </div>

   <div className="px-4 mt-6 md:px-0">
    {/* PERSONAL INFO */}
    <h2 className="font-semibold">Personal information</h2>
    <div className="mt-4 flex gap-4 items-end">
        <Input label='Email' value={user.email} disabled={true} />
        <Input label='Name' 
            value={name}
            placeholder="My name"
            onChange={(e) => {
                setNameChanged(true)
                setName(e.target.value)
            }}
        />
       {nameChanged && <Button text={saving ? 'Saving' : 'Save'} onClick={handleChangeName} disabled={saving} />}
    
    </div>
    {/* BILLING INFO */}
    <h2 className="mt-10 font-semibold">Billing and subscription plan</h2>
    <div className="">
     <p className="mt-2 p-2 rounded">
      See your billing history and{" "}
      <Link className="underline" to="/upgrade">
       subscription information here
      </Link>
      .
     </p>
    </div>

    {/* THEME */}
    <h2 className="mt-10 font-semibold">Theme</h2>
    <div className="">
     <div className="mt-2 p-2 rounded">
      <ThemeSwitcher controls={true} />
     </div>
    </div>

    {/* CABLE COLOURS */}
    <div className="mt-10">
     <h2 className="font-semibold">Cable colours</h2>

     <div className="mt-6 px-4 py-2 bg-gray-300 dark:bg-gray-900 rounded flex flex-col justify-between align-middle">
      {cableTypes.map((cable) => {
       return <SettingsCableColours key={cable.name} cable={cable} />;
      })}
     </div>
    </div>
   </div>
  </Layout>
 );
}
