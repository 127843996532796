import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export default function useIsMemberOfTeam() {
 const [isTeamAdmin, setIsTeamAdmin] = useState(false);
 const [isTeamMember, setIsTeamMember] = useState(false);

 const uid = useSelector((state) => state.firebase.auth.uid);
 const partOfTeam = useSelector((state) => state.team) || false;

 useEffect(() => {
  if (partOfTeam?.admin === uid) {
   setIsTeamAdmin(true);
  } else if (partOfTeam?.admin && partOfTeam?.admin !== uid) {
   setIsTeamMember(true);
  }
 }, [partOfTeam.admin, uid]);

 return { isTeamAdmin, isTeamMember };
}
