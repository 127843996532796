import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

import planVideo from "./assets/H2RGear_InAction_Compressed3.mp4";
import planImage from "./assets/plan.png";
import builderImage from "./assets/builder.png";
import GearItem from "../GearItem";
import Button from "../Button";
import PacklistItem from "../PacklistItem";
import PackProgress from "../PackProgress";
import { PricingTable } from "../Upgrade";

const items = [
 {
  _approved: true,
  _checked: true,

  added: {
   seconds: 1609509807,
   nanoseconds: 210000000,
  },
  colour: "#111111",
  cssClassName: "camera",
  height: 94,
  id: "AEtR6DhRhwjAqJUWDnd7",
  inputs: [
   {
    category: "audio",
    name: "Mic",
    type: "minijack",
   },
   {
    category: "audio",
    name: "XLR",
    type: "xlr",
   },
  ],
  location: {
   x: 238,
   y: -59,
  },
  manufacturer: "Blackmagic Design",
  name: "BMPCC 4K",
  outputs: [
   {
    category: "audio",
    name: "Phones",
    type: "minijack",
   },
   {
    category: "video",
    name: "HDMI",
    type: "hdmi",
   },
   {
    category: "data",
    name: "USB",
    type: "usb",
   },
  ],
  searchTerms: [
   "bmpcc",
   "4k",
   "blackmagic",
   "design",
   "pocket",
   "cinema",
   "camera",
  ],
  secondaryColour: "#333333",
  type: "camera",
  width: 150,
 },
 {
  _approved: false,
  _checked: false,

  added: {
   seconds: 1608651191,
   nanoseconds: 14000000,
  },
  colour: "#5d77ad",
  cssClassName: "network",
  height: 94,
  id: "mJVMD9m808ld6pBRKron",
  inputs: [
   {
    category: "network",
    name: "Input 1",
    type: "ethernet",
   },
   {
    category: "network",
    name: "Input 2",
    type: "ethernet",
   },
   {
    category: "network",
    name: "Input 3",
    type: "ethernet",
   },
   {
    category: "network",
    name: "Input 4",
    type: "ethernet",
   },
  ],
  location: {
   x: 435,
   y: -61,
  },
  manufacturer: "Netgear",
  name: "Network Switch",
  outputs: [
   {
    category: "network",
    name: "Output",
    type: "ethernet",
   },
  ],
  searchTerms: ["network", "switch", "netgear"],
  secondaryColour: "#555555",
  type: "network",
  width: 200,
 },
 {
  colour: "#18191a",
  cssClassName: "",
  height: 94,
  id: "K7oQxPAfjVuVpl5fFO8x",
  inputs: [
   {
    category: "video",
    name: "HDMI 1",
    type: "hdmi",
   },
   {
    category: "video",
    name: "HDMI 2",
    type: "hdmi",
   },
   {
    category: "video",
    name: "HDMI 3",
    type: "hdmi",
   },
   {
    category: "video",
    name: "HDMI 4",
    type: "hdmi",
   },
   {
    category: "audio",
    name: "Mic 1",
    type: "minijack",
   },
   {
    category: "audio",
    name: "Mic 2",
    type: "minijack",
   },
   {
    category: "network",
    name: "ETH",
    type: "ethernet",
   },
  ],
  location: {
   x: -171,
   y: -58,
  },
  manufacturer: "Blackmagic Design",
  name: "ATEM Mini Pro",
  outputs: [
   {
    category: "video",
    name: "HDMI",
    type: "hdmi",
   },
   {
    category: "data",
    name: "USB",
    type: "usb",
   },
  ],
  searchTerms: ["atem", "mini", "pro", "blackmagic", "design"],
  secondaryColour: "#fe5543",
  type: "switcher",
  tingItem: true,
  width: 350,
 },
 {
  colour: "#d62525",
  cssClassName: "",
  height: 94,
  id: "C10fAHYQLkR8UvKIGuZ6",
  inputs: [
   {
    category: "video",
    name: "SDI",
    type: "sdi",
   },
   {
    category: "video",
    name: "HDMI",
    type: "hdmi",
   },
  ],
  location: {
   x: -451,
   y: -62,
  },
  manufacturer: "Decimator Design",
  name: "MD-HX",
  outputs: [
   {
    category: "video",
    name: "HDMI ",
    type: "hdmi",
   },
   {
    category: "video",
    name: "SDI 1",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 2",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 3",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 4",
    type: "sdi",
   },
  ],
  searchTerms: ["md-hx", "md", "hx", "decimator", "design"],
  secondaryColour: "#9a1212",
  type: "converter",
  width: 250,
 },
 {
  _approved: true,
  _checked: true,
  added: {
   seconds: 1605971043,
   nanoseconds: 262000000,
  },
  colour: "#141414",
  cssClassName: "",
  height: 94,
  id: "tdDgc1Pj9NYqPA3njtDm",
  inputs: [
   {
    category: "video",
    name: "SDI 1",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 2",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 3",
    type: "sdi",
   },
   {
    category: "video",
    name: "HDMI 3",
    type: "hdmi",
   },
   {
    category: "video",
    name: "HDMI 4",
    type: "hdmi",
   },
   {
    category: "audio",
    name: "Audio",
    type: "rca",
   },
   {
    category: "data",
    name: "USB",
    type: "usb",
   },
   {
    category: "audio",
    name: "Mic",
    type: "minijack",
   },
  ],
  location: {
   x: -883,
   y: -58,
  },
  manufacturer: "Roland",
  name: "V-1SDI",
  outputs: [
   {
    category: "video",
    name: "Preview",
    type: "sdi",
   },
   {
    category: "video",
    name: "Program",
    type: "sdi",
   },
   {
    category: "video",
    name: "Multi-view",
    type: "hdmi",
   },
   {
    category: "audio",
    name: "Audio Out",
    type: "rca",
   },
   {
    category: "audio",
    name: "Phones",
    type: "minijack",
   },
  ],
  searchTerms: ["v-1sdi", "roland", "v1sdi", "v 1sdi"],
  secondaryColour: "#1c6dce",
  type: "switcher",
  width: 400,
 },
 {
  colour: "#0c691a",
  cssClassName: "pcie",
  height: 94,
  id: "ZFMgotR7jaHgtKdKgjyF",
  inputs: [
   {
    category: "video",
    name: "SDI 1",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 2",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 3",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 4",
    type: "sdi",
   },
   {
    category: "video",
    name: "Ref in",
    type: "sdi",
   },
  ],
  location: {
   x: 662.2128743952223,
   y: -62.66464540312845,
  },
  manufacturer: "Blackmagic Design",
  name: "DeckLink Duo 2",
  outputs: [
   {
    category: "video",
    name: "SDI 1",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 2",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 3",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 4",
    type: "sdi",
   },
  ],
  searchTerms: ["decklink", "duo", "2", "bmd", "blackmagic", "design", "pcie"],
  secondaryColour: "#b5b5b5",
  type: "",
  width: 180,
 },
 {
  colour: "#9a9a9a",
  cssClassName: "projector",
  height: 70,
  id: "ZEMXcfXucEF6M0Q0QjZ5",
  inputs: [
   {
    category: "video",
    name: "HDMI",
    type: "hdmi",
   },
   {
    category: "video",
    name: "SDI",
    type: "sdi",
   },
  ],
  location: {
   x: 897.4018673630599,
   y: -61.82617009916051,
  },
  manufacturer: "Generic",
  name: "Projector",
  outputs: [],
  searchTerms: ["projector"],
  secondaryColour: "#9a9a9a",
  type: "projector",
  width: 100,
 },
 {
  colour: "#111111",
  cssClassName: "mic",
  height: 70,
  id: "9dO4Eha2loRU1XUk1Fgd",
  inputs: [],
  location: {
   x: 1054.9103004033557,
   y: -55.051613839362844,
  },
  manufacturer: "",
  name: "Mic (XLR)",
  outputs: [
   {
    category: "audio",
    name: "XLR",
    type: "xlr",
   },
  ],
  searchTerms: ["xlr", "mic", "microphone"],
  secondaryColour: "#222222",
  type: "audio",
  width: 30,
 },
 {
  _approved: true,
  _checked: true,
  added: {
   seconds: 1605973674,
   nanoseconds: 338000000,
  },
  colour: "#111111",
  cssClassName: "wireless",
  height: 70,
  id: "vllblljgNPdjlOxZ2pkV",
  inputs: [],
  location: {
   x: 1131.1240583260799,
   y: -51.66433570946401,
  },
  manufacturer: "Hollyland",
  name: "Mars 400S Pro (RX)",
  outputs: [
   {
    category: "video",
    name: "HDMI",
    type: "hdmi",
   },
   {
    category: "video",
    name: "SDI",
    type: "sdi",
   },
   {
    category: "data",
    name: "USB",
    type: "usb",
   },
  ],
  searchTerms: ["mars", "400s", "pro", "rx", "hollyland", "receiver"],
  secondaryColour: "#4a90e2",
  type: "camera",
  width: 102,
 },
];

const itemsTwo = [
 {
  _approved: true,
  _checked: true,
  added: {
   seconds: 1605973674,
   nanoseconds: 338000000,
  },
  colour: "#111111",
  cssClassName: "wireless",
  height: 70,
  id: "vllblljgNPdjlOxZ2pkV",
  inputs: [],
  location: {
   x: 1131.1240583260799,
   y: -51.66433570946401,
  },
  manufacturer: "Hollyland",
  name: "Mars 400S Pro (RX)",
  outputs: [
   {
    category: "video",
    name: "HDMI",
    type: "hdmi",
   },
   {
    category: "video",
    name: "SDI",
    type: "sdi",
   },
   {
    category: "data",
    name: "USB",
    type: "usb",
   },
  ],
  searchTerms: ["mars", "400s", "pro", "rx", "hollyland", "receiver"],
  secondaryColour: "#4a90e2",
  type: "camera",
  width: 102,
 },
 {
  colour: "#111111",
  cssClassName: "mic",
  height: 70,
  id: "9dO4Eha2loRU1XUk1Fgd",
  inputs: [],
  location: {
   x: 1054.9103004033557,
   y: -55.051613839362844,
  },
  manufacturer: "",
  name: "Mic (XLR)",
  outputs: [
   {
    category: "audio",
    name: "XLR",
    type: "xlr",
   },
  ],
  searchTerms: ["xlr", "mic", "microphone"],
  secondaryColour: "#222222",
  type: "audio",
  width: 30,
 },

 {
  colour: "#18191a",
  cssClassName: "",
  height: 94,
  id: "K7oQxPAfjVuVpl5fFO8x",
  inputs: [
   {
    category: "video",
    name: "HDMI 1",
    type: "hdmi",
   },
   {
    category: "video",
    name: "HDMI 2",
    type: "hdmi",
   },
   {
    category: "video",
    name: "HDMI 3",
    type: "hdmi",
   },
   {
    category: "video",
    name: "HDMI 4",
    type: "hdmi",
   },
   {
    category: "audio",
    name: "Mic 1",
    type: "minijack",
   },
   {
    category: "audio",
    name: "Mic 2",
    type: "minijack",
   },
   {
    category: "network",
    name: "ETH",
    type: "ethernet",
   },
  ],
  location: {
   x: -171,
   y: -58,
  },
  manufacturer: "Blackmagic Design",
  name: "ATEM Mini Pro",
  outputs: [
   {
    category: "video",
    name: "HDMI",
    type: "hdmi",
   },
   {
    category: "data",
    name: "USB",
    type: "usb",
   },
  ],
  searchTerms: ["atem", "mini", "pro", "blackmagic", "design"],
  secondaryColour: "#fe5543",
  type: "switcher",
  tingItem: true,
  width: 350,
 },
 {
  _approved: false,
  _checked: false,

  added: {
   seconds: 1608651191,
   nanoseconds: 14000000,
  },
  colour: "#5d77ad",
  cssClassName: "network",
  height: 94,
  id: "mJVMD9m808ld6pBRKron",
  inputs: [
   {
    category: "network",
    name: "Input 1",
    type: "ethernet",
   },
   {
    category: "network",
    name: "Input 2",
    type: "ethernet",
   },
   {
    category: "network",
    name: "Input 3",
    type: "ethernet",
   },
   {
    category: "network",
    name: "Input 4",
    type: "ethernet",
   },
  ],
  location: {
   x: 435,
   y: -61,
  },
  manufacturer: "Netgear",
  name: "Network Switch",
  outputs: [
   {
    category: "network",
    name: "Output",
    type: "ethernet",
   },
  ],
  searchTerms: ["network", "switch", "netgear"],
  secondaryColour: "#555555",
  type: "network",
  width: 200,
 },

 {
  _approved: true,
  _checked: true,

  added: {
   seconds: 1609509807,
   nanoseconds: 210000000,
  },
  colour: "#111111",
  cssClassName: "camera",
  height: 94,
  id: "AEtR6DhRhwjAqJUWDnd7",
  inputs: [
   {
    category: "audio",
    name: "Mic",
    type: "minijack",
   },
   {
    category: "audio",
    name: "XLR",
    type: "xlr",
   },
  ],
  location: {
   x: 238,
   y: -59,
  },
  manufacturer: "Blackmagic Design",
  name: "BMPCC 4K",
  outputs: [
   {
    category: "audio",
    name: "Phones",
    type: "minijack",
   },
   {
    category: "video",
    name: "HDMI",
    type: "hdmi",
   },
   {
    category: "data",
    name: "USB",
    type: "usb",
   },
  ],
  searchTerms: [
   "bmpcc",
   "4k",
   "blackmagic",
   "design",
   "pocket",
   "cinema",
   "camera",
  ],
  secondaryColour: "#333333",
  type: "camera",
  width: 150,
 },
 {
  colour: "#9a9a9a",
  cssClassName: "projector",
  height: 70,
  id: "ZEMXcfXucEF6M0Q0QjZ5",
  inputs: [
   {
    category: "video",
    name: "HDMI",
    type: "hdmi",
   },
   {
    category: "video",
    name: "SDI",
    type: "sdi",
   },
  ],
  location: {
   x: 897.4018673630599,
   y: -61.82617009916051,
  },
  manufacturer: "Generic",
  name: "Projector",
  outputs: [],
  searchTerms: ["projector"],
  secondaryColour: "#9a9a9a",
  type: "projector",
  width: 100,
 },
 {
  colour: "#0c691a",
  cssClassName: "pcie",
  height: 94,
  id: "ZFMgotR7jaHgtKdKgjyF",
  inputs: [
   {
    category: "video",
    name: "SDI 1",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 2",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 3",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 4",
    type: "sdi",
   },
   {
    category: "video",
    name: "Ref in",
    type: "sdi",
   },
  ],
  location: {
   x: 662.2128743952223,
   y: -62.66464540312845,
  },
  manufacturer: "Blackmagic Design",
  name: "DeckLink Duo 2",
  outputs: [
   {
    category: "video",
    name: "SDI 1",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 2",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 3",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 4",
    type: "sdi",
   },
  ],
  searchTerms: ["decklink", "duo", "2", "bmd", "blackmagic", "design", "pcie"],
  secondaryColour: "#b5b5b5",
  type: "",
  width: 180,
 },
 {
  _approved: true,
  _checked: true,
  added: {
   seconds: 1605971043,
   nanoseconds: 262000000,
  },
  colour: "#141414",
  cssClassName: "",
  height: 94,
  id: "tdDgc1Pj9NYqPA3njtDm",
  inputs: [
   {
    category: "video",
    name: "SDI 1",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 2",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 3",
    type: "sdi",
   },
   {
    category: "video",
    name: "HDMI 3",
    type: "hdmi",
   },
   {
    category: "video",
    name: "HDMI 4",
    type: "hdmi",
   },
   {
    category: "audio",
    name: "Audio",
    type: "rca",
   },
   {
    category: "data",
    name: "USB",
    type: "usb",
   },
   {
    category: "audio",
    name: "Mic",
    type: "minijack",
   },
  ],
  location: {
   x: -883,
   y: -58,
  },
  manufacturer: "Roland",
  name: "V-1SDI",
  outputs: [
   {
    category: "video",
    name: "Preview",
    type: "sdi",
   },
   {
    category: "video",
    name: "Program",
    type: "sdi",
   },
   {
    category: "video",
    name: "Multi-view",
    type: "hdmi",
   },
   {
    category: "audio",
    name: "Audio Out",
    type: "rca",
   },
   {
    category: "audio",
    name: "Phones",
    type: "minijack",
   },
  ],
  searchTerms: ["v-1sdi", "roland", "v1sdi", "v 1sdi"],
  secondaryColour: "#1c6dce",
  type: "switcher",
  width: 400,
 },
 {
  colour: "#d62525",
  cssClassName: "",
  height: 94,
  id: "C10fAHYQLkR8UvKIGuZ6",
  inputs: [
   {
    category: "video",
    name: "SDI",
    type: "sdi",
   },
   {
    category: "video",
    name: "HDMI",
    type: "hdmi",
   },
  ],
  location: {
   x: -451,
   y: -62,
  },
  manufacturer: "Decimator Design",
  name: "MD-HX",
  outputs: [
   {
    category: "video",
    name: "HDMI ",
    type: "hdmi",
   },
   {
    category: "video",
    name: "SDI 1",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 2",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 3",
    type: "sdi",
   },
   {
    category: "video",
    name: "SDI 4",
    type: "sdi",
   },
  ],
  searchTerms: ["md-hx", "md", "hx", "decimator", "design"],
  secondaryColour: "#9a1212",
  type: "converter",
  width: 250,
 },
];

export default function Home() {
 const history = useHistory();

 //  useEffect(() => {
 //   return () => {
 //    document.getElementById("launcher").remove();
 //   };
 //  }, []);

 return (
  <>
   <header className="bg-red-500 text-white flex flex-col-reverse md:flex-row justify-center pt-10 md:pt-20 items-center md:items-start">
    {/* <Zendesk
     defer={true}
     zendeskKey={ZENDESK_KEY}
     {...zendeskSettings}
     onLoaded={() => console.log("is loaded")}
    /> */}
    <div className="mt-10 md:mt-0">
     <svg
      width="350"
      height="295"
      viewBox="0 0 350 295"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
     >
      <rect x="11" y="96" width="14" height="199" fill="#C53030" />
      <rect x="11" y="96" width="9" height="199" fill="#E53E3E" />
      <rect
       x="4.68262"
       y="58.8662"
       width="13.3787"
       height="37.4603"
       fill="#1A202C"
      />
      <rect
       x="18.061"
       y="58.8662"
       width="13.3787"
       height="37.4603"
       fill="#2D3748"
      />
      <rect
       x="7.3584"
       y="54.8526"
       width="21.4059"
       height="4.01361"
       fill="#4A5568"
      />
      <rect
       x="7.3584"
       y="28.0952"
       width="21.4059"
       height="5.35147"
       fill="#4A5568"
      />
      <rect
       x="2.00684"
       y="22.7438"
       width="32.1088"
       height="5.35147"
       fill="#EDF2F7"
      />
      <rect
       y="33.4467"
       width="36.1224"
       height="21.4059"
       rx="5"
       fill="#EDF2F7"
      />
      <line
       x1="18.561"
       y1="35.4535"
       x2="18.561"
       y2="52.1769"
       stroke="#4A5568"
      />
      <line
       x1="9.86523"
       y1="35.4535"
       x2="9.86523"
       y2="52.1769"
       stroke="#4A5568"
      />
      <line
       x1="27.2573"
       y1="35.4535"
       x2="27.2573"
       y2="52.1769"
       stroke="#4A5568"
      />
      <line
       x1="32.3589"
       y1="35.4535"
       x2="32.3589"
       y2="52.1769"
       stroke="#4A5568"
       strokeWidth="0.5"
      />
      <line
       x1="4.26367"
       y1="35.4535"
       x2="4.26367"
       y2="52.1769"
       stroke="#4A5568"
       strokeWidth="0.5"
      />
      <rect
       x="115.057"
       y="104.354"
       width="13.3787"
       height="190.646"
       fill="#C53030"
      />
      <rect
       x="115.057"
       y="104.354"
       width="8.69615"
       height="190.646"
       fill="#E53E3E"
      />
      <rect
       x="107.029"
       y="36.7914"
       width="29.4331"
       height="24.0816"
       rx="4"
       fill="#4A5568"
      />
      <path
       d="M103.685 56.8594H121.746V104.354H111.122L103.685 75.0938V56.8594Z"
       fill="#1A202C"
      />
      <path
       d="M139.807 56.8594H121.746V104.354H132.37L139.807 75.0938V56.8594Z"
       fill="#2D3748"
      />
      <rect
       x="110.374"
       y="36.7914"
       width="22.7438"
       height="20.068"
       fill="#EDF2F7"
      />
      <rect
       x="115.057"
       y="44.8186"
       width="4.01361"
       height="4.01361"
       fill="#4A5568"
      />
      <rect
       x="124.421"
       y="44.8186"
       width="4.01361"
       height="4.01361"
       fill="#4A5568"
      />
      <rect
       x="220.749"
       y="104.354"
       width="13.3787"
       height="190.646"
       fill="#C53030"
      />
      <rect
       x="220.749"
       y="104.354"
       width="8.69615"
       height="190.646"
       fill="#E53E3E"
      />
      <rect
       x="208.708"
       width="37.4603"
       height="60.2041"
       rx="2"
       fill="#EDF2F7"
      />
      <path
       d="M206.701 56.8594H227.438V133.787H215.239L206.701 77.2619L206.701 56.8594Z"
       fill="#1A202C"
      />
      <path
       d="M248.175 56.8594H227.438V133.787H239.636L248.175 76.9274V56.8594Z"
       fill="#2D3748"
      />
      <rect
       x="213.39"
       y="50.839"
       width="28.0952"
       height="1.33787"
       fill="#4A5568"
      />
      <rect
       x="222.755"
       y="3.34467"
       width="9.36508"
       height="4.01361"
       fill="#4A5568"
      />
      <rect
       x="328.447"
       y="104.354"
       width="13.3787"
       height="190.646"
       fill="#C53030"
      />
      <rect
       x="328.447"
       y="104.354"
       width="8.69615"
       height="190.646"
       fill="#E53E3E"
      />
      <rect
       x="331.123"
       y="11.3719"
       width="8.02721"
       height="48.8322"
       rx="2"
       fill="#EDF2F7"
      />
      <path
       d="M321.088 56.8594H335.136V120.408H326.873L321.088 73.7136L321.088 56.8594Z"
       fill="#1A202C"
      />
      <path
       d="M349.184 56.8594H335.136V120.408H343.399L349.184 73.4373V56.8594Z"
       fill="#2D3748"
      />
      <rect
       x="332.46"
       y="26.7574"
       width="5.35147"
       height="1.33787"
       fill="#4A5568"
      />
      <rect
       x="332.46"
       y="15.3855"
       width="5.35147"
       height="2.0068"
       fill="#4A5568"
      />
      <rect
       x="332.46"
       y="9.36507"
       width="5.35147"
       height="2.0068"
       fill="#4A5568"
      />
      <path
       d="M337.484 9.69954H333.123C332.018 9.69954 331.12 8.80098 331.278 7.70784C331.701 4.78941 332.987 1.33786 335.136 1.33786C337.286 1.33786 338.788 4.79225 339.295 7.71143C339.485 8.79968 338.589 9.69954 337.484 9.69954Z"
       fill="#E2E8F0"
      />
     </svg>
    </div>
    <div className="md:ml-20 space-y-6 md:space-y-10">
     <h1 className="text-4xl md:text-5xl font-bold">H2R Gear</h1>
     <p className="text-lg md:text-xl text-red-100">
      Plan, diagram and pack
      <br />
      for your next gig.
     </p>
     <div className="flex space-x-2">
      <SignupButton />
      <Button text="or Login" onClick={() => history.push("/plans")} />
     </div>
    </div>
   </header>
   <HomeSection
    title="Make a plan"
    subtitle="Add all sorts of gear items, connect cables and see how it all fits together."
    image={planImage}
    video={planVideo}
   />
   <HomeSection
    bgColour="bg-white"
    title="Community library"
    subtitle="Get going fast with gear added by other users."
   >
    <div
     className="h-24 grid mt-20"
     style={{ gridTemplateColumns: "repeat(10, max-content)" }}
    >
     {items.map((item, i) => {
      return (
       <div
        key={i}
        className="relative"
        style={{ width: `${item.width + 40}px` }}
       >
        <GearItem
         opacity={item.opacity}
         gearItem={{ ...item, location: { ...item.location, x: item.width } }}
        />
       </div>
      );
     })}
    </div>
    <div
     className="h-20 grid mt-10"
     style={{ gridTemplateColumns: "repeat(10, max-content)" }}
    >
     {itemsTwo.map((item, i) => {
      return (
       <div
        key={i}
        className="relative"
        style={{ width: `${item.width + 40}px` }}
       >
        <GearItem
         opacity={item.opacity}
         gearItem={{ ...item, location: { ...item.location, x: item.width } }}
        />
       </div>
      );
     })}
    </div>
   </HomeSection>
   <HomeSection
    title="Item builder"
    subtitle="Can’t find an item? Build it!"
    image={builderImage}
    backgroundSize="contain"
   />
   <HomeCTA />
   <HomeSection
    title="Know what to pack"
    subtitle="Items and cables in your plan will show up automatically on your packlist."
    bgColour="bg-white"
   >
    <HomePacklist />
   </HomeSection>

   <HomeSection
    title="Simple pricing"
    subtitle="Keeping it easy, so you can get to work."
   >
    <PricingTable demo={true} />
    <div className="mt-6">
     <p className="mb-4">And best of all, it's free to get started.</p>
     <SignupButton />
    </div>
   </HomeSection>
   <HomeSection
    bgColour="bg-white"
    title="And loads more..."
    subtitle="Customisable cable colours, sharable plans, cable lengths and tags."
   />
   <HomeFooter />
  </>
 );
}

const HomeSection = ({
 bgColour = "bg-gray-100",
 title = "Example title",
 subtitle = "This is an example subtitle.",
 image = null,
 video = null,
 backgroundSize = "cover",
 children,
}) => {
 return (
  <section
   className={`${bgColour} flex flex-col justify-center items-center py-16 relative overflow-hidden px-10 text-gray-800`}
  >
   <h2 className="text-3xl text-gray-800 font-semibold">{title}</h2>
   <p className="text-lg text-gray-700 mt-3">{subtitle}</p>
   {video && (
    <div className="hidden sm:flex mt-12 max-w-5xl rounded">
     <video
      className="rounded h-60 sm:h-96"
      src={video}
      loop
      autoPlay
      muted
     ></video>
    </div>
   )}
   {image && (
    <div
     style={{
      backgroundImage: `url(${image})`,
      backgroundPosition: "center",
      backgroundSize,
      backgroundRepeat: "no-repeat",
     }}
     className={`mt-20 h-80 w-full ${video ? "visible sm:hidden" : "visible"}`}
    ></div>
   )}
   {children}
  </section>
 );
};

const HomeCTA = () => {
 return (
  <section className="bg-red-500 text-white flex justify-center items-center py-20 overflow-hidden">
   <div className="leading-10">
    <h3 className="text-3xl font-bold">Give it a try</h3>
    <p>It's free to get started.</p>
   </div>
   <div className="ml-20">
    <SignupButton />
   </div>
  </section>
 );
};

const packlistItems = [
 {
  id: "ITEM_1",
  text: "ATEM Mini Pro ISO",
  packed: true,
 },
 {
  id: "ITEM_2",
  text: "HDMI Monitor",
  packed: true,
 },
 {
  id: "ITEM_3",
  text: "Wireless Lav Mic",
  packed: false,
 },
 {
  id: "ITEM_4",
  text: "Camera",
  packed: false,
 },
 {
  id: "ITEM_5",
  text: "Tripod",
  packed: false,
 },
 {
  id: "ITEM_6",
  text: "6x HDMI Cables",
  packed: false,
 },
];

const HomePacklist = () => {
 const [items, setItems] = useState([...packlistItems]);
 const [percentPacked, setPercentPacked] = useState(0);

 useEffect(() => {
  let total = 0;
  items.map((item) => {
   if (item.packed) {
    return total++;
   }
  });

  setPercentPacked((total / items.length) * 100);
 }, [items]);

 return (
  <div className="mt-20 rounded border border-solid border-gray-300 bg-white w-80 ">
   <div className="border-b-2 border-solid border-gray-300 flex justify-between items-center">
    <h3 className="px-6 py-5 text-xl font-bold">Packlist</h3>
    <div className="transform scale-75 ml-4">
     <PackProgress progress={percentPacked} />
     <div className="pt-2 text-gray-500 text-xs font-light uppercase text-right">
      % Packed
     </div>
    </div>
   </div>
   <div className="px-6 py-5 ">
    {items.map((item) => {
     return (
      <PacklistItem
       key={item.id}
       item={item}
       toggleCheckbox={() => {
        return setItems(
         ...[
          items.map((i) => {
           if (i.id === item.id) {
            return {
             ...i,
             packed: !i.packed,
            };
           } else {
            return { ...i };
           }
          }),
         ]
        );
       }}
       removable={false}
      />
     );
    })}
   </div>
  </div>
 );
};

export const HomeFooter = () => {
 return (
  <footer className="bg-red-400 pt-6 pb-6 text-white text-sm text-center">
   <div className="max-w-xl mx-auto">
    Made by John at Here to Record.{" "}
    <a
     href="https://heretorecord.com/about/"
     target="_blank"
     rel="noopener noreferrer"
     className="underline"
    >
     Say hello
    </a>
    !
   </div>
   <Link to="/terms" className="underline">
    Terms and cookies
   </Link>
   .
  </footer>
 );
};

const SignupButton = () => {
 const history = useHistory();

 return (
  <Button
   text="Sign up"
   onClick={() => {
    history.push("/signup");
   }}
   colour="white"
  />
 );
};
