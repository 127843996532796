import React, { useState, useEffect } from "react";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Layout from "./Layout";
import * as filterActions from "../actions/filterActions";
import GearCard from "./GearCard";
import GearCards from "./GearCards";
import Button from "./Button";
import { AddGearModal } from "./Modal";

export default function MyGear() {
 const [isLoading, setIsLoading] = useState(true);
 const [showGearModal, setShowGearModal] = useState(false);

 const firestore = useFirestore();
 const history = useHistory();

 const uid = useSelector((state) => state.firebase.auth.uid);
 const filters = useSelector((state) => state.filters);
 const gearItems = useSelector((state) => state.firestore.data.user_items);

 useEffect(() => {
  setIsLoading(true);
  firestore.onSnapshot(
   {
    collection: "users",
    doc: uid,
    subcollections: [{ collection: "items" }],
    storeAs: "user_items",
   },
   (doc) => {
    // console.log(doc);
    setIsLoading(false);
   }
  );
  // eslint-disable-next-line
 }, []);

 return (
  <Layout title="My gear">
   <div className="px-6 py-6 flex flex-row bg-red-500 md:flex-col md:px-0 md:py-0 md:bg-gray-200 md:dark:bg-transparent">
    <div className="flex items-center">
     <h1 className="text-2xl font-bold text-white md:text-gray-800">My gear</h1>
     <div className="ml-8 hidden md:block">
      {/* <Button
       text="Add gear"
       icon="add"
       onClick={() => history.push("/builder")}
      /> */}

      <Button
       text="Add gear"
       icon="add"
       onClick={() => setShowGearModal(true)}
      />

      {showGearModal && (
       <AddGearModal
        addToPlan={false}
        onCancel={() => setShowGearModal(false)}
       />
      )}
     </div>
    </div>

    <div className="relative">
     <input
      type="text"
      className="mt-6 px-6 h-12 w-full rounded-lg focus:outline-none focus:shadow-lg hidden md:block md:bg-gray-100 md:focus:bg-white"
      placeholder="Search gear..."
      onChange={(e) => filterActions.updateGearFilterText(e.target.value)}
      value={filters.gearText}
     />
    </div>

    {/* TABS */}
    <div className="px-4 pb-4 flex justify-start overflow-auto text-white bg-red-500 md:text-gray-600 md:pt-6 md:pb-0 md:bg-gray-200 md:justify-start">
     <button
      className={`ml-2 px-4 py-3 border-b-2 border-solid flex justify-center items-center md:ml-0 md:hover:text-gray-800 focus:outline-none focus:border-red-200 md:focus:border-red-400 md:border-gray-200 ${
       filters.gearTab === "all"
        ? "px-4 py-3 text-white border-white font-semibold flex justify-center items-center md:text-gray-800 md:hover:text-gray-800 md:border-red-500"
        : "border-red-500 md:border-gray-200"
      }`}
      onClick={() => {
       filterActions.updateGearFilterTab("all");
      }}
     >
      <svg className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path
        d="M5.833 5.833h8.334m1.666 3.334H4.167h11.666zm0 0a1.666 1.666 0 011.667 1.666v5a1.666 1.666 0 01-1.667 1.667H4.167A1.667 1.667 0 012.5 15.833v-5a1.667 1.667 0 011.667-1.666h11.666zm0 0V7.5a1.667 1.667 0 00-1.666-1.667l1.666 3.334zm-11.666 0V7.5a1.667 1.667 0 011.666-1.667L4.167 9.167zm1.666-3.334V4.167A1.667 1.667 0 017.5 2.5h5a1.667 1.667 0 011.667 1.667v1.666H5.833z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
       />
      </svg>
      <div className="ml-2 whitespace-no-wrap">All</div>
     </button>
     <button
      className={`ml-2 px-4 py-3 border-b-2 border-solid flex justify-center items-center md:hover:text-gray-800 focus:outline-none focus:border-red-200 md:focus:border-red-400 ${
       filters.gearTab === "private"
        ? "px-4 py-3 text-white border-white font-semibold flex justify-center items-center md:text-gray-800 md:hover:text-gray-800 md:border-red-500"
        : "border-red-500 md:border-gray-200"
      }`}
      onClick={() => {
       filterActions.updateGearFilterTab("private");
      }}
     >
      <svg className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path
        d="M10 12.5v1.667V12.5zm-5 5h10a1.667 1.667 0 001.667-1.667v-5A1.667 1.667 0 0015 9.167H5a1.667 1.667 0 00-1.667 1.666v5A1.666 1.666 0 005 17.5zm8.333-8.333V5.833a3.333 3.333 0 00-6.666 0v3.334h6.666z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
       />
      </svg>
      <div className="ml-2 whitespace-no-wrap">Private</div>
     </button>
     <button
      className={`ml-2 px-4 py-3 border-b-2 border-solid flex justify-center items-center md:hover:text-gray-800 focus:outline-none focus:border-red-200 md:focus:border-red-400 md:border-gray-200 ${
       filters.gearTab === "public"
        ? "px-4 py-3 text-white border-white font-semibold flex justify-center items-center md:text-gray-800 md:hover:text-gray-800 md:border-red-500"
        : "border-red-500 md:border-gray-200"
      }`}
      onClick={() => {
       filterActions.updateGearFilterTab("public");
      }}
     >
      <svg className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path
        d="M6.667 9.167V5.833a3.333 3.333 0 016.666 0M10 12.5v1.667M5 17.5h10a1.667 1.667 0 001.667-1.667v-5A1.667 1.667 0 0015 9.167H5a1.667 1.667 0 00-1.667 1.666v5A1.666 1.666 0 005 17.5z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
       />
      </svg>
      <div className="ml-2 whitespace-no-wrap">Public</div>
     </button>

     {/*   <button className="ml-2 px-4 py-3 flex justify-center items-center md:hover:text-gray-800">
     <svg className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
       fill="currentColor"
       d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm0 6a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zm11-1a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
      />
     </svg>
     <div className="ml-2 whitespace-no-wrap">Templates</div>
    </button> */}
    </div>
    {isLoading ? (
     <div className={"mt-6"}>
      <GearCard empty={true} />
     </div>
    ) : gearItems === null || gearItems === undefined ? (
     <p className="italic mt-10 text-gray-600">No gear items to show.</p>
    ) : (
     <GearCards isLoading={isLoading} />
    )}
   </div>
  </Layout>
 );
}
