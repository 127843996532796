import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// export const isActive = (payload) => {
//  const now = new Date();

//  const active = ["active", "trialing"].includes(payload?.status);
//  const deadline =
//   payload?.cancellation_effective_date || payload?.next_bill_date;
//  const isBeforeDeadline = deadline ? now < new Date(deadline) : false;
//  return active || isBeforeDeadline;
// };

export const addDays = (payload) => {
 const dateOfPurchase = new Date(payload.event_time);
 // Adding 8 days for a buffer
 const deadline = dateOfPurchase.setDate(dateOfPurchase.getDate() + 10);

 return deadline;
};

export const isActive = (payload) => {
 const now = new Date();
 // Product
 if (payload?.product_id) {
  const deadline = addDays(payload);
  const isBeforeDeadline = deadline ? now < new Date(deadline) : false;
  return isBeforeDeadline;
 }

 // Subscription
 const active = ["active", "trialing"].includes(payload?.status);
 const deadline =
  payload?.cancellation_effective_date || payload?.next_bill_date;
 const isBeforeDeadline = deadline ? now < new Date(deadline) : false;
 return active || isBeforeDeadline;
};

export default function useSubscriptionStatus() {
 const [isSubscribed, setIsSubscribed] = useState(false);

 //  const subscriptionPlanName =
 //   useSelector((state) => state.settings.planName) || "Starter";

 const subscriptionPlan = useSelector((state) => state.settings?.planDetails);

 const isActiveSubscription = isActive(subscriptionPlan);

 useEffect(() => {
  setIsSubscribed(isActiveSubscription);
 }, [subscriptionPlan]);

 return isSubscribed;
}
