import React from "react";
import { Link } from "react-router-dom";

import Logo from "./branding/Logo";

function NotFound() {
 return (
  <div className="flex flex-col justify-center bg-red-500 h-screen w-screen">
   <div className="px-12 py-10 flex flex-col max-w-sm bg-white dark:bg-gray-900 mx-auto rounded-lg">
    <div className="flex flex-col mb-5 text-center">
     <Logo />

     <span className="mt-8 mb-10 h-1 border bg-gray-200 dark:bg-gray-700"></span>

     <span className="text-center text-gray-700 dark:text-gray-300 animate-bounce">
      404 - Page not found{" "}
      <span role="img" aria-label="thinking-emoji">
       🤔
      </span>
     </span>
     <Link
      to="/plans"
      className="mt-4 underline text-gray-700 dark:text-gray-300"
     >
      Try logging in
     </Link>
    </div>
   </div>
  </div>
 );
}

export default NotFound;
