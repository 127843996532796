import React, { Fragment, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { createPortal } from "react-dom";
import { Menu, Item, Separator, useContextMenu } from "react-contexify";

import { biDirectionalCables } from "../db/categories";
import CableTextLabel from "./CableTextLabel";
import * as planActions from "../actions/planActions";
import calculateCables, {
 calculateCablesLTR,
} from "../helpers/calculateCables";

function Portal({ children }) {
 return createPortal(children, document.querySelector("#cable-menu"));
}

export default function Cable({
 plan,
 cable,
 panZoomState,
 onCableMouseEnter,
 connectedDevicesAndCables,
 setConnectedDevicesAndCables,
 getCableStyle,
 readOnly,
 getCableColour,
 handleAddCableLabel,
 takeSnapshot,
 ltr,
}) {
 //  const start = cable?.fromItem;
 //  const end = cable?.toItem;
 const auth = useSelector((state) => state.firebase.auth);

 const filteredFrom = useMemo(
  () => plan?.items?.filter((item) => item.id === cable?.fromItem),
  [cable?.fromItem, plan?.items]
 );
 const filteredTo = useMemo(
  () => plan?.items?.filter((item) => item.id === cable?.toItem),
  [cable?.toItem, plan?.items]
 );

 if (
  cable?.fromPosition > filteredFrom[0]?.outputs.length ||
  cable?.toPosition > filteredTo[0]?.inputs.length
 ) {
  console.log("removing cable");
  planActions.removeCable(plan.id, cable?.id);
  // return null;
 }

 const points = useMemo(
  () =>
   plan?.ltr
    ? calculateCablesLTR(filteredTo, filteredFrom, cable)
    : calculateCables(filteredTo, filteredFrom, cable),
  [cable, filteredFrom, filteredTo, plan?.ltr]
 );

 const connectedCable =
  connectedDevicesAndCables.length === 0
   ? "1"
   : connectedDevicesAndCables?.includes(cable?.id)
   ? "1"
   : "0.15";

 const { show } = useContextMenu({
  id: cable?.id,
 });

 const addCablePin = useCallback(
  (e, pinNumber = "") => {
   let x = (e.clientX - panZoomState.positionX) / panZoomState.scale;
   let y = (e.clientY - panZoomState.positionY - 20) / panZoomState.scale;
   return planActions.updateCable(plan.id, cable?.id, {
    [`pin${pinNumber}`]: {
     x,
     y,
    },
   });
  },
  [
   cable?.id,
   panZoomState.positionX,
   panZoomState.positionY,
   panZoomState.scale,
   plan.id,
  ]
 );

 const removeCablePin = (pinNumber = "") => {
  return planActions.updateCable(plan.id, cable?.id, {
   [`pin${pinNumber}`]: {
    x: null,
    y: null,
   },
  });
 };

 const handleRemoveLabel = () => {
  return planActions.updateCable(plan.id, cable?.id, { label: "", length: "" });
 };

 const handleAddRemoveflyoff = (bool) => {
  return planActions.updateCable(plan.id, cable?.id, { flyOff: bool });
 };

 const handleItemClick = ({ event }) => {
  switch (event.currentTarget.id) {
   case "add_pin":
    takeSnapshot();
    addCablePin(event);
    break;

   case "remove_pin":
    takeSnapshot();
    removeCablePin();
    break;

   case "add_pin_2":
    takeSnapshot();
    addCablePin(event, 2);
    break;

   case "remove_pin_2":
    takeSnapshot();
    removeCablePin(2);
    break;

   case "add_label":
    takeSnapshot();
    handleAddCableLabel(cable?.id);
    break;

   case "remove_label":
    takeSnapshot();
    handleRemoveLabel();
    break;

   case "duplicate":
    //   duplicateGearItem(gearItem.id);
    break;

   case "fly_off":
     takeSnapshot();
     handleAddRemoveflyoff(true)
     //   duplicateGearItem(gearItem.id);
     break;
     
     case "fly_off_remove":
       takeSnapshot();
       handleAddRemoveflyoff(false)

    //   duplicateGearItem(gearItem.id);
    break;
   case "delete":
    takeSnapshot();
    planActions.removeCable(plan.id, cable?.id);
    break;

   default:
    break;
  }
 };

 const displayMenu = useCallback(
  (e) => {
   if (readOnly) return null;
   show(e);
  },
  [readOnly]
 );

 const memoizedCable = useMemo(
  () => (
   <CableInternals
    uid={auth.uid}
    cable={cable}
    connectedCable={connectedCable}
    points={points}
    onCableMouseEnter={onCableMouseEnter}
    setConnectedDevicesAndCables={setConnectedDevicesAndCables}
    getCableStyle={getCableStyle}
    getCableColour={getCableColour}
    filteredFrom={filteredFrom}
    filteredTo={filteredTo}
    readOnly={readOnly}
    ltr={ltr}
    displayMenu={displayMenu}
    planId={plan?.id}
    authorUid={plan?.author_uid}
   />
  ),
  [
   auth.uid,
   cable,
   connectedCable,
   displayMenu,
   filteredFrom,
   filteredTo,
   getCableColour,
   getCableStyle,
   ltr,
   onCableMouseEnter,
   plan?.author_uid,
   plan?.id,
   points,
   readOnly,
   setConnectedDevicesAndCables,
  ]
 );

 if (!cable) return null;

 return (
  <>
   {memoizedCable}
   <Portal>
    <Menu id={cable?.id} style={{ zIndex: "1000" }}>
     {cable?.pin?.x && cable?.pin?.y ? (
      <Item id="remove_pin" disabled={cable?.pin2?.x} onClick={handleItemClick}>
       Remove pin
      </Item>
     ) : (
      <Item id="add_pin" onClick={handleItemClick}>
       Add pin
      </Item>
     )}

     {cable?.pin2?.x && cable?.pin2?.y ? (
      <Item id="remove_pin_2" onClick={handleItemClick}>
       Remove second pin
      </Item>
     ) : cable?.pin?.x ? (
      <Item id="add_pin_2" onClick={handleItemClick}>
       Add second pin
      </Item>
     ) : (
      ""
     )}
     
     {cable?.label ? (
      <Item id="remove_label" onClick={handleItemClick}>
       Remove label
      </Item>
     ) : (
      <Item id="add_label" onClick={handleItemClick}>
       Add label
      </Item>
     )}
     {
       plan?.ltr && 
       <>
       <Separator />
      {cable?.flyOff ? (
      <Item id="fly_off_remove" onClick={handleItemClick}>
       Show cable route
      </Item>
     ) : (
      <Item id="fly_off" onClick={handleItemClick}>
       Hide cable route
      </Item>
     )}
     </>
     }

     {/* <Item id="duplicate" onClick={handleItemClick}>
      Duplicate
     </Item> */}
     <Separator />
     <Item id="delete" onClick={handleItemClick}>
      Delete cable
     </Item>
    </Menu>
   </Portal>
  </>
 );
}

function CableInternals({
 uid,
 cable,
 connectedCable,
 points,
 onCableMouseEnter,
 setConnectedDevicesAndCables,
 getCableStyle,
 getCableColour,
 filteredFrom,
 filteredTo,
 readOnly,
 ltr,
 displayMenu,
 planId,
 authorUid,
 planSharing,
}) {
 const user = useSelector((state) => state.firebase.profile);

 const cableStyle = getCableStyle(cable);

 const cableColour = getCableColour(cable?.fromType || cable?.type);

 return (
  <Fragment>
   <svg
    style={{
     position: "absolute",
     overflow: "visible",
     top: 0,
     left: 0,
     zIndex: -1,
     opacity: connectedCable,
     transition: "500ms opacity ease-in-out",
    }}
   >
    <path
     d={points.points}
     onContextMenu={displayMenu}
     onClick={displayMenu}
     className="pointer-events-auto text-transparent hover:text-gray-300 transition-colors duration-150 ease-in-out cursor-pointer"
     strokeLinejoin="round"
     style={{
      fill: "none",
      stroke: "currentColor",
      strokeWidth: "10",
      strokeLinejoin: "round",
     }}
     onMouseDown={(e) => {
      if (e.button !== 0) return null;
      if (readOnly) return false;

      // addCablePin(e);
     }}
     //  onMouseEnter={() => {
     //   onCableMouseEnter(cable);
     //  }}
     //  onMouseLeave={() => {
     //   setConnectedDevicesAndCables([]);
     //  }}
    />
    <path
     data-tour="cable"
     d={points.points}
     strokeLinejoin="round"
     strokeDasharray={
      cableStyle === "solid" || cableStyle === "double"
       ? null
       : cableStyle === "dashed"
       ? "6,6"
       : "2,6"
     }
     style={{
      fill: "none",
      stroke: cableColour,
      strokeWidth: "1.5",
      strokeLinejoin: "round",
     }}
     // markerStart={`url(#arrow_${
     //  filteredTo[0]?.type === "shape"
     //   ? "shape"
     //   : filteredTo[0]?.inputs[cable?.toPosition - 1]?.type
     // })`}
     markerStart={`url(#${
      biDirectionalCables.includes(cable?.type) ? "startArrow" : "arrow"
     }_${
      filteredTo[0]?.type === "shape"
       ? "shape"
       : filteredTo[0]?.inputs[cable?.toPosition - 1]?.type
     })`}
     markerEnd={`url(#startArrow_${
      filteredFrom[0]?.type === "shape"
       ? "shape"
       : filteredFrom[0]?.outputs[cable?.fromPosition - 1]?.type
     })`}
    />
   </svg>
   <CableTextLabel
    planId={planId}
    from={filteredFrom}
    cable={cable}
    ltr={ltr}
    readOnly={
     uid === authorUid ||
     (user.partOfTeam === authorUid)
      ? false
      : true
    }
   />
   {
    cable?.flyOff && 
    <>
    <div style={{
    top: points.startY - 5.5,
    left: points.startX - 35,
    opacity: connectedCable,
   }} className="absolute transition-opacity text-[10px] pointer-events-none text-right">
    <span className="p-0.5 rounded-sm text-gray-900 dark:text-white bg-gray-200 dark:bg-gray-800 absolute right-[100%] whitespace-nowrap">→ {filteredFrom?.[0]?.name} ({filteredFrom?.[0]?.outputs?.[cable?.fromPosition - 1]?.name})</span>
   </div>
   <div style={{
    top: points.endY - 5.5,
    left: points.endX + (cable?.label ? 85 : 35),
    opacity: connectedCable,
   }} className="absolute transition-opacity text-[10px] pointer-events-none">
    <span className="p-0.5 rounded-sm text-gray-900 dark:text-white bg-gray-200 dark:bg-gray-800  whitespace-nowrap">→ {filteredTo?.[0]?.name} ({filteredTo?.[0]?.inputs?.[cable?.toPosition - 1]?.name})</span>
   </div>
   </>
   }
  </Fragment>
 );
}
